const SessionTwoTaskOne = ({
    latestSessionThreeTaskOne,
    latestTaskOne,
    latestTaskTwo,
}) => {
    if (!latestTaskOne || !latestTaskOne.content) {
        return <p>No data found</p>;
    }

    if (!latestSessionThreeTaskOne || !latestSessionThreeTaskOne.content) {
        return <p>Focus solution not found</p>;
    }

    if (!latestTaskTwo || !latestTaskTwo.content) {
        return <p>Focus problem not found</p>;
    }
    const contentArray = Object.entries(latestTaskOne.content).filter(
        (content) => typeof content[1] !== "string"
    );
    const focusProblem = latestTaskTwo.content.focus_problem;
    const focusSolution = latestSessionThreeTaskOne.content.focus_solution;

    return (
        <>
            <div>
                {contentArray.map((content) => {
                    const sdg = content[1].sdg;
                    const value = content[1].value;
                    if (value === focusProblem) {
                        return (
                            <div className="my-3 w-full rounded-md border border-purple-500 p-2 max-w-[320px] min-w-[320px]">
                                <div className="flex flex-row items-center">
                                    <img
                                        src="/images/problemImage.svg"
                                        className="aspect-square h-7"
                                    />
                                    <h3 className="ml-3 text-purple-500">
                                        The Problem
                                    </h3>
                                </div>

                                <div className={`my-3 w-auto rounded-md p-2 `}>
                                    <p className="my-5 ml-3">{value}</p>

                                    <div className="ml-3 flex flex-row items-center rounded-md bg-purple-100 p-2">
                                        <div className="flex-0.2 rounded-full bg-purple-300 px-2 py-0.5 text-xs font-semibold text-slate-800">
                                            SDG
                                        </div>
                                        <p className="flex-0.8 ml-4">{sdg}</p>
                                    </div>
                                </div>

                                <div className="mt-5 flex flex-row items-center">
                                    <img
                                        src="/images/problemImageFocus.svg"
                                        className="aspect-square h-7"
                                    />
                                    <h3 className="ml-3 text-[#51CF66]">
                                        The Solution
                                    </h3>
                                </div>
                                <div className="my-5 ml-3">{focusSolution}</div>
                            </div>
                        );
                    }
                })}
            </div>
        </>
    );
};

export default SessionTwoTaskOne;
