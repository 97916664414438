import {
    FaBold,
    FaItalic,
    FaListOl,
    FaListUl,
    FaRedo,
    FaUndo,
    FaStrikethrough,
} from "react-icons/fa";
import { type Editor } from "@tiptap/react";
import TypographyDropdown from "./TypographyDropdown";
import clsx from "clsx";

interface EditorBarProps {
    editor: Editor | null;
}

interface ToolbarAction {
    label: string;
    onClick: (editor: Editor) => void;
    isActive: (editor: Editor) => boolean;
    isDisabled: (editor: Editor) => boolean;
    content: () => React.ReactElement;
}

const actions = [
    {
        label: "Bold",
        content: () => <FaBold />,
        onClick: (editor) => editor.chain().focus().toggleBold().run(),
        isDisabled: (editor) => !editor.can().toggleBold(),
        isActive: (editor) => editor.isActive("bold"),
    },
    {
        label: "Italic",
        content: () => <FaItalic />,
        onClick: (editor) => editor.chain().focus().toggleItalic().run(),
        isDisabled: (editor) => !editor.can().toggleItalic(),
        isActive: (editor) => editor.isActive("italic"),
    },
    {
        label: "Strikethrough",
        content: () => <FaStrikethrough />,
        onClick: (editor) => editor.chain().focus().toggleStrike().run(),
        isDisabled: (editor) => !editor.can().toggleStrike(),
        isActive: (editor) => editor.isActive("strike"),
    },
    {
        label: "Unordered list",
        content: () => <FaListUl />,
        onClick: (editor) => editor.chain().focus().toggleBulletList().run(),
        isDisabled: (editor) => !editor.can().toggleBulletList(),
        isActive: (editor) => editor.isActive("bulletList"),
    },
    {
        label: "Ordered list",
        content: () => <FaListOl />,
        onClick: (editor) => editor.chain().focus().toggleOrderedList().run(),
        isDisabled: (editor) => !editor.can().toggleOrderedList(),
        isActive: (editor) => editor.isActive("orderedList"),
    },
    {
        label: "Undo change",
        content: () => <FaUndo />,
        onClick: (editor) => editor.chain().focus().undo().run(),
        isDisabled: (editor) => !editor.can().undo(),
        isActive: (editor) => editor.isActive("undo"),
    },
    {
        label: "Redo change",
        content: () => <FaRedo />,
        onClick: (editor) => editor.chain().focus().redo().run(),
        isDisabled: (editor) => !editor.can().redo(),
        isActive: (editor) => editor.isActive("redo"),
    },
] satisfies ToolbarAction[];

const EditorBar = ({ editor }: EditorBarProps) => {
    if (editor === null) {
        return null;
    }

    return (
        <section className="relative flex gap-4 bg-white border border-b-0 border-gray-300 rounded-t-md">
            <div className="flex divide-x divide-gray-300">
                <TypographyDropdown editor={editor} />

                {actions.map((action, idx) => (
                    <button
                        key={idx}
                        title={action.label}
                        type="button"
                        onClick={() => action.onClick(editor)}
                        disabled={action.isDisabled(editor)}
                        className={clsx(
                            "px-3 py-1.5 transition-colors  focus:outline-transparent focus:ring-1 focus:ring-inset focus:ring-purple-600 disabled:cursor-not-allowed disabled:bg-gray-200/50",
                            action.isActive(editor)
                                ? "bg-purple-100 hover:bg-purple-200"
                                : "hover:bg-purple-50"
                        )}
                    >
                        {action.content()}
                    </button>
                ))}
            </div>
        </section>
    );
};

export default EditorBar;
