import type { SchoolAdmin, Student, User } from "@src/models/types";
import axios from "./axios";
import { type QueryOptions } from "@tanstack/react-query";
import { UserFactory } from "@src/models";

export async function profile() {
    return await axios.get<User | Student | SchoolAdmin>(
        "/accounts/user/profile/"
    );
}

export function profileQueryOptions<TUserType = User>() {
    return {
        queryKey: ["profile"],
        queryFn: async () =>
            await profile().then((res) => UserFactory(res.data) as TUserType),
    } satisfies QueryOptions;
}

export async function updateUser(data: User) {
    return await axios.put("/accounts/user/profile/", data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export interface PasswordChangePayload {
    old_password: string;
    new_password: string;
    new_password_confirm: string;
}

export async function passwordChange(data: PasswordChangePayload) {
    return await axios.post<{ msg: string }>(
        "/accounts/user/password-change/",
        data
    );
}
