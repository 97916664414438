import PageHeader from "@src/components/PageHeader";
import TeamForm from "@src/containers/team/TeamForm";
import { useNavigate, redirect } from "react-router-dom";
import { type Student } from "@src/models/types";
import { type QueryClient } from "@tanstack/react-query";
import { profileQueryOptions } from "@src/api/user";

const breadcrumbPaths = [
    { name: "My Team", href: "/student/team" },
    { name: "Create Team", href: "/student/team/create" },
];

export function loader(queryClient: QueryClient) {
    return async () => {
        const user = await queryClient.ensureQueryData(
            profileQueryOptions<Student>()
        );

        const team = user.team();

        if (team !== null) {
            return redirect("/student/team");
        }

        return null;
    };
}

const CreateTeamPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                title={`Create Team`}
                breadcrumbPaths={breadcrumbPaths}
            />

            <section className="px-4 pt-6 pb-12 max-w-7xl sm:px-6 md:px-8">
                <div className="max-w-4xl px-4 py-6 bg-white rounded-md shadow sm:px-6 md:px-8">
                    <TeamForm
                        className="sm:max-w-3xl"
                        onSuccess={() => {
                            navigate("/student/team/edit");
                        }}
                    />
                </div>
            </section>
        </>
    );
};

export default CreateTeamPage;
