import {
    Link,
    useNavigate,
    useRouteError,
    useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import useStore from "@store/useStore";
import axios from "axios";
import { useNavigatorOnLine } from "@src/hooks/useNavigatorOnline";

function is401UnAuthorizedError(error) {
    if (!axios.isAxiosError(error)) {
        return false;
    }

    if (!error.response) {
        return false;
    }

    return error.response.status === 401;
}

export default function RootErrorPage() {
    const error = useRouteError();
    const logout = useStore((state) => state.logout);
    const navigate = useNavigate();
    const isOnline = useNavigatorOnLine();
    const location = useLocation();

    if (import.meta.env.DEV) {
        console.error(error);
    }

    useEffect(() => {
        (async () => {
            if (is401UnAuthorizedError(error)) {
                await logout();
                navigate("/", { replace: true });
            }
        })();
    }, []);

    useEffect(() => {
        const errorMsg = error.message;
        // Navigate to the previous path wne user comes back online
        if (isOnline && errorMsg === "Network Error") {
            const lastPath = location.pathname;
            navigate(lastPath);
        }
    }, [isOnline]);

    return (
        <main className={"min-h-screen bg-gray-50"}>
            <header className={"mx-auto max-w-7xl px-4 pt-4 sm:px-6 md:px-8"}>
                <Link to={"/"}>
                    <img
                        className="h-16"
                        src="/images/kineticp.png"
                        alt="Kinetic Platform"
                    />
                </Link>
            </header>
            <section className="rounden-md mx-auto mt-10 flex max-w-xl flex-col items-center justify-center bg-white px-4 py-10 shadow-md sm:px-6 md:px-8">
                <h1
                    className={
                        "text-center text-2xl font-semibold text-gray-900"
                    }
                >
                    We are sorry, an unexpected error has occurred.
                </h1>
                <p className={"mt-4 text-lg text-gray-700"}>{error.message}</p>
            </section>
        </main>
    );
}
