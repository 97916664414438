import { Dialog, Transition } from "@headlessui/react";
import { useRouteLoaderData } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import Pusher from "pusher-js";
import Button from "@components/Button";
import type { User } from "@src/models/types";

const BadgeModal = () => {
    const user = useRouteLoaderData("root") as User;

    const [isOpen, setIsOpen] = useState(false);
    const [playAnimation, setPlayAnimation] = useState(true);

    interface PusherData {
        animation_url: string;
        poster_url: string;
        animation_length: number;
        name: string;
        description: string;
        multiplier: number;
        event: string;
    }

    const [pusherData, setPusherData] = useState<PusherData>({
        animation_url: "/gifs/level-1/collaborator.gif",
        poster_url: "/images/collaborator.svg",
        animation_length: 2700,
        name: "badge-name",
        description: "badge-text",
        multiplier: 5,
        event: "received-badge",
    });

    const pusherKey = import.meta.env.VITE_PUSHER_API_KEY as string;

    const closeModal = () => {
        setPlayAnimation(true);
        setIsOpen(false);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    useEffect(() => {
        const pusher = new Pusher(pusherKey, {
            cluster: "eu",
        });

        const channel = pusher.subscribe(user?.id.toString());

        channel.bind("Badges", function (data: PusherData) {
            setPusherData(data);
            openModal();

            // animation timer
            setTimeout(() => {
                // Update state after 3000 milliseconds (3 seconds)
                setPlayAnimation(false);
                console.log("set animation false", playAnimation);
            }, pusherData?.animation_length);

            console.log("received badge", data);
        });

        return () => {
            pusher.unsubscribe(user.id.toString());
        };
    }, [pusherData]);

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10 " onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/75" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-1/2 transform overflow-hidden rounded-2xl bg-white p-6 py-[100px] text-center align-middle shadow-xl transition-all"
                                style={{ backgroundColor: "#fbfbfb" }}
                            >
                                <div className="flex justify-center">
                                    <div className="relative">
                                        <img
                                            className="md:h-[250px]"
                                            src={
                                                playAnimation
                                                    ? pusherData?.animation_url
                                                    : pusherData?.poster_url
                                            }
                                            alt="Collaborator"
                                            // style={{ height: "250px" }}
                                        />
                                        {pusherData?.multiplier !== 0 &&
                                            !playAnimation && (
                                                <div className="absolute bottom-0 right-0 float-right mr-[40px] flex justify-center rounded-full bg-gray-200 px-5">
                                                    <span className="flex items-center justify-center rounded-full p-2 text-3xl text-gray-500">
                                                        x
                                                        {pusherData?.multiplier}
                                                    </span>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="py-5">
                                    <p className="text-xl text-secondary-500">
                                        You earned the {pusherData?.name} Badge.
                                        Congrats!
                                    </p>
                                    <p className="text-base text-gray-500">
                                        {pusherData?.description}
                                    </p>
                                </div>

                                <div className="mt-5">
                                    <Button
                                        size="lg"
                                        intent="primary"
                                        variant="contained"
                                        className="bg-primary shadow-2xl"
                                        type="button"
                                        title="Continue"
                                        onClick={closeModal}
                                    >
                                        Continue
                                        <span className="sr-only">
                                            Continue
                                        </span>
                                    </Button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default BadgeModal;
