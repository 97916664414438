import SessionFiveTaskTwo from "./SessionFiveTaskTwo";

const SessionFive = ({ sessionFiveTasks = [], sessionTwoTasks = [] }) => {
    const task2Submissios = sessionFiveTasks.filter(
        (task) => task.task_identifier === "task_2"
    );

    const sessionTwoTask2Submissios = sessionTwoTasks.filter(
        (task) => task.task_identifier === "task_2"
    );

    const sortedSessionTwoTaskTwo = sessionTwoTask2Submissios.sort((a, b) => {
        return new Date(a.created) - new Date(b.created);
    });

    const sortedTaskTwo = task2Submissios.sort((a, b) => {
        return new Date(a.created) - new Date(b.created);
    });

    const latestTaskTwo = sortedTaskTwo[sortedTaskTwo.length - 1];
    const latestSessionTwoTaskTwo =
        sortedSessionTwoTaskTwo[sortedSessionTwoTaskTwo.length - 1];

    return (
        <SessionFiveTaskTwo
            latestTask={latestTaskTwo}
            latestSessionTwoTaskTwo={latestSessionTwoTaskTwo}
        />
    );
};

export default SessionFive;
