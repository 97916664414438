import styled from "styled-components";

export const KineticTopLeftLogo = styled.img`
    position: absolute;
    height: 200px;
    width: 240px;
    top: 10%;
    left: 10%;
    src: ${(props) => props.src};
`;

export const KineticBottomRightLogo = styled.img`
    position: absolute;
    height: 200px;
    width: 240px;
    bottom: 10%;
    right: 10%;
    src: ${(props) => props.src};
`;
