import { currentTeamQueryOptions, deleteTeam } from "@src/api/teams";
import { profileQueryOptions } from "@src/api/user";
import Button from "@src/components/Button";
import { GeneralError } from "@src/components/Form";
import UserSearchBox from "@src/components/Form/UserSearchBox";
import PageHeader from "@src/components/PageHeader";
import SavingIndicator from "@src/components/SavingIndicator";
import TeamForm from "@src/containers/team/TeamForm";
import UploadTeamLogo from "@src/containers/team/UploadTeamLogo";
import TeamList from "@src/containers/team/TeamList";
import useTeamActions from "@src/hooks/useTeamActions";
import type { Team, Student } from "@src/models/types";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import {
    type QueryClient,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
    type FieldValues,
    type UseFormSetError,
    useForm,
} from "react-hook-form";
import {
    redirect,
    useLoaderData,
    useNavigate,
    useRouteLoaderData,
} from "react-router-dom";

const breadcrumbPaths = [
    { name: "My Team", href: "/student/team" },
    { name: "Update Team", href: "/student/team/edit" },
];

export function loader(queryClient: QueryClient) {
    return async () => {
        const user = await queryClient.ensureQueryData(
            profileQueryOptions<Student>()
        );

        const team = user.team();

        if (team == null) {
            return redirect("/student/team");
        }

        return team;
    };
}

const EditTeamPage = () => {
    const navigate = useNavigate();

    const initialUser = useRouteLoaderData("root") as Student;

    const { data: user } = useQuery({
        ...profileQueryOptions<Student>(),
        initialData: initialUser,
    });

    const initialTeam = useLoaderData() as Team;

    const { data: team } = useQuery({
        ...currentTeamQueryOptions(initialTeam.id),
        initialData: initialTeam,
    });

    const [newMember, setNewMember] = useState<Student | null>(null);

    const { addMember } = useTeamActions(user);

    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            members: team?.members.map((member) => member.id) ?? [],
        },
    });

    const navigateToSessions = () => {
        navigate("/student/content");
    };

    useEffect(() => {
        clearErrors();
    }, [newMember]);

    const queryClient = useQueryClient();

    async function addNewMember() {
        if (team === undefined || newMember === null) {
            return;
        }

        try {
            await addMember(newMember.id);

            setNewMember(null);
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    }

    async function deleteTeamAction() {
        const teamName = prompt("Enter the team name to confirm deletion:");

        if (teamName !== null && teamName !== team.team_name) {
            return;
        }

        await deleteTeam(team.id);

        await queryClient.invalidateQueries({ queryKey: ["profile"] });
        queryClient.removeQueries({ queryKey: ["currentTeam"] });

        navigate("/student/team");
    }

    return (
        <>
            <PageHeader
                title={`Update ${team.team_name}`}
                breadcrumbPaths={breadcrumbPaths}
            />

            <section className="max-w-7xl px-4 pb-16 pt-6 sm:px-6 md:px-8">
                <section className="max-w-4xl rounded-md bg-white px-4 py-6 shadow sm:px-6 md:px-8">
                    <h2 className="text-2xl font-semibold">
                        Upload Team Photo
                    </h2>
                    <UploadTeamLogo />
                </section>

                <section className="mt-8 max-w-4xl rounded-md bg-white px-4 py-6 shadow sm:px-6 md:px-8">
                    <h2 className="text-2xl font-semibold">Team Details</h2>
                    <TeamForm team={team} className="mt-4 sm:max-w-3xl" />
                </section>

                <section className="mt-8 max-w-4xl rounded-md bg-white px-4 py-6 shadow sm:px-6 md:px-8">
                    <h2 className="text-2xl font-semibold">Team Members</h2>

                    <GeneralError
                        className={"mt-4"}
                        message={errors.members?.message}
                    />

                    <section className="mt-4">
                        <form
                            className="flex items-end space-x-4"
                            onSubmit={handleSubmit(addNewMember)}
                        >
                            <UserSearchBox
                                key={team.members.length}
                                label="Search for user"
                                selectedUser={newMember}
                                setSelectedUser={setNewMember}
                                setError={
                                    setError as UseFormSetError<FieldValues>
                                }
                            />

                            <Button type="submit" intent="secondary">
                                Add Member
                            </Button>
                        </form>

                        {isSubmitting && <SavingIndicator />}

                        <div className="mt-8 sm:w-4/6 md:max-w-xl">
                            <TeamList user={user} team={team} />
                        </div>

                        <div
                            className="mt-3 w-fit"
                            onClick={navigateToSessions}
                        >
                            <Button type="submit" intent="secondary">
                                Start Sessions
                            </Button>
                        </div>
                    </section>
                </section>

                {team.owner_id === user.id && (
                    <section className="mt-8 max-w-4xl rounded-md bg-white px-4 py-6 shadow sm:px-6 md:px-8">
                        <h2 className="text-2xl font-semibold">
                            Delete this team
                        </h2>
                        <div className="mt-1 flex justify-between space-x-8">
                            <p className="flex-1 text-lg leading-tight text-gray-700">
                                Once you delete this team there is no going
                                back, please be sure. All data associated to the
                                team will be lost.
                            </p>

                            <div>
                                <Button
                                    intent="danger"
                                    onClick={deleteTeamAction}
                                >
                                    Delete this team
                                </Button>
                            </div>
                        </div>
                    </section>
                )}
            </section>
        </>
    );
};

export default EditTeamPage;
