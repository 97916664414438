import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSessionProgress } from "@src/api/students";
import tasks from "../../../../containers/activities/SessionOne/tasks_schema.json";
import PageHeader from "@src/components/PageHeader";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import "../../../../components/Loaders/loader.css";

const SessionOnePage = () => {
    const [noOfComments, setNoOfComments] = useState(0);

    const location = useLocation();
    const { state } = location;
    const { task, member, team } = state;

    const { isLoading, data } = useQuery({
        queryKey: ["students", "session-progress", member.id],
        queryFn: async () => await getSessionProgress(member.id),
        enabled: Boolean(member.id),
    });

    const navigate = useNavigate();

    const handleBackClicked = () => navigate(-1);

    const breadcrumbPaths = [
        {
            name: `Team Submissions`,
            href: `/school/teams/${team.id}/submissions`,
        },
    ];

    const taskData = data ? data[0].submissions : {};
    const taskName = task.name;
    const currentTaskData = taskData[taskName];

    // IF no submissions
    if (!currentTaskData) {
        return (
            <>
                <PageHeader
                    title="Ideation and Formation Part I"
                    breadcrumbPaths={breadcrumbPaths}
                />

                <section className="mx-auto max-w-7xl space-y-8 bg-white px-4 pb-12 pt-6 sm:px-6 md:px-8">
                    <section className="max-w-5xl rounded-md px-4 py-6 shadow sm:px-6 md:px-8">
                        <div className="flex w-full flex-row items-center">
                            <div
                                className="flex h-6 w-9 cursor-pointer items-center justify-center rounded-md bg-slate-300"
                                onClick={handleBackClicked}
                            >
                                <ArrowLeftIcon className="h-4 w-4" />
                            </div>
                            <h2 className="ml-2 text-2xl font-semibold">
                                {member.full_name}
                            </h2>
                        </div>

                        <div className="w-full items-center justify-center p-5">
                            {isLoading ? (
                                <div className="flex w-full items-center justify-center p-4">
                                    <span className="loader "></span>
                                </div>
                            ) : (
                                <p>No submissions found</p>
                            )}
                        </div>
                    </section>
                </section>
            </>
        );
    }
    const latestTask = currentTaskData[0];
    // The student answers/submissions
    const taskContent = latestTask.content;

    // Attributes of the task
    const taskAttribures = tasks.find((task) => task.name === taskName);

    if (taskName === "task_1") {
        const interests = Object.entries(taskContent).filter((content) => {
            return typeof content[1] !== "object";
        });

        return (
            <>
                <PageHeader
                    title="Ideation and Formation Part I"
                    breadcrumbPaths={breadcrumbPaths}
                />

                <section className="mx-auto max-w-7xl space-y-8 bg-white px-4 pb-12 pt-6 sm:px-6 md:px-8">
                    <section className="max-w-5xl rounded-md px-4 py-6 shadow sm:px-6 md:px-8">
                        <div className="flex w-full flex-row items-center">
                            <div
                                className="flex h-6 w-9 cursor-pointer items-center justify-center rounded-md bg-slate-300"
                                onClick={handleBackClicked}
                            >
                                <ArrowLeftIcon className="h-4 w-4" />
                            </div>
                            <h2 className="ml-2 text-2xl font-semibold">
                                {member.full_name}
                            </h2>
                        </div>
                        <SubmissionsTemplate
                            latestTask={latestTask}
                            setNoOfComments={setNoOfComments}
                        >
                            {taskAttribures.steps.map((step) => {
                                const { question } = step;
                                return (
                                    <TaskTemplate question={question}>
                                        {interests.map((interest, idx) => {
                                            return (
                                                <div className="mt-2">
                                                    <p>{`Interest ${
                                                        idx + 1
                                                    }`}</p>
                                                    <div className="w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                        <p>{interest[1]}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </TaskTemplate>
                                );
                            })}
                        </SubmissionsTemplate>
                    </section>
                </section>
            </>
        );
    } else if (taskName === "task_2") {
        const pursuits = Object.entries(taskContent);
        return (
            <>
                <PageHeader
                    title="Ideation and Formation Part I"
                    breadcrumbPaths={breadcrumbPaths}
                />

                <section className="mx-auto max-w-7xl space-y-8 bg-white px-4 pb-12 pt-6 sm:px-6 md:px-8">
                    <section className="max-w-5xl rounded-md px-4 py-6 shadow sm:px-6 md:px-8">
                        <div className="flex w-full flex-row items-center">
                            <div
                                className="flex h-6 w-9 cursor-pointer items-center justify-center rounded-md bg-slate-300"
                                onClick={handleBackClicked}
                            >
                                <ArrowLeftIcon className="h-4 w-4" />
                            </div>
                            <h2 className="ml-2 text-2xl font-semibold">
                                {member.full_name}
                            </h2>
                        </div>
                        <SubmissionsTemplate
                            latestTask={latestTask}
                            setNoOfComments={setNoOfComments}
                        >
                            {taskAttribures.steps.map((step) => {
                                const { question } = step;
                                return (
                                    <TaskTemplate question={question}>
                                        {pursuits.map((pursuit, idx) => {
                                            return (
                                                <>
                                                    {pursuit[0] ===
                                                    "focus_problem" ? (
                                                        <div className="mt-2">
                                                            <p>Focus Problem</p>
                                                            <div className="w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                                <p>
                                                                    {pursuit[1]}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="mt-2">
                                                            <p>{`Pursuit ${
                                                                idx + 1
                                                            }`}</p>
                                                            <div className="w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                                <p>
                                                                    {pursuit[1]}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </TaskTemplate>
                                );
                            })}
                        </SubmissionsTemplate>
                    </section>
                </section>
            </>
        );
    } else if (taskName === "task_3") {
        const engagements = Object.entries(taskContent);
        return (
            <>
                <PageHeader
                    title="Ideation and Formation Part I"
                    breadcrumbPaths={breadcrumbPaths}
                />

                <section className="mx-auto max-w-7xl space-y-8 bg-white px-4 pb-12 pt-6 sm:px-6 md:px-8">
                    <section className="max-w-5xl rounded-md px-4 py-6 shadow sm:px-6 md:px-8">
                        <div className="flex w-full flex-row items-center">
                            <div
                                className="flex h-6 w-9 cursor-pointer items-center justify-center rounded-md bg-slate-300"
                                onClick={handleBackClicked}
                            >
                                <ArrowLeftIcon className="h-4 w-4" />
                            </div>
                            <h2 className="ml-2 text-2xl font-semibold">
                                {member.full_name}
                            </h2>
                        </div>
                        <SubmissionsTemplate
                            latestTask={latestTask}
                            setNoOfComments={setNoOfComments}
                        >
                            {taskAttribures.steps.map((step) => {
                                const { question } = step;
                                return (
                                    <TaskTemplate question={question}>
                                        {engagements.map((engagement, idx) => {
                                            return (
                                                <div className="mt-2">
                                                    <p>{`Engagement ${
                                                        idx + 1
                                                    }`}</p>
                                                    <div className="w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                        <p>{engagement[1]}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </TaskTemplate>
                                );
                            })}
                        </SubmissionsTemplate>
                    </section>
                </section>
            </>
        );
    }
    return <div>Session one page</div>;
};

export default SessionOnePage;
