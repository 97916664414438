import Login from "@pages/auth/Login";
import LoginV2 from "./pages/auth/LoginV2";
import WelcomeScreen from "./pages/welcome-screen";
import TeamCreation from "./pages/team-creation/TeamCreation";
import Logout, { loader as logoutLoader } from "@pages/auth/Logout";
import PasswordReset, {
    action as passwordResetAction,
} from "@pages/auth/PasswordReset";
import StudentDashBoard from "@pages/dashboard/StudentDashBoard";
import RootErrorPage from "@pages/error/RootErrorPage";
import LoadingIndicator from "@pages/LoadingIndicator";
import EditProfile from "@pages/profile/EditProfile";
import CreateTeamPage, {
    loader as createTeamLoader,
} from "@pages/team/CreateTeamPage";
import EditTeamPage, {
    loader as editTeamLoader,
} from "@pages/team/EditTeamPage";
import TeamPage from "@pages/team/TeamPage";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    Route,
} from "react-router-dom";
import ParentLayout from "./components/layout/ParentLayout";
import SchoolLayout from "./components/layout/SchoolLayout";
import StudentLayout from "./components/layout/StudentLayout";
import FreeTrialSignUp from "./pages/auth/FreeTrialSignUp";
import PasswordResetConfirm from "./pages/auth/PasswordResetConfirm";
import { PasswordResetConfirmV2 } from "./pages/auth/PasswordResetConfirm_v2";
import VerifyEmail, {
    loader as verifyEmailLoader,
} from "./pages/auth/VerifyEmail";
import AuthenticatedRoot, {
    loader as rootLoader,
} from "./pages/AuthenticatedRoot";
import MainContent, {
    loader as sessionsLoader,
} from "./pages/content/MainContent";
import SessionActivity from "./pages/content/SessionActivity";
import SessionCaseStudy from "./pages/content/SessionCaseStudy";
import SessionLayout, {
    loader as sessionLoader,
} from "./pages/content/SessionLayout";
import SessionObjective from "./pages/content/SessionObjective";
import SessionOverview from "./pages/content/SessionOverview";
import PageNotFound from "./pages/error/pagenotFound";
import SessionActivityErrorBoundary from "./pages/error/SessionActivityErrorBoundary";
import FiresideGallery, {
    loader as firesideGalleryLoader,
} from "./pages/fireside/FiresideGallery";
import ViewFireside, {
    loader as viewFiresideLoader,
} from "./pages/fireside/ViewFireside";
import GuestRoot from "./pages/GuestRoot";
import League, {
    teamLeaderboardLoader,
    individualLeaderboardLoader,
} from "./pages/league/league";
import StudentOnBoarding from "./pages/onboarding/StudentOnBoarding";
import ParentDashboardPage, {
    loader as parentDashboardLoader,
} from "./pages/parent/ParentDashboardPage";
import SessionDetailsOverview, {
    loader as SessionDetailsOverviewLoader,
} from "./pages/parent/ParentFeedback/SessionDetailsOverview";
import SingleFireSideViewed, {
    loader as SingleFireSideViewloader,
} from "./pages/parent/ParentFeedback/SingleFiresideViewed";
import SchoolDashboardPage from "./pages/school/SchoolDashboardPage";
import TeamSubmissionsPage, {
    loader as teamSubmissionsLoader,
} from "./pages/school/TeamSubmissionsPage";
import TeamSubmissionsDashboard from "./pages/school/TeamSubmissionsSummary";
import StudentReports from "./pages/student/report";
import ParentsOnboarding from "./pages/onboarding/ParentOnBoarding";
import MyScore from "./pages/student/score";
import IndividualSubmissionsPage from "./pages/school/IndividualSubmissionPage";
import SessionOnePage from "./pages/school/submissions/SessionOne/SessionOnePage";
import ParentsReports from "./pages/parent/Reports";
import Feed from "./pages/forums/Feed";
import PostDetail from "./pages/forums/PostDetail";
import CreatePost from "./pages/forums/CreatePost";
import EditPost from "./pages/forums/EditPost";
import InviteMembers from "./pages/team-creation/InviteMembers";
import IntroductionVideo from "./pages/onboarding/IntroductionVideo";
import TeamSetupVideo from "./pages/onboarding/TeamSetupVideo";

export default function getRouteConfig(queryClient) {
    return createBrowserRouter(
        createRoutesFromElements(
            <Route
                element={<LoadingIndicator />}
                errorElement={<RootErrorPage />}
            >
                <Route path="/" element={<GuestRoot />}>
                    <Route index element={<LoginV2 />} />

                    <Route path="/welcome-screen" element={<WelcomeScreen />} />

                    <Route path="/team-creation" element={<TeamCreation />} />

                    <Route path="/invite-members" element={<InviteMembers />} />

                    <Route path="/free-trial" element={<FreeTrialSignUp />} />

                    <Route
                        path="/team-setup-video"
                        element={<TeamSetupVideo />}
                    />

                    <Route
                        path="/verify-email/:uid/:token"
                        element={<VerifyEmail />}
                        loader={verifyEmailLoader}
                    />
                    <Route
                        path="/introduction-video"
                        element={<IntroductionVideo />}
                    />

                    <Route
                        path="/password-reset"
                        element={<PasswordReset />}
                        action={passwordResetAction}
                    />
                    <Route
                        path="/password-reset/:uid/:token"
                        element={<PasswordResetConfirmV2 />}
                    />

                    <Route
                        path="/cohort-reports/:parentId"
                        element={<ParentsReports />}
                    />
                    <Route
                        path="/cohort-reports/:parentId"
                        element={<ParentsReports />}
                    />
                </Route>

                <Route
                    id="root"
                    element={<AuthenticatedRoot />}
                    loader={rootLoader(queryClient)}
                >
                    <Route
                        path="/logout"
                        loader={logoutLoader(queryClient)}
                        element={<Logout />}
                    />

                    <Route path="/onboarding" element={<StudentOnBoarding />} />

                    <Route
                        path="/parents-onboarding"
                        element={<ParentsOnboarding />}
                    />

                    <Route
                        path="/parents-onboarding"
                        element={<ParentsOnboarding />}
                    />

                    <Route
                        path="/parents-onboarding"
                        element={<ParentsOnboarding />}
                    />

                    <Route
                        element={
                            <StudentLayout>
                                <Outlet />
                            </StudentLayout>
                        }
                    >
                        <Route path="/student/">
                            <Route
                                path="dashboard"
                                element={<StudentDashBoard />}
                            />

                            <Route path={"team"}>
                                <Route index element={<TeamPage />} />
                                <Route
                                    path={"create"}
                                    loader={createTeamLoader(queryClient)}
                                    element={<CreateTeamPage />}
                                />
                                <Route
                                    path={"edit"}
                                    loader={editTeamLoader(queryClient)}
                                    element={<EditTeamPage />}
                                />
                            </Route>

                            <Route path="profile" element={<EditProfile />} />

                            <Route
                                id="content"
                                path="content"
                                element={<MainContent />}
                                loader={sessionsLoader}
                            />

                            <Route
                                id="session"
                                path="content/:session_id/"
                                element={<SessionLayout />}
                                loader={sessionLoader(queryClient)}
                            >
                                <Route
                                    path="objectives"
                                    element={<SessionObjective />}
                                />
                                <Route
                                    path="overview"
                                    element={<SessionOverview />}
                                />
                                <Route
                                    path="case-study"
                                    element={<SessionCaseStudy />}
                                />
                                <Route
                                    path="quiz"
                                    element={<SessionActivity />}
                                    errorElement={
                                        <SessionActivityErrorBoundary />
                                    }
                                />
                            </Route>

                            <Route path="firesides">
                                <Route
                                    index
                                    element={<FiresideGallery />}
                                    loader={firesideGalleryLoader(queryClient)}
                                />
                                <Route
                                    path=":slug"
                                    element={<ViewFireside />}
                                    loader={viewFiresideLoader(queryClient)}
                                />
                            </Route>

                            <Route
                                path="league/team"
                                element={<League view="team" />}
                                loader={teamLeaderboardLoader(queryClient)}
                            />
                            <Route
                                path="league/individual"
                                element={<League view="individual" />}
                                loader={individualLeaderboardLoader(
                                    queryClient
                                )}
                            />
                            <Route
                                path="league/my-score"
                                element={<MyScore />}
                            />

                            <Route
                                path="reports"
                                element={<StudentReports />}
                            />

                            <Route path="forum" element={<Feed />} />
                            <Route path="forum/:id" element={<PostDetail />} />
                            <Route
                                path="forum/create"
                                element={<CreatePost />}
                            />
                            <Route path="forum/edit" element={<EditPost />} />
                        </Route>
                    </Route>

                    {/* School Admin Routes */}
                    <Route
                        path="/school/"
                        element={
                            <SchoolLayout>
                                <Outlet></Outlet>
                            </SchoolLayout>
                        }
                    >
                        <Route
                            path="dashboard"
                            element={<SchoolDashboardPage />}
                        />
                        <Route
                            path="teams/:team_id/submissions"
                            element={<TeamSubmissionsPage />}
                            loader={teamSubmissionsLoader(queryClient)}
                        />
                        <Route
                            path="team-submissions"
                            element={<TeamSubmissionsDashboard />}
                        />
                        {/* Will change this later */}
                        <Route
                            path="individual-submissions"
                            element={<IndividualSubmissionsPage />}
                        />
                        <Route
                            path="session-one-submission"
                            element={<SessionOnePage />}
                        />
                        <Route path="profile" element={<EditProfile />} />
                    </Route>
                    {/* END School Admin Routes */}

                    {/* Parent Routes */}
                    <Route
                        path="/parent/"
                        element={
                            <ParentLayout>
                                <Outlet />
                            </ParentLayout>
                        }
                    >
                        <Route path="profile" element={<EditProfile />} />

                        <Route
                            path="dashboard"
                            loader={parentDashboardLoader(queryClient)}
                            element={<ParentDashboardPage />}
                        />
                        <Route
                            path="students/:studentId/feedback-session/:sessionId"
                            loader={SessionDetailsOverviewLoader(queryClient)}
                            element={<SessionDetailsOverview />}
                        />
                        <Route
                            path="students/:studentId/feedback-fireside/:firesideId"
                            loader={SingleFireSideViewloader(queryClient)}
                            element={<SingleFireSideViewed />}
                        />
                    </Route>
                    {/* END Parent Routes */}
                </Route>

                <Route path="*" element={<PageNotFound />} />
            </Route>
        )
    );
}
