import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import { useForm } from "react-hook-form";
import { createTeam, updateTeam } from "@api/teams";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import Button from "@components/Button";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
    FieldError,
    GeneralError,
    Label,
    TextArea,
    TextField,
} from "@components/Form";
import React from "react";

const TeamSetupTab = React.forwardRef(({ next, previous }, ref) => {
    const user = useRouteLoaderData("root");

    const team = user.teams.length > 0 ? user.teams[0] : {};

    const revalidator = useRevalidator();

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid, isDirty },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            id: team.id,
            team_name: team.team_name ?? "",
            team_description: team.team_description ?? "",
        },
    });

    const submit = async (formData) => {
        if (!isDirty) {
            next();
            return;
        }

        const { team_name: teamName, team_description: teamDescription } = formData;

        try {
            if (formData.id) {
                await updateTeam(formData.id, { team_name: teamName, team_description: teamDescription });
            } else {
                await createTeam({
                    team_name: teamName,
                    team_description: teamDescription,
                    student: user.id,
                    school: user.school.school_id,
                });
            }

            clearErrors();

            revalidator.revalidate();

            next();
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    return (
        <section ref={ref} className="px-4 py-6 sm:px-6 md:px-8">
            <div>
                <h1 className={"font-semibold text-3xl text-purple-700"}>
                    Team Setup
                </h1>
                <div className={"mt-1"}>
                    <Button size={"sm"} intent={"white"} onClick={previous}>
                        <span className={"sr-only"}>Back</span>
                        <ArrowLeftIcon className={"h-6 w-6"} />
                    </Button>
                </div>
            </div>

            <section
                className={"mt-4 rounded-md py-6 shadow px-4 sm:px-6 md:px-8"}
            >
                <div
                    className={
                        "py-4 bg-gray-50 rounded-md text-gray-700 text-lg leading-snug"
                    }
                >
                    <p>
                        You are expected to work in teams. Delegate this
                        exercise to the team leader to create the team and add
                        all members. If you aren't the team leader you can skip
                        this step and go straight to the content.
                    </p>

                    <Button
                        className={"mt-2"}
                        intent={"secondary"}
                        href={"/student/content"}
                    >
                        Skip and go to the content
                    </Button>
                </div>

                <GeneralError
                    className={"mt-4"}
                    message={errors.general?.message}
                />

                <form
                    id={"form-create-team"}
                    className="mt-8"
                    onSubmit={handleSubmit(submit)}
                >
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor={"team-name"} name={"Team name"} />
                            <TextField
                                type={"text"}
                                id={"team-name"}
                                className="w-full mt-1"
                                {...register("team_name")}
                                autoFocus
                                autoComplete={"off"}
                                required
                            />
                            <FieldError
                                className={"mt-1"}
                                message={errors.team_name?.message}
                            />
                        </div>

                        <div>
                            <Label
                                htmlFor={"team-description"}
                                name={"Team description"}
                            />
                            <TextArea
                                id={"team-description"}
                                className="w-full mt-1"
                                {...register("team_description")}
                                required
                            />
                            <FieldError
                                className={"mt-1"}
                                message={errors.team_description?.message}
                            />
                        </div>
                    </div>

                    <div className="flex justify-end mt-8">
                        <div>
                            {isSubmitting && (
                                <span className={"text-gray-900 mr-2"}>
                                    Saving{" "}
                                    <span
                                        className={"motion-safe:animate-pulse"}
                                    >
                                        ...
                                    </span>
                                </span>
                            )}
                            <Button
                                type={"submit"}
                                form={"form-create-team"}
                                disabled={isSubmitting || !isValid}
                            >
                                Create team
                            </Button>
                        </div>
                    </div>
                </form>
            </section>
        </section>
    );
});

export default TeamSetupTab;
