import {
    BriefcaseIcon,
    MagnifyingGlassIcon,
    PlayCircleIcon,
    ListBulletIcon,
} from "@heroicons/react/24/outline";
import {
    NavLink,
    Navigate,
    Outlet,
    useLoaderData,
    useParams,
    useRouteLoaderData,
} from "react-router-dom";
import clsx from "clsx";
import { studentSessionQueryOptions } from "@api/sessions";
import PageHeader from "@src/components/PageHeader";
import useStore from "@store/useStore";
import { useEffect, useRef } from "react";
import { AccountStatus, SessionContentType } from "@src/models";
import { useQuery } from "@tanstack/react-query";

export function loader(queryClient) {
    return ({ params }) => {
        return queryClient.ensureQueryData(
            studentSessionQueryOptions(parseInt(params.session_id))
        );
    };
}

export default function SessionLayout() {
    const user = useRouteLoaderData("root");

    const sessionInitialData = useLoaderData();

    const isFreeTrial = user.account_status === AccountStatus.FREE_TRIAL;

    if (isFreeTrial && sessionInitialData.sort_order > 3) {
        return <Navigate to={"/student/content"} replace={true} />;
    }

    const [isSideBarExpanded, toggleSidebarExpansion] = useStore((state) => [
        state.isSideBarExpanded,
        state.toggleSidebarExpansion,
    ]);
    const wasSideBarExpanded = useRef(isSideBarExpanded);

    useEffect(() => {
        !isSideBarExpanded && toggleSidebarExpansion();

        return () => {
            wasSideBarExpanded.current && toggleSidebarExpansion();
        };
    }, []);

    const params = useParams();
    const { data: session } = useQuery({
        ...studentSessionQueryOptions(parseInt(params.session_id)),
        initialData: sessionInitialData,
    });

    const sessionIsPitchDay =
        session.title === "Pitch Day" || session.title === "Demo Day";

    const sessionHasCaseStudy = session.content?.some(
        (item) => item.content_type === SessionContentType.CASE_STUDY
    );

    const breadcrumbPaths = [
        { name: "Classroom", href: "/student/content" },
        {
            name: session.title,
            href: `/student/content/${session.session_id}/overview`,
        },
    ];

    return (
        <section className={"relative flex h-full flex-col"}>
            <div className={"sticky top-0 z-10"}>
                <PageHeader
                    title={session.title}
                    breadcrumbPaths={breadcrumbPaths}
                />
            </div>

            <section className="mt-5 flex max-w-screen-xl flex-1 flex-col px-4 pb-20 sm:px-6 md:px-8">
                <nav className="flex flex-wrap justify-evenly rounded-t-md border border-gray-200 text-center text-sm">
                    <SessionNavLink
                        name="Session Objective"
                        Icon={ListBulletIcon}
                        link={`/student/content/${session.session_id}/objectives`}
                    />
                    {!sessionIsPitchDay && (
                        <SessionNavLink
                            name="Learning Video"
                            Icon={PlayCircleIcon}
                            link={`/student/content/${session.session_id}/overview`}
                        />
                    )}

                    {sessionHasCaseStudy && (
                        <SessionNavLink
                            name="Case Study"
                            Icon={MagnifyingGlassIcon}
                            link={`/student/content/${session.session_id}/case-study`}
                        />
                    )}
                    <SessionNavLink
                        name="Activity"
                        Icon={BriefcaseIcon}
                        link={`/student/content/${session.session_id}/quiz`}
                    />
                </nav>

                <div className="w-full flex-1 rounded-b-md bg-purple-100">
                    <Outlet />
                </div>
            </section>
        </section>
    );
}

function SessionNavLink({ name, Icon, link }) {
    return (
        <NavLink
            to={link}
            className={({ isActive }) =>
                clsx(
                    "group inline-flex items-center border-b px-4 py-2 text-sm font-semibold transition",
                    isActive
                        ? `border-purple-500 bg-purple-200 text-purple-700`
                        : `group border-transparent text-gray-800 hover:border-b-purple-500 hover:bg-purple-100 hover:text-purple-700`
                )
            }
        >
            <Icon className="mr-1 h-6 w-6" />
            {name}
        </NavLink>
    );
}
