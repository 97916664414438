import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import EditorBar from "./EditorBar";
import { useEffect } from "react";
import Image from "./Image";

export interface TiptapEditorProps {
    content: string;
    onChange: (value: string) => void;
    height: string;
}

const TiptapEditor = ({
    height = "12rem",
    content = "",
    onChange,
}: TiptapEditorProps) => {
    const editor = useEditor({
        extensions: [StarterKit, Image],
        editorProps: {
            attributes: {
                class: "w-full h-full prose max-w-none text-black sm:prose-lg \
                        rounded-b-md border border-gray-300 px-3 py-2 shadow-sm \
                        transition focus:border-gray-500 focus:outline-none \
                        focus:ring-gray-500 bg-white overflow-y-auto",
            },
        },
        content,
        onUpdate: ({ editor }) => {
            onChange(editor.getHTML());
        },
    });

    useEffect(() => {
        return () => editor?.destroy();
    }, []);

    return (
        <article className="flex w-full flex-col" style={{ height }}>
            <EditorBar editor={editor} />
            <EditorContent className="min-h-0 flex-1" editor={editor} />
        </article>
    );
};

export default TiptapEditor;
