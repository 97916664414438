import { cva, type VariantProps } from "class-variance-authority";
import { type BaseButtonVariantProps } from "./BaseButton";

export type ContainedButtonVariantProps = BaseButtonVariantProps &
    VariantProps<typeof containedButton>;

const containedButton = cva("shadow-sm disabled:bg-gray-100", {
    variants: {
        intent: {
            primary:
                "border-transparent bg-purple-600 text-white hover:bg-purple-700",
            secondary:
                "border-transparent bg-purple-100 text-purple-700 hover:bg-purple-200",
            secondaryVariant:
                "border-transparent bg-slate-400 text-white hover:bg-slate-500 hover:text-white",
            accent: "border-transparent bg-orange-100 text-orange-700 hover:bg-orange-200",
            danger: "border-transparent bg-red-600 text-white hover:bg-red-700",
            white: "border-gray-300 bg-white text-gray-700 hover:bg-gray-50",
            primaryVariant:
                "border-transparent bg-orange-600 text-white hover:bg-orange-700",
        },
    },
});

export default containedButton;
