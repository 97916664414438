import { syncMembers, syncMultipleMembers } from "@src/api/teams";
import type { Student } from "@src/models/types";
import { useQueryClient } from "@tanstack/react-query";

export default function useTeamActions(user: Student) {
    const queryClient = useQueryClient();

    const addMember = async (userId: number) => {
        const team = user.team();

        if (team == null) {
            return;
        }

        await syncMembers(team.id, {
            members: [...team.members.map((member) => member.id), userId],
        });

        await queryClient.invalidateQueries(["profile"]);
        await queryClient.invalidateQueries({
            queryKey: ["currentTeam", team.id],
        });
    };

    const addInitialMembers = async (
        userIds: number[]
    ): Promise<boolean | unknown> => {
        const team = user.team();
        if (team == null) {
            return;
        }

        try {
            await syncMultipleMembers(team.id, {
                members: userIds,
            });
            return true;
        } catch (error) {
            console.log("ERROR ADDING MEMBERS ---> ", error);
            return error;
        }
    };

    const removeMember = async (userId: number) => {
        const team = user.team();

        if (team == null) {
            return;
        }

        await syncMembers(team.id, {
            members: team.members
                .filter((member) => member.id !== userId)
                .map((member) => member.id),
        });

        await queryClient.invalidateQueries(["profile"]);
        await queryClient.invalidateQueries(["currentTeam", team.id]);
    };

    return {
        addMember,
        removeMember,
        addInitialMembers,
    };
}
