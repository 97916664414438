import { create } from "zustand";
import { type AppState, type PersistedState } from "@store/types";
import { persist } from "zustand/middleware";
import { createAuthSlice } from "@store/createAuthSlice";
import { createUISlice } from "@store/createUISlice";
import { createFacilitatorSessionSlice } from "./createFacilitatorSessionSlice";
import { pick } from "lodash-es";

const useStore = create<AppState>()(
    persist(
        (...methods) => ({
            ...createAuthSlice(...methods),
            ...createUISlice(...methods),
            ...createFacilitatorSessionSlice(...methods),
        }),
        {
            name: "kinetic-store",
            partialize: (state): PersistedState =>
                pick<AppState>(state, [
                    "isAuthenticated",
                    "isSideBarExpanded",
                    "user",
                    "isOnBoarding",
                ]) as PersistedState,
        }
    )
);

export default useStore;
