import { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileUpload = ({ control, attributes, error, register }) => {
    const [surveyFiles, setsurveyFiles] = useState([]);

    return (
        <>
            {/* <FilePond 
   files={surveyFiles}
   onupdatefiles={setsurveyFiles}
   allowMultiple={true}
   maxFiles={10}
   name={attributes.name}
   server="/api"
   labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
   
   /> */}

            <div className="round-lg mx-auto w-1/2 border bg-purple-50 p-2">
                {console.log("FILE UPLOAD ATTRIBUTES ----> ", attributes)}
                <input
                    type="file"
                    multiple
                    {...register(attributes.name, {
                        onChange: (e) =>
                            console.log("ON FILE UPLOAD CHANGE : ", e),
                    })}
                />
            </div>
        </>
    );
};

export default FileUpload;
