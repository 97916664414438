import { type Notification } from "@src/api/types";
import axios from "./axios";

export async function getNotifications() {
    const response = await axios.get<Notification[]>(`notifications/ `);
    return response.data;
}

export async function markAllNotificationsRead() {
    const response = await axios.post(`notifications/mark-all-as-read/`);
    if (response.status === 200) return true;
    return false;
}

export async function markNotificationAsRead(notificationId: number) {
    const response = await axios.post(
        `notifications/${notificationId}/mark-as-read/`
    );
    if (response.status === 200) return true;
    return false;
}
