import { resendEmailVerification, verifyEmail } from "@src/api/auth";
import Alert from "@src/components/Alert";
import Button from "@src/components/Button";
import { GeneralError } from "@src/components/Form";
import AuthLayout from "@src/components/layout/AuthLayout";
import { useStore } from "@src/store";
import { useState } from "react";
import {
    Link,
    Navigate,
    useLoaderData,
    useParams,
} from "react-router-dom";

export async function loader({ params }) {
    const { uid, token } = params;

    try {
        const response = await verifyEmail(uid, token);

        return { ok: true, user: response.data.user };
    } catch (error) {
        if ("response" in error && error.response.status === 422) {
            return { ok: false, errors: error.response.data.errors };
        }
        return { ok: false, errors: { general: error.message } };
    }
}

const VerifyEmail = () => {
    const authenticate = useStore((store) => store.authenticate);

    const { uid } = useParams();

    const loaderData = useLoaderData();

    if (loaderData.ok) {
        authenticate(loaderData.user);
        return <Navigate to="/onboarding" replace="true" />;
    }

    const [errors, setErrors] = useState(() =>
        !loaderData.ok ? loaderData.errors : {}
    );

    const [loading, setLoading] = useState(false);

    const [successMessage, setSuccessMessage] = useState("");

    async function resendEmailVerificationRequest() {
        setLoading(true);
        try {
            const response = await resendEmailVerification(uid);

            setSuccessMessage(response.data.msg);
        } catch (error) {
            if ("response" in error && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                setErrors({ general: "generic" });
            }
        }
        setLoading(false);
    }

    return (
        <AuthLayout>
            <div className="max-w-2xl px-4 py-10 mx-auto mt-10 bg-white rounded-lg shadow-md sm:px-6 md:px-8">
                {errors && "already_verified" in errors && (
                    <Alert
                        title="Account already verified"
                        intent="info"
                        description={
                            <>
                                <p>You have already verified your account.</p>
                                <Link
                                    to={"/"}
                                    className="inline-block mt-2 font-medium underline hover:text-blue-600"
                                >
                                    Login to proceed
                                </Link>
                            </>
                        }
                    />
                )}

                {errors && "invalid_url" in errors && (
                    <Alert
                        title="Bad Request"
                        intent="danger"
                        description={errors.invalid_url[0]}
                    />
                )}

                {successMessage && (
                    <Alert
                        intent={"success"}
                        title="Verification email sent"
                        description={successMessage}
                        className="my-2"
                    />
                )}

                {errors && "invalid_token" in errors && (
                    <Alert
                        title="Invalid Token"
                        intent="danger"
                        description={
                            <>
                                <p>
                                    The token in the URL has either been
                                    tampered with or it has expired.
                                </p>
                                <Button
                                    onClick={resendEmailVerificationRequest}
                                    className="mt-2"
                                    variant="outlined"
                                    intent="danger"
                                    size="sm"
                                >
                                    {loading
                                        ? "Resending email..."
                                        : "Request for new verification email"}
                                </Button>
                            </>
                        }
                    />
                )}

                {errors && "general" in errors && (
                    <GeneralError message={errors.general} />
                )}
            </div>
        </AuthLayout>
    );
};

export default VerifyEmail;
