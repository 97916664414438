import { useState } from "react";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";

const SessionFourTaskTwoSubmissions = ({ data, sortOrder, session, task }) => {
    const [noOfComments, setNoOfComments] = useState(0);
    const sessionFour = data.find(
        (session) => session.sort_order === sortOrder
    );

    const taskName = task[0];
    const taskData = sessionFour.submissions[taskName];

    const recentTaskData = taskData[0];

    const userList = recentTaskData.content.user_list;

    // From the two market segments you have selected, create a list of 25 people with whom you will conduct your market research.

    return (
        <SubmissionsTemplate
            latestTask={recentTaskData}
            setNoOfComments={setNoOfComments}
        >
            <section>
                <TaskTemplate
                    question="Provide a list of five potential market segments,
                            based on the solutions discussed in the previous
                            session"
                >
                    <div className="mt-1 rounded-md bg-white px-4 py-6 text-lg sm:px-6 md:px-8">
                        {userList.map((person, idx) => {
                            return (
                                <div className="mt-2 flex flex-col items-start justify-between">
                                    <p className="mx-1.5">{`Person ${
                                        idx + 1
                                    }`}</p>
                                    <div className="flex w-full rounded bg-slate-50 px-4 py-6">
                                        <p>{person}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </TaskTemplate>
            </section>
        </SubmissionsTemplate>
    );
};

export default SessionFourTaskTwoSubmissions;
