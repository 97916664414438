import clsx from "clsx";
import { useState, Fragment } from "react";
import { RadioGroup } from "@headlessui/react";
import tasks from "../../../../containers/activities/SessionThree/tasks_schema.json";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";

const SessionThreeSubmission = ({ data, sortOrder, task }) => {
    const [selected, setSelected] = useState("");
    const [noOfComments, setNoOfComments] = useState(0);
    const sessionThree = data.find(
        (session) => session.sort_order === sortOrder
    );
    console.log("SESSION 3 DATA ---> ", sessionThree);
    const taskName = task[0];
    const taskAttributes = tasks.find((task) => task.name === taskName);
    const taskData = sessionThree.submissions[taskName];
    const latestTask = taskData[taskData.length - 1];
    const focusSolution = latestTask.content.focus_solution;

    const handleSelected = (question) => {
        setSelected(question);
    };

    if (taskName === "task_1") {
        const studentSolutions = latestTask.content.solutions;

        return (
            <SubmissionsTemplate
                latestTask={latestTask}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((step, idx) => {
                        const { fields, question } = step;
                        const fieldData = fields[0];

                        switch (fieldData.name) {
                            case "solutions":
                                return (
                                    <div
                                        className={` m-2 rounded-md border p-4 ${
                                            selected === question
                                                ? "border-purple-500"
                                                : ""
                                        }`}
                                        onClick={() => handleSelected(question)}
                                    >
                                        <TaskTemplate question={question}>
                                            <>
                                                {studentSolutions.map(
                                                    (sol, idx) => {
                                                        return (
                                                            <div className="m-2 flex flex-col items-start justify-between ">
                                                                <p className="mx-1.5">{`Solution ${
                                                                    idx + 1
                                                                }`}</p>
                                                                <div className="w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                                    <p>{sol}</p>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </>
                                        </TaskTemplate>
                                    </div>
                                );
                            case "focus_solution":
                                return (
                                    <div
                                        className={` m-2 rounded-md border p-4 ${
                                            selected === question
                                                ? "border-purple-500"
                                                : ""
                                        }`}
                                        onClick={() => handleSelected(question)}
                                    >
                                        <TaskTemplate question={question}>
                                            <>
                                                <RadioGroup
                                                    as={"ul"}
                                                    className={"divide-y"}
                                                    value={""}
                                                >
                                                    <RadioGroup.Label className="sr-only">
                                                        {"Radio Group Label"}
                                                    </RadioGroup.Label>

                                                    {studentSolutions.map(
                                                        (itemKey, idx) => (
                                                            <RadioGroup.Option
                                                                as={Fragment}
                                                                key={idx}
                                                                disabled
                                                            >
                                                                <li
                                                                    className={
                                                                        "flex divide-x bg-white"
                                                                    }
                                                                >
                                                                    <div className="flex items-center justify-center p-6">
                                                                        <span
                                                                            className={clsx(
                                                                                itemKey ===
                                                                                    focusSolution
                                                                                    ? "border-transparent bg-purple-600"
                                                                                    : "border-gray-300 bg-white",
                                                                                itemKey ===
                                                                                    focusSolution
                                                                                    ? "ring-2 ring-purple-500 ring-offset-2"
                                                                                    : "",
                                                                                "flex h-4 w-4 items-center justify-center rounded-full border"
                                                                            )}
                                                                            aria-hidden="true"
                                                                        >
                                                                            <span className="h-1.5 w-1.5 rounded-full bg-white" />
                                                                        </span>
                                                                    </div>

                                                                    <div
                                                                        className={
                                                                            "flex items-center justify-center px-4 py-6 text-gray-700"
                                                                        }
                                                                    >
                                                                        {`Solution ${
                                                                            idx +
                                                                            1
                                                                        }`}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            "flex flex-1 items-center px-4 py-6"
                                                                        }
                                                                    >
                                                                        {
                                                                            itemKey
                                                                        }
                                                                    </div>
                                                                </li>
                                                            </RadioGroup.Option>
                                                        )
                                                    )}
                                                </RadioGroup>
                                            </>
                                        </TaskTemplate>
                                    </div>
                                );
                        }
                    })}
                </>
            </SubmissionsTemplate>
        );
    } else {
        const taskData = sessionThree.submissions[taskName];
        const taskAttributes = tasks.find((task) => task.name === taskName);
        const recentTask = taskData[taskData.length - 1];

        const handleSelect = (question) => {
            setSelected(question);
        };

        return (
            <SubmissionsTemplate
                latestTask={latestTask}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((step, idx) => {
                        const { fields, question } = step;
                        const name = fields[0].name;

                        const data = recentTask.content[name];
                        if (typeof data === "object") {
                            return (
                                <div
                                    className={`m-2 flex w-full flex-col rounded border${
                                        selected === question
                                            ? " border-purple-400"
                                            : ""
                                    } p-2`}
                                    key={idx}
                                    onClick={() => handleSelect(question)}
                                >
                                    <TaskTemplate question={question}>
                                        <>
                                            {data.map((el, idx) => (
                                                <div className="flex flex-row items-center">
                                                    <p className="mr-1">{`${
                                                        idx + 1
                                                    } `}</p>
                                                    <p>{el}</p>
                                                </div>
                                            ))}
                                        </>
                                    </TaskTemplate>
                                </div>
                            );
                        } else {
                            return (
                                <div className="m-2 flex w-full flex-col rounded border border-purple-400 p-2">
                                    <TaskTemplate question={question}>
                                        <p>{data}</p>
                                    </TaskTemplate>
                                </div>
                            );
                        }
                    })}
                </>
            </SubmissionsTemplate>
        );
    }
};

export default SessionThreeSubmission;
