import { type VariantProps, cva } from "class-variance-authority";
import { type ComponentProps } from "react";

const badgeClasses = cva(
    ["inline-flex items-center font-medium rounded-md ring-1 ring-inset"],
    {
        variants: {
            theme: {
                primary: "bg-purple-50 text-purple-700 ring-purple-700/10",
                accent: "bg-orange-50 text-orange-800 ring-orange-600/20",
                gray: "bg-gray-50 text-gray-600 ring-gray-500/10",
                success: "bg-green-50 text-green-700 ring-green-600/20",
                warning: "bg-yellow-50 text-yellow-800 ring-yellow-600/20",
                danger: "bg-red-50 text-red-700 ring-red-600/10",
                info: "bg-blue-50 text-blue-700 ring-blue-700/10",
            },

            size: {
                sm: "px-1.5 py-0.5 text-sm",
                base: "px-2 py-1 text-sm",
            },
        },
    }
);

export type BadgeProps = VariantProps<typeof badgeClasses> &
    ComponentProps<"span">;

export default function Badge({
    theme = "gray",
    size = "base",
    className,
    children,
    ...props
}: BadgeProps) {
    return (
        <span
            className={badgeClasses({ theme, size, class: className })}
            {...props}
        >
            {children}
        </span>
    );
}
