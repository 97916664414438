import { useState, useEffect } from "react";
import { Post } from "@src/api/forums";

import TiptapEditor from "@src/components/Form/TiptapEditor";
import { useNavigate, useLocation } from "react-router-dom";
import PageHeader from "@src/components/PageHeader";
import Button from "@src/components/Button";
import { useEditPost } from "@src/api/forums";
import { toast } from "react-toastify";

interface EditPostProps {
    postData: Post;
}

const EditPost: React.FC<EditPostProps> = () => {
    const location = useLocation();
    const { postData } = location.state;
    const { id, content, title } = postData;
    const [newContent, setNewContent] = useState(content);
    const [newTitle, setNewTitle] = useState(title);
    const navigate = useNavigate();

    // const breadcrumbPaths = [
    //     {
    //         name: "Feed",
    //         href: `/student/forum/${id}/`,
    //     },
    // ];

    const editPostMutation = useEditPost();
    const isLoading = editPostMutation.isLoading;

    const handleEditPostClick = async () => {
        if (newTitle === title && newContent === content) {
            toast.success(`No change has been made`, {
                autoClose: 2000,
            });
            return;
        }

        try {
            await editPostMutation.mutateAsync(
                {
                    title: newTitle,
                    content: newContent,
                    id: id,
                },
                {
                    onSuccess(data, variables, context) {
                        toast.success(`Post editted succesfully`, {
                            autoClose: 2000,
                        });
                        navigate(-1);
                    },
                    onError(err) {
                        console.log("EDIT POST ERROR ----> ", err);
                        toast.error(`An unexpected error occurred`, {
                            autoClose: 2000,
                        });
                    },
                }
            );
        } catch (error: any) {}
    };

    return (
        <>
            <section className="mt-5 px-20">
                <div className="w-full pb-10">
                    <h2 className=" text-3xl font-bold">Title</h2>
                    <input
                        value={newTitle}
                        type="text"
                        onChange={(e) => {
                            setNewTitle(e.target.value);
                        }}
                        className="w-full text-2xl"
                    />
                </div>

                <h2 className=" text-3xl font-bold">Content</h2>
                <TiptapEditor
                    height="24rem"
                    content={newContent}
                    onChange={setNewContent}
                />

                <div
                    className="mt-3 flex items-center justify-end"
                    onClick={handleEditPostClick}
                >
                    <Button>
                        {isLoading ? <span className="loader" /> : "Edit Post"}
                    </Button>
                </div>
            </section>
        </>
    );
};

export default EditPost;
