import Button from "@components/Button";
import { useRouteLoaderData } from "react-router-dom";

const SessionObjective = () => {
    const session = useRouteLoaderData("session");

    return (
        <section className="px-4 pt-4 pb-8 sm:px-6 md:px-8">
            <h2
                data-cy="session-objectives-heading"
                className="text-2xl font-bold text-purple-800"
            >
                Session Overview
            </h2>
            <div
                data-cy="session-objectives"
                className="flex flex-col items-center justify-between gap-4 md:flex-row"
            >
                <div className="px-2 text-2xl leading-10">
                    {session.objective.split("\n").map((line, idx) => (
                        <p className="my-5" key={idx}>
                            {line}
                        </p>
                    ))}
                </div>
                <div className="order-first md:order-none">
                    <img
                        className="w-full h-auto my-4"
                        src="/images/session-objectives.png"
                        alt="session-objectives-image"
                    />
                </div>
            </div>
            <div className="flex justify-end">
                <Button
                    data-cy="session-objectives-next-button"
                    variant="outlined"
                    href={`/student/content/${session.session_id}/overview`}
                >
                    Next
                </Button>
            </div>
        </section>
    );
};

export default SessionObjective;
