import { requestPasswordReset } from "@src/api/auth";
import Alert from "@src/components/Alert";
import Button from "@src/components/Button";
import { FieldError, GeneralError, Label, TextField } from "@components/Form";
import AuthLayout from "@src/components/layout/AuthLayout";
import { getFormErrors } from "@src/utils/form-errors";
import { useEffect, useRef } from "react";
import { json, useActionData } from "react-router";
import { Form } from "react-router-dom";

export async function action({ request }) {
    const formData = await request.formData();

    try {
        const response = await requestPasswordReset({
            email: formData.get("email"),
        });
        const data = { message: response.data.msg };
        return json(data, { status: 200 });
    } catch (error) {
        const { response } = error;
        const errors =
            response.status === 422
                ? getFormErrors(response.data.errors)
                : { general: error.message };

        return { errors };
    }
}

export default function PasswordReset() {
    const actionData = useActionData();
    const errors = actionData?.errors;
    const message = actionData?.message;
    const form = useRef(null);

    useEffect(() => {
        if (message) {
            form.current.reset();
        }
    }, [message]);

    return (
        <AuthLayout>
            <div className="max-w-xl px-4 py-10 mx-auto mt-10 bg-white rounded-lg shadow-md sm:px-6 md:px-8">
                <GeneralError className="mb-2" message={errors?.general} />

                {message && (
                    <Alert
                        intent="success"
                        title="Success"
                        description={message}
                        className="mb-2"
                    />
                )}

                <p className="text-lg text-gray-800">
                    Forgot your password? No problem. Just let us know your
                    email address and we will email you a password reset link
                    that will allow you to choose a new one.
                </p>

                <Form ref={form} className="mt-5" method="POST">
                    <div>
                        <Label htmlFor="email" name="Email" />
                        <TextField
                            className="w-full mt-1"
                            id="email"
                            name="email"
                            type="email"
                            placeholder="you@example.com"
                            autoComplete="email"
                            autoFocus
                            required
                        />
                        <FieldError message={errors?.email} className="mt-1" />
                    </div>
                    <div className="flex justify-end mt-5">
                        <Button type="submit">Email Password Rest Link</Button>
                    </div>
                </Form>
            </div>
        </AuthLayout>
    );
}
