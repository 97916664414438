import { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import JSConfetti from "js-confetti";
import Pusher from "pusher-js";
import { useQuery } from "@tanstack/react-query";
import { profileQueryOptions } from "@src/api/user";

interface SidebarProps {
    userId: number;
    points: number;
}

const XPCard = ({ userId, points = 0 }: SidebarProps) => {
    const { data: user, refetch } = useQuery({ ...profileQueryOptions() });
    interface PusherData {
        points: number;
        event: string;
    }

    const [xpStyles, apiXP] = useSpring(() => ({
        background: "rgba(0,0,0,0)",
    }));
    const [coinStyles, apiCoin] = useSpring(() => ({ transform: "scale(0)" }));

    const [pusherData, setPusherData] = useState<PusherData>({
        points: 0,
        event: "experience-points",
    });

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const jsConfettiRef = useRef<JSConfetti>(null);

    const renderConfetti = () => {
        // Render Confetti
        if (canvasRef.current === null) {
            return;
        }

        const confettiConfig = { canvas: canvasRef.current };
        jsConfettiRef.current = new JSConfetti(confettiConfig);

        const addConfettiConfig = {
            confettiColors: ["#BF40BF", "#CF9FFF", "#CCCCFF"],
            confettiRadius: 3,
            confettiNumber: 500,
            confettiSpeed: 0.6,
        };

        // Handle the promise returned by addConfetti
        jsConfettiRef.current.addConfetti(addConfettiConfig).catch((error) => {
            console.error("Error adding confetti:", error);
        });
    };

    const pusherKey = import.meta.env.VITE_PUSHER_API_KEY as string;

    useEffect(() => {
        const pusher = new Pusher(pusherKey, {
            cluster: "eu",
        });

        const channel = pusher.subscribe(userId.toString());
        channel.bind("Points", function (data: PusherData) {
            setPusherData(data);

            apiXP.start({
                from: { background: "rgba(0,0,0,0)" },
                to: [
                    {
                        background:
                            "radial-gradient(circle at center, rgba(0,0,0,0) , rgba(231, 204, 125, 0.0))",
                    },
                    {
                        background:
                            "radial-gradient(circle at center, rgba(0,0,0,0) , rgba(231, 204, 125, 0.3))",
                    },
                    {
                        background:
                            "radial-gradient(circle at center, rgba(0,0,0,0) , rgba(231, 204, 125, 0.6))",
                    },
                    {
                        background:
                            "radial-gradient(circle at center, rgba(0,0,0,0) , rgba(231, 204, 125, 0.9))",
                    },
                    {
                        background:
                            "radial-gradient(circle at center, rgba(0,0,0,0) , rgba(231, 204, 125, 0))",
                    },
                ],
                immediate: true,
                config: { duration: 100 },
                // delay: 2
            });

            apiCoin.start({
                from: { transform: "scale(0)" },
                to: [
                    { transform: "scale(0.2)" },
                    { transform: "scale(0.5)" },
                    { transform: "scale(0.7)" },
                    { transform: "scale(1)" },
                ],
                immediate: true,
                config: { duration: 80 },
                // delay: 2
            });

            setTimeout(() => {
                apiCoin.start({
                    from: { transform: "scale(1)" },
                    to: [
                        { transform: "scale(0.7)" },
                        { transform: "scale(0.5)" },
                        { transform: "scale(0.2)" },
                        { transform: "scale(0)" },
                    ],
                    immediate: true,
                    config: { duration: 40 },
                });
            }, 5000);
        });

        return () => {
            pusher.unsubscribe(userId.toString());
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (pusherData.points > 0) {
                renderConfetti();
                await refetch();
            }
        })();
    }, [pusherData]);

    const baseCoinStyle = {
        backgroundColor: "#dfa251",
        marginRight: "20%",
    };

    return (
        <animated.div style={{ position: "relative", ...xpStyles }}>
            <canvas
                ref={canvasRef}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            />
            <div className="flex justify-end pt-2">
                <animated.div
                    className="float-right flex h-8 w-8 justify-center rounded-full"
                    style={{ ...baseCoinStyle, ...coinStyles }}
                >
                    <span className="flex items-center justify-center rounded-full p-3 text-sm font-bold text-white">
                        +{pusherData.points}
                    </span>
                </animated.div>
            </div>
            <div className="truncate pb-8 text-center text-lg font-bold text-gray-500">
                <span
                    className="pr-1 text-xl"
                    style={{
                        color: "#dfa251",
                    }}
                >
                    {user !== undefined ? user.points : points}
                </span>
                XP
            </div>
        </animated.div>
    );
};

export default XPCard;
