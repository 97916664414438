import axios, { type AxiosResponse } from "axios";
import { type FieldValues, type UseFormSetError } from "react-hook-form";

export function getFormErrors(responseErrors: Record<string, string[]>) {
    const processedErrors: Record<string, string> = {};

    for (const key in responseErrors) {
        processedErrors[key !== "non_field_errors" ? key : "general"] =
            responseErrors[key][0];
    }

    return processedErrors;
}

export function errorReducer(error: unknown) {
    if (axios.isAxiosError(error) && error.response != null) {
        return _errorResponseReducer(error.response);
    }

    return { general: "generic" };
}

export function responseErrorToFormErrors<
    TFieldValues extends FieldValues = FieldValues
>(error: unknown, setFormError: UseFormSetError<TFieldValues>) {
    const _errors = errorReducer(error);

    type FormSetErrorNameParam = Parameters<UseFormSetError<TFieldValues>>[0];

    Object.keys(_errors).forEach((fieldPath) => {
        setFormError(fieldPath as FormSetErrorNameParam, {
            type: "custom",
            message: _errors[fieldPath],
        });
    });
}

function _errorResponseReducer(response: AxiosResponse) {
    switch (response.status) {
        case 422:
            return getFormErrors(response.data.errors);
        case 404:
            return {
                general: import.meta.env.DEV
                    ? `[DEBUG] 404 response for '${response.request.responseURL}'. Check if your API server is running or the url exists.`
                    : "generic",
            };
        default:
            return { general: "generic" };
    }
}
