import AuthLayoutV2 from "@src/components/layout/AuthLayoutV2";
import { passwordResetConfirm } from "@src/api/auth";
import Button from "@components/Button";
import {
    FieldError,
    Label,
    TextField,
    GeneralError,
} from "@src/components/Form";
import PasswordFieldV2 from "@src/components/Form/PasswordFieldV2";
import {
    userTypeOnboardingRoute,
    resendAccountSetupEmail,
    userTypeHomeRoute,
} from "@src/api/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@src/components/Alert";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import useStore from "@store/useStore";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";

function isInvalidTokenError(errorMessage: string) {
    return errorMessage.toLowerCase().includes("invalid token");
}

function invalidTokenErrorMessage(requestNewToken: () => Promise<void>) {
    return (
        <>
            <p>
                Invalid token provided in the link. You can request for a new
                one to access your account.
            </p>
            <Button
                onClick={requestNewToken}
                variant={"text"}
                intent={"danger"}
                size={"sm"}
            >
                Request for new token
            </Button>
        </>
    );
}

export const PasswordResetConfirmV2 = () => {
    const [searchParams] = useSearchParams();

    const { uid, token: resetToken } = useParams();

    const authenticate = useStore((state) => state.authenticate);
    const isOnBoarding = useStore((state) => state.isOnBoarding);
    const isAuthenticated = useStore.getState().isAuthenticated;
    const [user] = useStore((state) => [state.user]);
    const navigate = useNavigate();

    console.log("IS ONBOARDING ---> ", isOnBoarding);
    console.log("IS AUTHENTICATED -----> ", isAuthenticated);

    const [isAccountSetup, setIsAccountSetup] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string>("");

    useEffect(() => {
        if (searchParams.get("source") != null) {
            setIsAccountSetup(searchParams.get("source") === "account_setup");
        }
    }, []);

    const schema = z
        .object({
            password: z.string(),
            password_confirm: z.string(),
        })
        .refine((data) => data.password === data.password_confirm, {
            message: "Passwords don't match",
            path: ["password_confirm"],
        });

    type PasswordConfirmFormData = ReturnType<typeof schema.parse>;
    type PasswordConfirmFormDataWithGeneral = PasswordConfirmFormData & {
        general: never;
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm<PasswordConfirmFormDataWithGeneral>({
        resolver: zodResolver(schema),
    });

    const onSubmit = async (data: PasswordConfirmFormData) => {
        try {
            const response = await passwordResetConfirm(
                uid,
                resetToken,
                data,
                isAccountSetup
            );

            clearErrors();
            const { user } = response.data;
            authenticate(user);

            if (isAccountSetup) {
                navigate(userTypeOnboardingRoute(user.user_type), {
                    replace: true,
                });
                return;
            }

            const isNewUser = user?.status === "NEW";
            if (isNewUser) {
                navigate(userTypeOnboardingRoute(user.user_type), {
                    replace: true,
                });
                return;
            }

            navigate(userTypeHomeRoute(user?.user_type), { replace: true });
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    async function requestNewToken() {
        try {
            const response = await resendAccountSetupEmail(uid);
            setSuccessMessage(response.data.msg);
            clearErrors();
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    }

    return (
        <AuthLayoutV2>
            <div
                className="mx-auto mt-32 max-w-md rounded-lg bg-white px-4 py-10 shadow-md sm:px-6 md:px-8"
                style={{ backgroundColor: "rgba(111, 56, 148, 0.3)" }}
            >
                <h1 className="mt-6  text-4xl font-bold text-purple-500">
                    {isAccountSetup
                        ? "Account Password Setup"
                        : "Password Reset Confirmation"}
                </h1>

                <h1 className="mt-6 text-2xl font-medium text-gray-900">
                    Welcome onboard with us!
                </h1>

                <GeneralError
                    className="mt-4"
                    message={
                        isInvalidTokenError(errors.general?.message ?? "")
                            ? invalidTokenErrorMessage(requestNewToken)
                            : errors.general?.message
                    }
                />

                {successMessage !== "" && (
                    <Alert
                        className="mt-4"
                        intent="success"
                        title={"Successful new token request"}
                        description={successMessage}
                    />
                )}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="mt-6 space-y-6 px-4"
                    method="POST"
                >
                    <div>
                        <Label htmlFor="password" name="New Password" />
                        <PasswordFieldV2
                            {...register("password")}
                            id="password"
                            className="mt-1 w-full"
                            autoFocus
                            autoComplete="off"
                            aria-invalid={!(errors.password == null)}
                            required
                        />
                        <FieldError
                            className="mt-1"
                            message={errors.password?.message}
                        />
                    </div>
                    <div className="mt-4">
                        <Label
                            htmlFor="password-confirm"
                            name="Confirm New Password"
                        />
                        <PasswordFieldV2
                            {...register("password_confirm")}
                            className="mt-1 w-full"
                            id="password-confirm"
                            autoComplete="off"
                            aria-invalid={!(errors.password_confirm == null)}
                            required
                        />
                        <FieldError
                            className="mt-1"
                            message={errors.password_confirm?.message}
                        />
                    </div>
                    <div className="mt-8 flex justify-end">
                        <Button type="submit">
                            {isAccountSetup ? "Setup" : "Reset"} Password
                        </Button>
                    </div>
                </form>
            </div>
        </AuthLayoutV2>
    );
};
