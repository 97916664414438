export default function RankingIcon({ className }: IconProps) {
    return (
        <svg
            className={className}
            width="100%"
            height="100%"
            viewBox="0 0 23 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.4383 1.90134L11.6086 0.220867C11.4715 -0.0709297 11.0426 -0.0814766 10.8914 0.220867L10.0617 1.90134L8.22305 2.16501C7.89609 2.21423 7.75547 2.61852 7.99805 2.8611L9.33398 4.16188L9.01758 5.99352C8.96836 6.32048 9.30586 6.5736 9.6082 6.42243L11.257 5.55055L12.8953 6.40837C13.1977 6.55954 13.5387 6.30641 13.4859 5.97946L13.1695 4.14782L14.5055 2.8611C14.7445 2.62204 14.6074 2.21774 14.2805 2.16501L12.4418 1.90134H12.4383ZM9 8.99938C8.37773 8.99938 7.875 9.50212 7.875 10.1244V16.8744C7.875 17.4966 8.37773 17.9994 9 17.9994H13.5C14.1223 17.9994 14.625 17.4966 14.625 16.8744V10.1244C14.625 9.50212 14.1223 8.99938 13.5 8.99938H9ZM1.125 11.2494C0.502734 11.2494 0 11.7521 0 12.3744V16.8744C0 17.4966 0.502734 17.9994 1.125 17.9994H5.625C6.24727 17.9994 6.75 17.4966 6.75 16.8744V12.3744C6.75 11.7521 6.24727 11.2494 5.625 11.2494H1.125ZM15.75 14.6244V16.8744C15.75 17.4966 16.2527 17.9994 16.875 17.9994H21.375C21.9973 17.9994 22.5 17.4966 22.5 16.8744V14.6244C22.5 14.0021 21.9973 13.4994 21.375 13.4994H16.875C16.2527 13.4994 15.75 14.0021 15.75 14.6244Z"
                fill="#FF6F8A"
            />
        </svg>
    );
}
