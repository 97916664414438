import { useState } from "react";
import { ArrowLeftIcon, CheckBadgeIcon } from "@heroicons/react/24/outline";
import Avatar from "../Avatar";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
    getTeamBadge,
    getStudentBadges,
    awardStudentBadge,
    awardTeamBadge,
} from "@src/api/league";
import SavingIndicator from "../SavingIndicator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import TemplateDialog from "../Dialog/TemplateDialog";

// This component contains the team information i.e the name, description, logo, team members etc

// The team member component
const TeamMember = ({ member, studentBadges }) => {
    const [open, setOpen] = useState(false);

    const { id, full_name } = member;

    let facilitatorBadges = [];

    facilitatorBadges = studentBadges?.filter(
        (badge) => badge.is_facilitator_assigned === true
    );

    const handleOpenModal = () => {
        setOpen(true);
    };

    // Modal that pops up when award badge is pressed
    const BadgeModal = () => {
        const [awardingBadge, setAwardingBadge] = useState(-Infinity);
        const { mutate: awardBadge } = useMutation({
            mutationFn: async (data) => {
                await awardStudentBadge(data);
            },
            onSuccess: () => {
                toast.success(`Badge awarded to ${full_name}`, {
                    autoClose: 2000,
                });
                setAwardingBadge(-Infinity);
                setOpen(false);
            },
            onError: () => {
                toast.error(`An unexpected error occured`, {
                    autoClose: 2000,
                });
                setAwardingBadge(-Infinity);
            },
        });

        const handleAwardStudentBadge = (badgeId) => {
            setAwardingBadge(badgeId);
            const postData = {
                badge: badgeId,
                student: id,
            };
            awardBadge(postData);
        };
        return (
            <div className="flex w-[350px] flex-col  items-center overflow-y-scroll">
                {facilitatorBadges.map((badge) => (
                    <div
                        className="mx-1 flex w-full cursor-pointer  flex-row items-center rounded-md p-2 hover:bg-purple-50"
                        onClick={() => handleAwardStudentBadge(badge.id)}
                    >
                        <img
                            src={badge.animation_url}
                            alt="bagde1"
                            className="mr-2 h-[70px] w-[70px]"
                        />
                        <div className="w-full flex-col items-start ">
                            <p className="mb-1.5 ml-2 text-lg font-semibold">
                                {badge.name}
                            </p>
                            <p className="ml-2 mt-1.5">{badge.description}</p>
                        </div>
                        {awardingBadge === badge.id && (
                            <SavingIndicator message="Awarding" />
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="m-1 flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
                <Avatar size="42" name={member.full_name ?? ""} />
                <p className="ml-2">{member.first_name}</p>
            </div>

            <TemplateDialog
                isOpen={open}
                setIsOpen={setOpen}
                children={<BadgeModal />}
                options={{ isStudent: true, student: member }}
            />
            <div
                className="flex w-[50px] min-w-36 cursor-pointer flex-row justify-around rounded-md border border-purple-500 p-2 hover:bg-purple-100"
                onClick={handleOpenModal}
            >
                <CheckBadgeIcon className=" h-6" color="purple" />
                <p className="text-purple-500"> Award Badge</p>
            </div>
        </div>
    );
};

// Team information, name description etc

const TeamData = ({ team }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleBackClicked = () => navigate("/school/dashboard");

    const handleOpenModal = () => {
        setOpen(true);
    };

    const { data: teamBadges } = useQuery({
        queryKey: ["team-badges"],
        queryFn: async () => await getTeamBadge("1"),
    });

    const { data: studentBadges } = useQuery({
        queryKey: ["student-badges"],
        queryFn: async () => await getStudentBadges("1"),
    });

    const {
        created,
        id,
        members,
        school,
        team_description,
        team_logo,
        team_name,
    } = team;

    const getInitials = (teamName) => {
        return `${teamName.charAt(0).toUpperCase()}`;
    };
    const BadgeModal = () => {
        const [awardingBadge, setAwardingBadge] = useState(-Infinity);
        let facilitatorBadges = [];
        facilitatorBadges = teamBadges?.filter(
            (badge) => badge.is_facilitator_assigned === true
        );
        const { mutate: awardBadge } = useMutation({
            mutationFn: async (data) => {
                await awardTeamBadge(data);
            },
            onSuccess: () => {
                toast.success(`Badge awarded to ${team_name}`, {
                    autoClose: 2000,
                });
                setAwardingBadge(-Infinity);
                setOpen(false);
            },
            onError: () => {
                toast.error(`An unexpected error occured`, {
                    autoClose: 2000,
                });
                setAwardingBadge(-Infinity);
            },
        });

        const handleAwardTeamBadge = (badgeId) => {
            setAwardingBadge(badgeId);
            const postData = {
                badge: badgeId,
                team: id,
            };
            awardBadge(postData);
        };
        return (
            <div className="flex w-full flex-col items-center">
                {facilitatorBadges.map((badge) => (
                    <div
                        className="mx-1 flex w-full cursor-pointer  flex-row items-center rounded-md p-2 hover:bg-purple-50"
                        onClick={() => handleAwardTeamBadge(badge.id)}
                    >
                        <img
                            src={badge.animation_url}
                            alt="bagde1"
                            className="mr-2 h-[70px] w-[70px]"
                        />
                        <div className="w-full flex-col items-start ">
                            <p className="mb-1.5 ml-2 text-lg font-semibold">
                                {badge.name}
                            </p>
                            <p className="ml-2 mt-1.5">{badge.description}</p>
                        </div>
                        {awardingBadge === badge.id && (
                            <SavingIndicator message="Awarding" />
                        )}
                    </div>
                ))}
            </div>
        );
    };
    return (
        <section className="max-w-5xl rounded-lg bg-white px-4 py-6 shadow sm:px-6 md:px-8">
            <>
                <div className="flex w-full flex-row items-center">
                    <div
                        className="flex h-6 w-9 cursor-pointer items-center justify-center rounded-md bg-slate-300"
                        onClick={handleBackClicked}
                    >
                        <ArrowLeftIcon className="h-4 w-4" />
                    </div>
                    <h2 className="ml-2 text-2xl font-semibold">
                        {team.team_name}
                    </h2>
                </div>

                <div className="flex w-full flex-col justify-between md:flex-row">
                    <div className="flex w-full flex-col items-start">
                        <div className="mt-4 flex w-full flex-row items-center justify-between ">
                            {/* The logo */}

                            <div className="flex h-36  min-w-40 items-center justify-center rounded-sm bg-gray-300">
                                {team_logo ? (
                                    <img
                                        src={team_logo}
                                        alt={`${team_name}`}
                                        className="h-12 w-12 rounded-full"
                                    />
                                ) : (
                                    <span className="text-lg font-bold text-white">
                                        {getInitials(team_name)}
                                    </span>
                                )}
                            </div>

                            {/* The team details */}

                            <div className="ml-2 flex h-36 flex-col items-start justify-between">
                                <p>Name</p>

                                <p>Description</p>

                                <p>Submissions</p>
                            </div>

                            <div className="ml-3 flex h-36 flex-col items-start justify-between">
                                <p className="font-semibold">{team_name}</p>

                                <p className="font-semibold">
                                    {team_description}
                                </p>

                                <p className="font-semibold">12</p>
                            </div>
                        </div>

                        <TemplateDialog
                            isOpen={open}
                            setIsOpen={setOpen}
                            children={<BadgeModal />}
                            options={{ isStudent: false, team }}
                        />

                        <div
                            className="mb-6 mt-6 flex w-[50px] min-w-48 cursor-pointer flex-row justify-around rounded-md border border-purple-500  p-2 hover:bg-purple-100"
                            onClick={handleOpenModal}
                        >
                            <CheckBadgeIcon className=" h-6" color="purple" />
                            <p className="text-purple-500"> Award Team Badge</p>
                        </div>
                    </div>

                    {/* Team Members */}
                    <div className="flex w-full flex-col">
                        <p>Team members</p>
                        <div className="mt-4 flex max-h-44 w-full flex-col overflow-x-scroll px-6">
                            {members.map((member) => {
                                return (
                                    <>
                                        <TeamMember
                                            key={member.id}
                                            member={member}
                                            studentBadges={studentBadges}
                                        />
                                        <hr className="m-1" />
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </>
        </section>
    );
};

export default TeamData;
