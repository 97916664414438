import { User } from "@src/models/types";
import axios from "./axios";
import { type QueryOptions } from "@tanstack/react-query";
import { string } from "prop-types";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiReactQueryKeys } from "./apiReactQueryKeys";

export interface Post {
    title: string;
    content: string;
}

export interface RankedUser {
    user: User;
    rank_summary?: any | null;
}

export interface PostFromAPI extends Post {
    id: number;
    created: string;
    modified: string;
    votes: number;
    views: number;
    created_by: RankedUser;
    comments_count: number;
    voted_by_current_user: boolean;
}

export interface PostWithThread {
    post: PostFromAPI;
    threads: ThreadFromAPI[];
}

export interface Page<T> {
    results: T[];
    count: number;
    next: string | null;
    previous: string | null;
}

export async function posts() {
    const res = axios.get<Page<PostFromAPI>>("/forums/post/");
    return (await res).data;
}

interface EditPostType {
    title: string;
    content: string;
    id: string;
}

const editPost = async (data: EditPostType) => {
    const { title, content, id } = data;
    const response = await axios.patch(`/forums/post/${id}/`, {
        title,
        content,
    });
    return response.data;
};

interface EditCommentType {
    threadId: string;
    content: string;
}

const editComment = async (data: EditCommentType) => {
    const { threadId, content } = data;

    const response = await axios.patch(`/forums/thread/${threadId}/`, {
        content,
    });
    return response.data;
};

export const useEditComment = () => {
    return useMutation({
        mutationKey: [ApiReactQueryKeys.EDIT_THREAD],
        mutationFn: async (data: EditCommentType) => await editComment(data),
    });
};

export const useEditPost = () => {
    const queryClient = useQueryClient();
    return useMutation(
        {
            mutationKey: [ApiReactQueryKeys.EDIT_FORUM_POST],
            mutationFn: async (data: EditPostType) => await editPost(data),
        },
        {
            onSuccess: async () => {
                try {
                    await queryClient.invalidateQueries([
                        ApiReactQueryKeys.FETCH_FORUM_POSTS,
                    ]);
                } catch (error) {
                    console.log("FAILED TO INVALIDATE ---> ", error);
                }
            },
        }
    );
};

export function postsQuery() {
    return {
        queryKey: [ApiReactQueryKeys.FETCH_FORUM_POSTS],
        queryFn: async () => await posts(),
    } satisfies QueryOptions;
}

export async function post(id: number) {
    let res = await axios.get<PostWithThread>(`/forums/post/${id}/`);
    return res.data;
}

export function postQuery(id: number) {
    return {
        queryKey: [id],
        queryFn: async () => await post(id),
    } satisfies QueryOptions;
}

export interface NewPost {
    title: string;
    content: string;
}

export async function newPost(data: NewPost) {
    let res = await axios.post(`/forums/post/`, data);
    return res.data;
}

export interface Thread {
    content: string;
    post: number;
    parent_thread: number;
    has_children: boolean;
}

export interface ThreadFromAPI extends Thread {
    id: number;
    created: string;
    modified: string;
    created_by: RankedUser;
}

export interface ThreadDetails extends ThreadFromAPI {
    children: ThreadFromAPI[];
}

export async function thread(thread: number) {
    let res = await axios.get<ThreadDetails>(`/forums/thread/${thread}/`);
    return res.data;
}

export function threadQuery(threadId: number) {
    return {
        queryKey: [threadId, "thread", "details"],
        queryFn: async () => await thread(threadId),
    } satisfies QueryOptions;
}

export interface NewThread {
    content: string;
    post: number;
    parent_thread?: number | null;
}

export async function newThread(
    data: NewThread,
    parent_thread?: number | null
) {
    let url = parent_thread
        ? `/forums/post/${data.post}/thread/${parent_thread!}/`
        : `/forums/post/${data.post}/thread/`;
    return (await axios.post<ThreadFromAPI>(url, (data = data))).data;
}

export async function vote(post: number) {
    let res = await axios.post(`/forums/post/${post}/vote/`);
    return res.data;
}

export async function unvote(post: number) {
    let res = await axios.post(`/forums/post/${post}/unvote/`);
    return res.data;
}


export interface File {
    name: string,
    type: "Image" | "Video",
    file: string,
}
