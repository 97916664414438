import PageHeader from "@src/components/PageHeader";
import Overview from "./Overview";
import TeamClassroomProgress from "./TeamClassroomProgress";
import FiresideWatchHistory from "./FiresideWatchHistory";
import { profileQueryOptions } from "@src/api/user";
import { useQuery, type QueryClient } from "@tanstack/react-query";
import { childrenQueryOptions } from "@src/api/parents";
import type { Student, User } from "@src/models/types";
import type { LoaderReturnType } from "@src/utils/type_helpers";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { useSessionStorage } from "@src/hooks/useSessionStorage";

export function loader(queryClient: QueryClient) {
    return async () => {
        const user = await queryClient.ensureQueryData(profileQueryOptions());
        return await queryClient.ensureQueryData(childrenQueryOptions(user.id));
    };
}

export default function ParentDashboardPage() {
    const user = useRouteLoaderData("root") as User;

    const initialData = useLoaderData() as LoaderReturnType<typeof loader>;

    const { data: students } = useQuery({
        ...childrenQueryOptions(user.id),
        initialData,
    });

    const [selectedStudent, setSelectedStudent] =
        useSessionStorage<Student | null>(
            "Selected_Student",
            students.length > 0 ? students[0] : null
        );

    return (
        <>
            <PageHeader title={"Dashboard"} breadcrumbPaths={[]} />
            <section className="container mx-auto px-4 pb-20 pt-10 sm:px-6 md:px-8">
                <div className="space-y-20">
                    <Overview
                        students={students}
                        selectedStudent={selectedStudent}
                        setSelectedStudent={setSelectedStudent}
                    />
                    <TeamClassroomProgress
                        studentId={selectedStudent?.id}
                        teamId={selectedStudent?.teams[0]?.id}
                    />
                    <FiresideWatchHistory studentId={selectedStudent?.id} />
                </div>
            </section>
        </>
    );
}
