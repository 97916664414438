import type React from "react";
import { useMemo } from "react";
import Badge from "@components/tables/components/Badge";
import Table from "@components/tables/index";
import { type ColumnDef } from "@tanstack/react-table";
import {
    type IndividualLeaderboardRecord,
    type Badge as LeaderboardBadge,
} from "@src/models/types";

const LeagueIndividualLeaderboard: React.FC<{
    leaderboard: IndividualLeaderboardRecord[];
}> = ({ leaderboard }) => {
    const columns: Array<ColumnDef<IndividualLeaderboardRecord>> = [
        {
            header: "Rank",
            accessorKey: "rank",
            cell: ({ row }) => {
                return (
                    <div className="justify-self-center">
                        <Badge rank={`${row.index + 1}`} />
                    </div>
                );
            },
        },
        {
            header: "Name",
            accessorFn: (row) => `${row.first_name} ${row.last_name}`.trim(),
            cell: ({ getValue, row }) => {
                const name = getValue() as string;
                return (
                    <div className="flex w-full h-full flex items-start justify-start pl-5">
                        <div className="md:px-4">
                            <img
                                src={
                                    row.original.avatar !== null
                                        ? row.original.avatar
                                        : row.original.gender === "F"
                                        ? "/images/avatar-f.png"
                                        : "/images/avatar-m.png"
                                }
                                title={name}
                                className="mr-2 hidden h-8  w-8 rounded-full border-2 border-[#B990D5] md:inline-block md:h-12 md:w-12"
                            />
                            <span>{name}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            header: "Points",
            accessorKey: "points",
            cell: ({ getValue }) => {
                return (
                    <span className="text-center text-primary-500">
                        {getValue() as string}
                    </span>
                );
            },
        },
        {
            header: "Badges",
            accessorKey: "badges",
            cell: ({ getValue }) => {
                const badges = getValue() as LeaderboardBadge[];
                return (
                    <div className="flex flex-row justify-self-center overflow-hidden">
                        {badges.length > 0 &&
                            badges.map((badge, idx) => (
                                <img
                                    key={idx}
                                    src={badge.poster_url}
                                    title={badge.name}
                                    className="mr-2 h-8 w-8 rounded-full"
                                />
                            ))}
                        {badges.length === 0 && <span>No Badges!</span>}
                    </div>
                );
            },
        },
    ];

    const leaderboardTableData = useMemo<IndividualLeaderboardRecord[]>(() => {
        return leaderboard;
    }, []);

    return (
        <section>
            <Table
                columns={columns}
                data={leaderboardTableData}
                title="Individual Leaderboard"
            />
        </section>
    );
};

export default LeagueIndividualLeaderboard;
