import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    getFilteredRowModel,
    getPaginationRowModel,
    type ColumnDef,
} from "@tanstack/react-table";
import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { cn } from "@src/utils";

export interface TableProps<TData, TValue> {
    title: string;
    data: TData[];
    columns: Array<ColumnDef<TData, TValue>>;
    pagination?: boolean;
}

const ParentDashboardTable = <TData, TValue>({
    title,
    data,
    columns,
    pagination = false,
}: TableProps<TData, TValue>) => {
    const [filtering, setFiltering] = useState("");

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            globalFilter: filtering,
        },
        onGlobalFilterChange: setFiltering,
        getPaginationRowModel: getPaginationRowModel(),
    });

    return (
        <>
            <div className="overflow-hidden bg-white rounded-lg shadow-md">
                <div className="flex items-center justify-between px-5 py-8 border-gray-200 rounded-lg">
                    <h2 className="my-5 text-2xl font-bold text-left">
                        {title}
                    </h2>
                    <input
                        type="text"
                        value={filtering}
                        onChange={(e) => {
                            setFiltering(e.target.value);
                        }}
                        className="border border-gray-400 rounded-md focus:border-purple-500 focus:outline-none focus:ring-1 focus:ring-purple-500"
                        placeholder="search"
                    />
                </div>
                <div className="relative overflow-x-auto">
                    <table className="w-full border border-gray-200 table-auto">
                        <thead className="text-lg text-gray-700 uppercase bg-gray-50">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className={cn(
                                                "px-6 py-3 text-start text-sm",
                                                {
                                                    "text-right":
                                                        header.column.columnDef
                                                            .meta?.align ===
                                                        "right",
                                                    "text-center":
                                                        header.column.columnDef
                                                            .meta?.align ===
                                                        "center",
                                                }
                                            )}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.length === 0 ? (
                                <tr>
                                    <td
                                        colSpan={columns.length}
                                        className="py-4 text-lg"
                                    >
                                        No data Available
                                    </td>
                                </tr>
                            ) : (
                                table.getRowModel().rows.map((row) => (
                                    <tr
                                        key={row.id}
                                        className={"bg-white even:bg-purple-50"}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <td
                                                key={cell.id}
                                                className={cn("px-6 py-4", {
                                                    "text-right":
                                                        cell.column.columnDef
                                                            .meta?.align ===
                                                        "right",
                                                    "text-center":
                                                        cell.column.columnDef
                                                            .meta?.align ===
                                                        "center",
                                                })}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    {pagination && (
                        <div className="flex items-center justify-center max-w-sm gap-4 mx-auto my-4">
                            <button
                                className="p-2 border border-purple-500 rounded-full hover:bg-purple-500 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
                                onClick={() => {
                                    table.previousPage();
                                }}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <ChevronLeftIcon className="w-4 h-4 text-black" />
                            </button>
                            <div className="mx-4">
                                {table.getState().pagination.pageIndex + 1} /{" "}
                                {table.getPageCount()}
                            </div>
                            <button
                                className="p-2 border border-purple-500 rounded-full hover:bg-purple-500 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
                                onClick={() => {
                                    table.nextPage();
                                }}
                                disabled={!table.getCanNextPage()}
                            >
                                <ChevronRightIcon className="w-4 h-4 text-black" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ParentDashboardTable;
