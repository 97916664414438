import { Link } from "react-router-dom";
import useStore from "@store/useStore";
import { Bars3Icon } from "@heroicons/react/24/outline";
import Button from "../Button";
import NotificationsPopover from "./NotificationsPopover";
import UserAvatarMenu from "./UserAvatarMenu";
import { userTypeHomeRoute } from "@src/api/auth";
import NetworkStatusIndicator from "../NetworkStatus/NetworkStatusIndicator";

const Topnav = () => {
    const toggleMobileMenu = useStore((state) => state.toggleMobileMenu);
    const user = useStore((state) => state.user);

    return (
        <div className="flex flex-col items-center">
            <header className="flex w-full items-center justify-between border-b border-gray-200 bg-white py-2 pl-6 pr-10">
                <Button
                    className="md:hidden"
                    variant="text"
                    intent="white"
                    size="sm"
                    type="button"
                    title="Open sidebar"
                    onClick={toggleMobileMenu}
                >
                    <Bars3Icon className="h-8 w-8" />
                </Button>
                <Link to={userTypeHomeRoute(user?.user_type) ?? "/"}>
                    <img
                        style={{ width: "100px" }}
                        src="/images/kineticp.png"
                        alt="Kinetic Platform"
                    />
                </Link>

                <div className="flex items-center">
                    <NotificationsPopover />
                    {user && <UserAvatarMenu />}
                </div>
            </header>
            <NetworkStatusIndicator />
        </div>
    );
};

export default Topnav;
