import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import Button from "@components/Button";

const TIMER_DURATION_IN_MS = 604_800_000; // 7 days
const ONE_HOUR_IN_MS = 60 * 60 * 1000;

const Timer = () => {
    const [time, setTime] = useState<number>(
        () => {
            const persistedTime = localStorage.getItem("timer");

            return persistedTime !== null ? parseInt(persistedTime) : TIMER_DURATION_IN_MS;
        }
    );

    useEffect(() => {
        let intervalId: number;

        if (time !== 0) {
            // Update time every 1 hour
            intervalId = setInterval(() => {
                setTime((prevTime) => {
                    const newTime = prevTime - ONE_HOUR_IN_MS;
                    return newTime;
                });
            }, ONE_HOUR_IN_MS);
        }

        return () => {
            if(intervalId !== undefined) {
                clearInterval(intervalId);
            }
        };
    }, []);

    // Sync with localStorage
    useEffect(() => {
        localStorage.setItem("timer", time.toString());
    }, [time]);

    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0");

    const resetTimer = () => {
        setTime(TIMER_DURATION_IN_MS);
    };

    return (
        <>
            <div className="flex items-center justify-center gap-2 p-4 bg-orange-500 rounded-lg">
                <section className="flex flex-col items-center">
                    <div className="px-5 py-1 font-bold text-orange-500 bg-orange-200 rounded-lg text-9xl">
                        {days}
                    </div>
                    <span className="mt-2 text-xl font-semibold text-white uppercase">
                        Days
                    </span>
                </section>

                {/* Colon */}
                <span className="self-start text-white text-9xl">:</span>

                <section className="flex flex-col items-center">
                    <section className="flex space-x-4 font-bold text-orange-500 text-9xl">
                        <div className="px-5 py-1 bg-orange-200 rounded-lg">
                            {hours[0]}
                        </div>
                        <div className="px-5 py-1 bg-orange-200 rounded-lg">
                            {hours[1]}
                        </div>
                    </section>
                    <span className="mt-2 text-xl font-semibold text-white uppercase">
                        Hours
                    </span>
                </section>
            </div>

            <div className="flex justify-center gap-5">
                <Button
                    size="xs"
                    intent="secondary"
                    variant="text"
                    type="button"
                    title="Reset timer"
                    onClick={resetTimer}
                >
                    {/* Reset */}
                    <span className="sr-only">Reset timer</span>
                    <ArrowPathIcon className="h-7 w-7" />
                </Button>
            </div>
        </>
    );
};

export default Timer;
