import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import {
    singleFiresideHistortyDetailsQuery,
    studentReflectionEntriesQuery,
    newFiresideSubmissionFeedback,
    parentFiresideFeedbackQuery,
} from "@src/api/students";
import { reflectionQuestionsQuery } from "@api/firesides";
import Button from "@src/components/Button";
import Video, { VideoType } from "@src/components/Content/Video";
import { FieldError, TextArea } from "@src/components/Form";
import PageHeader from "@src/components/PageHeader";
import type { LoaderReturnType } from "@src/utils/type_helpers";
import {
    type QueryClient,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import {
    type LoaderFunctionArgs,
    useNavigate,
    useParams,
    useLoaderData,
    useRouteLoaderData,
} from "react-router-dom";
import z from "zod";
import StudentInformationContext from "./StudentInformationContext";
import { Fragment } from "react";
import Avatar from "@src/components/Avatar";
import type { User } from "@src/models/types";
import Time from "@src/components/Time";
import { useForm } from "react-hook-form";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import SavingIndicator from "@src/components/SavingIndicator";

interface ParentFiresideCommentForm {
    content: string;
}

const RouteParamsSchema = z.object({
    studentId: z.coerce.number(),
    firesideId: z.coerce.number(),
});

export function loader(queryClient: QueryClient) {
    return async ({ params }: LoaderFunctionArgs) => {
        const { studentId, firesideId } = RouteParamsSchema.parse(params);

        const reflectionEntries = await queryClient.ensureQueryData(
            studentReflectionEntriesQuery(studentId, firesideId)
        );

        const watchedFireside = await queryClient.ensureQueryData(
            singleFiresideHistortyDetailsQuery(studentId, firesideId)
        );

        const reflectionQuestions = await queryClient.ensureQueryData(
            reflectionQuestionsQuery(watchedFireside.slug)
        );

        return [
            reflectionEntries,
            reflectionQuestions,
            watchedFireside,
        ] as const;
    };
}

const SingleFireSideViewed = () => {
    const [
        firesideLoaderData,
        reflectionsLoaderData,
        firesideDetailsLoaderData,
    ] = useLoaderData() as LoaderReturnType<typeof loader>;

    const user = useRouteLoaderData("root") as User;

    const { studentId, firesideId } = RouteParamsSchema.parse(useParams());

    const { data: reflectionEntries } = useQuery({
        ...studentReflectionEntriesQuery(studentId, firesideId),
        initialData: firesideLoaderData,
    });

    const { data: firesideDetails } = useQuery({
        ...singleFiresideHistortyDetailsQuery(studentId, firesideId),
        initialData: firesideDetailsLoaderData,
    });

    const { data: reflectionQuestions } = useQuery({
        ...reflectionQuestionsQuery(firesideDetails.slug),
        initialData: reflectionsLoaderData,
    });

    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<ParentFiresideCommentForm>({ defaultValues: { content: "" } });

    const submit = async (data: ParentFiresideCommentForm) => {
        try {
            await newFiresideSubmissionFeedback(reflectionEntries[0]?.id, data);

            reset();

            await queryClient.invalidateQueries({
                queryKey: ["fireside-comments", reflectionEntries[0]?.id],
            });
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    const { data: firesideFeedback } = useQuery({
        ...parentFiresideFeedbackQuery(reflectionEntries[0]?.id),
        initialData: [],
    });

    const navigate = useNavigate();

    const breadcrumbs = [
        {
            name: ` Fireside Feedback : ${firesideDetails.title}`,
            href: `/parent/students/${studentId}/feedback-fireside/${firesideId}`,
        },
    ];

    return (
        <>
            <PageHeader
                title={firesideDetails.title}
                breadcrumbPaths={breadcrumbs}
            />
            <StudentInformationContext />
            <section className="px-4 pb-20 mx-auto max-w-7xl sm:px-6 md:px-8">
                {/* back to origin source page  */}
                <div className="flex items-center gap-5 mt-5">
                    <Button
                        variant="outlined"
                        size="xs"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <ArrowLeftIcon className="w-4 h-4 mx-auto" />
                        <span className="mx-1">Back</span>
                    </Button>
                    <div>
                        <p className="text-2xl font-semibold">
                            {firesideDetails.speaker.name}
                        </p>
                        <p className="text-lg font-semibold">
                            {firesideDetails.speaker.title}{" "}
                        </p>
                    </div>
                </div>

                {/* video player */}
                <div className="mt-10">
                    <Video
                        src={firesideDetails.video_url}
                        title={firesideDetails.title}
                        className="max-w-6xl mx-auto aspect-video "
                        videoType={[VideoType.Fireside, 0, firesideDetails.slug]}
                    />
                </div>

                {/* questions & responses  */}
                <div className="max-w-5xl px-10 pt-10 pb-12 mx-auto mt-10 bg-white border rounded-md shadow">
                    <h2 className="mb-4 text-3xl font-semibold">
                        Student Reflections
                    </h2>
                    <>
                        {reflectionEntries?.[0]?.responses?.length > 0 ? (
                            <>
                                {reflectionQuestions.map((question, indx) => (
                                    <div key={indx} className="text-lg">
                                        <p className="font-medium ">
                                            {question?.prompt}
                                        </p>
                                        <Fragment>
                                            {reflectionEntries?.[0]?.responses
                                                .filter(
                                                    (resp) =>
                                                        resp.reflection_question ===
                                                        question.id
                                                )
                                                .map((resp, indx) => (
                                                    <p
                                                        key={indx}
                                                        className="pt-1 pb-4 leading-8 text-slate-600"
                                                    >
                                                        {
                                                            resp.content
                                                                ?.value as string
                                                        }
                                                    </p>
                                                ))}
                                        </Fragment>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="grid place-content-center">
                                    <div className="px-8 py-4 rounded-md bg-slate-300">
                                        <p className="font-medium ">
                                            No reflection responses were
                                            submitted
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                </div>

                {/* parent feedback  */}
                <div className="max-w-5xl px-10 pt-10 pb-12 mx-auto mt-10 bg-white border rounded-md shadow">
                    <h2 className="text-3xl font-semibold">Feedback</h2>
                    <section>
                        {firesideFeedback.length > 0 ? (
                            <>
                                <ul role="list" className="mt-4 space-y-8">
                                    {firesideFeedback?.map((comment) => (
                                        <li key={comment.id}>
                                            <div className="flex space-x-3">
                                                <div className="flex-shrink-0">
                                                    <Avatar
                                                        size="42"
                                                        name={
                                                            comment.submitter
                                                                .full_name ?? ""
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <div>
                                                        <span className="font-medium text-gray-900">
                                                            {
                                                                comment
                                                                    .submitter
                                                                    .full_name
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="mt-1 text-gray-700">
                                                        <p>{comment.content}</p>
                                                    </div>
                                                    <div className="mt-2 space-x-2">
                                                        <Time
                                                            value={
                                                                new Date(
                                                                    comment.created_at
                                                                )
                                                            }
                                                            className="font-medium text-gray-500"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <div className="p-10 mx-auto mt-4 border border-dashed rounded-md ">
                                <p className="text-lg text-center text-gray-700">
                                    Add new feedback using the comment box
                                    below
                                </p>
                            </div>
                        )}
                    </section>
                    <div className="flex gap-4 mt-10">
                        <div className="flex-shrink-0">
                            <Avatar
                                src={user.avatar ?? undefined}
                                name={user.full_name ?? ""}
                                size="42"
                            />
                        </div>
                        <form className="flex-1" onSubmit={handleSubmit(submit)}>
                            <TextArea
                                className="w-full"
                                rows={4}
                                placeholder="What are your thoughts?"
                                {...register("content")}
                            />
                            <FieldError
                                className="mt-1"
                                message={errors?.content?.message}
                            />
                            <div className="flex items-center justify-end py-4">
                                {isSubmitting && (
                                    <SavingIndicator className="mr-2" />
                                )}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="lg"
                                >
                                    Comment
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SingleFireSideViewed;
