import { login, userTypeHomeRoute } from "@api/auth";
import Button from "@components/Button";
import { FieldError, GeneralError, Label, PasswordField, TextField } from "@components/Form";
import AuthLayout from "@src/components/layout/AuthLayout";
import { responseErrorToFormErrors } from "@utils/form-errors";
import { Link, useNavigate } from "react-router-dom";
import useStore from "@store/useStore";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { posthog } from "posthog-js";

const loginFormSchema = z.object({
    email: z.string().email(),
    password: z.string(),
});

export default function Login() {
    const navigate = useNavigate();
    const authenticate = useStore((state) => state.authenticate);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({ resolver: zodResolver(loginFormSchema) });

    const onSubmit = async (data) => {
        try {
            const loginResponse = await login(data);

            clearErrors();

            const { user } = loginResponse.data;

            authenticate(user);

            // Identify the user with PostHog
            posthog.identify(
                user.email,
                {
                    email: user.email,
                    name: user.full_name,
                    accountStatus: user.account_status,
                    userType: user.user_type,
                    schoolName: user.school?.school_name,
                },
            );

            const redirectPath =
                user.status === "NEW"
                    ? "/reset-password"
                    : userTypeHomeRoute(user.user_type);
            navigate(redirectPath, { replace: true });
        } catch (error) {
            console.error(error);
            responseErrorToFormErrors(error, setError);
        }
    };

    return (
        <AuthLayout>
            <div className="max-w-md px-4 py-10 mx-auto mt-10 bg-white rounded-lg shadow-md sm:px-6 md:px-8">
                <div className="flex justify-center">
                    <img
                        className="h-28 w-28"
                        src="/images/stud_avatar.svg"
                        alt="Student Avatar"
                    />
                </div>
                <h1 className="mt-6 text-3xl font-bold text-center text-gray-900">
                    Let's get you back in
                </h1>

                <GeneralError message={errors.general?.message} />

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="px-4 mt-6 space-y-6"
                    method="POST"
                >
                    <div>
                        <Label htmlFor="email" name="Email address" />
                        <TextField
                            type="email"
                            name="email"
                            id="email"
                            {...register("email")}
                            className="w-full mt-1"
                            placeholder="email@example.com"
                            autoComplete="email"
                            autoFocus
                            required
                        />
                        <FieldError
                            message={errors.email?.message}
                            className="mt-1 error-message"
                        />
                    </div>

                    <div>
                        <Label htmlFor="password" name="Password" />
                        <PasswordField
                            id="password"
                            name="password"
                            className="w-full mt-1"
                            {...register("password")}
                            autoComplete="current-password"
                            placeholder="xxxxxxxx"
                            required
                        />
                        <FieldError
                            message={errors.password?.message}
                            className="mt-1 error-message"
                        />
                    </div>

                    <div className="flex items-center justify-center">
                        <div>
                            <Link
                                to="/password-reset"
                                className="font-medium text-purple-600 transition-colors hover:text-purple-500"
                            >
                                Forgot your password?
                            </Link>
                        </div>
                    </div>

                    <div>
                        <Button type="submit" size="lg" fullWidth>
                            Sign In
                        </Button>
                    </div>
                </form>
            </div>
        </AuthLayout>
    );
}
