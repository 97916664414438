import React from "react";
import ReactDOM from "react-dom/client";
import {
    RouterProvider,
    createRoutesFromElements,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import "./index.css";
import getRouteConfig from "./Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { posthog } from "posthog-js";

import { pdfjs } from "react-pdf";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";

posthog.init("phc_oE0omehNNqmxUEDu5zclEbGZ50cTGMVuvbbmmlDO2Jj", {
    api_host: "https://app.posthog.com",
});

// react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url
).toString();

// Sentry
Sentry.init({
    dsn: "https://e47aaed1a05a42aaa8fdba0fdc13b4b6@o461119.ingest.sentry.io/4505408562397184",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [
                "http://localhost:3000",
                "*.kinetic.education",
            ],
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromElements,
                matchRoutes
            ),
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
        new CaptureConsole({levels: ["error", "warn", "debug", "assert"]}),
    ],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_SENTRY_ENV,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={getRouteConfig(queryClient)} />
            <ToastContainer
                containerId="root-toast-container"
                position="top-center"
                hideProgressBar={true}
                autoClose={10000}
                closeOnClick={true}
                pauseOnHover={true}
                draggable={true}
                theme="light"
            />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>
);
