import { type FacilitatorSessionSlice, type SliceCreator } from "./types";

const initialState: FacilitatorSessionSlice = {
    setActiveSession: () => {},
    activeSession: {
        id: Infinity,
        title: "",
        description: "",
        objective: "",
        outcomes: "",
        session_tags: [],
        poster_image: "",
        sort_order: 0,
        content: [],
    },
    taskNumber: Infinity,
    setTaskNumber: () => {},
    sessionOneTaskLabel: "",
    setSessionOneTaskLabel: () => {},
};

export const createFacilitatorSessionSlice: SliceCreator<
    FacilitatorSessionSlice
> = (set) => ({
    ...initialState,
    setActiveSession: (session) => {
        set({ activeSession: session });
    },
    setTaskNumber: (taskNumber) => {
        set({ taskNumber });
    },
    setSessionOneTaskLabel: (taskLabel) => {
        set({ sessionOneTaskLabel: taskLabel });
    },
});
