import type React from "react";
import clsx from "clsx";

const ContentCountCard: React.FC<{
    Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
    contentCount: number;
    contentTitle: string;
    textColor: string;
    bgColorLight: string;
    bgColor: string;
    className?: string;
}> = (props) => {
    const {
        Icon,
        contentCount,
        bgColor,
        bgColorLight,
        textColor,
        contentTitle,
        className = "",
    } = props;

    return (
        <div
            className={clsx(
                "rounded-md",
                "border-[1px]",
                "border-gray-300",
                "bg-white",
                "px-14",
                "py-9",
                "flex",
                className
            )}
        >
            <div>
                <div className="mb-4">
                    <p className="text-base">{contentTitle}</p>
                    <span
                        className={clsx(
                            "h-0.5",
                            "w-6",
                            "block",
                            "rounded-md",
                            bgColor
                        )}
                    />
                </div>
                <div className="flex items-center gap-12">
                    <div className={clsx("rounded-full", "p-4", bgColorLight)}>
                        <Icon className={clsx("h-8", "w-8", textColor)} />
                    </div>
                    <div>
                        <span
                            className={clsx("text-8xl", "font-bold", textColor)}
                        >
                            {contentCount}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentCountCard;
