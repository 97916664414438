import PageHeader from "@src/components/PageHeader";

import {
    EyeIcon,
    ChatBubbleLeftIcon,
    PencilIcon,
} from "@heroicons/react/24/solid";
import CommentList from "@src/components/Forum/comentList";
import Button from "@src/components/Button";
import { StarAction } from "@src/components/Forum/StarAction";
import { useQuery } from "@tanstack/react-query";
import { type PostFromAPI, postQuery } from "@src/api/forums";
import { useEffect, useState } from "react";
import { useLocation, useRouteLoaderData, Link } from "react-router-dom";
import { type User } from "@src/models/types";
import IntermittentLoader from "@src/components/Loaders/Loaders/IntermittentLoader";

const breadcrumbPaths = [
    {
        name: "Feed",
        href: "/student/forum",
    },
];

const PostDetail = () => {
    const postLocal: PostFromAPI = useLocation().state;
    const user = useRouteLoaderData("root") as User;
    const [isPostCreatedByUser, setIsPostCreatedByUser] = useState(false);

    const [post, setPostLocal] = useState(postLocal);
    const { status, data, isLoading, refetch, isRefetching } = useQuery({
        ...postQuery(postLocal.id),
    });

    useEffect(() => {
        refetch()
            .then(() => {})
            .catch((err) => {
                console.log("ERROR ", err);
            });
    }, []);

    useEffect(() => {
        if (data?.post != null) {
            setPostLocal(data?.post);
        }
    }, [data]);

    useEffect(() => {
        if (!isLoading && status === "success") {
            setPostLocal(data.post);
        }
    }, [isLoading]);

    useEffect(() => {
        if (data !== null) {
            const postUser = data?.post?.created_by?.user;
            if (postUser != null && postUser.id === user.id) {
                setIsPostCreatedByUser(true);
            } else {
                setIsPostCreatedByUser(false);
            }
        }
    }, [data]);

    return (
        <>
            <PageHeader
                title="Post Details"
                breadcrumbPaths={breadcrumbPaths}
                actions={
                    <Button
                        size="lg"
                        intent="primary"
                        href="/student/forum/create"
                    >
                        Create Post
                    </Button>
                }
            />
            <div className="mb-1">{isRefetching && <IntermittentLoader />}</div>

            <section className="h-full overflow-auto bg-white px-20">
                <div className="flex w-fit flex-row items-center gap-5">
                    <h3 className="text-3xl font-semibold">{post.title}</h3>
                    {isPostCreatedByUser && (
                        <Link
                            to="/student/forum/edit"
                            state={{
                                postData: post,
                            }}
                        >
                            <PencilIcon className="h-6 w-6" />
                        </Link>
                    )}
                </div>

                <div
                    className="my-5"
                    dangerouslySetInnerHTML={{ __html: post.content }}
                />

                <div className="flex flex-row space-x-2">
                    <StarAction
                        initialVotes={post.votes}
                        postID={post.id}
                        voted_={post.voted_by_current_user}
                    />
                    <div className="flex flex-row rounded-lg bg-purple-200 p-2">
                        <EyeIcon className="h-6 w-6 cursor-pointer pr-1 text-purple-400" />
                        {post.views}
                    </div>
                    <div className="flex flex-row rounded-lg bg-purple-100 p-2 hover:bg-purple-200">
                        <ChatBubbleLeftIcon className="h-6 w-6 cursor-pointer pr-1 text-purple-400" />
                        {post.comments_count}
                    </div>
                </div>

                <div className="mb-5 mt-5">
                    {isLoading ? (
                        <div className="m-11 flex w-full items-center justify-center">
                            <span className="loader"></span>
                        </div>
                    ) : (
                        <CommentList
                            commentList={data != null ? data.threads : []}
                            with_children={true}
                            post={post.id}
                            refetch={refetch}
                        />
                    )}
                </div>
            </section>
        </>
    );
};

export default PostDetail;
