import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const data = [
    {
        name: "ANNA TAPIA",
        submissions: 6,
    },
    {
        name: "BREANNA REYNOLDS",
        submissions: 17,
    },
    {
        name: "CAMILLA SAUNDERS",
        submissions: 19,
    },
    {
        name: "ENRIQUE ROTH",
        submissions: 27,
    },
    {
        name: "GAUGE KRUEGER",
        submissions: 18,
    },
    {
        name: "HEATHER MUNOZ",
        submissions: 23,
    },
    {
        name: "ISABELLA TATE",
        submissions: 10,
    },
];

const StudentSubmissionChart = () => {
    return (
        <div className="mt-8 rounded-2xl bg-white py-8 drop-shadow-md">
            <div className="flex items-center justify-between px-6">
                <div>
                    <h2 className="text-2xl font-bold leading-6 text-gray-900">
                        Submission
                    </h2>
                </div>
                <div className="flex gap-4"></div>
            </div>
            <ResponsiveContainer
                width="100%"
                height="100%"
                className="mt-8 aspect-video px-5"
            >
                <AreaChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <defs>
                        <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="5%"
                                stopColor="#9659C0"
                                stopOpacity={0.1}
                            />
                            <stop
                                offset="95%"
                                stopColor="#9659C0"
                                stopOpacity={0}
                            />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="name"
                        tickLine={false}
                        dx={0}
                        // tick={<CustomizedTick />}
                        interval={0}
                        // fontSize={9}
                        height={100}
                    />
                    <YAxis tickLine={false} />
                    <Tooltip content={<CustomizedTooltip />} />
                    <Legend
                        verticalAlign="top"
                        height={36}
                        align="right"
                        content={<CustomizedLegend />}
                    />
                    <Area
                        dot={false}
                        type="monotone"
                        dataKey="submissions"
                        stroke="#9659C0"
                        activeDot={{ r: 8 }}
                        strokeWidth={3}
                        fillOpacity={0.1}
                        fill="#9659C0"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

const CustomizedTick: React.FC<{
    x: any[];
    y: any[];
    payload: any[];
}> = ({ x, y, payload }: any) => {
    return (
        <text
            x={x}
            y={y}
            dy={16}
            textAnchor="middle"
            width={50}
            fill="#666"
            className="break-words"
        >
            {payload.value}
        </text>
    );
};

const CustomizedTooltip: React.FC<{
    active: boolean;
    payload: any[];
    label: any[];
}> = ({ active, payload, label }) => {
    if (active) {
        return (
            <div className="bg-white p-5">
                <h5 className="text-xs font-thin">{label}</h5>
                <h2 className="text-lg font-bold">
                    {payload[0].value} Submissions
                </h2>
                <h5 className="text-sm">Sesson 4 - Product Design</h5>
            </div>
        );
    }
    return <></>;
};

const CustomizedLegend: React.FC = () => {
    return (
        <div className="flex justify-end">
            <Menu as="div" className="relative mx-2 inline-block text-left">
                <div>
                    <Menu.Button
                        className="inline-flex w-full justify-center rounded-md bg-violet-200 px-4 py-2 text-sm font-medium text-violet-500
                     hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                        Cohort
                        <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5 text-violet-500 hover:text-violet-100"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white
                     shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active
                                                ? "bg-violet-500 text-white"
                                                : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Cohort 1
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active
                                                ? "bg-violet-500 text-white"
                                                : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Cohort 2
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button
                        className="inline-flex w-full justify-center rounded-md bg-violet-200 px-4 py-2 text-sm font-medium text-violet-500
                     hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                        Session 1 - 7
                        <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5 text-violet-500 hover:text-violet-100"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white
                     shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active
                                                ? "bg-violet-500 text-white"
                                                : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Cohort 1
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active
                                                ? "bg-violet-500 text-white"
                                                : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Cohort 2
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>

            <form>
                <label className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Search
                </label>
                <div className="relative">
                    <input
                        type="search"
                        id="default-search"
                        className="mx-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        placeholder="Search..."
                        required
                    />
                </div>
            </form>
        </div>
    );
};

export default StudentSubmissionChart;
