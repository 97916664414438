import MarketTemplate from "../MarketTemplate";

const SessionFiveTaskTwo = ({ latestTask, latestSessionTwoTaskTwo }) => {
    if (!latestTask || !latestTask.content) {
        return <p>No data found</p>;
    }

    if (!latestSessionTwoTaskTwo || !latestSessionTwoTaskTwo.content) {
        return <p>Focus problem not found</p>;
    }
    const taskContent = latestTask.content;
    const { age, full_name, industry, organisation_size } = taskContent;
    const { job_title, report_to, performance } = taskContent;
    const { job_goals, job_challenges, job_responsibilties } = taskContent;
    const { tools, communication } = taskContent;
    const focusProblem = latestSessionTwoTaskTwo.content.focus_problem;

    return (
        <>
            <MarketTemplate question={focusProblem}>
                <>
                    <p className="mt-2">Name</p>
                    <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                        {full_name}
                    </div>

                    <p className="mt-3">Age</p>
                    <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                        {age}
                    </div>
                    <p className="mt-3">Industry they work in</p>
                    <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                        {industry}
                    </div>
                    <p className="mt-3">Size of organization</p>
                    <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                        {organisation_size}
                    </div>
                </>
            </MarketTemplate>
        </>
    );
};

export default SessionFiveTaskTwo;
