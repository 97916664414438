import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@components/Button";
import useStore from "../../store/useStore";
import useWindowDimensions from "@src/hooks/useWindowDimensions";

const WelcomeScreen = () => {
    const [imageDimensions, setImageDimensions] = useState(0);
    const [welcomeMessage, setWelcomeMessage] = useState("Good Morning");
    const { width, height } = useWindowDimensions();

    const [user] = useStore((state) => [state.user]);

    const { first_name } = user;

    const navigate = useNavigate();

    const today = new Date();
    const curHr = today.getHours();

    useEffect(() => {
        if (curHr < 12) {
            setWelcomeMessage("Good Morning");
        } else if (curHr < 18) {
            setWelcomeMessage("Good Afternoon");
        } else {
            setWelcomeMessage("Good Evening");
        }
    }, [curHr]);

    const navigateNext = () => {
        navigate("/introduction-video");
    };

    useEffect(() => {
        if (width > height) {
            const halfWidth = Math.floor(width / 2) - 70;
            const minDimen = Math.min(halfWidth, height);
            setImageDimensions(minDimen);
        } else {
            const minDimen = height - 70;
            setImageDimensions(minDimen);
        }
    }, [width, height]);

    return (
        <section className="grid h-screen w-screen grid-cols-2 items-center bg-purple-500">
            <div className="  flex h-screen w-full flex-col items-start justify-center px-[60px] py-5">
                <h1 className="mb-6 text-7xl font-bold leading-normal text-white">
                    {`${welcomeMessage} ${first_name}, Welcome to the Entrepreneurial
                    Playground!
                    `}
                </h1>

                <div onClick={navigateNext}>
                    <Button
                        type="submit"
                        size="lg"
                        intent="primaryVariant"
                        fullWidth={false}
                    >
                        Watch Intro Video
                    </Button>
                </div>
            </div>

            <div className="flex h-full w-full items-center justify-center overflow-hidden p-10">
                <img
                    style={{
                        width: `${imageDimensions}px`,
                        height: `${imageDimensions}px`,
                    }}
                    src="/images/welcome-screen.png"
                    alt="Welcome screen"
                />
            </div>
        </section>
    );
};

export default WelcomeScreen;
