import { getTeam } from "@api/teams";
import Button from "@components/Button";
import { FieldError, GeneralError } from "@components/Form";
import UserSearchBox from "@components/Form/UserSearchBox";
import { ArrowLeftIcon, UserIcon } from "@heroicons/react/24/outline";
import useTeamActions from "@src/hooks/useTeamActions";
import React, { useMemo, useState } from "react";
import { type FieldValues, type UseFormSetError, useForm } from "react-hook-form";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import { AccountStatus, UserFactory } from "@src/models";
import Alert from "@src/components/Alert";
import { useQuery } from "@tanstack/react-query";
import SavingIndicator from "@src/components/SavingIndicator";
import { profile } from "@src/api/user";
import type { Student } from "@src/models/types";

export interface TeamInvitesTabProps {
    next: () => void;
    previous: () => void;
}

const TeamInvitesTab = React.forwardRef<HTMLDivElement, TeamInvitesTabProps>(
    ({ next, previous }, ref) => {
        const revalidator = useRevalidator();

        const rootLoaderUser = useRouteLoaderData("root") as Student;

        const { data: user } = useQuery({
            queryKey: ["profile"],
            queryFn: async () => {
                return await profile().then(
                    (res) => UserFactory(res.data) as Student
                );
            },
            initialData: rootLoaderUser,
        });

        const initialTeam = user?.team();

        const isFreeTrial = user.account_status === AccountStatus.FREE_TRIAL;

        const [selectedUser, setSelectedUser] = useState<Student | null>(null);

        const { data: team, ...teamQuery } = useQuery({
            queryKey: ["teams", "onboarding", {initialTeam, initialTeamId: initialTeam?.id}],
            queryFn: async () => {
                if (initialTeam === null) {
                    return;
                }
                return await getTeam(initialTeam?.id);
            },
            enabled: Boolean(initialTeam?.id),
        });

        const memberList = useMemo(() => {
            return team?.members.filter((person) => person.id !== user?.id);
        }, [team]);

        const { addMember: addMemberAction, removeMember: removeMemberAction } =
            useTeamActions(user);

        const addMember = async () => {
            if (selectedUser == null) {
                return;
            }

            await addMemberAction(selectedUser.id);

            setSelectedUser(null);

            await teamQuery.refetch();

            revalidator.revalidate();
        };

        const removeMember = async (userId: number) => {
            await removeMemberAction(userId);

            await teamQuery.refetch();

            revalidator.revalidate();
        };

        interface UserSearchForm { query: string; general: never };

        const {
            handleSubmit,
            formState: { errors, isSubmitting },
            setError,
        } = useForm<UserSearchForm>({
            defaultValues: {
                query: "",
            },
        });

        return (
            <section ref={ref} className="px-4 py-6 sm:px-6 md:px-8">
                <div>
                    <h1 className={"text-3xl font-semibold text-purple-700"}>
                        Team Invites
                    </h1>
                    <div className={"mt-1"}>
                        <Button size={"sm"} intent={"white"} onClick={previous}>
                            <span className={"sr-only"}>Back</span>
                            <ArrowLeftIcon className={"h-6 w-6"} />
                        </Button>
                    </div>
                </div>

                <section
                    className={
                        "mt-4 rounded-md bg-white px-4 pb-12 pt-6 shadow sm:px-6 md:px-8"
                    }
                >
                    {isFreeTrial && (
                        <Alert
                            className="mb-4"
                            intent={"info"}
                            title={"Free Trial Notice"}
                            description={
                                <>
                                    <p>
                                        During the free trial period you will be
                                        the sole member of your team. You still
                                        get to experience and explore the full
                                        potential of the platform.
                                    </p>
                                    <button
                                        type="button"
                                        className="mt-2 font-medium underline hover:text-blue-900"
                                        onClick={next}
                                    >
                                        Skip to the next step
                                    </button>
                                </>
                            }
                        />
                    )}

                    <p className={"text-xl text-gray-700"}>
                        You can search for your team members using either their
                        name or email address.
                    </p>

                    <GeneralError
                        className={"mt-4"}
                        message={errors.general?.message}
                    />

                    <form className="mt-4" onSubmit={handleSubmit(addMember)}>
                        <fieldset disabled={isFreeTrial}>
                            <div className={"flex items-end space-x-4"}>
                                <UserSearchBox
                                    key={memberList?.length ?? 0}
                                    label="Add Member"
                                    selectedUser={selectedUser}
                                    setSelectedUser={setSelectedUser}
                                    setError={(setError as UseFormSetError<FieldValues>)}
                                />
                                <div className={"flex-shrink-0"}>
                                    <Button
                                        intent={"secondary"}
                                        type={"submit"}
                                    >
                                        Add Member
                                    </Button>
                                </div>
                            </div>
                            <FieldError
                                className={"mt-1"}
                                message={errors.query?.message}
                            />
                        </fieldset>
                    </form>

                    <section className="mt-8">
                        {teamQuery.isLoading || memberList?.length === 0 ? (
                            <section
                                className={
                                    "w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center sm:w-5/6"
                                }
                            >
                                <span className="block mt-2 text-sm font-medium text-gray-900">
                                    Search and add users using the text field
                                    above.
                                </span>
                            </section>
                        ) : (
                            <section>
                                <h2 className={"font-medium text-gray-500"}>
                                    The following users are members of your
                                    team:
                                </h2>
                                <ul
                                    role="list"
                                    className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
                                >
                                    {memberList?.map((person, personIdx) => (
                                        <li
                                            key={personIdx}
                                            className="flex items-center justify-between py-4 space-x-3"
                                        >
                                            <div className="flex items-center flex-1 min-w-0 space-x-3">
                                                <div className="flex-shrink-0">
                                                    <UserIcon className="w-8 h-8 text-gray-400" />
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-sm font-medium text-gray-900 truncate">{`${person.first_name} ${person.last_name}`}</p>
                                                    <p className="text-sm font-medium text-gray-500 truncate">
                                                        {person.email}
                                                    </p>
                                                </div>
                                            </div>

                                            <div>
                                                <Button
                                                    onClick={async () => {
                                                        await removeMember(
                                                            person.id
                                                        );
                                                    }}
                                                    type={"button"}
                                                    size={"sm"}
                                                    variant={"text"}
                                                    intent={"secondary"}
                                                >
                                                    Remove
                                                </Button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </section>
                        )}

                        <div className={"mt-4 flex items-center justify-end"}>
                            <div>
                                {isSubmitting && (
                                    <SavingIndicator className="mr-2" />
                                )}
                                <Button onClick={next}>
                                    Review team details
                                </Button>
                            </div>
                        </div>
                    </section>
                </section>
            </section>
        );
    }
);

export default TeamInvitesTab;
