import { useState } from "react";
import tasks from "../../../../containers/activities/SessionEight/tasks_schema.json";
import PdfViewer from "@src/components/PdfViewer/PdfViewer";
import Timer from "@src/components/Timer/Timer";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";
import Button from "../../../../components/Button/Button";

const SessionEightSubmission = ({ data, sortOrder, session, task }) => {
    const [noOfComments, setNoOfComments] = useState(0);
    const [selectedTask, setSelectedTask] = useState("");

    const sessioEight = data.find(
        (session) => session.sort_order === sortOrder
    );
    const taskName = task[0];
    const taskData = sessioEight.submissions[taskName];
    const taskAttributes = tasks.find((task) => task.name === taskName);

    const latestTask = taskData[0];

    const studentSubmissions = latestTask.content;

    if (taskName === "task_1") {
        const tagLine = studentSubmissions.tag_line;
        const mission = studentSubmissions.mission;
        const vision = studentSubmissions.vision;

        return (
            <SubmissionsTemplate
                latestTask={latestTask}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((step, idx) => {
                        const { fields, question } = step;
                        const fieldData = fields[0];

                        switch (fieldData.name) {
                            case "tag_line":
                                return (
                                    <TaskTemplate question={question} key={idx}>
                                        <p>{tagLine}</p>
                                    </TaskTemplate>
                                );
                            case "mission":
                                return (
                                    <TaskTemplate question={question} key={idx}>
                                        <p>{mission}</p>
                                    </TaskTemplate>
                                );

                            case "vision":
                                return (
                                    <TaskTemplate question={question} key={idx}>
                                        <p>{vision}</p>
                                    </TaskTemplate>
                                );
                        }
                    })}
                </>
            </SubmissionsTemplate>
        );
    } else {
        const files = session.task_2[0].file_uploads;

        const recentFile = files[files.length - 1];
        const handleDownload = () => {
            const signedurlPromise = fetch(recentFile);
            signedurlPromise
                .then((response) => {
                    window.open(response.url, "_blank");
                })
                .catch((error) => {
                    console.log("FILE DOWNLOAD ERROR", error);
                });
        };
        return (
            <SubmissionsTemplate
                latestTask={latestTask}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((step, idx) => {
                        const { fields, question } = step;
                        const fieldData = fields[0];
                        const { name, label, options, type } = fieldData;

                        switch (fieldData.name) {
                            case "sketch_logo":
                                return (
                                    <TaskTemplate question={question}>
                                        <PdfViewer attributes={fieldData} />
                                    </TaskTemplate>
                                );

                            case "time_image":
                                return (
                                    <TaskTemplate question={question}>
                                        <Timer />
                                    </TaskTemplate>
                                );
                            case "logo_files":
                                return (
                                    <TaskTemplate question={question}>
                                        {!recentFile ? (
                                            <p>No files found</p>
                                        ) : (
                                            <div className="w-full overflow-scroll">
                                                <p className="mb-2 w-1/2">
                                                    Files are available
                                                </p>
                                                <Button
                                                    onClick={() =>
                                                        handleDownload()
                                                    }
                                                >
                                                    Download
                                                </Button>
                                            </div>
                                        )}
                                    </TaskTemplate>
                                );
                        }
                    })}
                </>
            </SubmissionsTemplate>
        );
    }
};

export default SessionEightSubmission;
