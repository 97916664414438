import {
    PencilSquareIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/outline";
import Button from "@src/components/Button";
import { FieldError, TextArea } from "@src/components/Form";
import { useState } from "react";
import type { GroupedSessionSubmissions, User } from "@src/models/types";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import RenderTaskContent from "@src/containers/activities/RenderTaskContent";
import {
    getSubmissionFeedback,
    newSubmissionFeedback,
} from "@src/api/sessions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Avatar from "@src/components/Avatar";
import { useRouteLoaderData } from "react-router-dom";
import { useForm } from "react-hook-form";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import SavingIndicator from "@src/components/SavingIndicator";
import Time from "@src/components/Time";

interface ParentCommentForm {
    parentFeeback: string;
}

function sessionActivityFeedbackQuery(activitySubmissionId: number) {
    return {
        queryKey: ["submission-comments", activitySubmissionId],
        queryFn: async () => await getSubmissionFeedback(activitySubmissionId),
    };
}

const TasksDetails = ({
    sessionSubmissionData,
}: {
    sessionSubmissionData: GroupedSessionSubmissions;
}) => {
    const user = useRouteLoaderData("root") as User;

    const tabTitles = Object.keys(sessionSubmissionData);
    const tabsLength = tabTitles.length;

    const [selectedTab, setSelectedTab] = useState(0);
    const selectedTaskId = sessionSubmissionData[tabTitles[selectedTab]][0]?.id;

    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<ParentCommentForm>({ defaultValues: { parentFeeback: "" } });
    const submit = async (data: ParentCommentForm) => {
        try {
            await newSubmissionFeedback(selectedTaskId, data.parentFeeback);
            reset();
            await queryClient.invalidateQueries({
                queryKey: ["submission-comments", selectedTaskId],
            });
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    const handleNext = () => {
        setSelectedTab((prevTab) => (prevTab + 1) % tabsLength);
    };

    const handleBack = () => {
        setSelectedTab((prevTab) =>
            prevTab === 0 ? tabsLength - 1 : prevTab - 1
        );
    };

    const { data: taskFeedback } = useQuery({
        ...sessionActivityFeedbackQuery(selectedTaskId),
        initialData: [],
    });
    return (
        <>
            <div className="w-full px-2 mx-auto mt-4 sm:px-0">
                <Tab.Group
                    selectedIndex={selectedTab}
                    onChange={setSelectedTab}
                >
                    <Tab.List className="flex p-1 space-x-1 bg-white rounded-md">
                        {tabTitles.map((taskTitle) => (
                            <Tab
                                key={taskTitle}
                                className={({ selected }) =>
                                    clsx(
                                        "w-full rounded-lg py-2.5 text-sm font-medium capitalize leading-5",
                                        "ring-0 ring-offset-0 focus:outline-none",
                                        selected
                                            ? "bg-purple-100 shadow"
                                            : " hover:bg-white/[0.12] hover:text-purple-500"
                                    )
                                }
                            >
                                {taskTitle?.replace(/_/g, " ")}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="mt-4">
                        {Object.values(sessionSubmissionData).map(
                            (tasks, idx) => (
                                <Tab.Panel key={idx}>
                                    {/* Question with submission content */}
                                    <section className="px-4 py-8 bg-white rounded-md shadow sm:px-6 md:px-8">
                                        <p className="flex gap-2 px-5 text-lg font-medium">
                                            <span className="font-semibold">
                                                Question:{" "}
                                            </span>
                                            Lorem ipsum dolor sit amet
                                            consectetur, adipisicing elit. Vero
                                            nemo qui, tempore vel temporibus
                                            inventore ducimus sit natus
                                            doloribus fugiat ut odit, nam hic
                                            fuga.
                                        </p>
                                        <section className="grid gap-10 mt-8 md:grid-cols-1 ">
                                            {Object.entries(
                                                tasks[0]?.content
                                            )?.map(([key, value]) => (
                                                <section
                                                    key={key}
                                                    className="p-2 bg-white border rounded-md "
                                                >
                                                    <div className="flex items-center px-4 ">
                                                        <PencilSquareIcon className="w-12 h-12 text-purple-600" />
                                                        <p className="text-lg uppercase">
                                                            {key?.replace(
                                                                /_/g,
                                                                " "
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="px-8 py-4 ">
                                                        <article className="font-medium text-md ">
                                                            <RenderTaskContent
                                                                content={value}
                                                            />
                                                        </article>
                                                    </div>
                                                </section>
                                            ))}
                                        </section>

                                        {/* navigation buttons for the tasks tabs UI */}
                                        <div className="flex justify-end gap-10 mt-10">
                                            <Button
                                                variant="outlined"
                                                size="xs"
                                                disabled={selectedTab === 0}
                                                onClick={handleBack}
                                            >
                                                <ChevronLeftIcon className="w-4 h-4 mx-auto " />
                                                <p className="pr-2">
                                                    Previous Task
                                                </p>
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="xs"
                                                disabled={
                                                    selectedTab ===
                                                    tabsLength - 1
                                                }
                                                onClick={handleNext}
                                            >
                                                <p className="pl-4">
                                                    Next Task
                                                </p>
                                                <ChevronRightIcon className="w-4 h-4 mx-auto" />
                                            </Button>
                                        </div>
                                    </section>

                                    {/* Feedback  */}
                                    <section className="pt-8 mt-10 overflow-hidden bg-white rounded-lg shadow">
                                        <div className="px-4 sm:px-6 md:px-8">
                                            <h2 className="text-3xl font-semibold text-gray-900">
                                                Feedback
                                            </h2>
                                            <section className="mt-8">
                                                {taskFeedback.length === 0 ? (
                                                    <div className="p-10 mx-auto mt-4 border border-dashed rounded-md ">
                                                        <p className="text-lg text-center text-gray-600">
                                                            Add new feedback
                                                            using the comment
                                                            box below.
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <ul
                                                            role="list"
                                                            className="mt-4 space-y-8"
                                                        >
                                                            {taskFeedback.map(
                                                                (comment) => (
                                                                    <li
                                                                        key={
                                                                            comment.id
                                                                        }
                                                                    >
                                                                        <div className="flex space-x-3">
                                                                            <div className="flex-shrink-0">
                                                                                <Avatar
                                                                                    size="42"
                                                                                    name={
                                                                                        comment
                                                                                            .submitter
                                                                                            .full_name ??
                                                                                        ""
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <div>
                                                                                    <span
                                                                                        className="font-medium text-gray-900"
                                                                                    >
                                                                                        {
                                                                                            comment
                                                                                                .submitter
                                                                                                .full_name
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-1 text-gray-700">
                                                                                    <p>
                                                                                        {
                                                                                            comment.content
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className="mt-2 space-x-2">
                                                                                    <Time
                                                                                        value={
                                                                                            new Date(
                                                                                                comment.created_at
                                                                                            )
                                                                                        }
                                                                                        className="font-medium text-gray-500"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </>
                                                )}
                                            </section>
                                        </div>

                                        <div className="px-4 pt-8 mt-10 bg-gray-50 sm:px-6 md:px-8">
                                            <div className="flex w-full max-w-5xl gap-4">
                                                <Avatar
                                                    src={
                                                        user.avatar ?? undefined
                                                    }
                                                    name={user.full_name ?? ""}
                                                    size="42"
                                                />
                                                <div className="w-full">
                                                    <form
                                                        onSubmit={handleSubmit(
                                                            submit
                                                        )}
                                                    >
                                                        <TextArea
                                                            className="w-full h-48 max-w-5xl"
                                                            placeholder="Add your comment here"
                                                            {...register(
                                                                "parentFeeback"
                                                            )}
                                                            required
                                                        />
                                                        <FieldError
                                                            className="mt-1"
                                                            message={
                                                                errors
                                                                    ?.parentFeeback
                                                                    ?.message
                                                            }
                                                        />
                                                        <div className="flex items-center justify-end py-4">
                                                            {isSubmitting && (
                                                                <SavingIndicator className="mr-2" />
                                                            )}
                                                            <Button
                                                                type="submit"
                                                                className=""
                                                                variant="contained"
                                                                size="lg"
                                                            >
                                                                Comment
                                                            </Button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </Tab.Panel>
                            )
                        )}
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </>
    );
};

export default TasksDetails;
