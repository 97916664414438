import { cx } from "class-variance-authority";
import type React from "react";


interface LabelProps extends React.ComponentProps<"label"> {
    name?: string;
}

const Label = ({
    name,
    className = "",
    children,
    ...props
}: LabelProps) => {
    return (
        <label
            className={cx(
                "block text-base font-medium text-gray-700",
                className
            )}
            {...props}
        >
            {name ?? children}
        </label>
    );
};

export default Label;
