import { useMemo } from "react";
import { type ColumnDef } from "@tanstack/react-table";
import Table from "@src/components/tables";
import Button from "@src/components/Button";
import {
    type Reflection,
    type FiresideVideosResponse,
} from "@src/models/types";

const StudentFiresideVideosTable: React.FC = () => {
    const firesideVideosTableColumns = useMemo<
        Array<ColumnDef<FiresideVideosResponse>>
    >(
        () => [
            {
                header: () => {
                    return (
                        <p className="inline-block px-8 align-middle">Title</p>
                    );
                },
                accessorKey: "title",
                cell: ({ getValue }) => {
                    return <p className="px-8">{getValue() as string}</p>;
                },
            },
            {
                header: "Date Watched",
                accessorKey: "date_watched",
            },
            {
                header: "Reflection",
                accessorKey: "reflection",
                cell: ({ getValue }) => {
                    const { name, content } = getValue() as Reflection;
                    return (
                        <>
                            <h2 className="font-lg font-bold">{name}</h2>
                            <p className="font-xs">{content}</p>
                        </>
                    );
                },
            },
            {
                header: "Action",
                accessorKey: "id",
                cell: ({ getValue }) => {
                    return (
                        <Button
                            variant="outlined"
                            intent="white"
                            size="lg"
                            type="button"
                            href={`/student/firesides/video/${
                                getValue() as number
                            }`}
                        >
                            View
                        </Button>
                    );
                },
                disableGlobalFilter: true,
            },
        ],
        []
    );

    const firesideVideosTableData = useMemo<FiresideVideosResponse[]>(() => {
        return [
            {
                id: 1,
                title: "Building a great team",
                date_watched: "1/2/2023",
                reflection: {
                    name: "Rogers Mukaaya",
                    content:
                        "Nunc pretium nunc augue, sed eleifend lectus ... ",
                },
            },
            {
                id: 2,
                title: "It's hard to learn if you don't lose",
                date_watched: "1/2/2023",
                reflection: {
                    name: "Abigail Jane",
                    content:
                        "Bibendum rhoncus lectus, sit amet placerat velit ...",
                },
            },
            {
                id: 3,
                title: "Data and intuition in making decisions",
                date_watched: "1/2/2023",
                reflection: {
                    name: "Emily Karen",
                    content:
                        "Duis mollis metus vel arcu molestie iac fivamus tinc ... ",
                },
            },
        ];
    }, []);

    return (
        <Table
            columns={firesideVideosTableColumns}
            data={firesideVideosTableData}
            title="Fireside Videos"
        />
    );
};

export default StudentFiresideVideosTable;
