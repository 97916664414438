import { useMemo, useState } from "react";
import clsx from "clsx";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import { type ColumnDef } from "@tanstack/react-table";
import { getStudentsInSchool } from "@src/api/schools";
import { type StudentsViewsetResponse } from "@src/models/types";
import { timeSince } from "@src/utils";
import type { Cohort } from "@src/models/types";
import Button from "@src/components/Button";
import Table from "@src/components/tables";
import { resendAccountSetupEmail } from "@src/api/auth";

interface SchoolDashboardStudentsTableProps {
    cohorts: Cohort[] | undefined;
}

const SchoolDashboardStudentsTable: React.FC<
    SchoolDashboardStudentsTableProps
> = ({ cohorts }) => {
    const [cohort, setCohort] = useState<Cohort>({
        created: "",
        modified: "",
        name: "",
        sort_order: null,
        completion_date: "",
        active: false,
        school: null,
    });

    console.log("SELECTED COHORT DASH ---> ", cohort);

    const studentsQuery = useQuery({
        queryKey: ["students", cohort.sort_order],
        queryFn: async () => await getStudentsInSchool(cohort.sort_order),
    });

    const studentsTableColumns = useMemo<
        Array<ColumnDef<StudentsViewsetResponse>>
    >(
        () => [
            {
                header: "Name",
                accessorKey: "student_name",
            },
            {
                header: "Team",
                accessorKey: "team_name",
            },
            {
                header: "Completion",
                accessorKey: "completion_percentage",
                cell: ({ getValue }) => {
                    const completionPercentage = getValue() as number;
                    return (
                        <span
                            className={clsx(
                                "px-6",
                                "py-0.5",
                                "rounded",
                                "flex",

                                "items-center",
                                completionPercentage === 0 &&
                                    "bg-neutral-700/10 text-neutral-700",
                                completionPercentage > 0 &&
                                    completionPercentage < 100 &&
                                    "bg-orange-500/10 text-orange-500",
                                completionPercentage === 100 &&
                                    "bg-green-500/10 text-green-500"
                            )}
                        >
                            {completionPercentage} %
                        </span>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                header: "Last Login",
                accessorKey: "last_login",
                cell: ({ getValue }) => (
                    <>{timeSince(new Date(getValue() as string))} ago</>
                ),
                disableGlobalFilter: true,
            },
            {
                header: "Re-Invite",
                accessorKey: "id",
                cell: ({ getValue, row }) => {
                    const studentId = getValue() as number;
                    const [setupEmailSent, setSetupEmailSent] = useState<
                        "success" | "error" | "loading" | "not-sent"
                    >("not-sent");

                    const setupEmailQuery = useQuery({
                        queryKey: ["resendSetupEmail", studentId],
                        queryFn: async () =>
                            await resendAccountSetupEmail(
                                btoa(`${studentId}`)
                                    .replace(/\+/g, "-")
                                    .replace(/\//g, "_")
                                    .replace(/=+$/, "")
                            ),
                        enabled: false,
                        retry: false,
                    });

                    return (
                        <Button
                            variant="outlined"
                            intent="white"
                            type="button"
                            size="md"
                            onClick={async () => {
                                try {
                                    const resp =
                                        await setupEmailQuery.refetch();
                                    setSetupEmailSent(resp.status);
                                } catch (error: any) {
                                    setSetupEmailSent("error");
                                }
                            }}
                            disabled={setupEmailSent !== "not-sent"}
                        >
                            {setupEmailSent === "not-sent" && (
                                <>
                                    Email
                                    <span className="sr-only">
                                        invitation to{" "}
                                        {row.original.student_name}
                                    </span>
                                </>
                            )}
                            {setupEmailSent === "success" && (
                                <div className="flex items-center text-green-500">
                                    Sent{" "}
                                    <CheckCircleIcon className="ml-2 h-5 w-5" />
                                </div>
                            )}
                            {setupEmailSent === "error" && (
                                <div className="flex items-center text-red-500">
                                    Error{" "}
                                    <ExclamationCircleIcon className="ml-2 h-5 w-5" />
                                </div>
                            )}
                            {setupEmailQuery.isFetching && (
                                <div className="flex items-center text-red-500">
                                    Sending...
                                </div>
                            )}
                        </Button>
                    );
                },
                disableGlobalFilter: true,
            },
        ],
        []
    );

    const studentsTableData = useMemo<StudentsViewsetResponse[]>(() => {
        if (studentsQuery.data == null) return [];
        return studentsQuery.data.map((student) => ({
            ...student,
            completion_percentage: student.completion_percentage * 100,
        }));
    }, [studentsQuery.data]);

    return (
        <Table
            columns={studentsTableColumns}
            data={studentsTableData}
            title="Students"
            cohorts={cohorts}
            setCohort={setCohort}
        />
    );
};

export default SchoolDashboardStudentsTable;
