import useStore from "@store/useStore";
import { type QueryClient } from "@tanstack/react-query";
import { posthog } from "posthog-js";
import { Navigate } from "react-router-dom";

export function loader(queryClient: QueryClient) {
    return async () => {
        await useStore.getState().logout(() => {
            queryClient.removeQueries();
            posthog.reset();
        });

        return null;
    };
}

export default function Logout() {
    return <Navigate to="/" />;
}
