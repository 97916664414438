const Badge: React.FC<{ rank: string }> = ({ rank }) => {
    let bgColor: string;
    switch (Number.parseInt(rank)) {
        case 1:
            bgColor = "bg-[#FFCC00]";
            break;

        case 2:
            bgColor = "bg-primary-300";
            break;

        case 3:
            bgColor = "bg-[#FFD9D9]";
            break;

        default:
            bgColor = "bg-none";
            break;
    }

    return (
        <div
            className={`inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-purple-500 p-4 font-bold text-purple-500 md:h-12 md:w-12 md:p-5 ${bgColor}`}
        >
            {rank}
        </div>
    );
};

export default Badge;
