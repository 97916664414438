import clsx from "clsx";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { addDays, formatDistanceToNowStrict, isPast } from "date-fns";
import { useStore } from "@src/store";

interface FreeTrialCountdownBannerProps {
    className?: string;
}

const FreeTrialCountdownBanner = ({
    className = "",
}: FreeTrialCountdownBannerProps) => {
    const user = useStore((state) => state.user);

    if (user == null) {
        throw new Error("Cannot render without user data");
    }

    const endDate = addDays(new Date(user.free_trial_start_date), 15);

    const remainingTime = formatDistanceToNowStrict(endDate);

    return (
        <section
            className={clsx("bg-red-500/80 px-4 py-2 text-white", className)}
        >
            <div className="flex items-center justify-between">
                <InformationCircleIcon className="w-8 h-8 text-white" />

                <p className="ml-2 font-medium">
                    {isPast(endDate) ? (
                        "Your free trial period has elapsed"
                    ) : (
                        <>
                            Free Trial:{" "}
                            <span className="font-semibold">
                                {remainingTime}
                            </span>{" "}
                            remaining
                        </>
                    )}
                </p>
            </div>
        </section>
    );
};

export default FreeTrialCountdownBanner;
