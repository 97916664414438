import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import { reportsQuery } from "@src/api/parents";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import MyScore from "@src/pages/parent/Reports/Score";
import "../../../components/Loaders/loader.css";
import SessionTwo from "./SessionTwo/SessionTwo";
import SessionFive from "./SessionFive/SessionFive";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

export default function ParentsReports() {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const parentsid = parseInt(params.parentId!);

    const { data: reports, isLoading } = useQuery({
        ...reportsQuery(parentsid),
    });

    if (isLoading) {
        return (
            <div className="m-11 flex w-full items-center justify-center">
                <span className="loader"></span>
            </div>
        );
    }

    if (reports == null) {
        return (
            <div className="flex justify-center">
                <div className="flex w-1/2 flex-col items-center justify-center gap-4">
                    <ClipboardDocumentListIcon className="h-56 w-56 stroke-1 text-slate-300" />
                    <p className="text-center text-2xl">No data found</p>
                </div>
            </div>
        );
    }

    const { cohort_feedback, ranked_summary, submissions, team } = reports;

    const { team_desctiption, team_name, members } = team;

    const sessionTwo = submissions.filter(
        (submissions) => submissions.session_id === 2
    );

    const sessionThree = submissions.filter(
        (submissions) => submissions.session_id === 3
    );

    const sessionFive = submissions.filter(
        (submissions) => submissions.session_id === 5
    );

    return (
        <>
            <ToastContainer position="top-center" hideProgressBar={true} />

            <div className={"flex min-h-screen flex-col"}>
                <header className="border-b border-gray-200 bg-white">
                    <div className="mx-auto flex max-w-screen-2xl justify-between px-4 py-3 sm:px-6 md:px-8">
                        <Link className="inline-block" to="/">
                            <img
                                className="h-16 w-auto"
                                src="/images/kineticp.png"
                                alt="Kinetic Platform"
                            />
                        </Link>
                    </div>
                </header>

                <main className="mb-20 flex flex-col items-center justify-items-center">
                    <section className="mt-5 w-4/5 justify-self-center">
                        <div className="text-4xl font-extrabold">
                            {cohort_feedback.title}
                        </div>
                    </section>

                    <section className="mt-5 flex w-full items-center justify-center">
                        <div
                            className="w-4/5 text-lg"
                            dangerouslySetInnerHTML={{
                                __html: cohort_feedback.feedback,
                            }}
                        />
  
                    </section>

                    <hr className="border-1 mx-auto my-8 w-3/4" />

                    <MyScore myScore={ranked_summary} />

                    <hr className="border-1 mx-auto my-8 w-3/4" />

                    <div className="mt-5 flex w-4/5 justify-between md:divide-x divide-y md:divide-y-0 flex-wrap md:flex-nowrap">
                        <section className="w-[320px] pr-10 py-10">
                            <div className="pb-5 text-2xl font-semibold uppercase">
                                The team
                            </div>
                            <div className="text-4xl font-extrabold text-purple-700">
                                {team_name}
                            </div>
                            <div className="text-lg font-medium">
                                {team_desctiption}
                            </div>

                            <div className="w-[320px]">
                                {members?.map((member) => {
                                    const { full_name } = member;
                                    return (
                                        <div className="flex items-center justify-items-center py-1">
                                            <div className="flex-shrink-0">
                                                <UserIcon className="h-8 w-8 text-gray-400" />
                                            </div>
                                            <p className="min-w-0 text-lg font-bold text-black">{`${full_name}`}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </section>

                        <section className="w-full px-10 py-10">
                            <div className="pb-5 text-2xl font-semibold uppercase">
                                The Idea
                            </div>

                            <SessionTwo
                                sessionTwoTasks={sessionTwo}
                                sessionThreeTasks={sessionThree}
                            />
                        </section>

                        <section className="w-full px-10 py-10">
                            <div className="pb-5 text-2xl font-semibold uppercase">
                                The Market
                            </div>

                            <SessionFive
                                sessionFiveTasks={sessionFive}
                                sessionTwoTasks={sessionTwo}
                            />
                        </section>
                    </div>
                </main>
            </div>
        </>
    );
}
