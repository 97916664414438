import IntroLayout from "@src/components/layout/IntroLayout";
import Topnav from "@src/components/Navbar/Topnav";
import Button from "@src/components/Button";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import useStore from "store/useStore";
import { userTypeHomeRoute } from "@src/api/auth";
import UserSearchBox from "@src/components/Form/UserSearchBox";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import useTeamActions from "@src/hooks/useTeamActions";
import { profileQueryOptions } from "@src/api/user";
import { useQuery } from "@tanstack/react-query";
import { type Team, type MemberRecord, type Student } from "@src/models/types";
import {
    type FieldValues,
    type UseFormSetError,
    useForm,
} from "react-hook-form";
import SavingIndicator from "@src/components/SavingIndicator";
import { UserIcon } from "@heroicons/react/24/solid";
import Badge from "@src/components/Badge";

const InviteMembers = () => {
    const [isInvitingMembers, setIsInvitingMembers] = useState(false);
    const navigate = useNavigate();

    const [user, saveStudentData] = useStore((state) => [
        state.user,
        state.saveStudentData,
    ]);

    const [team, setTeam] = useState<Team | null>({
        id: Infinity,
        team_name: "",
        team_description: "",
        team_logo: null,
        members: [],
        owner_id: Infinity,
    });

    const [newMembers, setNewMembers] = useState<
        Student[] | MemberRecord[] | undefined
    >([]);

    const { data: userData, isLoading } = useQuery({
        ...profileQueryOptions<Student>(),
    });

    useEffect(() => {
        if (userData != null) {
            const teamData = userData.team();
            setTeam(teamData);
            saveStudentData(userData);
            setNewMembers(teamData?.members);
        }
    }, [userData, isLoading]);

    const { addInitialMembers } = useTeamActions(userData);

    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            members: [],
        },
    });

    const handleAddNewMembers = (member: Student) => {
        if (member?.id != null) {
            const foundMember = newMembers?.find((el) => el.id === member.id);

            if (foundMember != null) {
                return;
            }
            clearErrors();

            setNewMembers((prev) => [...prev, member]);
        }
    };

    const handleRemoveMembers = (member: MemberRecord | Student) => {
        const filtered = newMembers?.filter((el) => el.id !== member.id);
        setNewMembers(filtered);
    };

    const submitMembers = async () => {
        if (newMembers != null && newMembers.length <= 0) {
            toast.info("Add some members to get started");
            return;
        }

        const userIDs = newMembers?.map((member) => {
            return member.id;
        });

        const filtered = userIDs?.filter((el) => el !== user?.id);

        setIsInvitingMembers(true);
        const response = await addInitialMembers(filtered);
        setIsInvitingMembers(false);
        if (response === true) {
            navigateToNext();
        } else {
            toast.error("An unexpected error occured");
        }
    };

    const navigateBack = () => {
        navigate(-1);
    };

    const navigateToNext = () => {
        if (user === null) {
            return;
        }
        const redirectPath = userTypeHomeRoute(user?.user_type);
        navigate(redirectPath, { replace: true });
    };

    interface AddTeamMemberProps {
        person: MemberRecord | Student;
    }

    const AddedTeamMember: React.FC<AddTeamMemberProps> = ({ person }) => {
        return (
            <div className="flex  min-w-0 flex-1 items-center space-x-3 bg-white p-2">
                <div className="flex-shrink-0">
                    <UserIcon className="h-8 w-8 text-gray-400" />
                </div>
                <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900">{`${person.first_name} ${person.last_name}`}</p>
                    <p className="truncate text-sm font-medium text-gray-500">
                        {person.email}
                    </p>
                </div>

                <div>
                    {person.is_owner && <Badge theme="accent">Owner</Badge>}

                    {user?.id === team?.owner_id && user?.id !== person.id && (
                        <Button
                            onClick={() => {
                                handleRemoveMembers(person);
                            }}
                            type={"button"}
                            size={"sm"}
                            variant={"text"}
                            intent={"secondary"}
                        >
                            Remove
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <IntroLayout showGears={true}>
            <Topnav />
            <div className="relative left-[100px] top-[100px] flex w-full items-start justify-start">
                <div
                    className=" cursor:pointer h-fit w-fit rounded-md bg-orange-500 p-2 hover:bg-orange-700"
                    onClick={navigateBack}
                >
                    <ArrowLeftIcon className="h-6 w-6" color="white" />
                </div>
            </div>
            <div
                className="min-w-md mx-auto mt-32 w-[550px] rounded-lg bg-white px-4 py-10 shadow-md sm:px-6 md:px-8"
                style={{ backgroundColor: "rgba(111, 56, 148)" }}
            >
                <div className="flex w-full flex-col items-center">
                    <h1 className="mt-6 text-4xl text-white">
                        Invite people to your team{" "}
                    </h1>
                    <h1 className="mt-6 text-center text-sm text-white">
                        Invite people to your team and we will send them an
                        invite to join your team
                    </h1>
                    <div className="mt-6 flex w-full flex-col items-start">
                        <p className="mt-5 text-sm text-orange-500">
                            Who do you want to invite
                        </p>
                        <form className="flex w-full items-end space-x-4">
                            <UserSearchBox
                                key={team?.members.length}
                                label=""
                                selectedUser={null}
                                setSelectedUser={handleAddNewMembers}
                                setError={
                                    setError as UseFormSetError<FieldValues>
                                }
                            />
                        </form>

                        <div className="mt-8 sm:w-4/6 md:max-w-xl">
                            {newMembers?.map((member, idx: number) => {
                                return (
                                    <AddedTeamMember
                                        person={member}
                                        key={idx}
                                    />
                                );
                            })}
                        </div>

                        {/* <TextFieldV2
                            type="text"
                            id="team_name"
                            className="mt-1 h-14 w-[400px]"
                            placeholder="Enter Email"
                            autoFocus
                            required
                        />
                        <FieldError
                            message="Required"
                            className="error-message mt-1 text-orange-500"
                        /> */}
                    </div>

                    <div className="mt-11 flex w-full flex-row  items-center justify-between ">
                        <div className="flex flex-row items-center gap-2">
                            {isInvitingMembers && <SavingIndicator />}
                            <Button
                                variant="contained"
                                intent="primaryVariant"
                                size="lg"
                                onClick={() => {
                                    submitMembers()
                                        .then((response) => {
                                            if (
                                                response !== null &&
                                                response !== true
                                            ) {
                                                toast.error(
                                                    `An unexpected error occurred`
                                                );
                                            }
                                            setIsInvitingMembers(false);
                                        })
                                        .catch((error) => {
                                            toast.error(`${error}`);
                                            setIsInvitingMembers(false);
                                        });
                                }}
                            >
                                Create Team
                            </Button>
                        </div>

                        <>
                            <Button
                                variant="outlined"
                                intent="primaryVariant"
                                size="lg"
                                onClick={navigateToNext}
                            >
                                Skip
                            </Button>
                        </>
                    </div>
                </div>
            </div>
            <div className=" flex w-full flex-row items-center justify-between px-[100px]">
                <>
                    <Button
                        variant="contained"
                        intent="primaryVariant"
                        size="lg"
                        onClick={navigateBack}
                    >
                        Back
                    </Button>
                </>

                <>
                    <Button
                        variant="contained"
                        intent="secondaryVariant"
                        size="lg"
                        onClick={navigateToNext}
                    >
                        Next
                    </Button>
                </>
            </div>
        </IntroLayout>
    );
};

export default InviteMembers;
