import { Link, matchPath, useLocation, useRouteLoaderData } from "react-router-dom";
import {
    ChevronRightIcon,
    ChevronLeftIcon,
    HomeIcon,
} from "@heroicons/react/20/solid";
import { userTypeHomeRoute } from "@src/api/auth";
import { type User } from "@src/models/types";

export interface BreadcrumbPath {
    name: string;
    href: string;
}

export interface BreadcrumbProps {
    paths: BreadcrumbPath[];
}

export default function Breadcrumb({ paths }: BreadcrumbProps) {
    const location = useLocation();
    const user = useRouteLoaderData("root") as User;

    const previousLocationPath = paths.length > 1 ? paths.at(-2) : {href: '#'};

    return (
        <div>
            <nav className="sm:hidden" aria-label="Back">
                <Link
                    to={previousLocationPath?.href ?? "#"}
                    className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                    <ChevronLeftIcon
                        className="flex-shrink-0 w-5 h-5 mr-1 -ml-1 text-gray-400"
                        aria-hidden="true"
                    />
                    Back
                </Link>
            </nav>
            <nav className="hidden md:block" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                    <li>
                        <div>
                            <Link
                                to={userTypeHomeRoute(user.user_type)}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <HomeIcon
                                    className="flex-shrink-0 w-5 h-5"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Home</span>
                            </Link>
                        </div>
                    </li>
                    {paths.map((page) => (
                        <li key={page.name}>
                            <div className="flex items-center">
                                <ChevronRightIcon
                                    className="flex-shrink-0 w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                <Link
                                    to={page.href}
                                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    aria-current={
                                        (matchPath(page.href, location.pathname) != null)
                                            ? "page"
                                            : undefined
                                    }
                                >
                                    {page.name}
                                </Link>
                            </div>
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    );
}
