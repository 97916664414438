import IntroLayout from "@src/components/layout/IntroLayout";
import Topnav from "@src/components/Navbar/Topnav";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SavingIndicator from "@src/components/SavingIndicator";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import TextFieldV2 from "@src/components/Form/TextFieldV2";
import { Label, FieldError } from "@src/components/Form";
import Button from "@src/components/Button";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { createTeam } from "@src/api/teams";
import useStore from "@store/useStore";
import { toast } from "react-toastify";
import { type MemberRecord, type Student } from "@src/models/types";
import { UserIcon } from "@heroicons/react/24/solid";
import Badge from "@src/components/Badge";

const TeamCreation = () => {
    // const user = useRouteLoaderData("root") as Student;
    const [user] = useStore((state) => [state.user, state.isOnBoarding]);
    const school = user?.school;
    const navigate = useNavigate();
    const studentTeam = user?.teams;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            team_name: "",
            team_description: "",
        },
    });

    const submit = async (data: {
        team_name: string;
        team_description: string;
    }) => {
        if (user == null) {
            toast.error(`User not found`, {
                autoClose: 2000,
            });
            return;
        }

        try {
            await createTeam({
                ...data,
                student: user.id,
                school: school?.school_id,
            });
            clearErrors();
            toast.success(`Team created succesfully`, {
                autoClose: 2000,
            });
            navigate("/invite-members");
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    const navigateBack = () => {
        navigate(-1);
    };

    const navigateToNext = () => {
        navigate("/invite-members");
    };

    interface AddTeamMemberProps {
        person: MemberRecord | Student;
    }

    const AddedTeamMember: React.FC<AddTeamMemberProps> = ({ person }) => {
        return (
            <div className="flex  min-w-0 flex-1 items-center space-x-3 bg-white p-2">
                <div className="flex-shrink-0">
                    <UserIcon className="h-8 w-8 text-gray-400" />
                </div>
                <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900">{`${person.first_name} ${person.last_name}`}</p>
                    <p className="truncate text-sm font-medium text-gray-500">
                        {person.email}
                    </p>
                </div>

                <div>
                    {person.is_owner && <Badge theme="accent">Owner</Badge>}

                    {/* {user?.id === team?.owner_id && user?.id !== person.id && (
                        <Button
                            onClick={() => {
                                handleRemoveMembers(person);
                            }}
                            type={"button"}
                            size={"sm"}
                            variant={"text"}
                            intent={"secondary"}
                        >
                            Remove
                        </Button>
                    )} */}
                </div>
            </div>
        );
    };

    return (
        <IntroLayout showGears={true}>
            <Topnav />
            <div className="relative left-[100px] top-[100px] flex w-full items-start justify-start">
                <div
                    className=" cursor:pointer h-fit w-fit rounded-md bg-orange-500 p-2 hover:bg-orange-700"
                    onClick={navigateBack}
                >
                    <ArrowLeftIcon className="h-6 w-6" color="white" />
                </div>
            </div>
            {studentTeam != null && studentTeam.length <= 0 ? (
                <div
                    className="min-w-md mx-auto mt-32 w-[550px] rounded-lg bg-white px-4 py-10 shadow-md sm:px-6 md:px-8"
                    style={{ backgroundColor: "rgba(111, 56, 148)" }}
                >
                    <div className="flex w-full flex-col items-center">
                        <h1 className="mt-6 text-4xl text-white">Team Name</h1>
                        <h1 className="mt-6 text-center text-sm text-white">
                            After creating a team you can Invite people to join
                            your team.
                        </h1>
                        <form onSubmit={handleSubmit(submit)}>
                            {/* <GeneralError
                            className={"mt-4"}
                            message={"An unexpeced error occurred"}
                        /> */}

                            <fieldset className="mt-2 space-y-4">
                                <div className="mt-6 flex flex-col items-start">
                                    <Label
                                        htmlFor={"team-name"}
                                        name={"Team name"}
                                        className="text-orange-500"
                                    />

                                    <TextFieldV2
                                        type={"text"}
                                        id={"team-name"}
                                        className="mt-1 h-14 w-[400px]"
                                        {...register("team_name", {
                                            required: true,
                                        })}
                                        placeholder="Enter team name"
                                        autoComplete={"off"}
                                        required
                                    />
                                    <FieldError
                                        message={errors.team_name?.message}
                                        className="error-message mt-1 text-orange-500"
                                    />
                                </div>
                            </fieldset>

                            <div className="mt-11 flex w-full flex-row  items-center justify-between ">
                                <div className="flex justify-end">
                                    {isSubmitting && (
                                        <SavingIndicator className="mr-2" />
                                    )}
                                    <Button
                                        variant="contained"
                                        intent="primaryVariant"
                                        size="lg"
                                        type={"submit"}
                                        disabled={isSubmitting}
                                    >
                                        Create Team
                                    </Button>
                                </div>

                                <>
                                    <Button
                                        variant="outlined"
                                        intent="primaryVariant"
                                        size="lg"
                                        onClick={() => {
                                            navigateToNext();
                                        }}
                                    >
                                        Skip
                                    </Button>
                                </>
                            </div>
                        </form>
                    </div>
                </div>
            ) : (
                <div
                    className="min-w-md mx-auto mt-32 w-[550px] rounded-lg bg-white px-4 py-10 shadow-md sm:px-6 md:px-8"
                    style={{ backgroundColor: "rgba(111, 56, 148)" }}
                >
                    <div className="flex w-full flex-col items-center">
                        <h1 className="mt-6 text-4xl text-white">
                            This is your team
                        </h1>
                        <h1 className="mt-6 text-4xl text-white">
                            {studentTeam != null
                                ? studentTeam[0].team_name
                                : ""}
                        </h1>
                    </div>
                    <h1 className="mb-3 mt-6 text-sm text-white">
                        Team members
                    </h1>
                    {studentTeam?.[0]?.members?.map((member, idx: number) => {
                        return <AddedTeamMember person={member} key={idx} />;
                    })}
                </div>
            )}

            <div className=" flex w-full flex-row items-center justify-between px-[100px]">
                <>
                    <Button
                        variant="contained"
                        intent="primaryVariant"
                        size="lg"
                        onClick={navigateBack}
                    >
                        Back
                    </Button>
                </>

                <>
                    <Button
                        variant="contained"
                        intent="secondaryVariant"
                        size="lg"
                        onClick={() => {
                            navigateToNext();
                        }}
                    >
                        Next
                    </Button>
                </>
            </div>
        </IntroLayout>
    );
};

export default TeamCreation;
