import clsx, { type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

import { format } from "date-fns";

export function pluralize(count: number) {
    if (count === 0 || count > 1) return "s";
    return "";
}

export function timeSince(date: Date) {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return (
            Math.floor(interval).toString() +
            ` year${pluralize(Math.floor(interval))}`
        );
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return (
            Math.floor(interval).toString() +
            ` month${pluralize(Math.floor(interval))}`
        );
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return (
            Math.floor(interval).toString() +
            ` day${pluralize(Math.floor(interval))}`
        );
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return (
            Math.floor(interval).toString() +
            ` hour${pluralize(Math.floor(interval))}`
        );
    }
    interval = seconds / 60;
    if (interval > 1) {
        return (
            Math.floor(interval).toString() +
            ` minute${pluralize(Math.floor(interval))}`
        );
    }
    return (
        Math.floor(seconds).toString() +
        ` second${pluralize(Math.floor(interval))}`
    );
}

export function formatISODateString(dateTimestamp: string) {
    return format(new Date(dateTimestamp), "MMM dd, yyyy 'at' hh:mm:ss a O");
}

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}
