import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";
import { type ColumnDef } from "@tanstack/react-table";
import type { Cohort } from "@src/models/types";
import { getTeamsInSchool } from "@src/api/schools";

import { type TeamsViewsetResponse } from "@src/models/types";
import Button from "@src/components/Button";
import Table from "@src/components/tables";

interface SchoolDashboardTeamsTableProps {
    cohorts: Cohort[] | undefined;
}

const SchoolDashboardTeamsTable: React.FC<SchoolDashboardTeamsTableProps> = ({
    cohorts,
}) => {
    const [cohort, setCohort] = useState<Cohort>({
        created: "",
        modified: "",
        name: "",
        sort_order: null,
        completion_date: "",
        active: false,
        school:null,
    });

    const teamsQuery = useQuery({
        queryKey: ["teams", cohort.sort_order],
        queryFn: async () => await getTeamsInSchool(cohort.sort_order),
    });

    const teamsTableColumns = useMemo<Array<ColumnDef<TeamsViewsetResponse>>>(
        () => [
            {
                header: "Name",
                accessorKey: "team_name",
            },
            {
                header: "Members",
                accessorKey: "member_count",
                disableGlobalFilter: true,
            },
            {
                header: "Current Session",
                accessorKey: "current_session",
            },
            {
                header: "Completion",
                accessorKey: "completion_percentage",
                cell: ({ getValue }) => {
                    const completionPercentage = getValue() as number;
                    return (
                        <span
                            className={clsx(
                                "px-8",
                                "py-2",
                                "rounded",
                                "flex",
                                "w-full",
                                "items-center",

                                completionPercentage === 0 &&
                                    "bg-neutral-700/10 text-neutral-700",
                                completionPercentage > 0 &&
                                    completionPercentage < 100 &&
                                    "bg-orange-500/10 text-orange-500",
                                completionPercentage === 100 &&
                                    "bg-green-500/10 text-green-500"
                            )}
                        >
                            {completionPercentage} %
                        </span>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                header: "Submissions",
                accessorKey: "id",
                cell: ({ getValue, row }) => {
                    const teamId = getValue() as number;
                    return (
                        <Link to={`/school/teams/${teamId}/submissions`}>
                            <Button
                                variant="outlined"
                                intent="white"
                                type="button"
                                size="lg"
                            >
                                View Submissions
                                <span className="sr-only">
                                    for {row.original.team_name}
                                </span>
                            </Button>
                        </Link>
                    );
                },
                disableGlobalFilter: true,
            },
        ],
        []
    );

    const teamsTableData = useMemo<TeamsViewsetResponse[]>(() => {
        if (teamsQuery.data == null) return [];
        return teamsQuery.data.map((team) => ({
            ...team,
            completion_percentage: team.completion_percentage * 100,
        }));
    }, [teamsQuery.data]);

    return (
        <Table
            columns={teamsTableColumns}
            data={teamsTableData}
            title="Teams"
            cohorts={cohorts}
            setCohort={setCohort}
        />
    );
};

export default SchoolDashboardTeamsTable;
