import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    XCircleIcon,
    XMarkIcon,
} from "@heroicons/react/20/solid";
import { cva } from "class-variance-authority";
import { useState } from "react";

const icons = {
    success: CheckCircleIcon,
    danger: XCircleIcon,
    warning: ExclamationTriangleIcon,
    info: InformationCircleIcon,
};

const container = cva(["p-4 rounded-md"], {
    variants: {
        intent: {
            success: "bg-green-100",
            danger: "bg-red-100",
            warning: "bg-yellow-100",
            info: "bg-blue-100",
        },
    },
});

const iconClasses = cva("w-5 h-5", {
    variants: {
        intent: {
            success: "text-green-400",
            danger: "text-red-400",
            warning: "text-yellow-400",
            info: "text-blue-400",
        },
    },
});

const titleClasses = cva("font-semibold", {
    variants: {
        intent: {
            success: "text-green-800",
            danger: "text-red-800",
            warning: "text-yellow-800",
            info: "text-blue-800",
        },
    },
});

const descriptionClasses = cva("mt-2", {
    variants: {
        intent: {
            success: "text-green-700",
            danger: "text-red-700",
            warning: "text-yellow-700",
            info: "text-blue-700",
        },
    },
});

const dismissIconClasses = cva(
    "inline-flex rounded-md p-1.5 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2",
    {
        variants: {
            intent: {
                success:
                    "bg-green-100 text-green-500 hover:bg-green-200 focus:ring-green-600 focus:ring-offset-green-100",
                danger: "bg-red-100 text-red-500 hover:bg-red-200 focus:ring-red-600 focus:ring-offset-red-100",
                warning:
                    "bg-yellow-100 text-yellow-500 hover:bg-yellow-200 focus:ring-yellow-600 focus:ring-offset-yellow-100",
                info: "bg-blue-100 text-blue-500 hover:bg-blue-200 focus:ring-blue-600 focus:ring-offset-blue-100",
            },
        },
    }
);

export default function Alert({
    title,
    description,
    intent,
    className = "",
    dismissible = false,
    ...props
}) {
    const Icon = icons[intent];
    const [show, setShow] = useState(true);

    return show ? (
        <section
            data-cy="alert"
            className={container({ intent, class: className })}
            {...props}
        >
            <div className="flex">
                <div className="flex-shrink-0">
                    <Icon
                        className={iconClasses({ intent })}
                        aria-hidden="true"
                    />
                </div>
                <div className="flex-1 ml-3">
                    <h3 className={titleClasses({ intent })}>{title}</h3>
                    <div className={descriptionClasses({ intent })}>
                        {description}
                    </div>
                </div>

                {dismissible && (
                    <div className="-mx-1.5 -my-1.5">
                        <button
                            type="button"
                            onClick={() => setShow(false)}
                            className={dismissIconClasses({ intent })}
                        >
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                    </div>
                )}
            </div>
        </section>
    ) : (
        <></>
    );
}
