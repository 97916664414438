import { markFiresideAsWatched } from "@src/api/firesides";
import { markContentVideoWatched } from "@src/api/sessions";
import clsx from "clsx";
import { useState } from "react";
import ReactPlayer from "react-player/vimeo";

// alternative player: https://videojs.com/guides/react/

export enum VideoType {
    Fireside,
    CaseStudyVideo,
    LearningVideo,
    Other
}

interface VideoProps {
    src: string;
    title: string;
    videoType: [VideoType, number, string];
    className?: string;
}

interface PlayerProgress {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
}

/**
 * Video component for displaying Vimeo videos.
 * @prop {string} src - The Vimeo video URL.
 * @prop {string} title - The title of the video.
 * @prop {string} className - The class name to apply to the video.
 * @prop {VideoType} videoType - The type of video content being played, its id or slug
 * @returns
 */

const Video = ({ src, className, videoType = [VideoType.Other, 0, ""] }: VideoProps) => {
    const [playerReady, setPlayerReady] = useState(false);
    const [error, setError] = useState(false);
    let markedAsWatched = false;

    const markAsWatched = async () => {
        if (!markedAsWatched) { 
            markedAsWatched = true;
            switch(videoType[0]) {
                case VideoType.Fireside:
                    await markFiresideAsWatched(videoType[2]);
                    break;
                case VideoType.CaseStudyVideo:
                case VideoType.LearningVideo:
                    await markContentVideoWatched(videoType[1]);
                    break;
                default:
                    return;
            }
        }
    }

    // function to track player progress
    const playerProgress = (playerProgress: PlayerProgress) => {
        const { loaded } = playerProgress;

        if ((loaded * 100) > 80) {
            markAsWatched()
        }
    };

    return (
        <div
            className={clsx(
                "rounded-md",
                !playerReady && !error && "animate-pulse",
                error ? "animate-none bg-black" : "bg-purple-200",
                className
            )}
        >
            {error ? (
                <div className="flex aspect-video h-full w-full flex-col items-center justify-center">
                    <p className="text-2xl sm:text-4xl font-semibold text-white">
                        Error loading video
                    </p>
                    <p className="text-white">Please try again later</p>
                </div>
            ) : (
                <ReactPlayer
                    url={src}
                    width="100%"
                    height="100%"
                    controls
                    onProgress={playerProgress}
                    config={{
                        playerOptions: {
                            title: true, // show the video title
                            byline: true, // show the video owner's name
                            portrait: true, // show the owner's avatar
                        },
                    }}
                    onReady={() => {
                        setPlayerReady(true);
                    }}
                    onError={() => {
                        // ReactPlayer doesn't automatically show video errors
                        // so we have to do it ourselves.
                        setError(true);
                    }}
                />
            )}
        </div>
    );
};

export default Video;
