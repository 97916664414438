import type { Student, Team } from "@src/models/types";
import { useForm } from "react-hook-form";
import { useRouteLoaderData } from "react-router-dom";
import {
    Label,
    TextField,
    TextArea,
    FieldError,
    GeneralError,
} from "@components/Form";
import SavingIndicator from "@src/components/SavingIndicator";
import Button from "@src/components/Button";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import { createTeam, updateTeam } from "@src/api/teams";
import { useQueryClient } from "@tanstack/react-query";

type TeamFormData = Pick<Team, "team_name" | "team_description">;

interface TeamFormProps {
    team?: Team | null;
    className: string;
    onSuccess?: () => void;
}

const TeamForm = ({ team, className = "", onSuccess }: TeamFormProps) => {
    const user = useRouteLoaderData("root") as Student;

    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
        setError,
        clearErrors,
    } = useForm<TeamFormData & { general?: string }>({
        defaultValues: {
            team_name: team?.team_name,
            team_description: team?.team_description,
        },
    });

    const submit = async (data: TeamFormData) => {
        if (team != null && !isDirty) {
            return;
        }

        try {
            if (team != null) {
                await updateTeam(team.id, data);
            } else {
                await createTeam({
                    ...data,
                    student: user.id,
                    school: user.school.school_id,
                });
            }
            clearErrors();

            await queryClient.invalidateQueries({ queryKey: ["profile"] });
            await queryClient.invalidateQueries({queryKey: ["currentTeam"]});

            onSuccess?.();
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    return (
        <form className={className} onSubmit={handleSubmit(submit)}>
            <GeneralError
                className={"mt-4"}
                message={errors.general?.message}
            />

            <fieldset className="mt-2 space-y-4">
                <div>
                    <Label htmlFor={"team-name"} name={"Team name"} />
                    <TextField
                        type={"text"}
                        id={"team-name"}
                        className="w-full mt-1"
                        {...register("team_name", { required: true })}
                        autoComplete={"off"}
                        required
                    />
                    <FieldError
                        className={"mt-1"}
                        message={errors.team_name?.message}
                    />
                </div>

                <div>
                    <Label
                        htmlFor={"team-description"}
                        name={"Team description"}
                    />
                    <TextArea
                        id={"team-description"}
                        className="w-full mt-1"
                        {...register("team_description", { required: true })}
                        required
                    />
                    <FieldError
                        className={"mt-1"}
                        message={errors.team_description?.message}
                    />
                </div>
            </fieldset>

            <div className="flex justify-end mt-8">
                <div>
                    {isSubmitting && <SavingIndicator className="mr-2" />}

                    <Button type={"submit"} disabled={isSubmitting}>
                        {user.team() != null
                            ? "Update Team Details"
                            : "Create Team"}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default TeamForm;
