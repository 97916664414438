import { useState } from "react";
import useStore from "@store/useStore";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import Button from "@src/components/Button";
import TeamSessionTask from "./TeamSessionTask";
import tasks from "../../containers/activities/SessionOne/tasks_schema.json";
import { Link } from "react-router-dom";
import Avatar from "@src/components/Avatar";
import "../../components/Loaders/loader.css";

// Disclosure for session one since they are individual submissions

const defaultSession = {
    id: Infinity,
    title: "",
    description: "",
    objective: "",
    outcomes: "",
    session_tags: [],
    poster_image: "",
    sort_order: 0,
    content: [],
};

const SessionOneDisclosure = ({ team }) => {
    const [openTask, setOpenTask] = useState("");
    const { members } = team;

    const [sessionOneTaskLabel, setSesionOneTaskLabel, setActiveSession] =
        useStore((state) => [
            state.sessionOneTaskLabel,
            state.setSessionOneTaskLabel,
            state.setActiveSession,
        ]);

    const handleOpenTask = (taskLabel) => {
        setOpenTask(taskLabel);
        setSesionOneTaskLabel(taskLabel);
    };

    const handleOpenSessionOne = () => {
        if (sessionOneTaskLabel.length === 0) {
            setSesionOneTaskLabel("Task 1");
        } else {
            setSesionOneTaskLabel("");
        }

        setActiveSession(defaultSession);
    };

    return (
        <div className="flex w-full p-2">
            <div className="flex w-full flex-col rounded-md border border-slate-200">
                <div className=" flex w-full flex-row items-center justify-between border-b border-slate-200 p-4">
                    <span className="font-medium">{`Session 1`}</span>

                    <div className="flex flex-row items-center">
                        <Button
                            variant="outlined"
                            intent="white"
                            type="button"
                            size="md"
                            className="mr-6 hidden"
                        >
                            Download
                        </Button>

                        <div
                            className="w-fit"
                            onClick={() => handleOpenSessionOne()}
                        >
                            <ChevronUpIcon
                                className={`${
                                    sessionOneTaskLabel.length > 0
                                        ? "rotate-180 transform"
                                        : ""
                                } h-5 w-5 text-purple-500`}
                            />
                        </div>
                    </div>
                </div>

                {sessionOneTaskLabel.length > 0 && (
                    <div className="flex-flex-col w-full">
                        {tasks.map((task, index) => {
                            return (
                                <div className="mt-2 flex w-full flex-col px-6">
                                    <div className="flex flex-row items-center justify-between py-4">
                                        <p className="text-sm font-semibold">{`Task ${
                                            index + 1
                                        }`}</p>
                                        <div
                                            className="p-1"
                                            onClick={() =>
                                                handleOpenTask(task.label)
                                            }
                                        >
                                            {task.label ===
                                            sessionOneTaskLabel ? (
                                                <ChevronDownIcon className="h-6 w-6" />
                                            ) : (
                                                <ChevronUpIcon className="h-6 w-6" />
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {members.map((member, idx) => {
                                            return (
                                                <div
                                                    className={`grid w-full grid-cols-3 border-b p-5 ${
                                                        task.label ===
                                                        sessionOneTaskLabel
                                                            ? "visible"
                                                            : "hidden"
                                                    }`}
                                                    key={idx}
                                                >
                                                    <div className="flex flex-row items-center place-self-start">
                                                        <Avatar
                                                            size="32"
                                                            name={
                                                                member.full_name
                                                            }
                                                        />
                                                        <p className="ml-2">
                                                            {member.full_name}
                                                        </p>
                                                    </div>
                                                    <div className="mr-11 flex min-w-12 items-center justify-center ">
                                                        <></>
                                                    </div>

                                                    <Link
                                                        to="/school/session-one-submission"
                                                        state={{
                                                            task,
                                                            member,
                                                            team,
                                                        }}
                                                        className="place-self-end"
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            intent="white"
                                                            type="button"
                                                            size="md"
                                                        >
                                                            View
                                                        </Button>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

// Submissions for the team
const TeamSessions = ({ sessions = [], team = {} }) => {
    const [selectedSession, setSelectedSession] = useState({});

    const [activeSession, setActiveSession, setSessionOneTaskLabel] = useStore(
        (state) => [
            state.activeSession,
            state.setActiveSession,
            state.setSessionOneTaskLabel,
        ]
    );

    const handleSessionClick = (session) => {
        const sessionDetails = session.session_details;
        setSelectedSession(sessionDetails);
        setActiveSession(sessionDetails);

        setSessionOneTaskLabel("");
    };

    return (
        <div className="w-full ">
            {/* Session one Disclosure */}
            <SessionOneDisclosure team={team} sessions={sessions} />

            {sessions.length <= 0 && (
                <div className="flex w-full items-center justify-center p-4">
                    <span className="loader "></span>
                </div>
            )}

            <div className="mx-auto w-full rounded-2xl bg-white px-2">
                {sessions.map((session, idx) => {
                    const sessionData = { ...session };
                    const sortOrder = session.session_details.sort_order;
                    delete sessionData.session_details;
                    const tasksArray = Object.entries(sessionData);
                    const taskCount = tasksArray.length;

                    return (
                        <div className="my-3 flex w-full">
                            <div className="flex w-full flex-col rounded-md border border-slate-200">
                                <div className=" flex w-full flex-row items-center justify-between border-b border-slate-200 p-4">
                                    <span className="font-medium">{`Session ${sortOrder}`}</span>

                                    <div
                                        className="p-1"
                                        onClick={() =>
                                            handleSessionClick(session)
                                        }
                                    >
                                        <ChevronUpIcon
                                            className={`${
                                                activeSession?.id ===
                                                session.session_details.id
                                                    ? "rotate-180 transform"
                                                    : ""
                                            } h-5 w-5 text-purple-500`}
                                        />
                                    </div>
                                </div>
                                {activeSession.id ===
                                    session.session_details.id && (
                                    <div className="mt-2 w-full px-6">
                                        {tasksArray.map((task, idx) => {
                                            return (
                                                <div
                                                    className="flex w-full flex-col items-start"
                                                    key={idx}
                                                >
                                                    <TeamSessionTask
                                                        task={task}
                                                        session={session}
                                                        team={team}
                                                        taskCount={taskCount}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TeamSessions;
