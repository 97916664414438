import { Popover, Transition } from "@headlessui/react";
import {
    ArrowLeftIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import {
    ClipboardDocumentListIcon,
    FaceFrownIcon,
    FaceSmileIcon,
} from "@heroicons/react/24/outline";
import { myScoreQuery, xpLogQuery } from "@src/api/league";
import Avatar from "@src/components/Avatar";
import Button from "@src/components/Button";
import PageHeader from "@src/components/PageHeader";
import Table from "@src/components/tables";
import type { Badge, EventLog, Student } from "@src/models/types";
import { type ColumnDef } from "@tanstack/react-table";
import { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { type AxiosError } from "axios";
import { formatDistance } from "date-fns";

const XPLogSection: React.FC = () => {
    const { data: xpLog } = useQuery({ ...xpLogQuery() });

    const columns: Array<ColumnDef<EventLog>> = [
        {
            header: "Milestone",
            accessorKey: "rule.event_name",
            cell: ({ getValue }) => {
                const milestone = getValue() as string;
                return (
                    <div className="justify-self-start px-4">
                        <p>{milestone}</p>
                    </div>
                );
            },
        },
        {
            header: "Points",
            accessorKey: "rule.points",
            cell: ({ getValue }) => {
                const points = getValue() as number;
                return (
                    <div className="justify-self-center">
                        <p>{points}</p>
                    </div>
                );
            },
        },
        {
            header: "Date",
            accessorKey: "created",
            cell: ({ getValue }) => {
                const datetime = getValue() as string;
                return (
                    <div className="justify-self-center">
                        <p>
                            {formatDistance(new Date(datetime), new Date())} ago
                        </p>
                    </div>
                );
            },
        },
    ];

    return (
        <Table
            title="Experience Points Log"
            columns={columns}
            data={xpLog !== undefined ? xpLog : []}
        />
    );
};

const BadgeSection: React.FC<{ badges: Badge[] }> = ({ badges }) => {
    return (
        <div className="mb-8 grid grid-cols-1 gap-4 py-8 md:grid-cols-2 lg:grid-cols-3">
            {badges.length > 0 ? (
                badges.map((badge, idx) => (
                    <div
                        className="flex items-center gap-6 rounded-lg border-[1px] bg-white p-4"
                        key={idx}
                    >
                        <div>
                            <img
                                className="h-28 w-28 rounded-full"
                                src={badge.poster_url}
                            />
                        </div>
                        <div>
                            <p className="text-xl font-bold">{badge.name}</p>
                            <p className="mb-2 font-bold">
                                {badge.description}
                            </p>
                            <p className="text-sm text-slate-400">
                                {formatDistance(
                                    new Date(badge.created),
                                    new Date()
                                )}
                            </p>
                        </div>
                    </div>
                ))
            ) : (
                <div className="col-span-3">
                    <p className="text-center text-2xl">
                        You have not earned any badges yet!
                    </p>
                </div>
            )}
        </div>
    );
};

const MyScore = () => {
    const navigator = useNavigate();
    const user = useRouteLoaderData("root") as Student;

    const {
        data: myScore,
        isLoading,
        error,
    } = useQuery({ ...myScoreQuery(user.id) });

    return (
        <>
            <PageHeader
                title="Leaderboard"
                breadcrumbPaths={[
                    { name: "Leaderboard", href: "/student/league/team" },
                    { name: "My Score", href: "/student/league/my-score" },
                ]}
            />
            <section className="px-4 py-6 sm:px-6 md:px-8">
                <div className="mb-8">
                    <Button
                        intent="white"
                        onClick={() => {
                            navigator(-1);
                        }}
                        className="mr-3"
                    >
                        <ArrowLeftIcon className="h-5 w-5" />
                    </Button>
                    <span className="text-3xl">{user.first_name}'s Score</span>
                </div>
                {myScore !== undefined &&
                    (myScore.points > 0 ? (
                        <>
                            <h1 className="text-2xl font-bold">Overview</h1>
                            <div className="mb-8 grid divide-x rounded-lg border-2 bg-white px-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">Student</h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex gap-4">
                                        <div>
                                            {user.avatar != null ? (
                                                <>
                                                    <img
                                                        className="h-14 w-14 rounded-full"
                                                        src={user.avatar}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Avatar
                                                        name={user.full_name}
                                                        size="3.5rem"
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            <p className="text-lg font-bold">
                                                {user.full_name}
                                            </p>
                                            {/* <p className="text-lg font-bold">{user.}</p> */}
                                            <p className="text-lg text-slate-400">
                                                {user.school.school_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">Total Points</h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex items-center gap-4">
                                        <div>
                                            <img
                                                className="h-12 w-12 rounded-full"
                                                src="/images/points.svg"
                                            />
                                        </div>
                                        <div>
                                            <span className="text-7xl font-bold">
                                                {myScore.points}
                                            </span>
                                            <span className="text-4xl font-bold">
                                                /{myScore.total_points}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">Badges</h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex flex-wrap items-center gap-1">
                                        {myScore.badges.length === 0 && (
                                            <p className="w-full text-center text-2xl">
                                                You have no badges!
                                            </p>
                                        )}
                                        {myScore.badges
                                            .slice(0, 4)
                                            .map((badge, idx) => (
                                                <>
                                                    <Popover
                                                        key={idx}
                                                        className="relative"
                                                    >
                                                        {({ open }) => (
                                                            <>
                                                                <Popover.Button
                                                                    as={
                                                                        Fragment
                                                                    }
                                                                >
                                                                    <img
                                                                        key={
                                                                            idx
                                                                        }
                                                                        src={
                                                                            badge.poster_url
                                                                        }
                                                                        title={
                                                                            badge.name
                                                                        }
                                                                        className="m-1 h-16 w-16 rounded-full"
                                                                    />
                                                                </Popover.Button>

                                                                {open && (
                                                                    <Transition
                                                                        as={
                                                                            Fragment
                                                                        }
                                                                        enter="transition ease-out duration-200"
                                                                        enterFrom="opacity-0 translate-y-1"
                                                                        enterTo="opacity-100 translate-y-0"
                                                                        leave="transition ease-in duration-150"
                                                                        leaveFrom="opacity-100 translate-y-0"
                                                                        leaveTo="opacity-0 translate-y-1"
                                                                    >
                                                                        <Popover.Panel
                                                                            className="absolute left-2/4 top-2/4 z-10 "
                                                                            static
                                                                        >
                                                                            <div className="w-fit rounded-b-lg rounded-r-lg bg-purple-200 p-4">
                                                                                <p className="mb-1 text-nowrap text-xl font-bold">
                                                                                    {
                                                                                        badge.name
                                                                                    }
                                                                                </p>
                                                                                <p className="mb-4">
                                                                                    {
                                                                                        badge.description
                                                                                    }
                                                                                </p>
                                                                                <p className="text-slate-400">
                                                                                    {formatDistance(
                                                                                        new Date(
                                                                                            badge.created
                                                                                        ),
                                                                                        new Date()
                                                                                    )}{" "}
                                                                                    ago
                                                                                </p>
                                                                            </div>
                                                                        </Popover.Panel>
                                                                    </Transition>
                                                                )}
                                                            </>
                                                        )}
                                                    </Popover>
                                                </>
                                            ))}
                                    </div>
                                </div>
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">Team Ranking</h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex items-center gap-4">
                                        <div>
                                            {myScore.team_rank /
                                                myScore.total_in_team <=
                                            0.5 ? (
                                                <FaceSmileIcon className="h-14 w-14 text-green-500" />
                                            ) : (
                                                <FaceFrownIcon className="h-14 w-14 text-red-500" />
                                            )}
                                        </div>
                                        <div className="text-blue-500">
                                            <span className="text-7xl font-bold">
                                                {myScore.team_rank}
                                            </span>
                                            <span className="text-4xl font-bold">
                                                /{myScore.total_in_team}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">
                                        Student Ranking
                                    </h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex items-center gap-4">
                                        <div>
                                            {myScore.cohort_rank /
                                                myScore.total_in_cohort <=
                                            0.5 ? (
                                                <FaceSmileIcon className="h-14 w-14 text-green-500" />
                                            ) : (
                                                <FaceFrownIcon className="h-14 w-14 text-red-500" />
                                            )}
                                        </div>
                                        <div className="text-orange-500">
                                            <span className="text-7xl font-bold">
                                                {myScore.cohort_rank}
                                            </span>
                                            <span className="text-4xl font-bold">
                                                /{myScore.total_in_cohort}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-8">
                                <XPLogSection />
                            </div>
                            <h1 className="text-2xl font-bold">Badges</h1>
                            <BadgeSection badges={myScore.badges} />
                        </>
                    ) : (
                        <div className="flex justify-center">
                            <div className="flex w-1/2 flex-col items-center justify-center gap-4">
                                <ClipboardDocumentListIcon className="h-56 w-56 stroke-1 text-slate-300" />
                                <p className="text-center text-2xl">
                                    No scores yet, take a session to accumulate
                                    scores
                                </p>
                                <Button
                                    intent="primary"
                                    className="!text-2xl drop-shadow-lg"
                                    href="/students/session/1/objectives"
                                    size="lg"
                                >
                                    Session 1
                                </Button>
                            </div>
                        </div>
                    ))}
                {myScore === undefined && (
                    <>
                        {isLoading && (
                            <div className="animate-pulse">
                                <div className="mb-4 h-6 w-56 rounded bg-slate-200" />
                                <div className="mb-8 h-60 w-full rounded-lg bg-slate-200" />
                                <div className="mb-4 h-6 w-56 rounded bg-slate-200" />
                                <div className="mb-8 h-60 w-full rounded-lg bg-slate-200" />
                            </div>
                        )}
                        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                        {error && (
                            <div className="flex justify-center">
                                <div className="flex w-1/2 flex-col items-center justify-center gap-4">
                                    <ExclamationCircleIcon className="h-56 w-56 stroke-1 text-red-500" />
                                    <p className="text-center text-2xl">
                                        {(error as AxiosError).message}
                                    </p>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </section>
        </>
    );
};

export default MyScore;
