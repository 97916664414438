const TableIndicator = () => {
    return (
      <>
           <div className="w-full bg-slate-100 p-4  border border-gray-200 space-y-10 rounded-lg shadow motion-safe:animate-pulse ">
              {[1,2,4,5,6,7,8,9,10,11,12].map((bar, i)=>(
                  <div key={bar} className="flex items-center px-10 justify-between">
                      <div>
                          <div className="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                          <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
                      </div>
                      <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
                  </div>
              ))}  
          </div>
      </>
    )
  }
  
  export default TableIndicator
  