import type { Team } from "@src/models/types";
import axios from "./axios";
import { type QueryOptions } from "@tanstack/react-query";

export async function getTeams(schooldId: number) {
    const params = Object.entries({
        school: schooldId.toString(),
    });
    const urlPath = `teams/` + `?${new URLSearchParams(params).toString()}`;

    const res = await axios.get<Team[]>(urlPath);

    return res.data;
}

export async function getTeam(teamId: number) {
    const res = await axios.get<Team>(`teams/${teamId}/`);

    return res.data;
}

export function currentTeamQueryOptions(teamId: number) {
    return {
        queryKey: ["currentTeam", teamId],
        queryFn: async () => await getTeam(teamId),
    } satisfies QueryOptions;
}

export async function createTeam(data: Record<string, unknown>) {
    return await axios.post("teams/", data);
}

export async function syncMembers(
    teamId: number,
    data: Record<string, unknown>
) {
    return await axios.post(`teams/${teamId}/sync_members/`, data);
}

export const syncMultipleMembers = async (
    teamId: number,
    data: {
        members: number[];
    }
) => {
    return await axios.post(`teams/${teamId}/sync_members/`, data);
};

export async function updateTeam(
    teamId: number,
    data: Record<string, unknown>
) {
    return await axios.patch(`teams/${teamId}/`, data);
}

export async function deleteTeam(teamId: number) {
    return await axios.delete(`teams/${teamId}/`);
}
