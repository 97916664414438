import { formatISO, format, formatDistanceToNow } from "date-fns";
import { type TimeHTMLAttributes } from "react";

interface TimeProps {
    value: Date;
    dateFormat?: string;
}

const Time = ({ value, dateFormat = "relative", ...props }: TimeProps & TimeHTMLAttributes<HTMLTimeElement>) => {
    const defaultDateFormat = "MMM dd, yyyy 'at' hh:mm:ss a O";

    return (
        <time
            {...props}
            dateTime={formatISO(value)}
            title={format(value, defaultDateFormat)}
        >
            {dateFormat === "relative"
                ? formatDistanceToNow(value, { addSuffix: true })
                : format(value, dateFormat)}
        </time>
    );
};

export default Time;
