import type { ApiResponse, ConstructorParams } from "@src/utils/type_helpers";
import axios from "./axios";
import type {
    Student,
    StudentSession,
    SubmissionFeedback,
} from "@src/models/types";
import {
    type WatchedFiresideChat,
    type FiresideChat,
    type ReflectionEntry,
    type QuestionResponsePayload,
    type ReflectionQuestion,
} from "./firesides";

export async function getStudents(
    params: ConstructorParams<typeof URLSearchParams>[0]
) {
    let urlPath = "students/";

    if (params !== undefined) {
        urlPath += `?${new URLSearchParams(params).toString()}`;
    }

    return await axios.get(urlPath);
}

export async function searchStudents(query: string) {
    const params = { search: query };

    return await getStudents(params);
}

export async function getStudentDetailsById(studentId: number | undefined) {
    if (studentId === undefined) {
        return await Promise.reject(new Error("Student ID is required"));
    }
    const res = await axios.get<Student>(`students/${studentId}`);
    return res.data;
}

export async function getSessionProgress(studentId: number | undefined) {
    if (studentId === undefined) {
        return await Promise.reject(new Error("Student ID is required."));
    }

    const res = await axios.get<StudentSession[]>(
        `/students/${studentId}/session-progress/`
    );
    return res.data;
}

export async function getFiresideHistory(studentId: number | undefined) {
    if (studentId === undefined) {
        return await Promise.reject(new Error("Student ID is required."));
    }

    const res = await axios.get<WatchedFiresideChat[]>(
        `students/${studentId}/firesides/`
    );
    return res.data;
}

export async function getSingleFiresideHistoryDetails(
    studentId: number,
    firesideId: number
) {
    const res = await axios.get<FiresideChat>(
        `students/${studentId}/firesides/${firesideId}`
    );
    return res.data;
}

export function singleFiresideHistortyDetailsQuery(
    studentId: number,
    firesideId: number
) {
    return {
        queryKey: ["students", studentId, "firesides", firesideId],
        queryFn: async () =>
            await getSingleFiresideHistoryDetails(studentId, firesideId),
    };
}

export function fireSideHistoryQuery(studentId: number | undefined) {
    return {
        queryKey: ["students", studentId, "firesides"],
        queryFn: async () => await getFiresideHistory(studentId),
    };
}

export async function getStudentReflectionEntries(
    studentId: number,
    firesideId: number
) {
    const res = await axios.get<ReflectionEntry[]>(
        `/students/${studentId}/firesides/${firesideId}/reflection-entries`
    );
    return res.data;
}
/**
 * Post answers from fireside chat reflection questions
 * @param data QuestionResponsePayload to submit
 * @param studentId id of student
 * @param firesideId id of fireside chat
 * @returns reflection response entries
 */
export async function submitReflectionResponse(
    data: QuestionResponsePayload,
    studentId: number,
    firesideId: number
) {
    return await axios.post<ReflectionQuestion[]>(
        `/students/${studentId}/firesides/${firesideId}/reflection-entries/`,
        data
    );
}

export function studentReflectionEntriesQuery(
    studentId: number,
    firesideId: number
) {
    return {
        queryKey: [
            "students",
            studentId,
            "firesides",
            firesideId,
            "reflection-entries",
        ],
        queryFn: async () =>
            await getStudentReflectionEntries(studentId, firesideId),
    };
}

export async function getParentFiresideFeedback(reflectionEntry: number) {
    const res = await axios.get<SubmissionFeedback[]>(
        `reflection-entry/${reflectionEntry}/feedback/`
    );
    return res.data;
}

export function parentFiresideFeedbackQuery(reflectionEntry: number) {
    return {
        queryKey: ["fireside-comments", reflectionEntry],
        queryFn: async () => await getParentFiresideFeedback(reflectionEntry),
    };
}

export async function newFiresideSubmissionFeedback(
    reflectionEntry: number,
    data: Record<"content", string>
) {
    return await axios.post<ApiResponse<SubmissionFeedback>>(
        `reflection-entry/${reflectionEntry}/feedback/`,
        data
    );
}
