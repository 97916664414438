export default function SettingsCard({ title, form, actions }) {
    return (
        <div
            className={
                "bg-white rounded-md max-w-3xl mx-auto shadow overflow-hidden"
            }
        >
            <div className={"px-4 pt-6 pb-8 sm:px-6 md:px-8"}>
                <h3 className="font-semibold text-xl">{title}</h3>

                <div className="mt-6">{form}</div>
            </div>

            <div
                className={
                    "px-4 sm:px-6 md:px-8 py-6 bg-gray-50 flex justify-end"
                }
            >
                {actions}
            </div>
        </div>
    );
}
