import Avatar from "@src/components/Avatar";
import Time from "@src/components/Time";

const Comments = ({ comment }) => {
    return (
        <div key={comment.id}>
            <div className="flex space-x-3 py-3">
                <div className="flex-shrink-0">
                    <Avatar
                        size="36"
                        name={comment.submitter.full_name ?? ""}
                    />
                </div>
                <div>
                    <div className="flex flex-row items-center">
                        <a
                            href="#"
                            className="text-md mr-4 font-semibold text-gray-900"
                        >
                            {comment.submitter.full_name}
                        </a>
                        <Time
                            value={new Date(comment.created_at)}
                            className="text-sm text-gray-500"
                        />
                    </div>
                    <div className="mt-1 text-gray-700">
                        <p>{comment.content}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comments;
