import type { SessionSubmissionRecord } from "@src/models/types";
import { PencilIcon } from "@heroicons/react/24/outline";
import { type ValueTypes } from "@src/utils/type_helpers";

type SubmissionsContentType = ValueTypes<SessionSubmissionRecord["content"]>;

interface RenderTaskContentProps {
    content: SubmissionsContentType;
}

function renderTypeMatcher(content: SubmissionsContentType): React.ReactNode {
    if (Array.isArray(content)) {
        return (
            <div className="px-8 py-4 space-y-5 overflow-y-scroll max-h-96">
                {content?.map((content: string, indx: number) => (
                    <div key={indx} className="flex items-center gap-4">
                        <PencilIcon className="w-5 h-5 text-purple-700" />
                        <p className="text-lg font-medium">
                            {renderTypeMatcher(content)}
                        </p>
                    </div>
                ))}
            </div>
        );
    } else if (typeof content === "object") {
        return (
            <>
                {"sdg" in content ? (
                    <>
                        <p>{content?.value}</p>
                        <br />
                        <p>{content?.sdg}</p>
                    </>
                ) : (
                    JSON.stringify(content)
                )}
            </>
        );
    } else if (typeof content === "string") {
        return (
            <div
                className="px-4 overflow-y-scroll text-lg font-medium leading-10 max-h-96"
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            ></div>
        );
    }

    return content;
}

export default function RenderTaskContent({ content }: RenderTaskContentProps) {
    return renderTypeMatcher(content);
}
