import styled from "styled-components";

export const HalfCircle = styled.div`
    position: absolute;
    height: ${(props) => (props.h ? `${props.h}` : "30px")};
    width: ${(props) => (props.width ? `${props.width}` : "30px")};
    top: ${(props) => (props.top ? `${props.top}` : "-15px")};
    left: ${(props) => (props.left ? `${props.left}` : "10%")};
    background-color: rgba(111, 56, 148, 0.3);
    border-radius: 100% 100%;
`;
