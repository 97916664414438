import { Popover, Transition } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from "react";
import VimeoPlayer from "react-player/vimeo";
import { ChevronRightIcon, ChevronLeftIcon, CheckIcon } from "@heroicons/react/20/solid";

type ConfigProps = {
    playerRef: MutableRefObject<VimeoPlayer>;
}

type State = "default" | "quality" | "speed"

const qualityOptions  = ["Auto", "360p", "540", "720p", "1080p"] as const

type VideoQuality = typeof qualityOptions[number]

const speedOptions = [0.5 , 0.75 , 1 , 1.25 , 1.5 , 2] as const
type PlaybackRate = typeof speedOptions[number]


export const ConfigControls = (props: ConfigProps) => {
    const [dispState, setDispState] = useState<State>("default")
    const [quality, setQuality] = useState<VideoQuality>("Auto")
    const [playbackRate, setPlaybackRate] = useState<PlaybackRate>(1)

    const resetDispState = () => setDispState("default")

    const tempRotate = (element: HTMLElement, time: number = 150) => {
      element.classList.add("animate-spin")
      setTimeout(() => {
        element.classList.remove("animate-spin")
      }, time)
    }
  
    const showConfigOptions = () => {
      let gear = document.getElementById("config-gear")
      tempRotate(gear!)
    }

    const setQualityAfterFirstLoad = (quality_: VideoQuality) => {
        setQuality(quality_)
        let internalPlayer = props.playerRef.current.getInternalPlayer()
        internalPlayer.setQuality(quality_)
    }

    const setPlaybackRateAfterFirstLoad = (playback: PlaybackRate) => {
        setPlaybackRate(playback)
        let internalPlayer = props.playerRef.current.getInternalPlayer()
        internalPlayer.setPlaybackRate(playback)
    }

    return (
        <>
            <Popover>
                <Popover.Button>
                    <Cog6ToothIcon id="config-gear" className="w-8 h-8 cursor-pointer" onClick={showConfigOptions}/>
                </Popover.Button>
                <Popover.Panel className="absolute right-10 bottom-20 rounded-md w-80 bg-gray-900">
                    {() => {
                        switch (dispState) {
                            case "quality":
                                return (<QualityControls
                                    quality={quality}
                                    setQuality={setQualityAfterFirstLoad}
                                    resetDispState={resetDispState}/>)
                            case "speed":
                                return (<PlaybackRateControls
                                    playback={playbackRate}
                                    setPlayback={setPlaybackRateAfterFirstLoad}
                                    resetDispState={resetDispState}/>)
                            default:
                                return (
                                    <ul className="py-2">
                                        <li 
                                            className="p-4 hover:bg-gray-400 cursor-pointer"
                                            onClick={() => setDispState("quality")}>
                                                <div className="flex justify-between">
                                                    <p>Quality</p>
                                                    <div className="flex space-x-2">
                                                        <p>{quality}</p>
                                                        <ChevronRightIcon className="w-6 h-6"/>
                                                    </div>
                                                </div>
                                        </li>
                                        <li 
                                            className="p-4 hover:bg-gray-400 cursor-pointer"
                                            onClick={() => setDispState("speed")}>
                                                <div className="flex justify-between">
                                                    <p>Speed</p>
                                                    <div className="flex space-x-2">
                                                        <p>{playbackRate === 1 ? "Normal" : playbackRate}</p>
                                                        <ChevronRightIcon className="w-6 h-6"/>
                                                    </div>
                                                </div>
                                        </li>
                                    </ul>
                                )
                        }
                    }}
                </Popover.Panel>
            </Popover>
        </>
    )
}

type QualityControlsProps = {
    quality: VideoQuality;
    setQuality: (arg0: VideoQuality) => void;
    resetDispState: () => void;
}

const QualityControls = (props: QualityControlsProps) => {
    return (
        <div className="divide-y py-4">
            <div className="flex space-x-2 flex-row cursor-pointer p-4" onClick={props.resetDispState}>
                <ChevronLeftIcon className="w-6 h-6"/>
                <p>Quality</p>
            </div>
            <ul>
                {qualityOptions.map((quality) => (
                    <li 
                        className="p-4 flex space-x-6 cursor-pointer hover:bg-gray-400"
                        onClick={() => props.setQuality(quality)}>
                            <p>{quality}</p>
                            {quality === props.quality?
                                <CheckIcon className="w-6 h-6"/>:
                                <></>
                            }
                    </li>
                ))}
            </ul>
        </div>
    )
}

type PlaybackRateControlProps = {
    playback: PlaybackRate;
    setPlayback: (arg0: PlaybackRate) => void;
    resetDispState: () => void;
}

const PlaybackRateControls = (props: PlaybackRateControlProps) => {
    return (
        <div className="divide-y py-4">
            <div className="flex space-x-2 flex-row cursor-pointer p-4" onClick={props.resetDispState}>
                <ChevronLeftIcon className="w-6 h-6"/>
                <p>Speed</p>
            </div>
            <ul>
                {speedOptions.map((playback_) => (
                    <li 
                        className="p-4 flex space-x-6 cursor-pointer hover:bg-gray-400"
                        onClick={() => props.setPlayback(playback_)}>
                            <p>{playback_ === 1 ? "Normal" : playback_}</p>
                            {playback_ === props.playback?
                                <CheckIcon className="w-6 h-6"/>:
                                <></>
                            }
                    </li>
                ))}
            </ul>
        </div>
    )
}
