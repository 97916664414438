import {
    RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import SideBar, { type SideBarNavItem } from "../Navbar/SideBar";
import MainLayout, { type MainLayoutProps } from "./MainLayout";

interface ParentsLayoutProps extends MainLayoutProps {}

const navItems: SideBarNavItem[] = [
    {
        name: "Dashboard",
        link: "/parent/dashboard",
        icon: RectangleGroupIcon,
    },
];

const ParentLayout = ({ children }: ParentsLayoutProps) => {
    return (
        <MainLayout sidebar={<SideBar navItems={navItems} />}>
            {children}
        </MainLayout>
    );
};

export default ParentLayout;
