import {
    type ReflectionQuestion,
    type QuestionResponsePayload,
    type ReflectionEntry,
} from "@src/api/firesides";
import {
    studentReflectionEntriesQuery,
    submitReflectionResponse,
} from "@src/api/students";
import { FieldError, Label, TextArea } from "@src/components/Form";
import Button from "@components/Button";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import SavingIndicator from "../SavingIndicator";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { toast } from "react-toastify";

interface ReflectionQuestionsFormProps {
    questions: ReflectionQuestion[];
    className: string;
    firesideId: number;
    studentId: number;
}

type FormValues = Record<`question_${number}`, string>;

function transformFormValues(data: FormValues) {
    const payload: QuestionResponsePayload = {};

    for (const entry in data) {
        const value = data[entry as keyof FormValues];
        payload[entry as keyof FormValues] = { value };
    }

    return payload;
}

export default function ReflectionQuestionsForm({
    questions,
    firesideId,
    studentId,
    className,
}: ReflectionQuestionsFormProps) {
    // get all reflection entries for this fireside
    const { data: reflectionEntries } = useQuery({
        ...studentReflectionEntriesQuery(studentId, firesideId),
    });

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isDirty, isSubmitting },
        reset,
    } = useForm<FormValues>();

    /**
     * get current reflection entry response and set as default values
     * for the form fields.
     * @param reflectionEntry - latest reflection entry for the fireside.
     * @returns void - calls reset function to set default values.
     */
    const extractDefaultValues = (reflectionEntry: ReflectionEntry) => {
        const { responses } = reflectionEntry;

        const defaultValues: FormValues = {};

        for (const response of responses) {
            defaultValues[`question_${response.reflection_question}`] = response
                .content.value as string;
        }

        reset(defaultValues);
    };

    useEffect(() => {
        if (reflectionEntries != null && reflectionEntries.length > 0) {
            // the latest reflection entry is the first array element
            extractDefaultValues(reflectionEntries[0]);
        }
    }, [reflectionEntries]);

    const submit = async (data: FormValues) => {
        if (!isDirty) {
            return;
        }

        try {
            const response = await submitReflectionResponse(
                transformFormValues(data),
                studentId,
                firesideId
            );

            toast.success("Responses saved!", {
                autoClose: 2000,
            });

            console.log("RESPONSE ---> ", response);
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    // submit button text
    let submitText = "Submit Responses";
    if (reflectionEntries != null && reflectionEntries.length > 0) {
        submitText = "Update Responses";
    }

    return (
        <section
            id="reflection-questions"
            className={clsx(
                "rounded-lg bg-white px-4 py-10 shadow sm:px-6 md:px-8",
                className
            )}
        >
            <h2 className="text-3xl font-medium text-purple-600">
                Reflection Questions
            </h2>

            <form className="mt-6 max-w-4xl" onSubmit={handleSubmit(submit)}>
                <fieldset className="space-y-8">
                    {questions.map((question, idx) => (
                        <div key={question.id}>
                            <Label
                                className="text-xl font-normal"
                                name={question.prompt}
                                htmlFor={`question-${idx + 1}`}
                            />
                            <TextArea
                                id={`question-${idx + 1}`}
                                {...register(`question_${question.id}`, {
                                    required: "This field is required",
                                })}
                                rows={5}
                                className="mt-2 w-full"
                                required
                            />
                            <FieldError
                                className="mt-2"
                                message={
                                    errors[`question_${question.id}`]?.message
                                }
                            />
                        </div>
                    ))}
                </fieldset>

                <div className="mt-8 flex items-center justify-end">
                    {isSubmitting && <SavingIndicator className="mr-2" />}
                    <Button type="submit">{submitText}</Button>
                </div>
            </form>
        </section>
    );
}
