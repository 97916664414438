import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'MON',
    Activities: 4.000,
    Learning: 2.400,
    FiresideChats: 2.400,
  },
  {
    name: 'TUE',
    Activities: 3.000,
    Learning: 1.398,
    FiresideChats: 2.210,
  },
  {
    name: 'WED',
    Activities: 2.000,
    Learning: 9.800,
    FiresideChats: 2.290,
  },
  {
    name: 'THU',
    Activities: 2.780,
    Learning: 3.908,
    FiresideChats: 2.000,
  },
  {
    name: 'FRI',
    Activities: 1.890,
    Learning: 4.800,
    FiresideChats: 2.181,
  },
  {
    name: 'SAT',
    Activities: 2.390,
    Learning: 3.800,
    FiresideChats: 2.500,
  },
  {
    name: 'SUN',
    Activities: 3.490,
    Learning: 4.300,
    FiresideChats: 2.100,
  },
];

const StudentEngagementChart = () => {
    return (
        <div className="mt-8 rounded-2xl bg-white py-8 drop-shadow-md">
        <div className="flex items-center justify-between px-6">
            <div>
                <h2 className="text-2xl font-bold leading-6 text-gray-900">
                    Engagement
                </h2>
            </div>
            <div className="flex gap-4">
            </div>
        </div>
        <ResponsiveContainer width="100%" height="100%" className="aspect-video px-5 mt-8">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false}/>
          <XAxis dataKey="name" tickLine={false} />
          <YAxis tickLine={false} tickFormatter={val => `${val} hrs`}/>
          <Tooltip />
          <Legend verticalAlign="top" height={36} align="right" iconType="circle" formatter={(value, entry, index) => <span className="text-black">{value}</span>}/>
          <Bar dataKey="Learning" stackId="a" fill="#962DFF" />
          <Bar dataKey="Activities" stackId="a" fill="#C893FD" />
          <Bar dataKey="FiresideChats" stackId="a" fill="#F0E5FC" radius={[10, 10, 0, 0]}/>
        </BarChart>
      </ResponsiveContainer>
    </div>
    );
  }

export default StudentEngagementChart;
