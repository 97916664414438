import { Popover } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import Button from "@src/components/Button";
import { useEditComment } from "@src/api/forums";
import { toast } from "react-toastify";
import "../../components/Loaders/loader.css";

interface EditCommentProps {
    comment: string;
    threadId: number;
    setHasEdditedComment: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditComment: React.FC<EditCommentProps> = ({
    comment = "",
    threadId,
    setHasEdditedComment,
}) => {
    const [editedComment, setEdditedComment] = useState("");

    const editCommentMututation = useEditComment();
    const isLoading = editCommentMututation.isLoading;

    useEffect(() => {
        setEdditedComment(comment);
    }, [comment]);

    const handleFeedbackChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const comment = e.target.value;
        setEdditedComment(comment);
    };

    const handleEditComentClick = async () => {
        try {
            await editCommentMututation.mutateAsync(
                {
                    threadId: threadId.toString(),
                    content: editedComment,
                },
                {
                    onSuccess() {
                        toast.success(`Comment editted succesfully`, {
                            autoClose: 2000,
                        });
                        setHasEdditedComment(true);
                    },
                    onError(err) {
                        console.log("EDIT COMMENT FAILED ", err);
                        toast.error(`An unexpected error occurred`, {
                            autoClose: 2000,
                        });
                        setHasEdditedComment(false);
                    },
                }
            );
        } catch (error: any) {
            console.log("ERRor ", error);
            setHasEdditedComment(false);
        }
    };

    return (
        <Popover>
            <Popover.Button>
                <div className=" flex-row rounded-lg p-1  hover:bg-purple-100 hover:bg-purple-200">
                    <PencilIcon className="h-4 w-4 cursor-pointer  " />
                </div>
            </Popover.Button>
            <Popover.Panel className="flex flex-col">
                <>
                    <textarea
                        value={editedComment}
                        onChange={(e) => {
                            handleFeedbackChange(e);
                        }}
                        rows={10}
                        className="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                    ></textarea>

                    <div
                        className="flex w-full justify-end"
                        // onClick={submitFeedback}
                    >
                        <div
                            className="flex flex-row gap-2"
                            onClick={handleEditComentClick}
                        >
                            <Button type="submit">Edit Comment</Button>

                            {isLoading ? <div className="loader"></div> : <></>}
                        </div>
                    </div>
                </>
            </Popover.Panel>
        </Popover>
    );
};

export default EditComment;
