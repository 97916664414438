import PdfViewer from "@src/components/PdfViewer/PdfViewer";
import Timer from "@src/components/Timer/Timer";
import { useState } from "react";
import tasks from "../../../../containers/activities/SessionNine/tasks_schema.json";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";
import Button from "../../../../components/Button/Button";

const SessionNineSubmission = ({ data, sortOrder, session, task }) => {
    const [noOfComments, setNoOfComments] = useState(0);

    const sessionNine = data.find(
        (session) => session.sort_order === sortOrder
    );

    const taskName = task[0];
    const taskData = sessionNine.submissions[taskName];
    const taskAttributes = tasks.find((task) => task.name === taskName);
    const latestTask = taskData[0];

    const files = session.task_1[0].file_uploads;

    const recentFile = files[files.length - 1];
    const handleDownload = () => {
        const signedurlPromise = fetch(recentFile);
        signedurlPromise
            .then((response) => {
                window.open(response.url, "_blank");
            })
            .catch((error) => {
                console.log("FILE DOWNLOAD ERROR", error);
            });
    };

    return (
        <SubmissionsTemplate
            latestTask={latestTask}
            setNoOfComments={setNoOfComments}
        >
            <>
                {taskAttributes.steps.map((attribute) => {
                    const { fields, question } = attribute;
                    const fieldData = fields[0];
                    switch (fieldData.name) {
                        case "market_plan":
                            return (
                                <TaskTemplate question={question}>
                                    <PdfViewer attributes={fields[0]} />
                                </TaskTemplate>
                            );
                        case "time_image":
                            return (
                                <TaskTemplate question={question}>
                                    <Timer />
                                </TaskTemplate>
                            );
                        case "marketPlanTemplate":
                            return (
                                <TaskTemplate question={question}>
                                    <PdfViewer attributes={fields[0]} />
                                </TaskTemplate>
                            );

                        case "survey_files":
                            return (
                                <TaskTemplate question={question}>
                                    {!recentFile ? (
                                        <p>No files found</p>
                                    ) : (
                                        <div className="w-full overflow-scroll">
                                            <p className="mb-2 w-1/2">
                                                Files are available
                                            </p>
                                            <Button
                                                onClick={() => handleDownload()}
                                            >
                                                Download
                                            </Button>
                                        </div>
                                    )}
                                </TaskTemplate>
                            );
                    }
                })}
            </>
        </SubmissionsTemplate>
    );
};

export default SessionNineSubmission;
