import React from "react";
import { cx } from "class-variance-authority";

const TextFieldV2 = React.forwardRef<
    HTMLInputElement,
    React.InputHTMLAttributes<HTMLInputElement>
>(({ type = "text", className, ...props }, ref) => {
    return (
        <>
            <input
                ref={ref}
                type={type}
                className={cx(
                    "block appearance-none rounded-md border border-orange-300 bg-purple-100  px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-orange-500 focus:outline-none focus:ring-orange-500",
                    className
                )}
                {...props}
            />
        </>
    );
});

export default TextFieldV2;
