import Topnav from "../Navbar/Topnav";

export interface MainLayoutProps {
    sidebar: JSX.Element;
    children: React.ReactNode;
}

const MainLayout = ({ sidebar, children }: MainLayoutProps) => {

    return (
        <div className="flex flex-col h-screen antialiased text-gray-900 bg-gray-100">
            <div className="relative flex flex-1 min-h-0">
                {sidebar}
                <main className="relative flex-1 overflow-y-auto">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default MainLayout;
