import { useState } from "react";
import { CheckBox } from "@src/components/Form";
import tasks from "../../../../containers/activities/SessionFour/tasks_schema.json";
import SessionFourTaskTwoSubmissions from "./SessionFourTaskTwoSubmissions";
import SessionFourTaskThreeSubmission from "./SessionFourTaskThreeSubmission";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";

const SessionFourSubmission = ({ data, sortOrder, session, task }) => {
    const [selectedTask, setSelectedTask] = useState("");
    const [noOfComments, setNoOfComments] = useState(0);

    const sessionFour = data.find(
        (session) => session.sort_order === sortOrder
    );

    const taskName = task[0];
    const taskData = sessionFour.submissions[taskName];

    const taskAttributes = tasks.find((task) => task.name === taskName);
    const selectedMarkets = taskData[0].content.selected_market;

    const handleSelect = (question) => {
        setSelectedTask(question);
    };

    if (taskName === "task_1") {
        const marketSegments = taskData[0].content.market_segements;

        return (
            <>
                <SubmissionsTemplate
                    latestTask={taskData[0]}
                    setNoOfComments={setNoOfComments}
                >
                    <>
                        {taskAttributes.steps.map((step, idx) => {
                            const { fields, hint, question } = step;
                            const fieldData = fields[0];

                            switch (fieldData.name) {
                                case "market_segements":
                                    return (
                                        <div
                                            onClick={() =>
                                                handleSelect(question)
                                            }
                                            className={`rounded-md border p-3 ${
                                                question === selectedTask
                                                    ? "border-purple-500"
                                                    : ""
                                            }`}
                                            key={idx}
                                        >
                                            <TaskTemplate question={question}>
                                                <>
                                                    {marketSegments.map(
                                                        (segement, idx) => {
                                                            return (
                                                                <div className="mt-2 flex flex-col items-start justify-between">
                                                                    <p className="mx-1.5">{`Market Segement ${
                                                                        idx + 1
                                                                    }`}</p>
                                                                    <div className="w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                                        <p>
                                                                            {
                                                                                segement
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            </TaskTemplate>
                                        </div>
                                    );
                                case "selected_market":
                                    return (
                                        <div
                                            onClick={() =>
                                                handleSelect(question)
                                            }
                                            className={`mt-2 rounded-md border p-3 ${
                                                question === selectedTask
                                                    ? "border-purple-500"
                                                    : ""
                                            }`}
                                            key={idx}
                                        >
                                            <TaskTemplate question={question}>
                                                <>
                                                    {marketSegments.map(
                                                        (segement, idx) => {
                                                            return (
                                                                <div key={idx}>
                                                                    <div className="flex divide-x bg-white">
                                                                        <div className="flex items-center justify-center p-6">
                                                                            <CheckBox
                                                                                value={
                                                                                    segement
                                                                                }
                                                                                checked={selectedMarkets.includes(
                                                                                    segement
                                                                                )}
                                                                            />
                                                                        </div>
                                                                        <div className="flex items-center justify-center px-4 py-6">
                                                                            {`Market ${
                                                                                idx +
                                                                                1
                                                                            }`}
                                                                        </div>
                                                                        <div className="flex items-center justify-center px-4 py-6">
                                                                            <p className="text-bold">
                                                                                {
                                                                                    segement
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            </TaskTemplate>
                                        </div>
                                    );
                            }
                        })}
                    </>
                </SubmissionsTemplate>
            </>
        );
    } else if (taskName === "task_2") {
        return (
            <SessionFourTaskTwoSubmissions
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        );
    } else {
        return (
            <SessionFourTaskThreeSubmission
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        );
    }
};

export default SessionFourSubmission;
