import type { Prettify } from "@utils/type_helpers";
import { cva, cx } from "class-variance-authority";
import {
    type AnchorHTMLAttributes,
    type ButtonHTMLAttributes,
    type PropsWithChildren,
} from "react";
import React from "react";
import { Link } from "react-router-dom";
import baseButton from "./BaseButton";
import containedButton, {
    type ContainedButtonVariantProps,
} from "./ContainedButtonVariant";
import outlinedButton, {
    type OutlinedButtonVariantProps,
} from "./OutlinedButtonVariant";
import textButton, { type TextButtonVariantProps } from "./TextButtonVariant";

type ButtonVariant = "outlined" | "contained" | "text";
function variantButtonClasses(variant: ButtonVariant) {
    return {
        contained: containedButton,
        outlined: outlinedButton,
        text: textButton,
    }[variant];
}

const leadingIconClasses = cva("", {
    variants: {
        buttonSize: {
            xs: "-ml-0.5 mr-2 w-4 h-4",
            sm: "-ml-1 mr-2 w-4 h-4",
            base: "-ml-1 mr-2 w-5 h-5",
            md: "-ml-1 mr-3 w-5 h-5",
            lg: "-ml-1 mr-3 w-5 h-5",
        },
    },
});

const trailingIconClasses = cva("", {
    variants: {
        buttonSize: {
            xs: "-mr-0.5 ml-2 w-4 h-4",
            sm: "-mr-1 ml-2 w-5 h-5",
            base: "-mr-1 ml-2 w-5 h-5",
            md: "-mr-1 ml-3 w-5 h-5",
            lg: "-mr-1 ml-3 w-5 h-5",
        },
    },
});

type ButtonVariantProps = Prettify<
    | ContainedButtonVariantProps
    | OutlinedButtonVariantProps
    | TextButtonVariantProps
>;

type BaseButtonProps = ButtonVariantProps & {
    type?: "submit" | "reset" | "button" | undefined;
    href?: string;
    variant?: ButtonVariant;
    startIcon?: React.ComponentType<{ className?: string }>;
    endIcon?: React.ComponentType<{ className?: string }>;
};

type ButtonProps = PropsWithChildren<
    BaseButtonProps &
        (
            | ButtonHTMLAttributes<HTMLButtonElement>
            | AnchorHTMLAttributes<HTMLAnchorElement>
        )
>;

const Button = React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ButtonProps
>(
    (
        {
            type,
            href,
            variant = "contained",
            intent = "primary",
            size = "md",
            fullWidth = false,
            startIcon: StartIcon,
            endIcon: EndIcon,
            className,
            children,
            ...props
        },
        ref
    ) => {
        const buttonClasses = cx(
            baseButton({ size, fullWidth, class: className }),
            variantButtonClasses(variant)({ intent })
        );

        if (href != null) {
            return (
                <Link
                    to={href}
                    className={buttonClasses}
                    {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}
                    ref={ref as React.Ref<HTMLAnchorElement>}
                >
                    {StartIcon != null && (
                        <StartIcon
                            className={leadingIconClasses({ buttonSize: size })}
                        />
                    )}
                    {children}
                    {EndIcon != null && (
                        <EndIcon
                            className={trailingIconClasses({
                                buttonSize: size,
                            })}
                        />
                    )}
                </Link>
            );
        }

        return (
            <button
                type={type ?? "button"}
                className={buttonClasses}
                {...(props as ButtonHTMLAttributes<HTMLButtonElement>)}
                ref={ref as React.Ref<HTMLButtonElement>}
            >
                {StartIcon != null && (
                    <StartIcon
                        className={leadingIconClasses({ buttonSize: size })}
                    />
                )}
                {children}
                {EndIcon != null && (
                    <EndIcon
                        className={trailingIconClasses({ buttonSize: size })}
                    />
                )}
            </button>
        );
    }
);

export default Button;
