import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const TemplateModal = ({ title, open = false, setOpen, children }) => {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                                <div>
                                    <div className="flex items-center text-center">
                                        <div className="flex-1">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-2xl font-semibold uppercase leading-6 text-gray-900"
                                            >
                                                {title}
                                            </Dialog.Title>
                                        </div>

                                        <div>
                                            <button
                                                title="Close modal"
                                                type="button"
                                                className={
                                                    "transition-color group rounded-full p-2 hover:bg-gray-100 focus:bg-gray-100"
                                                }
                                                onClick={() => setOpen(false)}
                                            >
                                                <XMarkIcon
                                                    className={
                                                        "h-6 w-6 text-gray-700"
                                                    }
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={"mt-3"}>{children}</div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default TemplateModal;
