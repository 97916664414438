import tasks from "../../../../containers/activities/SessionFive/tasks_schema.json";
import { useState, Fragment } from "react";
import { CheckBox } from "@src/components/Form";
import { RadioGroup } from "@headlessui/react";
import SubmissionFeedback from "../SubmissionFeedback";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";

import clsx from "clsx";

const SessionFiveTaskOne = ({ data, sortOrder, session, task }) => {
    const [noOfComments, setNoOfComments] = useState(0);

    const sessionFive = data.find(
        (session) => session.sort_order === sortOrder
    );

    const taskName = task[0];

    if (taskName === "task_1") {
        const taskData = sessionFive.submissions[taskName];
        const taskAttributes = tasks.find((task) => task.name === taskName);

        const latestTask = taskData[0];
        const selectedMarket = latestTask.content.selected_market;
        const twoMarketComparison = latestTask.content.two_market_comparison;

        return (
            <SubmissionsTemplate
                latestTask={latestTask}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((attribute) => {
                        const { fields, question } = attribute;
                        const fieldData = fields[0];

                        switch (fieldData.name) {
                            case "two_market_comparison":
                                return (
                                    <TaskTemplate question={question}>
                                        <>
                                            <table className="table-auto rounded-lg bg-white ">
                                                <thead className="border-b">
                                                    <tr>
                                                        <th className="p-2">
                                                            Questions
                                                        </th>
                                                        <th className="p-2">
                                                            {selectedMarket[0]}
                                                        </th>
                                                        <th className="p-2">
                                                            {selectedMarket[1]}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    {fieldData.options.questions.map(
                                                        (question, idx) => {
                                                            const {
                                                                content,
                                                                identifier,
                                                            } = question;

                                                            return (
                                                                <tr
                                                                    className="even:bg-gray-50"
                                                                    key={idx}
                                                                >
                                                                    <td className="px-4 py-3 font-medium">
                                                                        {
                                                                            content
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <CheckBox
                                                                            value={
                                                                                question.identifier
                                                                            }
                                                                            defaultChecked={twoMarketComparison.market_one?.includes(
                                                                                question.identifier
                                                                            )}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <CheckBox
                                                                            value={
                                                                                question.identifier
                                                                            }
                                                                            defaultChecked={twoMarketComparison.market_two?.includes(
                                                                                question.identifier
                                                                            )}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </>
                                    </TaskTemplate>
                                );

                            case "Selected_Markets":
                                return (
                                    <TaskTemplate question={question}>
                                        <>
                                            <RadioGroup
                                                as={"ul"}
                                                className={"divide-y"}
                                            >
                                                <RadioGroup.Label className="sr-only">
                                                    From the questions you
                                                    selected in the previous
                                                    step, narrow down to one
                                                    market segment from the two
                                                    previously selected
                                                </RadioGroup.Label>
                                                {selectedMarket.map(
                                                    (segment, idx) => {
                                                        return (
                                                            <RadioGroup.Option
                                                                as={Fragment}
                                                                key={idx}
                                                                value={segment}
                                                            >
                                                                {({
                                                                    active,
                                                                    checked,
                                                                }) => (
                                                                    <li
                                                                        className={
                                                                            "flex divide-x bg-white"
                                                                        }
                                                                    >
                                                                        <div className="flex items-center justify-center p-6">
                                                                            <span
                                                                                className={clsx(
                                                                                    checked
                                                                                        ? "border-transparent bg-purple-600"
                                                                                        : "border-gray-300 bg-white",
                                                                                    active
                                                                                        ? "ring-2 ring-purple-500 ring-offset-2"
                                                                                        : "",
                                                                                    "flex h-4 w-4 items-center justify-center rounded-full border"
                                                                                )}
                                                                                aria-hidden="true"
                                                                            >
                                                                                <span className="h-1.5 w-1.5 rounded-full bg-white" />
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            className={
                                                                                "flex items-center justify-center px-4 py-6 text-gray-700"
                                                                            }
                                                                        >
                                                                            {`Market Segement ${
                                                                                idx +
                                                                                1
                                                                            }`}
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                "flex flex-1 items-center px-4 py-6"
                                                                            }
                                                                        >
                                                                            {
                                                                                segment
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                )}
                                                            </RadioGroup.Option>
                                                        );
                                                    }
                                                )}
                                            </RadioGroup>
                                        </>
                                    </TaskTemplate>
                                );
                        }
                    })}
                </>
            </SubmissionsTemplate>
        );
    } else {
        const taskData = sessionFive.submissions[taskName];
        const taskAttributes = tasks.find((task) => task.name === taskName);

        const steps = taskAttributes.steps;

        const recentTaskData = taskData[0];

        const taskContent = recentTaskData.content;
        const { age, full_name, industry, organisation_size } = taskContent;
        const { job_title, report_to, performance } = taskContent;
        const { job_goals, job_challenges, job_responsibilties } = taskContent;
        const { tools, communication } = taskContent;

        return (
            <SubmissionsTemplate
                latestTask={recentTaskData}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {steps.map((step, idx) => {
                        const { fields, question } = step;

                        switch (idx) {
                            case 0:
                                return (
                                    <TaskTemplate question={question}>
                                        <>
                                            <p>Name</p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {full_name}
                                            </div>

                                            <p className="mt-3">Age</p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {age}
                                            </div>
                                            <p className="mt-3">
                                                Industry they work in
                                            </p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {industry}
                                            </div>
                                            <p className="mt-3">
                                                Size of organization
                                            </p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {organisation_size}
                                            </div>
                                        </>
                                    </TaskTemplate>
                                );

                            case 1:
                                return (
                                    <TaskTemplate question={question}>
                                        <>
                                            <p>Job Title</p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {job_title}
                                            </div>

                                            <p className="mt-3">
                                                Job measured by
                                            </p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {performance}
                                            </div>
                                            <p className="mt-3">Reports to</p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {report_to}
                                            </div>
                                        </>
                                    </TaskTemplate>
                                );

                            case 2:
                                return (
                                    <TaskTemplate question={question}>
                                        <>
                                            <p>Goals and Objectives</p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {job_goals}
                                            </div>

                                            <p className="mt-3">
                                                Biggest challenge
                                            </p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {job_challenges}
                                            </div>

                                            <p className="mt-3">
                                                Responsibilities
                                            </p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {job_responsibilties}
                                            </div>
                                        </>
                                    </TaskTemplate>
                                );
                            case 3:
                                return (
                                    <TaskTemplate question={question}>
                                        <>
                                            <p>Tools they use</p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {tools}
                                            </div>

                                            <p className="mt-3">
                                                Method of communication
                                            </p>
                                            <div className="mt-2 w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                {communication}
                                            </div>
                                        </>
                                    </TaskTemplate>
                                );
                        }
                    })}
                </>
            </SubmissionsTemplate>
        );
    }
};

export default SessionFiveTaskOne;
