import { useState } from "react";
import Button from "@src/components/Button";

const UploadTeamLogo = () => {
    const [file, setFile] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.files);
        if (e.target.files != null) {
            setFile(URL.createObjectURL(e.target.files[0]));
        }
    };

    return (
        <div className="w-full flex-col items-center gap-2">
            <img src={file} className="h-[100px] w-[100px]" />
            <input type="file" onChange={handleChange} className="my-3" />
            <div className="mt-8 flex justify-end">
                <div>
                    {/* {isSubmitting && <SavingIndicator className="mr-2" />} */}

                    <Button type={"submit"}>Upload team photo</Button>
                </div>
            </div>
        </div>
    );
};

export default UploadTeamLogo;
