export default function PieIcon({ className }: IconProps) {
    return (
        <svg
            className={className}
            width="100%"
            height="100%"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.5 0.125C6.94248 0.125 5.41992 0.586861 4.12489 1.45218C2.82985 2.31749 1.82049 3.5474 1.22445 4.98637C0.628412 6.42534 0.472461 8.00874 0.776319 9.53634C1.08018 11.0639 1.8302 12.4671 2.93154 13.5685C4.03288 14.6698 5.43607 15.4198 6.96367 15.7237C8.49127 16.0275 10.0747 15.8716 11.5136 15.2756C12.9526 14.6795 14.1825 13.6702 15.0478 12.3751C15.9131 11.0801 16.375 9.55753 16.375 8C16.3726 5.91215 15.5422 3.9105 14.0658 2.43417C12.5895 0.957832 10.5879 0.127382 8.5 0.125ZM8.5 14.75C6.70979 14.75 4.9929 14.0388 3.72703 12.773C2.46116 11.5071 1.75 9.79021 1.75 8C1.75 6.20979 2.46116 4.4929 3.72703 3.22703C4.9929 1.96116 6.70979 1.25 8.5 1.25V8L13.2706 12.7706C12.6449 13.3984 11.9014 13.8965 11.0828 14.2362C10.2641 14.5759 9.38636 14.7505 8.5 14.75Z"
                fill="#9659C0"
            />
        </svg>
    );
}
