import { UserIcon } from "@heroicons/react/24/solid";
import Badge from "@src/components/Badge";
import Button from "@src/components/Button";
import useTeamActions from "@src/hooks/useTeamActions";
import type { Team, Student } from "@src/models/types";
import clsx from "clsx";

interface TeamListProps {
    user: Student;
    team: Team;
    className?: string;
}

const TeamList = ({ user, team, className = "" }: TeamListProps) => {
    const { removeMember } = useTeamActions(user);

    async function remove(userId: number) {
        if (!confirm("Are you sure you want to do this?")) {
            return;
        }
        await removeMember(userId);
    }

    return (
        <ul
            role={"list"}
            className={clsx(
                "divide-y divide-gray-200 border-b border-t border-gray-200",
                className
            )}
        >
            {team.members.map((person, idx) =>
                typeof person === "number" ? (
                    <li key={idx} className="flex py-1">
                        <div className="h-12 w-full bg-gray-100 motion-safe:animate-pulse"></div>
                    </li>
                ) : (
                    <li
                        key={idx}
                        className="flex items-center justify-between space-x-3 py-4"
                    >
                        <div className="flex min-w-0 flex-1 items-center space-x-3">
                            <div className="flex-shrink-0">
                                <UserIcon className="h-8 w-8 text-gray-400" />
                            </div>
                            <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900">{`${person.first_name} ${person.last_name}`}</p>
                                <p className="truncate text-sm font-medium text-gray-500">
                                    {person.email}
                                </p>
                            </div>

                            <div>
                                {person.is_owner && (
                                    <Badge theme="accent">Owner</Badge>
                                )}

                                {user.id === team.owner_id &&
                                    user.id !== person.id && (
                                        <Button
                                            onClick={async () => {
                                                await remove(person.id);
                                            }}
                                            type={"button"}
                                            size={"sm"}
                                            variant={"text"}
                                            intent={"secondary"}
                                        >
                                            Remove
                                        </Button>
                                    )}
                            </div>
                        </div>
                    </li>
                )
            )}
        </ul>
    );
};

export default TeamList;
