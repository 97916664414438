import StudentEngagementChart from "@src/components/Charts/StudentEngangementChart";
import StudentSubmissionChart from "@src/components/Charts/StudentSubmissionChart";
import PageHeader from "@src/components/PageHeader";
import StudentFiresideVideosTable from "@src/components/tables/StudentFIresideVideosTable";

const SchoolDashboardPage = () => {
    // const { data: teamsStats } = useQuery({
    //     queryKey: ["teams", "stats"],
    //     queryFn: async () => await getTeamStatsInSchool(),
    // });

    return (
        <div>
            <PageHeader title='Reports' breadcrumbPaths={[]} />

            <section className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                <h2 className="mb-4 mt-8 text-2xl font-bold leading-6 text-gray-900">
                    Overview
                </h2>

                <StudentEngagementChart/>
                <StudentSubmissionChart/>
                <StudentFiresideVideosTable/>
            </section>
        </div>
    );
};

export default SchoolDashboardPage;
