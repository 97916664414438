import clsx from "clsx";
import { Link } from "react-router-dom";
import type React from "react";
import { HalfCircle } from "../Shapes/half-circle";
import {
    KineticTopLeftLogo,
    KineticBottomRightLogo,
} from "../Shapes/kinetic-logos";

interface AuthLayoutV2Props {
    children: React.ReactNode;
}

const AuthLayoutV2: React.FC<AuthLayoutV2Props> = ({ children }) => {
    return (
        <div className={clsx("flex min-h-screen flex-col")}>
            <HalfCircle
                h={"100px"}
                width={"100px"}
                top={"-50px"}
                left={"20%"}
            />

            <KineticTopLeftLogo src={"/images/gears.png"} />
            <KineticBottomRightLogo src={"/images/gears.png"} />
            <HalfCircle
                h={"100px"}
                width={"100px"}
                top={"40%"}
                left={"50%"}
            ></HalfCircle>
            
            <main className="flex-1">{children}</main>

            <HalfCircle
                h={"400px"}
                width={"400px"}
                top={"-220px"}
                left={"50%"}
            ></HalfCircle>
        </div>
    );
};

export default AuthLayoutV2;
