import { useState } from "react";
import Timer from "@src/components/Timer/Timer";
import parse from "html-react-parser";
import PdfViewer from "@src/components/PdfViewer/PdfViewer";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";
import tasks from "../../../../containers/activities/SessionSix/tasks_schema.json";

const SessionSixSubmissions = ({ data, sortOrder, session, task }) => {
    const [noOfComments, setNoOfComments] = useState(0);

    const sessionSix = data.find((session) => session.sort_order === sortOrder);

    const taskName = task[0];

    if (taskName === "task_1") {
        const taskData = sessionSix.submissions[taskName];
        const taskAttributes = tasks.find((task) => task.name === taskName);
        const recentTask = taskData[0];
        const userList = recentTask.content.user_list;
        const surveyQuestions = recentTask.content.survey_questions_session_6;

        return (
            <SubmissionsTemplate
                latestTask={recentTask}
                setNoOfComments={setNoOfComments}
            >
                {taskAttributes.steps.map((step, idx) => {
                    const { fields, question } = step;
                    const fieldData = fields[0];
                    switch (fieldData.name) {
                        case "user_list":
                            return (
                                <TaskTemplate question={question}>
                                    <>
                                        {userList.map((usr, idx) => (
                                            <p key={idx}>{usr}</p>
                                        ))}
                                    </>
                                </TaskTemplate>
                            );
                        case "research_questions":
                            return (
                                <TaskTemplate question={question}>
                                    <PdfViewer attributes={fields[0]} />
                                </TaskTemplate>
                            );

                        default:
                            return (
                                <TaskTemplate question={question}>
                                    <div>{parse(surveyQuestions)}</div>
                                </TaskTemplate>
                            );
                    }
                })}
            </SubmissionsTemplate>
        );
    } else {
        const taskData = sessionSix.submissions[taskName];
        const taskAttributes = tasks.find((task) => task.name === taskName);
        const recentTask = taskData[0];
        const productResponses = recentTask.content.product_design_1_responses;
        const productRecentNeeds =
            recentTask.content.product_design_1_most_needs;
        const productInterpretedNeeds =
            recentTask.content.product_design_1_interpreted_needs;

        return (
            <SubmissionsTemplate
                latestTask={recentTask}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((step, idx) => {
                        const { fields, question } = step;
                        const fieldData = fields[0];
                        const fieldName = fieldData.name;
                        switch (fieldName) {
                            case "guide":
                                return (
                                    <TaskTemplate question={question}>
                                        <PdfViewer attributes={fields[0]} />
                                    </TaskTemplate>
                                );
                            case "questionnaire_pause":
                                return (
                                    <TaskTemplate question={question}>
                                        <Timer />
                                    </TaskTemplate>
                                );

                            case "product_design_1_responses":
                                return (
                                    <TaskTemplate question={question}>
                                        <div>{parse(productResponses)}</div>
                                    </TaskTemplate>
                                );
                            case "product_design_1_most_needs":
                                return (
                                    <TaskTemplate question={question}>
                                        {productRecentNeeds.map((need, idx) => (
                                            <div>
                                                <p>{`Need ${idx + 1}`}</p>
                                                <div className="w-full rounded bg-slate-50 pb-5 pl-2 pt-5">
                                                    <p>{need}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </TaskTemplate>
                                );
                            case "product_design_1_interpreted_needs":
                                return (
                                    <TaskTemplate question={question}>
                                        <p>{productInterpretedNeeds}</p>
                                    </TaskTemplate>
                                );
                        }
                    })}
                </>
            </SubmissionsTemplate>
        );
    }
};

export default SessionSixSubmissions;
