import React from "react";
import Select, { type SelectProps } from "./Select";

export const SDG_LIST = [
    "No Poverty",
    "Zero Hunger",
    "Good Health and Well-being",
    "Quality Education",
    "Gender Equality",
    "Clean Water and Sanitation",
    "Affordable and Clean Energy",
    "Decent Work and Economic Growth",
    "Industry, Innovation, and Infrastructure",
    "Reduced Inequalities",
    "Sustainable Cities and Communities",
    "Responsible Consumption and Production",
    "Climate Action",
    "Life Below Water",
    "Life on Land",
    "Peace, Justice, and Strong Institutions",
    "Partnerships for the Goals",
];

const SDGSelect = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
    return (
        <Select ref={ref} {...props}>
            <option value="">--Select an SDG--</option>
            {SDG_LIST.map((option, idx) => (
                <option key={idx} value={`SDG ${idx + 1}: ${option}`}>
                    {`SDG ${idx + 1}: ${option}`}
                </option>
            ))}
        </Select>
    );
})

export default SDGSelect;
