import { useState, useEffect } from "react";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/20/solid";
import Avatar from "@src/components/Avatar";
import Time from "@src/components/Time";
import { getSubmissionFeedback } from "@src/api/sessions";
import { useQuery } from "@tanstack/react-query";
import Comments from "../Comments";
import AddComment from "../AddComment";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";
import tasks from "../../../../containers/activities/SessionOne/tasks_schema.json";

export const dummyComments = [
    {
        id: 1,
        submitter: {
            full_name: "Victor Minjire",
        },
        content: "Hey",
        created_at: "2024-02-20T05:42:33.187734Z",
    },
    {
        id: 2,
        submitter: {
            full_name: "Sharon Melly",
        },
        content: "This is not what we discussed",
        created_at: "2024-02-20T05:42:33.187734Z",
    },
    {
        id: 3,
        submitter: {
            full_name: "Arron Arr",
        },
        content: "Lets call the group captain",
        created_at: "2024-02-20T05:42:33.187734Z",
    },
];

function submissionFeedbackListQuery(submissionId) {
    return {
        queryKey: ["submission-comments", submissionId],
        queryFn: async () => await getSubmissionFeedback(submissionId),
    };
}

const SessionOneSubmission = ({ submissions }) => {
    const [activeSubmisison, setActiveSubmission] = useState(
        submissions ? submissions?.task_1[0] : {}
    );

    const { data: feedbackList } = useQuery({
        ...submissionFeedbackListQuery(activeSubmisison.id),
        initialData: [],
    });

    const handleSubmissionClick = (taskName) => {
        setActiveSubmission(submissions[taskName][0]);
    };

    const taskOne = submissions.task_1.at(-1)?.content;
    const taskTwo = submissions.task_2.at(-1)?.content;
    const taskThree = submissions.task_3.at(-1)?.content;

    return (
        <>
            <div className="flex w-full flex-row items-start justify-between pt-3">
                {/* Submission info */}
                <div className="bg mr-3 flex flex-1 flex-col p-2">
                    <p>Task</p>
                    <p className="mt-3">
                        Let’s start with what interests you! One way to identify
                        your interests is to ask your friends what you always
                        talk about.
                    </p>

                    <p className="mt-4"> Submisions</p>

                    {/* The submission card task one */}
                    <div
                        className={`mt-2 flex flex-1 flex-col items-center rounded-lg border ${
                            activeSubmisison.task_identifier === "task_1"
                                ? "border-purple-300"
                                : ""
                        } p-5 `}
                        onClick={() => handleSubmissionClick("task_1")}
                    >
                        <div
                            // className={clsx(
                            //     "grid place-content-center px-2 py-1 text-center",
                            //     focusProblem === problem.value
                            //         ? "bg-purple-300"
                            //         : "bg-purple-200"
                            // )}
                            className="flex flex-row items-center px-2 py-1 text-center"
                        >
                            {/* <img
                                src={"/images/problemImage.svg"}
                                className="mt-2 aspect-square h-11"
                            />
                            <h3 className="text-md ml-4 mt-2 font-medium">
                                Problem 1
                            </h3> */}

                            <div className="mx-2 w-full rounded-md border-2 lg:w-72">
                                <div className="grid place-content-center bg-orange-100 px-2 py-4 text-center">
                                    <img
                                        src="/images/interest.svg"
                                        className="mx-auto aspect-square h-40 p-4"
                                    />
                                    <h3 className="text-2xl font-bold text-orange-800">
                                        Interests
                                    </h3>
                                </div>
                                <div className="">
                                    {Object.entries(taskOne)?.map(
                                        ([key, value], index) => {
                                            if (typeof value !== "object") {
                                                return (
                                                    <div key={index}>
                                                        <p className="overflow-x-auto p-2 text-lg">
                                                            {value}
                                                        </p>
                                                        <hr />
                                                    </div>
                                                );
                                            }
                                            return <></>;
                                        }
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* <p className="mt-4">
                            There is no effective and efficient way to pick up
                            students from their homes to school
                        </p> */}

                        {/* SDG VIEW */}
                        <div className="mt-2 mt-5 flex flex-row items-center bg-purple-50 p-2">
                            <div className="flex-0.2 rounded-full bg-purple-300 px-2 py-0.5 text-xs font-semibold text-slate-800">
                                SDG
                            </div>
                            <p className="flex-0.8 ml-4">
                                Sustainable cities and communities
                            </p>
                        </div>

                        {/* Comments view */}
                        <div className="mt-4 grid grid-cols-2">
                            <p>4 comments</p>

                            <div className="flex flex-row items-center">
                                <ChatBubbleBottomCenterTextIcon
                                    className="h-6 w-6"
                                    color="gray"
                                />
                                <p className="ml-3">Add Comment</p>
                            </div>
                        </div>
                    </div>

                    {/* Submission Card for Task 2 */}
                    <div
                        className={`mt-2 flex flex-1 flex-col items-center rounded-lg border ${
                            activeSubmisison.task_identifier === "task_2"
                                ? "border-purple-300"
                                : ""
                        } p-5 `}
                        onClick={() => handleSubmissionClick("task_2")}
                    >
                        <div className="mx-2 w-full rounded-md border-2 lg:w-72">
                            <div className="grid place-content-center bg-purple-200 px-2 py-4 text-center">
                                <img
                                    src="/images/skills.svg"
                                    className="mx-auto aspect-square h-40 p-4"
                                />
                                <h3 className="text-2xl font-bold text-purple-800">
                                    Skills
                                </h3>
                            </div>
                            <div className="">
                                {Object.entries(taskTwo)?.map(
                                    ([key, value], index) => (
                                        <div key={index}>
                                            <p className="over overflow-x-auto p-2 text-lg">
                                                {value}
                                            </p>
                                            <hr />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Submission Card for Task 3 */}
                    <div
                        className={`mt-2 flex flex-1 flex-col items-center rounded-lg border ${
                            activeSubmisison.task_identifier === "task_3"
                                ? "border-purple-300"
                                : ""
                        } p-5 `}
                        onClick={() => handleSubmissionClick("task_3")}
                    >
                        <div className="mx-2 w-full rounded-md border-2 lg:w-72">
                            <div className="grid place-content-center bg-blue-100 px-2 py-4 text-center">
                                <img
                                    src="/images/clock.svg"
                                    className="mx-auto aspect-square h-40 p-4"
                                />
                                <h3 className="text-2xl font-bold text-blue-800">
                                    Free Time Activities
                                </h3>
                            </div>
                            <div className="">
                                {Object.entries(taskThree)?.map(
                                    ([key, value], index) => (
                                        <div key={index}>
                                            <p className="overflow-x-auto p-2 text-lg">
                                                {value}
                                            </p>
                                            <hr />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* The Feedback view */}
                <div className="ml-3 flex flex-1 flex-col p-2">
                    <p> Recent Feedback </p>
                    {/* Map through the comments */}
                    {dummyComments.map((comment) => {
                        return <Comments comment={comment} />;
                    })}
                    <div className="mt-3">
                        <AddComment />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SessionOneSubmission;
