import Button from "@components/Button";
import PageHeader from "@components/PageHeader";
import clsx from "clsx";

export async function loader() {}

const StudentDashBoard = () => {
    return (
        <section>
            <PageHeader
                title="Dashboard"
                breadcrumbPaths={[
                    { name: "Dashboard", href: "/student/dashboard" },
                ]}
            />

            <main className="max-w-7xl px-4 pt-6 pb-12 sm:px-6 md:px-8">
                <section className="grid grid-cols-3 gap-x-4 gap-y-8">
                    <Card title="Session In Progress" className="col-span-3">
                        <div className="flex items-center justify-between">
                            <p className="mt-1 text-xl font-medium">
                                Ideation and Formation Part I
                            </p>
                            <Button
                                href={`/student/content/{week}`}
                                intent="primary"
                                variant="outlined"
                                size="base"
                            >
                                Continue Session
                            </Button>
                        </div>
                    </Card>

                    <Card className={"text-center"} title="Overall Engagement">
                        <p className="mt-2 text-2xl font-bold text-orange-600">
                            60
                        </p>
                        <p className="mt-2">Aim for atleast 90%</p>
                    </Card>

                    <Card className={"text-center"} title="Session Score">
                        <p className="mt-2 text-2xl font-bold text-orange-600">
                            42
                        </p>
                        <p className="mt-2">Aim for atleast 75%</p>
                    </Card>

                    <Card className={"text-center"} title="Stat Insight">
                        <p className="mt-2 text-2xl font-bold text-orange-600">
                            42
                        </p>
                        <p className="mt-2">Aim for atleast 75%</p>
                    </Card>

                    <Card className={"text-center"} title={"My Team League"}>
                        <div className="w-full grid grid-cols-3 gap-1 border-b-8 border-purple-800 h-[300px]">
                            <LeagueBar
                                position={2}
                                height={50}
                                userAvatar="/images/imgLeague1.svg"
                            />
                            <LeagueBar
                                position={1}
                                height={60}
                                userAvatar="/images/imgLeague2.svg"
                            />
                            <LeagueBar
                                position={3}
                                height={45}
                                userAvatar="/images/imgLeague3.svg"
                            />
                        </div>
                        <Button className="mt-3" href={"/student/league"}>
                            View More
                        </Button>
                    </Card>
                </section>
            </main>
        </section>
    );
};

export default StudentDashBoard;

function Card({ title, children, className = "" }) {
    return (
        <section className={clsx("rounded-md bg-white p-4 shadow", className)}>
            <h2 className="text-sm font-bold uppercase tracking-wide text-gray-500">
                {title}
            </h2>
            {children}
        </section>
    );
}

function LeagueBar({ position, userAvatar, height = 60 }) {
    return (
        <div className="flex flex-col justify-end items-end h-full">
            {parseInt(position) === 1 && (
                <img
                    className="mx-auto h-8 w-8 object-contain"
                    src="/images/crown.svg"
                    alt="Crown"
                />
            )}
            <img
                className="mx-auto h-14 w-14 object-contain"
                src={userAvatar}
                alt="Student Avatar"
            />
            <div
                className="flex-0 w-full rounded-t-lg bg-purple-600"
                style={{ height: `${height}%` }}
            >
                <span className={"text-sm text-white"}>{position}</span>
            </div>
        </div>
    );
}
