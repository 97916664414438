import { cva, type VariantProps } from "class-variance-authority";

export type BaseButtonVariantProps = VariantProps<typeof baseButton>;

const baseButton = cva(
    "inline-flex items-center justify-center font-medium border rounded-md transition disabled:text-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-indigo-500 focus:ring-2 focus:ring-offset-2",
    {
        variants: {
            size: {
                xs: "px-2.5 py-1.5 text-xs",
                sm: "px-3 py-2 text-sm leading-4",
                base: "px-4 py-2 text-base",
                md: "px-4 py-2 text-base",
                lg: "px-6 py-3 text-base",
            },
            fullWidth: {
                true: "w-full",
            },
        },
        defaultVariants: { size: "md" },
    }
);

export default baseButton;
