import Button from "@components/Button";
import { FieldError, Label, PasswordField } from "@components/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import SettingsCard from "./SettingsCard";
import { passwordChange } from "@api/user";
import { responseErrorToFormErrors } from "@src/utils/form-errors";

function Form() {
    const schema = z
        .object({
            old_password: z.string(),
            new_password: z.string(),
            new_password_confirm: z.string(),
        })
        .refine((data) => data.new_password === data.new_password_confirm, {
            message: "Passwords don't match",
            path: ["new_password_confirm"],
        });

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        reset,
    } = useForm({ resolver: zodResolver(schema) });

    const submit = async (data) => {
        try {
            await passwordChange(data);

            reset();

            alert("Password changed successfully.");
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    return (
        <form
            id={"form-password-change"}
            onSubmit={handleSubmit(submit)}
            className={"max-w-lg space-y-4"}
        >
            <div>
                <Label htmlFor={"old-password"} name={"Old Password"} />
                <PasswordField
                    className={"mt-1 w-full"}
                    id={"old-password"}
                    {...register("old_password")}
                    autoComplete="off"
                    required
                />
                <FieldError
                    className={"mt-1"}
                    message={errors.old_password?.message}
                />
            </div>

            <div>
                <Label htmlFor={"new-password"} name={"New Password"} />
                <PasswordField
                    className={"mt-1 w-full"}
                    id={"new-password"}
                    {...register("new_password")}
                    autoComplete="off"
                    required
                />
                <FieldError
                    className={"mt-1"}
                    message={errors.new_password?.message}
                />
            </div>

            <div>
                <Label
                    htmlFor={"new-password-confirm"}
                    name={"Confirm New Password"}
                />
                <PasswordField
                    className={"mt-1 w-full"}
                    id={"new-password-confirm"}
                    {...register("new_password_confirm")}
                    autoComplete="off"
                    required
                />
                <FieldError
                    className={"mt-1"}
                    message={errors.new_password_confirm?.message}
                />
            </div>
        </form>
    );
}

export default function PasswordChange() {
    return (
        <SettingsCard
            title={"Password Update"}
            form={<Form />}
            actions={
                <Button
                    type="submit"
                    form={"form-password-change"}
                    intent={"primary"}
                >
                    Change Password
                </Button>
            }
        />
    );
}
