import axios from "axios";

const airtableApiToken = import.meta.env.VITE_AIRTABLE_TOKEN;

if (airtableApiToken === undefined) {
    throw new Error(
        "Airtable intergration requires VITE_AIRTABLE_TOKEN to be set as an environment variable."
    );
}

const $http = axios.create({
    baseURL: "https://api.airtable.com/v0/app2HEnmyOqcSlXQr",
    headers: {
        Authorization: `Bearer ${airtableApiToken}`,
        "Content-Type": "application/json",
    },
});

const UX_SURVEY_TABLE = "Product User Experience Survey";

export interface UXSurveyRecord {
    id: string;
    createdTime: string;
    fields: {
        ID: number;
        email: string;
        school: string;
        experience_improvement: string;
        experienced_errors: boolean;
        error_description: string;
        most_liked_thing: string;
        recommendation_likelihood: number;
        experience_rating: number;
        created_at: string;
        environment: "dev" | "staging" | "production";
    };
}

export interface UXSurveyRecordsResponse {
    records: UXSurveyRecord[];
}

export async function getUXSurveyData() {
    const res = await $http.get<UXSurveyRecordsResponse>(UX_SURVEY_TABLE);
    return res.data;
}

export async function postUXSurveyData(fields: Record<string, any>) {
    return await $http.post(UX_SURVEY_TABLE, { fields });
}
