const TaskTemplate = ({ children, question }) => {
    return (
        <div className="my-3 flex w-full flex-col items-start rounded-md border border-slate-200 p-4">
            <p className="font-semibold text-slate-600">Task</p>
            <p className="mt-5">{question}</p>
            <p className="mt-7 font-semibold text-slate-600">Submissions</p>
            <div className="mt-2 w-full rounded-md p-3 shadow">
                <div className="min-width-0">{children}</div>
            </div>
        </div>
    );
};

export default TaskTemplate;
