import type React from "react";
import ProfileHighlight from "./ProfileHighlight";
import clsx from "clsx";
import {
    type IndividualLeaderboardRecord,
    type TeamLeaderboardRecord,
} from "@src/models/types";

interface TopPerformer {
    image: string;
    name: string;
    points: number;
}

interface HighlightProps {
    performers: IndividualLeaderboardRecord[] | TeamLeaderboardRecord[]; // Top 3 performers
    type: "individual" | "team";
}

const LeaderboardHighlight: React.FC<HighlightProps> = ({
    performers,
    type,
}) => {
    performers = performers.slice(0, 3);
    let topPerformers: TopPerformer[];
    if (type === "individual") {
        const topIndividualPerformers =
            performers as IndividualLeaderboardRecord[];
        topPerformers = topIndividualPerformers.map((individual) => ({
            points: individual.points,
            name: `${individual.first_name} ${individual.last_name}`.trim(),
            image: individual.avatar,
        }));
    } else {
        const topTeamPerformers = performers as TeamLeaderboardRecord[];
        topPerformers = topTeamPerformers.map((team) => ({
            points: team.points,
            name: team.team_name,
            image: team.team_logo,
        }));
    }

    return (
        <section
            className="flex flex-col items-center justify-center rounded-lg p-8 md:flex-row"
            style={{
                backgroundImage: 'url("/images/leaderboardbg.png")',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top left",
                backgroundSize: "cover",
            }}
        >
            {topPerformers.map((performer, idx) => {
                return (
                    <div
                        className={clsx(
                            "flex",
                            "flex-col",
                            "mx-12",
                            idx === 2 && "md:order-first",
                            idx === 1 && "md:order-last"
                        )}
                        key={idx}
                    >
                        <ProfileHighlight
                            position={idx}
                            image={performer.image}
                            height={idx === 0 ? "20em" : "14em"}
                        />
                        <p className="text-center text-xl font-bold text-white">
                            {performer.name}
                        </p>
                        <p className="text-center  text-lg text-white">
                            {performer.points} Points
                        </p>
                    </div>
                );
            })}
        </section>
    );
};

export default LeaderboardHighlight;
