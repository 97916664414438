import { useRouteLoaderData } from "react-router-dom";
import { lazy, Suspense, useCallback } from "react";

const sessionNameMap = {
    1: "SessionOne",
    2: "SessionTwo",
    3: "SessionThree",
    4: "SessionFour",
    5: "SessionFive",
    6: "SessionSix",
    7: "SessionSeven",
    8: "SessionEight",
    9: "SessionNine",
    10: "SessionTen",
};

const SessionActivity = () => {
    const session = useRouteLoaderData("session");
    const sessionIdx = session?.sort_order;

    const sessionName = sessionNameMap[sessionIdx];
    const Controller = useCallback(
        lazy(() =>
            import(
                `../../containers/activities/${sessionName}/${sessionName}Controller.jsx`
            )
        ),
        [sessionIdx]
    );

    return (
        <Suspense fallback={<ActivitySkeleton />}>
            <Controller />
        </Suspense>
    );
};

export default SessionActivity;

const ActivitySkeleton = () => {
    return (
        <section className="">
            <div className={"h-10 bg-purple-500 animate-pulse"}></div>
            <div className="flex items-center justify-center bg-purple-100 h-72">
                <p className="text-xl font-semibold text-purple-700">
                    Loading ...
                </p>
            </div>
        </section>
    );
};
