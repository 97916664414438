import {
    CheckBox,
    FieldError,
    GeneralError,
    Label,
    PasswordField,
    TextField,
} from "@components/Form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@components/Button";
import { freeTrialSingUp, freeTrialSignupPushToAirtable } from "@src/api/auth";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/24/outline";

const FreeTrialFormSchema = z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email(),
    password: z.string(),
    password_confirmation: z.string(),
    privacy_policy: z.boolean(),
});

type FreeTrialFormData = z.infer<typeof FreeTrialFormSchema> & {
    general: never;
};

const FreeTrialSignUp = () => {
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
    } = useForm<FreeTrialFormData>({
        resolver: zodResolver(FreeTrialFormSchema),
    });

    const onSubmit = async (data: FreeTrialFormData) => {
        setLoading(true);

        try {
            await freeTrialSingUp(data);
            // Fire and forget is intentional
            freeTrialSignupPushToAirtable({
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email
            });


            toast.success(
                "Your account has been created successfully. A confirmation email has been sent to your inbox with further instructions.",
                {
                    autoClose: false,
                }
            );

            reset();
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }

        setLoading(false);
    };

    return (
        <>
            <ToastContainer position="top-center" hideProgressBar={true} />

            <div className={"flex min-h-screen flex-col"}>
                <header className="bg-white border-b border-gray-200">
                    <div className="flex justify-between px-4 py-3 mx-auto max-w-screen-2xl sm:px-6 md:px-8">
                        <Link className="inline-block" to="/">
                            <img
                                className="w-auto h-16"
                                src="/images/kineticp.png"
                                alt="Kinetic Platform"
                            />
                        </Link>

                        <section className="flex items-center">
                            <Button href="/" variant="outlined">
                                Login
                            </Button>
                        </section>
                    </div>
                </header>

                <main className="flex-1">
                    <section className="grid h-full grid-cols-1 gap-10 bg-gray-50 md:grid-cols-2 md:gap-0">
                        <section className="px-4 py-12 sm:px-6 md:px-12">
                            <div>
                                <img
                                    src="https://s3.amazonaws.com/backendgetkinetic.co/media/Free-Trial-Image.webp"
                                    className="object-cover w-full overflow-hidden rounded-lg aspect-video"
                                    alt="student using kinetic platform"
                                />
                            </div>
                            <h2 className="mt-8 text-3xl font-semibold text-orange-500 ">
                                Unlock Your Entrepreneurial Potential with
                                Kinetic
                            </h2>

                            <ul className="px-4 mt-4 ml-6 space-y-5 text-xl text-gray-600 list-disc">
                                <li>
                                    Curated learning material for aspiring
                                    innovators designed specifically for young
                                    minds eager to venture into
                                    entrepreneurship.
                                </li>
                                <li>
                                    Kinetic is more than just a platform for
                                    students, parents can immerse themselves in
                                    their child's learning journey, fostering a
                                    shared passion for entrepreneurship.
                                </li>
                                <li>
                                    Learn from global entrepreneurs with
                                    exclusive access to fireside chats featuring
                                    successful entrepreneurs from around the
                                    world, sharing their insights and wisdom.
                                </li>
                            </ul>
                        </section>

                        <section className="px-4 py-12 max-md:bg-white">
                            <h1 className="mb-5 text-3xl font-bold text-center text-orange-500">
                                Try Kinetic free for a limited time
                            </h1>
                            <div className="max-w-2xl mx-auto">
                                <GeneralError
                                    message={errors.general?.message}
                                />
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    className="px-4 mt-6 space-y-6"
                                    method="POST"
                                >
                                    <div className="grid gap-x-4 gap-y-6 sm:grid-cols-1">
                                        <div>
                                            <Label
                                                htmlFor="first_name"
                                                name="First Name"
                                            />
                                            <TextField
                                                type="input"
                                                id="first_name"
                                                {...register("first_name")}
                                                className="w-full mt-1"
                                                placeholder="First Name"
                                                autoComplete="off"
                                                autoFocus
                                                required
                                            />
                                            <FieldError
                                                message={
                                                    errors.first_name?.message
                                                }
                                                className="mt-1"
                                            />
                                        </div>
                                        <div>
                                            <Label
                                                htmlFor="last_name"
                                                name="Last Name"
                                            />
                                            <TextField
                                                type="input"
                                                id="last_name"
                                                {...register("last_name")}
                                                className="w-full mt-1"
                                                placeholder="Last Name"
                                                autoComplete="off"
                                                required
                                            />
                                            <FieldError
                                                message={
                                                    errors.last_name?.message
                                                }
                                                className="mt-1"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Label
                                            htmlFor="email"
                                            name="Email address"
                                        />
                                        <TextField
                                            type="email"
                                            id="email"
                                            {...register("email")}
                                            className="w-full mt-1"
                                            placeholder="email@example.com"
                                            autoComplete="email"
                                            required
                                        />
                                        <FieldError
                                            message={errors.email?.message}
                                            className="mt-1"
                                        />
                                    </div>

                                    <div>
                                        <Label
                                            htmlFor="password"
                                            name="Password"
                                        />
                                        <PasswordField
                                            id="password"
                                            className="w-full mt-1"
                                            {...register("password")}
                                            autoComplete="current-password"
                                            placeholder="xxxxxxxx"
                                            required
                                        />
                                        <FieldError
                                            message={errors.password?.message}
                                            className="mt-1"
                                        />
                                    </div>
                                    <div>
                                        <Label
                                            htmlFor="password_confirmation"
                                            name="Confirm Password"
                                        />
                                        <PasswordField
                                            id="password_confirmation"
                                            className="w-full mt-1"
                                            {...register(
                                                "password_confirmation"
                                            )}
                                            autoComplete="current-password"
                                            placeholder="xxxxxxxx"
                                            required
                                        />
                                        <FieldError
                                            message={
                                                errors.password_confirmation
                                                    ?.message
                                            }
                                            className="mt-1"
                                        />
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <CheckBox
                                            id="privacy-policy"
                                            {...register("privacy_policy")}
                                            required
                                        />
                                        <Label htmlFor="privacy-policy">
                                            I have read the{" "}
                                            <Link
                                                target="_blank"
                                                to="https://kinetic.education/privacy-policy"
                                                className="text-purple-400 underline "
                                            >
                                                privacy policy
                                            </Link>
                                        </Label>
                                    </div>

                                    <div>
                                        <Button
                                            type="submit"
                                            size="lg"
                                            fullWidth
                                        >
                                            {loading
                                                ? "Creating account..."
                                                : "Start your 15-day free trial"}
                                        </Button>
                                    </div>
                                </form>
                            </div>

                            <div className="max-w-2xl px-4 mx-auto mt-10">
                                <figure className="flex flex-col justify-between flex-auto p-6 bg-white shadow-md rounded-2xl ring-1 ring-gray-900/5">
                                    <blockquote className="text-lg leading-8 text-gray-900">
                                        <p>
                                            “The Kinetic Program provided me
                                            with a hands-on approach to applying
                                            my entrepreneurial skills. It guided
                                            me through the process of ideation,
                                            validating real-world problems
                                            through research, developing
                                            effective solutions, and
                                            demonstrating their functionality.
                                            Additionally, the program played a
                                            pivotal role in refining my public
                                            speaking abilities through engaging
                                            presentations and pitching
                                            sessions.”
                                        </p>
                                    </blockquote>
                                    <figcaption className="flex items-center mt-8 gap-x-6 ">
                                        <div className="p-2 rounded-full h-14 w-14 bg-gray-50 ring-1 ring-gray-400">
                                            <UserIcon className="text-gray-400"/>
                                        </div>

                                        <div className="text-base">
                                            <div className="font-semibold text-gray-900">
                                                Gabriel Kanjama
                                            </div>
                                            <div className="mt-1 text-gray-500">
                                                Crawford International School Alumni
                                            </div>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        </section>
                    </section>
                </main>
            </div>
        </>
    );
};

export default FreeTrialSignUp;
