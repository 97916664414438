import { useEffect } from "react";
import Comments from "./Comments";
import AddComment from "./AddComment";
import { dummyComments } from "./SessionOne/SessionOneSubmission";
import { getSubmissionFeedback } from "@src/api/sessions";
import { useQuery } from "@tanstack/react-query";

function submissionFeedbackListQuery(submissionId) {
    return {
        queryKey: ["submission-comments", submissionId],
        queryFn: async () => await getSubmissionFeedback(submissionId),
    };
}

const SubmissionFeedback = ({ submissionId, setNumberOfComments }) => {
    const { data: feedbackList } = useQuery({
        ...submissionFeedbackListQuery(submissionId),
        initialData: [],
    });

    useEffect(() => {
        setNumberOfComments(feedbackList.length);
    }, [feedbackList]);

    return (
        <div className="ml-3 flex flex-1 flex-col p-2">
            <p className="font-semi"> Recent Feedback </p>
            {feedbackList?.length === 0 ? (
                <section className="mx-auto mt-4 rounded-md border border-dashed p-8">
                    <p className="text-center text-lg text-gray-700">
                        No comments available for this task entry
                    </p>
                </section>
            ) : (
                <>
                    {feedbackList.map((feedback) => {
                        return <Comments comment={feedback} />;
                    })}
                </>
            )}
            <div className="mt-3">
                <AddComment submissionId={submissionId} />
            </div>
        </div>
    );
};

export default SubmissionFeedback;
