import { StarIcon } from "@heroicons/react/24/solid";
import { unvote, vote } from "@src/api/forums";
import { useEffect, useState } from "react";

interface StarIconProps {
    initialVotes: number,
    postID: number,
    voted_: boolean
}

export const StarAction = ({initialVotes, postID, voted_}: StarIconProps) => {
    const [votes, setVotes] = useState(initialVotes);
    const [voted, setVoted] = useState(voted_);

    const onClick = () => {
        let voted_ = !voted
        setVoted(voted_);
        setVotes(voted ? votes - 1: votes + 1 );
        voted_? vote(postID): unvote(postID);
    }

    return (
        <>

        <div 
            className={"flex flex-row bg-purple-100 hover:bg-purple-200 p-2 rounded-lg items-center cursor-pointer"}
            onClick={() => onClick()}>
            <StarIcon className={`w-6 h-6 pr-1 ${voted? "text-orange-400": "text-purple-300"}`}/>
            {votes}
        </div>
        </>
    )
}
