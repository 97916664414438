import { cva, type VariantProps } from "class-variance-authority";
import { type BaseButtonVariantProps } from "./BaseButton";

export type TextButtonVariantProps = BaseButtonVariantProps &
    VariantProps<typeof textButton>;

const textButton = cva("border-transparent", {
    variants: {
        intent: {
            primary: "text-purple-700 hover:bg-purple-50",
            secondary: "text-gray-700 hover:bg-gray-50",
            accent: "text-orange-700 hover:bg-orange-50",
            danger: "text-red-700 hover:bg-red-50",
            white: "",
        },
    },
});

export default textButton;
