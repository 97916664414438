import {
  Dispatch,
  SetStateAction,
  MutableRefObject,
  useState,
  useEffect
} from "react";
import { PlayIcon, PauseIcon, BackwardIcon, ForwardIcon, ArrowsPointingOutIcon, Cog6ToothIcon, SpeakerXMarkIcon, SpeakerWaveIcon } from "@heroicons/react/20/solid";
import VimeoPlayer from "react-player/vimeo";
import { FaRegClosedCaptioning, FaClosedCaptioning } from "react-icons/fa";
import { ConfigControls } from "./ConfigControls_v2";

type ControlsProps = {
  playing: boolean;
  setPlaying: Dispatch<SetStateAction<boolean>>;
  playedSeconds: number;
  duration: number;
  playerRef: MutableRefObject<VimeoPlayer>;
  playerId: any;
  enableCaptions: boolean;
  setPlaybackRate: Dispatch<SetStateAction<number>>;
  playerReady: boolean;
};

const formatTime = (seconds: number) => {
  var hours   = Math.floor(seconds / 3600)
  var minutes = Math.floor(seconds / 60) % 60
  var seconds = Math.trunc(seconds % 60)

  return [hours,minutes,seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v,i) => v !== "00" || i > 0)
      .join(":")
}

const Controls = (props: ControlsProps) => {
  const [captionsEnabled, setCaptionsEnabled] = useState(props.enableCaptions);

  const seek = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.playerRef.current.seekTo(+e.target.value, "seconds");
  };

  const forward = () => {
    props.playerRef.current.seekTo(props.playedSeconds+10, "seconds");
  }

  const rewind = () => {
    if (props.playedSeconds > 10) {
      props.playerRef.current.seekTo((props.playedSeconds-10));
    }
  }

  const fullscreen = () => {
    if (!document.fullscreenElement) {
      document.getElementById(props.playerId)!.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  const enableEnTrack = (player: any) => {
    player.enableTextTrack('en-x-autogen').then(() => {}).catch((error: any) => {
      switch (error.name) {
          case 'InvalidTrackLanguageError':
              // no track was available with the specified language
              console.log(error)
              break

          case 'InvalidTrackError':
              // no track was available with the specified language and kind
              break

          default:
              // some other error occurred
              break
      }
    })
  }

  const setCaptionsOnLoad = () => {
    if (!props.playerReady) return
    let val = true
    let internalPlayer = props.playerRef.current.getInternalPlayer()
    val ? enableEnTrack(internalPlayer): internalPlayer.disableTextTrack()
  }

  const toggleCaptions = () => {
    let captions = !captionsEnabled
    setCaptionsEnabled(captions)
    let internalPlayer = props.playerRef.current.getInternalPlayer()
    captions ? enableEnTrack(internalPlayer): internalPlayer.disableTextTrack()
  }

  useEffect(setCaptionsOnLoad, [props.playerReady])

  return (
    <div className="w-full flex flex-col">
      <input
        type="range"
        value={props.playedSeconds}
        min="0"
        max={props.duration}
        onChange={seek}
        className="
            h-2 w-full cursor-pointer
            [&::-webkit-slider-thumb]:invisible [&::-moz-range-thumb]:invisible [&::-ms-thumb]:invisible
            [&::-webkit-slider-runnable-track]:h-full [&::-moz-range-progress]:h-full [&::-ms-fill-lower]:h-full
            [&::-webkit-slider-runnable-track]:bg-purple-500 [&::-moz-range-progress]:bg-purple-500 [&::-ms-fill-lower]:bg-purple-500"
      />

      <div className="px-6 py-2 flex justify-between items-center bg-purple-300 rounded-md">

        <div className="text-purple-700 text-lg h-fit">
          {formatTime(props.playedSeconds)} / {formatTime(props.duration)}
        </div>

        <div className="flex flex-row w-1/5 h-fit text-white items-center space-x-4">
          <BackwardIcon className="w-8 h-8 cursor-pointer" onClick={rewind}/>
          <button 
            onClick={() => props.setPlaying(!props.playing)}
            className="h-full p-2 ml-2 rounded-full bg-purple-500">
            {props.playing ? 
              <PauseIcon className="w-8 h-8 cursor-pointer"/> : 
              <PlayIcon className="w-8 h-8 pl-1 cursor-pointer"/>}
          </button>
          <ForwardIcon className="w-8 h-8 cursor-pointer" onClick={forward}/>
        </div>

        <div className="flex flex-row h-full w-fit space-x-4 text-white">
          {captionsEnabled ?
            (<FaClosedCaptioning className="w-8 h-8 text-purple-700 cursor-pointer" onClick={toggleCaptions}/>):
            (<FaRegClosedCaptioning className="w-8 h-8 text-purple-700 cursor-pointer" onClick={toggleCaptions}/>)
          }
          <ConfigControls playerRef={props.playerRef}/>
          <ArrowsPointingOutIcon className="w-8 h-8 cursor-pointer" onClick={fullscreen}/>
        </div>
      </div>
    </div>
  );
};

export default Controls;