import { useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { newSubmissionFeedback } from "@src/api/sessions";
import Avatar from "@src/components/Avatar";
import Button from "@src/components/Button";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const AddComment = ({ submissionId }) => {
    const user = useRouteLoaderData("root");
    const [feedback, setFeedback] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };
    const queryClient = useQueryClient();

    const submitFeedback = async () => {
        if (feedback.length <= 0) return;
        setIsLoading(true);
        try {
            await newSubmissionFeedback(submissionId, feedback);
            setFeedback("");
            await queryClient.invalidateQueries({
                queryKey: ["submission-comments", submissionId],
            });
            setIsLoading(false);
        } catch (error) {
            toast.error(`An unexpected error occured`, {
                autoClose: 2000,
            });
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-row items-start">
            <div className="flex-0.2 mr-3">
                <Avatar
                    src={user.avatar ?? undefined}
                    name={user?.full_name ?? ""}
                    size="36"
                />
            </div>

            <div class="flex-0.8 mx-auto w-full">
                <div class="w-full ">
                    <label
                        class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700"
                        for="grid-password"
                    >
                        Your Comment
                    </label>
                    <textarea
                        value={feedback}
                        onChange={handleFeedbackChange}
                        rows="10"
                        class="mb-3 block w-full appearance-none rounded border border-gray-200 bg-gray-200 px-4 py-3 leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
                    ></textarea>
                </div>
                <div class="flex w-full justify-end" onClick={submitFeedback}>
                    <Button type="submit">Comment</Button>
                </div>
            </div>
        </div>
    );
};

export default AddComment;
