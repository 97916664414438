import ParentDashboardTable from "@src/components/tables/ParentDashboardTable";
import Button from "@src/components/Button";
import { useQuery } from "@tanstack/react-query";
import { getSessionProgress } from "@src/api/students";
import TableIndicator from "./TableIndicator";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import {
    type SessionStatusValues,
    type StudentSession,
} from "@src/models/types";
import { type ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { SessionStatus } from "@src/models";
import Badge, { type BadgeProps } from "@src/components/Badge";
interface TeamClassroomProgressProps {
    studentId: number | undefined;
    teamId: number | undefined;
}

export default function TeamClassroomProgress({
    studentId,
}: TeamClassroomProgressProps) {
    const { isLoading, data } = useQuery({
        queryKey: ["students", "session-progress", studentId],
        queryFn: async () => await getSessionProgress(studentId),
        enabled: Boolean(studentId),
    });

    const columnHelper = createColumnHelper<StudentSession>();

    const columns = useMemo(
        () => [
            columnHelper.accessor("session_id", {
                header: "Session",
                meta: {
                    align: "left",
                },
                cell: (info) => `Session ${info.getValue()}`,
            }),
            columnHelper.accessor("title", {
                header: "Title",
                meta: {
                    align: "left",
                },
                cell: (info) => `${info.getValue()}`,
            }),

            columnHelper.accessor("status", {
                header: "Status",
                cell: (info) => {
                    const value = info.getValue().replace(/_/g, " ");
                    const themes: Record<
                        SessionStatusValues,
                        BadgeProps["theme"]
                    > = {
                        [SessionStatus.IN_PROGRESS]: "warning",
                        [SessionStatus.DONE]: "success",
                        [SessionStatus.NOT_STARTED]: "gray",
                    };

                    return (
                        <Badge
                            theme={themes[info.getValue()]}
                            className={"uppercase"}
                        >
                            {value}
                        </Badge>
                    );
                },
            }),
            columnHelper.accessor("completion_percentage", {
                header: "Completion",
                meta: {
                    align: "right",
                },
                cell: (info) => `${info.getValue()}%`,
            }),
            columnHelper.display({
                id: "actions",
                meta: {
                    align: "center",
                },
                cell: (info) => (
                    <div className="grid place-content-center">
                        <Link
                            to={`/parent/students/${studentId}/feedback-session/${info.row.original.session_id}`}
                        >
                            <Button intent="white">View</Button>
                        </Link>
                    </div>
                ),
            }),
        ],
        [studentId]
    );

    return (
        <>
            {isLoading ? (
                <TableIndicator />
            ) : (
                <>
                    <ParentDashboardTable
                        title="Team Classroom Progress"
                        data={data ?? []}
                        columns={
                            columns as Array<ColumnDef<StudentSession, unknown>>
                        }
                        pagination={false}
                    />
                </>
            )}
        </>
    );
}
