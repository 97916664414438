import clsx from "clsx";
import { Link } from "react-router-dom";
import type React from "react";

interface AuthLayoutProps {
    showDoodle?: boolean;
    children: React.ReactNode;
}

export default function AuthLayout({ showDoodle = true, children }: AuthLayoutProps) {
    return (
        <div
            className={clsx(
                "flex min-h-screen flex-col",
                showDoodle && "bg-doodle bg-cover"
            )}
        >
            <header className="bg-white">
                <div className="px-4 py-3 mx-auto max-w-7xl sm:px-6 md:px-8">
                    <Link to="/">
                        <img
                            className="h-14"
                            src="/images/kineticp.png"
                            alt="Kinetic Platform"
                        />
                    </Link>
                </div>
            </header>

            <main className="flex-1">{children}</main>
        </div>
    );
}
