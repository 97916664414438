import PageHeader from "@src/components/PageHeader";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

import { useLocation, useNavigate } from "react-router-dom";
import SubmissionsController from "./submissions/SubmissionsController";

const IndividualSubmissionsPage = () => {
    const location = useLocation();
    const { state } = location;
    const { session, member, team } = state;

    const { full_name } = member;
    const title = session.session_details.title;
    const navigate = useNavigate();
    const handleBackClicked = () => navigate(-1);

    // We may change these later
    const breadcrumbPaths = [
        {
            name: `Team Submissions`,
            href: `/school/teams/${team.id}/submissions`,
        },
    ];

    return (
        <>
            <PageHeader title={`${title}`} breadcrumbPaths={breadcrumbPaths} />

            <section className="mx-auto max-w-7xl space-y-8 bg-white px-4 pb-12 pt-6 sm:px-6 md:px-8">
                <section className="max-w-5xl rounded-md px-4 py-6 shadow sm:px-6 md:px-8">
                    <div className="flex w-full flex-row items-center">
                        <div
                            className="flex h-6 w-9 cursor-pointer items-center justify-center rounded-md bg-slate-300"
                            onClick={handleBackClicked}
                        >
                            <ArrowLeftIcon className="h-4 w-4" />
                        </div>
                        <h2 className="ml-2 text-2xl font-semibold">
                            {full_name}
                        </h2>
                    </div>
                    <SubmissionsController sessionDetails={state} />
                </section>
            </section>
        </>
    );
};

export default IndividualSubmissionsPage;
