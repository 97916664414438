import type React from "react";
import Topnav from "@components/Navbar/Topnav";
import MainLayout, { type MainLayoutProps } from "./MainLayout";
import SchoolSideBar, { type SideBarNavItem } from "../Navbar/SchoolSideBar";
import {
    DocumentArrowUpIcon,
    Squares2X2Icon,
    WrenchIcon,
} from "@heroicons/react/20/solid";

interface SchoolLayoutProps extends React.PropsWithChildren {}

const navItems: SideBarNavItem[] = [
    {
        name: "Dashboard",
        link: "/school/dashboard",
        icon: Squares2X2Icon,
    },
    {
        name: "Team Submissions",
        link: "/school/team-submissions",
        icon: DocumentArrowUpIcon,
    },
    {
        name: "Settings",
        link: "/school/settings",
        icon: WrenchIcon,
    },
];

const SchoolLayout = ({ children }: SchoolLayoutProps) => {
    // return (
    //     <div className="flex h-screen flex-col bg-gray-100 text-gray-900 antialiased">
    //         <Topnav />

    //         <div className="relative flex min-h-0 flex-1">
    //             <main className="flex-1 overflow-y-auto">{children}</main>
    //         </div>
    //     </div>
    // );
    return (
        <MainLayout sidebar={<SchoolSideBar navItems={navItems} />}>
            {children}
        </MainLayout>
    );
};

export default SchoolLayout;
