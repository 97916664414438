import clsx from "clsx";

interface SavingIndicatorProps {
    className?: string;
    message?: string;
}

const SavingIndicator = ({
    className = "",
    message = "Saving",
}: SavingIndicatorProps) => {
    return (
        <span className={clsx("text-gray-900", className)}>
            {`${message}`}
            <span className={"motion-safe:animate-pulse"}>...</span>
        </span>
    );
};

export default SavingIndicator;
