import axios from "./axios";

export interface FiresideSpeaker {
    id: number;
    name: string;
    title: string;
    avatar: string;
}

export interface FiresideCategory {
    id: number;
    label: string;
}

export interface FiresideChat {
    id: number;
    title: string;
    description?: string | null;
    video_url: string;
    slug: string;
    is_featured: boolean;
    featured_image?: string | null;
    poster_image?: string | null;
    categories: FiresideCategory[];
    speaker: FiresideSpeaker;
    duration_in_minutes: number;
    is_premium: boolean;
    created: string;
    update: string;
}

export type ReflectionQuestionValueType =
    | "text"
    | "single_choice"
    | "multi_choice";

export interface ReflectionEntryResponse {
    content: Record<string, unknown>;
    reflection_question: number;
    reflection_entry: number;
}

export interface ReflectionEntry {
    id: number;
    responses: ReflectionEntryResponse[];
    created: string;
    modified: string;
    fireside: number;
    user: number;
}

export interface ReflectionQuestion {
    id: number;
    prompt: string;
    value_type: ReflectionQuestionValueType;
    metadata?: Record<string, unknown> | null;
}

export type QuestionResponsePayload = Record<
    `question_${number}`,
    { value: string }
>;

export interface WatchedFiresideChat extends FiresideChat {
    watched_at: string;
    questions: ReflectionQuestion[];
}

export async function getAllFiresides() {
    const res = await axios.get<FiresideChat[]>(`/firesides`);
    return res.data;
}

export async function getFireside(slug: string) {
    const res = await axios.get<FiresideChat>(`/firesides/${slug}`);
    return res.data;
}

export async function getReflectionQuestions(slug: string) {
    const res = await axios.get<ReflectionQuestion[]>(
        `/firesides/${slug}/reflection-questions/`
    );
    return res.data;
}

export function reflectionQuestionsQuery(slug: string) {
    return {
        queryKey: ["firesides", slug, "reflection-questions"],
        queryFn: async () => await getReflectionQuestions(slug),
    };
}

export async function submitReflectionResponse(
    slug: string,
    data: QuestionResponsePayload
) {
    return await axios.post<ReflectionQuestion[]>(
        `/firesides/${slug}/reflection-questions/`,
        data
    );
}

/**
 * mark a video as watched when the user has watched it for 90% of the duration.
 * @param slug
 * @returns
 */
export async function markFiresideAsWatched(slug: string) {
    return await axios.post(`/firesides/${slug}/mark-as-watched/`);
}
