import { Navigate, Outlet } from "react-router-dom";
import useStore from "@store/useStore";
import { profile } from "@src/api/user";
import { UserFactory } from "@src/models";
import { type QueryClient } from "@tanstack/react-query";

export function loader(queryClient: QueryClient) {
    return async () => {
        if (!useStore.getState().isAuthenticated) {
            return null;
        }

        return await queryClient.ensureQueryData({
            queryKey: ["profile"],
            queryFn: async () =>
                await profile().then((res) => UserFactory(res.data)),
        });
    };
}

export default function AuthenticatedRoot() {
    const isAuthenticated = useStore.getState().isAuthenticated;
    return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
}
