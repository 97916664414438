import {
    type TeamsViewsetResponse,
    type School,
    type StudentsViewsetResponse,
    type Cohort,
} from "@src/models/types";
import axios from "./axios";

export async function getSchool(schoolId: number) {
    const res = await axios.get<School>(`school/${schoolId}`);
    return res.data;
}

export async function updateSchool(schoolId: number, data: Partial<School>) {
    return await axios.patch<School>(`school/${schoolId}/`, data);
}

export async function getTeamStatsInSchool() {
    const res = await axios.get<Record<"teams_count", number>>(
        `school/team-stats`
    );
    return res.data;
}

export const getCohorts = async () => {
    const res = await axios.get<Cohort[]>(`school/cohorts`);
    return res.data;
};

export async function getStudentStatsInSchool() {
    const res = await axios.get<
        Record<"total_students" | "current_students", number>
    >(`school/student-stats`);
    return res.data;
}

export async function getTeamsInSchool(cohort?: string) {
    console.log("GET TEAMS IN SCHOOL COHORT ---> ", cohort);
    const res = await axios.get<TeamsViewsetResponse[]>("school/teams", {
        params: {
            cohort,
        },
    });
    return res.data;
}

export async function getStudentsInSchool(cohort?: string) {
    const res = await axios.get<StudentsViewsetResponse[]>("school/students", {
        params: {
            cohort,
        },
    });
    return res.data;
}
