import { useState } from "react";
import { ChevronDoubleLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
    Link,
    NavLink,
    useLocation,
    useRouteLoaderData,
} from "react-router-dom";
import Avatar from "@src/components/Avatar";
import useStore from "@src/store/useStore";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import clsx from "clsx";
import type { User } from "@src/models/types";
import { userTypeRootRoute } from "@src/api/auth";

type HeroiconComonent = React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
        title?: string;
        titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
>;

export interface SideBarNavItem {
    name: string;
    link: string;
    icon: HeroiconComonent;
}

export interface SideBarProps {
    navItems: SideBarNavItem[];
}

const SchoolSideBar = ({ navItems }: SideBarProps) => {
    const user = useRouteLoaderData("root") as User;

    const [
        isSideBarExpanded,
        toggleSidebarExpansion,
        isMobileMenuOpen,
        toggleMobileMenu,
    ] = useStore((state) => [
        state.isSideBarExpanded,
        state.toggleSidebarExpansion,
        state.isMobileMenuOpen,
        state.toggleMobileMenu,
    ]);

    const location = useLocation();
    useEffect(() => {
        isMobileMenuOpen && toggleMobileMenu();
    }, [location.pathname]);

    return (
        <>
            <Transition.Root show={isMobileMenuOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-40 lg:hidden"
                    onClose={toggleMobileMenu}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pb-4 pt-5">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute right-0 top-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={toggleMobileMenu}
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex flex-shrink-0 items-center px-4">
                                    <img
                                        className="h-12 w-auto"
                                        src="/images/kineticp.png"
                                        alt="Kinetic Platform"
                                    />
                                </div>
                                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                                    <nav className="space-y-1 px-2">
                                        {navItems.map((item) => (
                                            <NavLink
                                                key={item.name}
                                                to={item.link}
                                                className={({ isActive }) =>
                                                    clsx(
                                                        isActive
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                                        "group flex items-center rounded-md px-2 py-2 text-base font-medium"
                                                    )
                                                }
                                            >
                                                <item.icon
                                                    className={
                                                        "mr-4 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                    }
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <div
                className={`sticky top-0 hidden flex-shrink-0 flex-col transition-width md:flex ${
                    isSideBarExpanded ? "w-72" : "w-28"
                }`}
            >
                <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
                    <section className="flex-shrink-0 bg-purple-50">
                        <Link
                            to={`${userTypeRootRoute(user.user_type)}/profile`}
                            className="group flex flex-col items-center py-4 transition-colors"
                        >
                            <Avatar
                                src={user?.avatar}
                                name={user?.full_name ?? ""}
                                size={isSideBarExpanded ? "72" : "64"}
                            />
                            {isSideBarExpanded && (
                                <span className="mt-2 truncate text-xl font-bold text-purple-900 group-hover:text-purple-700">{`${user.full_name}`}</span>
                            )}
                        </Link>
                    </section>

                    <div className="flex flex-1 flex-col overflow-y-auto">
                        <nav className="flex-1 space-y-1 px-2 pb-8">
                            {navItems.map((item) => (
                                <NavLink
                                    key={item.name}
                                    to={item.link}
                                    className={({
                                        isActive,
                                    }) => `group flex items-center rounded-md px-2 py-2 text-center font-medium transition
                    ${
                        isActive
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                    }
                    ${
                        isSideBarExpanded
                            ? "flex-row gap-4 text-base"
                            : "flex-col justify-center gap-1 text-sm"
                    }
                `}
                                >
                                    <item.icon
                                        className={`h-6 w-6 flex-shrink-0 text-gray-400 transition group-hover:text-gray-500`}
                                    />
                                    {item.name}
                                </NavLink>
                            ))}
                        </nav>
                    </div>
                    <footer>
                        <button
                            type="button"
                            className="group ml-auto flex items-center px-2 py-2 text-sm text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                            onClick={toggleSidebarExpansion}
                        >
                            <ChevronDoubleLeftIcon
                                className={`mr-1 h-4 w-4 text-gray-400 transition group-hover:text-gray-500 ${
                                    isSideBarExpanded
                                        ? "rotate-0"
                                        : "rotate-180"
                                }`}
                            />
                            {isSideBarExpanded ? "Collapse" : "Expand"}
                        </button>
                    </footer>
                </div>
            </div>
        </>
    );
};

export default SchoolSideBar;
