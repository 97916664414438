import { useLoaderData } from "react-router-dom";
import { studentSessions } from "@src/api/sessions";
import SessionCard from "./SessionCard";
import UXSurvey from "@src/components/Survey/UXSurvey";
import PageHeaderV2 from "@src/components/PageHeader_v2";
import useStore from "@store/useStore";

const breadcrumbPaths = [{ name: "Classroom", href: "/student/content" }];

const MainContent = () => {
    const sessions = useLoaderData();
    const setIsOnboarding = useStore((state) => state.setIsOnboarding);
    setIsOnboarding(false);
    return (
        <>
            <PageHeaderV2
                title="My Classroom"
                breadcrumbPaths={breadcrumbPaths}
            />
            <UXSurvey />
            <section className="mx-auto max-w-7xl px-4 py-6 sm:px-6 md:px-8">
                <ul
                    data-cy="sessions-container"
                    className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
                >
                    {sessions.map((session, idx) => (
                        <li key={idx}>
                            <SessionCard session={session} />
                        </li>
                    ))}
                </ul>
            </section>
        </>
    );
};

export default MainContent;

export async function loader() {
    const res = await studentSessions();
    return res.data;
}
