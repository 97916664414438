import type { Student } from "@src/models/types";

const StudentInformationContext = () => {
    const persistedSelectedStudent: Student | null = JSON.parse(
        sessionStorage.getItem("Selected_Student") ?? ""
    );
  return (
    <>
      <p className=" bg-slate-200  px-2 rounded-md absolute top-3 md:top-2 right-6 md:right-10  ">
          Student : 
          <span className="px-1 font-semibold">{persistedSelectedStudent?.full_name}</span>
      </p>
    
    </>
  )
}

export default StudentInformationContext