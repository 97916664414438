import {
    type MyScoreStats,
    type IndividualLeaderboardRecord,
    type TeamLeaderboardRecord,
    type EventLog,
    type TeamRankDetail,
} from "@src/models/types";
import axios from "./axios";
import type { QueryOptions } from "@tanstack/react-query";

export async function getTeamLeaderboard() {
    const res = await axios.get<TeamLeaderboardRecord[]>(`/league/team/ranks`);
    return res.data;
}

export async function getIndividualLeaderboard() {
    const res = await axios.get<IndividualLeaderboardRecord[]>(
        `/league/student/ranks?scope=Cohort`
    );
    return res.data;
}

export async function getMyScore(studentId: number) {
    const res = await axios.get<MyScoreStats>(
        `/league/student/ranks/${studentId}/`
    );
    return res.data;
}

export async function getTeamScore(teamId: number) {
    const res = await axios.get<TeamRankDetail>(
        `/league/team/ranks/${teamId}/`
    );
    return res.data;
}

export async function getXpLog() {
    const res = await axios.get<Array<Partial<EventLog>>>(
        "/league/student/events/"
    );
    return res.data;
}

// Define the get type later
export const getStudentBadges = async (studentId?: string) => {
    const res = await axios.get(`/league/badges/`);
    return res.data;
};

export const getTeamBadge = async (teamId?: string) => {
    const res = await axios.get(`/league/badges/`);
    return res.data;
};

export const awardStudentBadge = async (data: object) => {
    return await axios.post(`league/student/badges/`, data);
};

export const awardTeamBadge = async (data: object) => {
    return await axios.post(`league/team/badges/`, data);
};

export const getStudentBadgesQuery = async (studnetId: string) => {
    return {
        queryKey: ["studentBadges", studnetId],
        queryFn: async () => await getStudentBadges(studnetId),
    } satisfies QueryOptions;
};

export const getTeamBadgesQuery = async (teamId: string) => {
    return {
        queryKey: ["teamBadges", teamId],
        queryFn: async () => await getTeamBadge(teamId),
    } satisfies QueryOptions;
};

// Queries
export function teamLeaderboardQuery() {
    return {
        queryKey: ["leaderboard", "teams"],
        queryFn: async () => await getTeamLeaderboard(),
    };
}

export function individualLeaderboardQuery() {
    return {
        queryKey: ["leaderboard", "individuals"],
        queryFn: async () => await getIndividualLeaderboard(),
    };
}

export function myScoreQuery(studentId: number) {
    return {
        queryKey: ["my-score", studentId],
        queryFn: async () => await getMyScore(studentId),
    };
}

export function teamScoreQuery(teamId: number) {
    return {
        queryKey: ["team-score", teamId],
        queryFn: async () => await getTeamScore(teamId),
    };
}

export function xpLogQuery() {
    return {
        queryKey: ["xp-log"],
        queryFn: async () => await getXpLog(),
    };
}
