import Alert from "../Alert";
import GenericErrorAlert from "@components/GenericErrorAlert";

export default function GeneralError({ message, className = "" }) {
    return (
        (message === "generic" && (
            <GenericErrorAlert className={className} />
        )) ||
        (message && (
            <Alert
                intent="danger"
                title="Something went wrong!"
                description={message}
                className={className}
            />
        ))
    );
}
