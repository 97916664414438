import {
    type SliceCreator,
    type UISlice,
    type UISliceState,
} from "@store/types";

const initialState: UISliceState = {
    isSideBarExpanded: false,
    isMobileMenuOpen: false,
};

export const createUISlice: SliceCreator<UISlice> = (set) => ({
    ...initialState,

    reset: () => {
        set(initialState);
    },

    toggleSidebarExpansion: () => {
        set(({ isSideBarExpanded }) => ({
            isSideBarExpanded: !isSideBarExpanded,
        }));
    },

    toggleMobileMenu: () => {
        set(({ isMobileMenuOpen }) => ({
            isMobileMenuOpen: !isMobileMenuOpen,
        }));
    },
});
