import { type Editor } from "@tiptap/react";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

interface TypographyDropdownProps {
    editor: Editor | null;
}

interface TypographyOption {
    label: string;
    onSelected: (editor: Editor) => void;
    isActive: (editor: Editor) => boolean;
}

const options = [
    {
        label: "Paragraph",
        onSelected: (editor: Editor) => {
            editor.chain().focus().setParagraph().run();
        },
        isActive: (editor: Editor) => {
            return editor.isActive("paragraph");
        },
    },
    {
        label: "Heading 1",
        onSelected: (editor: Editor) => {
            editor.chain().focus().toggleHeading({ level: 1 }).run();
        },
        isActive: (editor: Editor) => {
            return editor.isActive("heading", { level: 1 });
        },
    },
    {
        label: "Heading 2",
        onSelected: (editor: Editor) => {
            editor.chain().focus().toggleHeading({ level: 2 }).run();
        },
        isActive: (editor: Editor) => {
            return editor.isActive("heading", { level: 2 });
        },
    },
    {
        label: "Heading 3",
        onSelected: (editor: Editor) => {
            editor.chain().focus().toggleHeading({ level: 3 }).run();
        },
        isActive: (editor: Editor) => {
            return editor.isActive("heading", { level: 3 });
        },
    },
    {
        label: "Heading 4",
        onSelected: (editor: Editor) => {
            editor.chain().focus().toggleHeading({ level: 4 }).run();
        },
        isActive: (editor: Editor) => {
            return editor.isActive("heading", { level: 4 });
        },
    },
] satisfies TypographyOption[];

const TypographyDropdown = ({ editor }: TypographyDropdownProps) => {
    if (editor === null) {
        return <></>;
    }

    const [selectedOption, setSelectedOption] = useState<TypographyOption>(
        options[0]
    );

    const lastFocusedPositionRef = useRef<number>(editor.state.selection.from);

    useEffect(() => {
        editor.commands.focus();
    }, []);

    const handleListboxOpen = () => {
        lastFocusedPositionRef.current = editor.state.selection.from;
    };

    const updateSelectedOption = (value: TypographyOption) => {
        setSelectedOption(value);
        value.onSelected(editor);

        setTimeout(() => {
            editor.commands.focus(lastFocusedPositionRef.current);
        }, 0);
    };

    useEffect(() => {
        function handleChange() {
            if (editor === null) {
                return;
            }

            for (const option of options) {
                if (option.isActive(editor)) {
                    setSelectedOption(option);
                }
            }
        }

        editor.on("transaction", handleChange);

        return () => {
            editor.off("transaction", handleChange);
        };
    }, [editor]);

    return (
        <Listbox value={selectedOption} onChange={updateSelectedOption} as="div">
            {({ open }) => (
                <>
                    <Listbox.Label className="sr-only">
                        Typorgraphy selection
                    </Listbox.Label>
                    <div className="relative">
                        <Listbox.Button
                            onClick={handleListboxOpen}
                            className="relative h-full w-full cursor-default bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 focus:outline-transparent focus:ring-1 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                        >
                            <span className="block truncate">
                                {selectedOption.label}
                            </span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronUpDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {options.map((option) => (
                                    <Listbox.Option
                                        key={option.label}
                                        value={option}
                                        // disabled={}
                                        className={({ active }) =>
                                            `relative cursor-default select-none px-2 py-1 ${
                                                active
                                                    ? "font-semibold text-gray-900"
                                                    : "text-gray-600"
                                            }`
                                        }
                                    >
                                        {option.label}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};

export default TypographyDropdown;
