import Video from "@src/components/Content/Video_v2";
import Topnav from "@src/components/Navbar/Topnav";
import Button from "@src/components/Button";
import { useNavigate } from "react-router-dom";
import IntroLayout from "@src/components/layout/IntroLayout";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

const TeamSetupVideo = () => {
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(-1);
    };

    const handleNavigateNext = () => {
        navigate("/team-creation");
    };

    return (
        <IntroLayout showGears={false}>
            <Topnav />

            <p className=" w-full text-center text-[30px] font-semibold">
                {" "}
                Set up a Team
            </p>
            <p className=" w-full text-center text-2xl font-semibold text-purple-500">
                {" "}
                Watch the video below to create your team
            </p>
            <div className="relative left-[100px] top-[30px] flex w-full items-start justify-start">
                <div
                    className=" cursor:pointer h-fit w-fit rounded-md bg-orange-500 p-2 hover:bg-orange-700"
                    onClick={navigateBack}
                >
                    <ArrowLeftIcon className="h-6 w-6" color="white" />
                </div>
            </div>

            <div className="mb-8 flex w-full flex-col items-center">
                <div className={"my-4 h-[400px] w-[800px] md:my-8 "}>
                    <Video
                        className="aspect-video w-full"
                        title="Kinetic Team Setup Guide Video"
                        src="https://player.vimeo.com/video/817555991?h=9fec5b32fb"
                    />
                </div>
            </div>

            <div className=" mb-8 flex w-full flex-row items-center justify-between px-[100px]">
                <>
                    <Button
                        variant="contained"
                        intent="primaryVariant"
                        size="lg"
                        onClick={navigateBack}
                    >
                        Back
                    </Button>
                </>

                <>
                    <Button
                        variant="contained"
                        intent="secondaryVariant"
                        size="lg"
                        onClick={handleNavigateNext}
                    >
                        Create Team
                    </Button>
                </>
            </div>
        </IntroLayout>
    );
};

export default TeamSetupVideo;
