import axios from "./axios";

export function login(data) {
    return axios.post("/accounts/signin/", data);
}

export function freeTrialSingUp(data) {
    return axios.post("/accounts/free-trial-registration/", data);
}

export function freeTrialSignupPushToAirtable(data) {
    const Airtable_Api_Token =
        "patayBdaAtkUDXMnK.21f6d87d76297ddd8a074456199c425800d9609889e4ec30c351649da27332f0";
    const headers_ = {
        Authorization: `Bearer ${Airtable_Api_Token}`,
        "Content-Type": "application/json",
    };
    return axios.post(
        "https://api.airtable.com/v0/app4ccH66mNiXhCY2/Table%202",
        { fields: data },
        { headers: headers_ }
    );
}

export function logout() {
    return axios.post("/accounts/signout/");
}

export function userTypeRootRoute(userType) {
    return {
        student: "/student",
        parent: "/parent",
        school_admin: "/school",
    }[userType];
}

export function userTypeHomeRoute(userType) {
    return {
        student: `${userTypeRootRoute(userType)}/content`,

        parent: `${userTypeRootRoute(userType)}/dashboard`,
        school_admin: `${userTypeRootRoute(userType)}/dashboard`,
    }[userType];
}

export function userTypeLoginRoute(userType) {
    return {
        student: `${userTypeRootRoute(userType)}/content`,

        parent: `${userTypeRootRoute(userType)}/dashboard`,
        school_admin: `${userTypeRootRoute(userType)}/dashboard`,
    }[userType];
}

export function userTypeOnboardingRoute(userType) {
    return {
        // student: "/onboarding",
        student: "/welcome-screen",
        parent: "/parents-onboarding",
    }[userType];
}

export function requestPasswordReset(data) {
    return axios.post("/accounts/password_reset/", data);
}

export function passwordResetConfirm(
    userHashId,
    token,
    data,
    isAccountSetup = false
) {
    let urlPath = `/accounts/reset/${userHashId}/${token}/`;

    if (isAccountSetup) {
        urlPath += `?${new URLSearchParams({
            source: "account_setup",
        }).toString()}`;
    }

    return axios.post(urlPath, data);
}

export function resendAccountSetupEmail(userHashId) {
    return axios.get(`/accounts/resend-account-setup/${userHashId}/`);
}

export function verifyEmail(uid, token) {
    return axios.post(`/accounts/verify-email/${uid}/${token}/`);
}

export function resendEmailVerification(uid) {
    return axios.post(`/accounts/resend-email-verification/${uid}/`);
}
