import Breadcrumb, { type BreadcrumbPath } from "./Breadcrumb";

interface PageHeaderProps {
    title: string;
    breadcrumbPaths: BreadcrumbPath[];
    actions?: React.ReactNode;
}

export default function PageHeader({
    title,
    breadcrumbPaths,
    actions,
}: PageHeaderProps) {
    return (
        <header
            data-cy="page-header"
            className="bg-white px-4 pb-6 pt-3 sm:px-6 md:px-8"
        >
            <Breadcrumb paths={breadcrumbPaths} />

            <div className="mt-4 md:flex md:items-center md:justify-between">
                <div className="min-w-0 flex-1">
                    <h2 className="inline-flex items-center space-x-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        <span>{title}</span>
                    </h2>
                </div>

                {/* Actions: This is placeholder JSX to demonstrate what actions would look like */}
                <>{actions}</>
            </div>
        </header>
    );
}
