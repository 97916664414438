import ParentDashboardTable from "@src/components/tables/ParentDashboardTable";
import Button from "@src/components/Button";
import { useQuery } from "@tanstack/react-query";
import TableIndicator from "./TableIndicator";
import { getFiresideHistory } from "@src/api/students";
import { type ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { type WatchedFiresideChat } from "@src/api/firesides";
import { useMemo } from "react";
import { Link } from "react-router-dom";

interface FiresideWatchHistoryProps {
    studentId: number | undefined;
}

const columnHelper = createColumnHelper<WatchedFiresideChat>();

export default function FiresideWatchHistory({
    studentId,
}: FiresideWatchHistoryProps) {
    const { isLoading, data } = useQuery({
        queryKey: ["students", "firesides", studentId],
        queryFn: async () => await getFiresideHistory(studentId),
        enabled: Boolean(studentId),
    });

    const columns = useMemo(
        () => [
            columnHelper.accessor("title", {
                header: "Fireside Title",
                cell: (info) => (
                    <span className="line-clamp-2">{info.getValue()}</span>
                ),
            }),
            columnHelper.accessor("speaker", {
                header: "Speaker",
                cell: (info) => (
                    <div className="flex items-center space-x-4">
                        <img
                            src={info.getValue().avatar}
                            alt="avatar-image"
                            className="w-12 h-12 border border-purple-500 rounded-full "
                        />
                        <span className="line-clamp-2">
                            {info.getValue().name}
                        </span>
                    </div>
                ),
            }),
            columnHelper.display({
                id: "actions",
                cell: ({ row }) => (
                    <div className="grid place-content-center">
                          <Link
                                to={
                                    `/parent/students/${studentId}/feedback-fireside/${row.original.id}`
                                }
                            >
                                <Button intent="white">View</Button>
                            </Link>
                    </div>
                ),
            }),
        ],
        [studentId]
    );

    return (
        <>
            {isLoading ? (
                <TableIndicator />
            ) : (
                <ParentDashboardTable
                    title="Fireside Chats Watched"
                    data={data ?? []}
                    columns={columns as Array<ColumnDef<WatchedFiresideChat>>}
                    pagination={true}
                />
            )}
        </>
    );
}
