import { type User } from "@src/models/types";
import { useRouteLoaderData, useSearchParams } from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import Button from "../Button";
import { FieldError, TextArea } from "../Form";
import { useQuery } from "@tanstack/react-query";
import Time from "../Time/Time";
import { useForm } from "react-hook-form";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import SavingIndicator from "../SavingIndicator";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Disclosure } from "@headlessui/react";
import {
    ChevronUpIcon,
    ClipboardDocumentListIcon,
    ChatBubbleLeftIcon,
} from "@heroicons/react/20/solid";
import { type UseQueryResult } from "@tanstack/react-query";

import {
    newThread,
    type ThreadFromAPI,
    threadQuery,
    type PostWithThread,
} from "@src/api/forums";
import EditComment from "./EditContent";

interface CommentListTempProps {
    commentList: ThreadFromAPI[];
    with_children: boolean;
    post: number;
    refetch: any;
}

interface CommentContentProps {
    comment: ThreadFromAPI;
    with_children: boolean;
    setHasEdditedComment: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommentListContent = ({
    comment,
    with_children,
    setHasEdditedComment,
}: CommentContentProps) => {
    const user = useRouteLoaderData("root") as User;
    const [subThread, setSubThread] = useState(1);

    interface CommentForm {
        content: string;
        parent_thread?: number | null;
    }

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<CommentForm>({ defaultValues: { content: "" } });

    const submit = async (data: CommentForm) => {
        try {
            let newComment = await newThread(
                { content: data.content, post: comment.post },
                comment.id
            );
            reset();
            setSubThread(subThread + 1);
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    return (
        <>
            <li key={comment.id}>
                <div className="flex space-x-3">
                    <div className="flex-shrink-0">
                        <Avatar
                            size="42"
                            name={comment.created_by.user.full_name ?? ""}
                        />
                    </div>
                    {/* The first comment */}
                    <div>
                        <div className="">
                            <a href="#" className="font-medium text-gray-900">
                                {comment.created_by.user.full_name}
                            </a>
                        </div>
                        <div className="mt-1 flex flex-row items-center gap-5 text-gray-700">
                            <p>{comment.content}</p>
                        </div>
                        <>
                            {user.id === comment.created_by.user.id && (
                                // <div className=" flex-row rounded-lg p-1  hover:bg-purple-100 hover:bg-purple-200">
                                //     <PencilIcon className="h-4 w-4 cursor-pointer  " />
                                // </div>
                                <EditComment
                                    comment={comment.content}
                                    threadId={comment.id}
                                    setHasEdditedComment={setHasEdditedComment}
                                />
                            )}
                        </>
                        <div className="mt-2 flex flex-row space-x-2">
                            <Time
                                value={new Date(comment.created)}
                                className="font-medium text-gray-500"
                            />
                        </div>
                        <Disclosure>
                            {() => (
                                <>
                                    <Disclosure.Button>
                                        <div className="mt-2 flex flex-row">
                                            <div className="flex flex-row rounded-lg bg-purple-100 p-2 hover:bg-purple-200">
                                                <ChatBubbleLeftIcon className="h-6 w-6 cursor-pointer pr-1 text-purple-400" />
                                            </div>
                                        </div>
                                    </Disclosure.Button>
                                    <Disclosure.Panel>
                                        <div className="flex w-full space-x-3">
                                            <div className="flex-shrink-0">
                                                <Avatar
                                                    src={
                                                        user.avatar ?? undefined
                                                    }
                                                    name={user?.full_name ?? ""}
                                                    size="45"
                                                />
                                            </div>
                                            <div className="relative min-w-0 flex-1">
                                                <form
                                                    action="#"
                                                    onSubmit={handleSubmit(
                                                        submit
                                                    )}
                                                >
                                                    <div>
                                                        <label
                                                            htmlFor="comment"
                                                            className="sr-only"
                                                        >
                                                            Comment
                                                        </label>
                                                        <TextArea
                                                            id="comment"
                                                            rows={4}
                                                            className="w-full"
                                                            {...register(
                                                                "content"
                                                            )}
                                                            required
                                                        />
                                                        <FieldError
                                                            className="mt-1"
                                                            message={
                                                                errors.content
                                                                    ?.message
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mt-3 flex items-center justify-end">
                                                        {isSubmitting && (
                                                            <SavingIndicator className="ml-2" />
                                                        )}
                                                        <Button type="submit">
                                                            Add New Comment
                                                        </Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        {comment.has_children && with_children ? (
                            <Disclosure as="div" className="mt-2">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex h-14 w-full items-center justify-between rounded-lg py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                            <span className="font-semibold">{`Read More`}</span>
                                            <ChevronUpIcon
                                                className={`${
                                                    open
                                                        ? ""
                                                        : "rotate-180 transform"
                                                } h-5 w-5 font-semibold`}
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel>
                                            <LoadedCommentList
                                                key={subThread}
                                                post={comment.post}
                                                thread={comment.id}
                                            />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </li>
        </>
    );
};

export interface LoadedComentListProps {
    post: number;
    thread: number;
}

const LoadedCommentList = ({ thread }: LoadedComentListProps) => {
    const { data, isLoading, refetch } = useQuery({ ...threadQuery(thread) });
    const [hasEdittedComment, setHasEditedComment] = useState(false);

    useEffect(() => {
        if (hasEdittedComment) {
            refetch()
                .then(() => {})
                .catch((error: unknown) => {
                    console.log("ERROR", error);
                });
            setHasEditedComment(false);
        }
    }, [hasEdittedComment]);

    if (isLoading) {
        return (
            <div className="m-11 flex w-full items-center justify-center">
                <span className="loader"></span>
            </div>
        );
    }

    if (data === undefined) {
        return (
            <div className="flex justify-center">
                <div className="flex w-1/2 flex-col items-center justify-center gap-4">
                    <ClipboardDocumentListIcon className="h-56 w-56 stroke-1 text-slate-300" />
                    <p className="text-center text-2xl">No data found</p>
                </div>
            </div>
        );
    }

    return (
        <ul role="list" className="mt-4 space-y-8">
            {data?.children.map((comment) => (
                <CommentListContent
                    comment={comment}
                    with_children={false}
                    setHasEdditedComment={setHasEditedComment}
                />
            ))}
        </ul>
    );
};

const CommentList = ({
    commentList,
    with_children,
    post,
    refetch,
}: CommentListTempProps) => {
    const [searchParams] = useSearchParams();
    const [commentList_, setCommentList] = useState(commentList);
    const scrollToComments = searchParams.get("scrollToComments");
    const commentsSectionRef = useRef<HTMLDivElement>(null);
    const [hasEdittedComment, setHasEditedComment] = useState(false);

    useEffect(() => {
        if (hasEdittedComment) {
            refetch()
                .then((response: UseQueryResult<PostWithThread>) => {
                    setCommentList(
                        response.data != null ? response.data.threads : []
                    );
                })
                .catch((error: unknown) => {
                    console.log("ERROR", error);
                });
            setHasEditedComment(false);
        }
    }, [hasEdittedComment]);

    /**
     * Scroll to comments section
     * when the student clicks on a feedback notification
     */
    const scrollToElement = () => {
        commentsSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        if (scrollToComments === "true") {
            scrollToElement();
        }
    }, [scrollToComments]);
    const user = useRouteLoaderData("root") as User;

    interface CommentForm {
        content: string;
    }

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<CommentForm>({ defaultValues: { content: "" } });

    const submit = async (data: CommentForm) => {
        try {
            const newComment = await newThread({ content: data.content, post });
            reset();
            commentList_.push(newComment);
            setCommentList(commentList_);
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    return (
        <>
            <section className={clsx("my-6 bg-white")}>
                <div className="px-4 py-6 sm:px-6" ref={commentsSectionRef}>
                    <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                            <Avatar
                                src={user.avatar ?? undefined}
                                name={user?.full_name ?? ""}
                                size="45"
                            />
                        </div>
                        <div className="relative min-w-0 flex-1">
                            <form action="#" onSubmit={handleSubmit(submit)}>
                                <div>
                                    <label
                                        htmlFor="comment"
                                        className="sr-only"
                                    >
                                        Comment
                                    </label>
                                    <TextArea
                                        id="comment"
                                        rows={4}
                                        className="w-full"
                                        {...register("content")}
                                        required
                                    />
                                    <FieldError
                                        className="mt-1"
                                        message={errors.content?.message}
                                    />
                                </div>

                                <div className="mt-3 flex items-center justify-end">
                                    {isSubmitting && (
                                        <SavingIndicator className="ml-2" />
                                    )}
                                    <Button type="submit">
                                        Add New Comment
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section className={clsx("rounded-md bg-gray-50 shadow")}>
                <div className="px-4 py-6 sm:px-6 md:px-8">
                    <h2 className="text-2xl font-semibold">Comments</h2>

                    {commentList_?.length === 0 ? (
                        <section className="mx-auto mt-4 rounded-md border border-dashed p-8">
                            <p className="text-center text-lg text-gray-700">
                                No comments available for this post.
                            </p>
                        </section>
                    ) : (
                        <ul role="list" className="mt-4 space-y-8">
                            {commentList_.map((comment) => (
                                <CommentListContent
                                    comment={comment}
                                    with_children={with_children}
                                    setHasEdditedComment={setHasEditedComment}
                                />
                            ))}
                        </ul>
                    )}
                </div>
            </section>
        </>
    );
};

export default CommentList;
