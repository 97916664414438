import {
    type TeamSubmission,
    type TeamSubmissionSummary,
} from "@src/models/types";
import axios from "./axios";
import { type QueryOptions } from "@tanstack/react-query";

export const getTeamSubmissions = async (teamId: string) => {
    const res = await axios.get<TeamSubmission>(`teams/${teamId}/submissions/`);
    return res.data;
};

export const getTeamSubmissionsSummary = async () => {
    const res = await axios.get<TeamSubmissionSummary>(
        `teams/submissions/summary/`
    );
    return res.data;
};

export const getTeamSubmissionsQuery = (teamId: string) => {
    return {
        queryKey: ["teamSubmissions", teamId],
        queryFn: async () => await getTeamSubmissions(teamId),
    } satisfies QueryOptions;
};

export const getTeamSubmissionsSummaryQuery = () => {
    return {
        queryKey: ["teamSubmissionsSummary"],
        queryFn: async () => await getTeamSubmissionsSummary(),
    } satisfies QueryOptions;
};
