import Button from "@components/Button";
import { FieldError, Label, TextField, Select } from "@components/Form";
import { useForm, type UseFormReturn } from "react-hook-form";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import SettingsCard from "./SettingsCard";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import type React from "react";
import { type School, type SchoolAdmin } from "@src/models/types";
import { SchoolCurriculums } from "@src/models";
import { updateSchool } from "@src/api/schools";

const Form: React.FC<{ form: UseFormReturn<School>; schoolId: number }> = (
    props
) => {
    const { form, schoolId } = props;
    const revalidator = useRevalidator();

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = form;

    const submit = async (data: Partial<School>) => {
        try {
            await updateSchool(schoolId, data);
            clearErrors();
            revalidator.revalidate();

            alert("School information updated successfully.");
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    return (
        <form
            id={"form-school-information"}
            onSubmit={handleSubmit(submit)}
            className={"max-w-lg space-y-4"}
        >
            <div>
                <Label htmlFor={"school-name"} name={"School Name"} />
                <TextField
                    className={"mt-1 w-full"}
                    type="text"
                    id={"school-name"}
                    {...register("school_name")}
                    autoComplete="off"
                    required
                />
                <FieldError
                    className={"mt-1"}
                    message={errors.school_name?.message}
                />
            </div>

            <div>
                <Label htmlFor={"location"} name={"Location"} />
                <TextField
                    className={"mt-1 w-full"}
                    type="text"
                    id={"location"}
                    {...register("location")}
                    autoComplete="off"
                    required
                />
                <FieldError
                    className={"mt-1"}
                    message={errors.location?.message}
                />
            </div>

            <div>
                <Label htmlFor={"curriculum"} name={"curriculum"} />
                <Select
                    className={"mt-1 w-full"}
                    id={"curriculum"}
                    {...register("curriculum")}
                    required
                >
                    {Object.keys(SchoolCurriculums).map((curriculum, idx) => (
                        <option
                            key={idx}
                            value={
                                SchoolCurriculums[
                                    curriculum as keyof typeof SchoolCurriculums
                                ]
                            }
                        >
                            {curriculum.replace("_", "")}
                        </option>
                    ))}
                </Select>
                <FieldError
                    className={"mt-1"}
                    message={errors.curriculum?.message}
                />
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div>
                    <Label htmlFor={"school-email"} name={"School Email"} />
                    <TextField
                        className={"mt-1 w-full"}
                        type="text"
                        id={"school-email"}
                        {...register("school_email")}
                        autoComplete="off"
                        required
                    />
                    <FieldError
                        className={"mt-1"}
                        message={errors.school_email?.message}
                    />
                </div>

                <div>
                    <Label htmlFor={"school-phone"} name={"School Phone"} />
                    <TextField
                        className={"mt-1 w-full"}
                        type="text"
                        id={"school-phone"}
                        {...register("school_phone")}
                        autoComplete="off"
                        required
                    />
                    <FieldError
                        className={"mt-1"}
                        message={errors.school_phone?.message}
                    />
                </div>
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div>
                    <Label
                        htmlFor={"school-contact-email"}
                        name={"School Contact Email"}
                    />
                    <TextField
                        className={"mt-1 w-full"}
                        type="text"
                        id={"school-contact-email"}
                        {...register("school_contact_email")}
                        autoComplete="off"
                        required
                    />
                    <FieldError
                        className={"mt-1"}
                        message={errors.school_contact_email?.message}
                    />
                </div>

                <div>
                    <Label
                        htmlFor={"school-contact-phone"}
                        name={"School Contact Phone"}
                    />
                    <TextField
                        className={"mt-1 w-full"}
                        type="text"
                        id={"school-contact-phone"}
                        {...register("school_contact_phone")}
                        autoComplete="off"
                        required
                    />
                    <FieldError
                        className={"mt-1"}
                        message={errors.school_contact_phone?.message}
                    />
                </div>
            </div>
        </form>
    );
};

export default function UpdateSchoolInformation() {
    const user = useRouteLoaderData("root") as SchoolAdmin;
    const { school } = user;

    const form = useForm({
        defaultValues: {
            ...school,
        },
    });

    const {
        formState: { isSubmitting },
    } = form;

    return (
        <SettingsCard
            title={"School Information"}
            form={<Form form={form} schoolId={school.school_id} />}
            actions={
                <div className={"flex items-center"}>
                    {isSubmitting && (
                        <span className={"text-gray-900 mr-2"}>
                            Saving{" "}
                            <span className={"motion-safe:animate-pulse"}>
                                ...
                            </span>
                        </span>
                    )}
                    <Button
                        type="submit"
                        form="form-school-information"
                        intent={"primary"}
                    >
                        Update School
                    </Button>
                </div>
            }
        />
    );
}
