import React from "react";
import clsx from "clsx";

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    resizable?: boolean;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({ className = "", resizable = false, ...props }, ref) => {
        return (
            <textarea
                ref={ref}
                className={clsx(
                    "block rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-purple-500 focus:outline-none focus:ring-purple-500",
                    !resizable && "resize-none",
                    className
                )}
                {...props}
            ></textarea>
        );
    }
);

export default TextArea;
