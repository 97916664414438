import SessionOneSubmission from "./SessionOne/SessionOneSubmission";
import SessionTwoSubmission from "./SessionTwo/SessionTwoSubmission";
import SessionThreeSubmission from "./SessionThree/SessionThreeSubmission";
import SessionFourSubmission from "./SessionFour/SessionFourTaskOneSubmissions";
import SessionFiveTaskOne from "./SessionFive/SessionFiveTaskOneSubmissions";
import SessionSixSubmissions from "./SessionSix/SessionSixSubmissions";
import SessionSevenSubmission from "./SessionSeven/SessionSevenSubmissions";
import SessionEightSubmission from "./SessionEight/SessionEightSubmission";
import SessionNineSubmission from "./SessionNine/SessionNineSubmission";
import "../../../components/Loaders/loader.css";

import { useState, useEffect } from "react";

const SubmissionsController = ({ sessionDetails = {} }) => {
    // console.log("Submissions Controller session ---> ", sessionDetails);
    const [sortOrder, setSortOrder] = useState(0);
    const [submissions, setSubmissions] = useState({});
    const { isFirst, session, data, task } = sessionDetails;

    useEffect(() => {
        if (isFirst && sessionDetails) {
            const { sort_order, submissions } = sessionDetails;
            setSubmissions(submissions);
            setSortOrder(sort_order);
        } else {
            const { session_details } = session;
            const { sort_order } = session_details;
            setSortOrder(sort_order);
        }
    }, [isFirst, sessionDetails]);

    const renderComponent = {
        1: <SessionOneSubmission submissions={submissions} />,
        2: (
            <SessionTwoSubmission
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        ),
        3: (
            <SessionThreeSubmission
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        ),
        4: (
            <SessionFourSubmission
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        ),
        5: (
            <SessionFiveTaskOne
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        ),
        6: (
            <SessionSixSubmissions
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        ),
        7: (
            <SessionSevenSubmission
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        ),
        8: (
            <SessionEightSubmission
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        ),
        9: (
            <SessionNineSubmission
                data={data}
                sortOrder={sortOrder}
                session={session}
                task={task}
            />
        ),
    };

    return <div>{renderComponent[sortOrder]}</div>;
};
export default SubmissionsController;
