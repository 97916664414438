import type { ReportSummary, Student } from "@src/models/types";
import axios from "./axios";
import { type QueryOptions } from "@tanstack/react-query";

export async function getParentsChildren(parentId: number) {
    const res = await axios.get<Student[]>(`parents/${parentId}/students`);
    return res.data;
}

export async function getReports(parentsId: number) {
    const res = await axios.get<ReportSummary>(
        `/school/cohort-reports/${parentsId}/`
    );
    return res.data;
}

export function childrenQueryOptions(parentId: number) {
    return {
        queryKey: ["parents", parentId, "children"],
        queryFn: async () => await getParentsChildren(parentId),
    } satisfies QueryOptions;
}

export function reportsQuery(parentId: number) {
    return {
        queryKey: ["report", parentId],
        queryFn: async () => await getReports(parentId),
    };
}
