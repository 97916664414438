import PageHeader from "@components/PageHeader";
import PasswordChange from "@pages/profile/PasswordChange";
import UpdatePersonalInformation from "@pages/profile/UpdatePersonalInformation";
import { userTypeRootRoute } from "@src/api/auth";
import { UserType } from "@src/models";
import { useStore } from "@src/store";
import UpdateSchoolInformation from "./UpdateSchoolInformation";
import { useMemo } from "react";
import type { User } from "@src/models/types";

export default function EditProfile() {
    const user = useStore((state) => state.user) as User;

    const breadcrumbPaths = useMemo(
        () => [
            {
                name: "Edit Profile",
                href: `${userTypeRootRoute(user.user_type)}/profile`,
            },
        ],
        [user.user_type]
    );

    return (
        <main>
            <PageHeader
                title={"Edit Profile"}
                breadcrumbPaths={breadcrumbPaths}
            />

            <section className="px-4 pt-6 pb-12 space-y-8 sm:px-6 md:px-8">
                <UpdatePersonalInformation />
                {user.user_type === UserType.SCHOOL_ADMIN && (
                    <UpdateSchoolInformation />
                )}
                <PasswordChange />
            </section>
        </main>
    );
}
