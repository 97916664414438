import {
    type SubmissionFeedback,
    type GroupedSessionSubmissions,
    type LearningSession,
    type StudentSession,
} from "@src/models/types";
import axios from "./axios";
import type { ApiResponse } from "@src/utils/type_helpers";

export async function studentSessions() {
    return await axios.get(`content/student-sessions/`);
}

export async function getStudentSession(sessionId: number) {
    const response = await axios.get<StudentSession>(
        `content/student-sessions/${sessionId}/`
    );
    return response.data;
}

export async function updateStudentSession(sessionId: number, data: object) {
    return await axios.put(`content/student-sessions/${sessionId}/`, data);
}

export async function updateSessionSubmissions(
    sessionId: number,
    data: object
) {
    console.log("POST DATA TO UPLOAD API---> ", data);
    return await axios.post(
        `content/student-sessions/${sessionId}/submissions/`,
        data
    );
}

// for any file uploads

export const uploadStudentFiles = async (
    files: FileList,
    session: number,
    taskIdentifier: string
) => {
    Array.from(files).forEach(async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("session", session.toString());
        formData.append("task_identifier", taskIdentifier);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        try {
            const response = await axios.post(
                `content/file-uploads/`,
                formData,
                config
            );
            console.log("FILE API UPLOAD SUCCESS", response);
            return response;
        } catch (error) {
            return error;
        }
    });
};

export async function getSessions() {
    const res = await axios.get<LearningSession[]>("content/sessions/");
    return res.data;
}

export function getSessionsQuery() {
    return {
        queryKey: ["sessions"],
        queryFn: async () => await getSessions(),
    };
}

export async function getSessionSubmissions(
    sessionId: number,
    studentId: number
) {
    const res = await axios.get<GroupedSessionSubmissions>(
        `/students/${studentId}/sessions/${sessionId}/submissions/`
    );
    return res.data;
}

export function sessionSubmissionsQuery(sessionId: number, studentId: number) {
    return {
        queryKey: ["students", studentId, "sessions", sessionId, "submissions"],
        queryFn: async () => await getSessionSubmissions(sessionId, studentId),
    };
}

export async function getSubmissionFeedback(submissionId: number) {
    const res = await axios.get<{ data: SubmissionFeedback[] }>(
        `content/submissions/${submissionId}/feedback/`
    );

    return res.data.data;
}

export async function newSubmissionFeedback(
    submissionId: number,
    content: string
) {
    return await axios.post<ApiResponse<SubmissionFeedback>>(
        `content/submissions/${submissionId}/feedback/`,
        { content }
    );
}

// Queries
export function studentSessionQueryOptions(sessionId: number) {
    return {
        queryKey: ["sessions", sessionId],
        queryFn: async () => await getStudentSession(sessionId),
    };
}

export async function markContentVideoWatched(sessionId: number) {
    return await axios.post(`/content/${sessionId}/mark-as-watched/`);
}

export const onDownloadFile = (url: string) => {
    console.log();
    axios
        .get(url)
        .then((response) => {
            console.log("ON FILE DOWNLOAD SUCCESS------> ", response);
            return response;
        })
        .catch((err) => {
            console.log("ON FILE DOWNLOAD ERROR------> ", err);
        });
};
