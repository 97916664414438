import JSConfetti from "js-confetti"
import { useEffect, useRef } from "react"

const Confetti = () => {
    // the jsConfetti variable is declared as a ref using useRef().
    // Then, the useEffect() hook is used to initialize jsConfetti with a new instance of JSConfetti and trigger the animation only once when the component is mounted.
    const jsConfetti=useRef(null)
    useEffect(()=>{
        jsConfetti.current=new JSConfetti()
        jsConfetti.current.addConfetti({
            confettiColors: ['#BF40BF', '#CF9FFF', '#CCCCFF']
        });
    },[])
  return (
    <></>
  )
}

export default Confetti
