import SessionTwoTaskOne from "./SessionTwoTaskOne";
const SessionTwo = ({ sessionTwoTasks = [], sessionThreeTasks = [] }) => {
    const task1Submissios = sessionTwoTasks.filter(
        (task) => task.task_identifier === "task_1"
    );
    const task2Submissios = sessionTwoTasks.filter(
        (task) => task.task_identifier === "task_2"
    );

    const task1sessionThreeSubmissions = sessionThreeTasks.filter(
        (task) => task.task_identifier === "task_1"
    );

    const sortedSessionthreeTaskOne = task1sessionThreeSubmissions.sort(
        (a, b) => {
            return new Date(a.created) - new Date(b.created);
        }
    );

    const sortedTaskOne = task1Submissios.sort((a, b) => {
        return new Date(a.created) - new Date(b.created);
    });

    const sortedTaskTwo = task2Submissios.sort((a, b) => {
        return new Date(a.created) - new Date(b.created);
    });

    const latestTaskOne = sortedTaskOne[sortedTaskOne.length - 1];
    const latestTaskTwo = sortedTaskTwo[sortedTaskTwo.length - 1];
    const latestSessionThreeTaskOne =
        sortedSessionthreeTaskOne[sortedSessionthreeTaskOne.length - 1];

    return (
        <SessionTwoTaskOne
            latestTaskOne={latestTaskOne}
            latestTaskTwo={latestTaskTwo}
            latestSessionThreeTaskOne={latestSessionThreeTaskOne}
        />
    );
};

export default SessionTwo;
