import Button from "@components/Button";
import PageHeader from "@components/PageHeader";
import TeamList from "@src/containers/team/TeamList";
import { type Student } from "@src/models/types";
import { useRouteLoaderData } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/24/outline";
import useTeamActions from "@src/hooks/useTeamActions";
import { useQuery } from "@tanstack/react-query";
import { profileQueryOptions } from "@src/api/user";

const breadcrumbPaths = [{ name: "My Team", href: "/student/team" }];

const TeamPage = () => {
    const initialUser = useRouteLoaderData("root") as Student;

    const { data: user } = useQuery({
        ...profileQueryOptions<Student>(),
        initialData: initialUser,
    });

    const team = user.team();

    const { removeMember } = useTeamActions(user);

    async function leaveTeamAction() {
        if (team === null) {
            return;
        }

        if (!confirm(`Are you sure you want to leave '${team.team_name}'`)) {
            return;
        }

        await removeMember(user.id);
    }

    return (
        <section>
            <PageHeader title={"My Team"} breadcrumbPaths={breadcrumbPaths} />

            <div className={"max-w-7xl px-4 pb-16 pt-8 sm:px-6 md:px-8"}>
                {team !== null ? (
                    <section className="flex flex-col max-w-lg">
                        <header
                            className={
                                "flex h-24 items-center justify-center bg-orange-100"
                            }
                        ></header>

                        <div className={"flex-1 bg-white p-4"}>
                            <div className="flex items-center justify-between">
                                <div className="flex-1">
                                    <h2 className="text-2xl font-semibold text-gray-900">
                                        {team.team_name}
                                    </h2>
                                    <p
                                        className={
                                            "mt-1 text-lg leading-tight text-gray-700"
                                        }
                                    >
                                        {team.team_description}
                                    </p>
                                </div>

                                <div>
                                    {team.owner_id === user.id ? (
                                        <Button
                                            size="sm"
                                            variant="contained"
                                            intent="white"
                                            startIcon={PencilIcon}
                                            href={"/student/team/edit"}
                                        >
                                            Edit
                                        </Button>
                                    ) : (
                                        <Button
                                            size="sm"
                                            variant="outlined"
                                            intent="danger"
                                            onClick={leaveTeamAction}
                                        >
                                            Leave
                                        </Button>
                                    )}
                                </div>
                            </div>

                            <TeamList user={user} team={team} className="mt-4" />
                        </div>
                    </section>
                ) : (
                    <section
                        className={
                            "mx-auto flex max-w-3xl flex-col items-center justify-center rounded-md border-2 border-dashed p-12"
                        }
                    >
                        <p className={"text-xl font-medium"}>
                            You don't belong to any team.
                        </p>
                        <Button
                            href={"/student/team/create"}
                            className="mt-2"
                            type={"button"}
                            intent={"secondary"}
                        >
                            Create your team
                        </Button>
                    </section>
                )}
            </div>
        </section>
    );
};

export default TeamPage;
