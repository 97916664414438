import type React from "react";
import { Fragment, useMemo } from "react";
import Badge from "@components/tables/components/Badge";
import Table from "@components/tables/index";
import { type ColumnDef } from "@tanstack/react-table";
import {
    type TeamLeaderboardRecord,
    type Badge as LeaderboardBadge,
} from "@src/models/types";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";

const LeagueTeamLeaderboard: React.FC<{
    leaderboard: TeamLeaderboardRecord[];
}> = ({ leaderboard }) => {
    const columns: Array<ColumnDef<TeamLeaderboardRecord>> = [
        {
            header: "Rank",
            accessorKey: "rank",
            cell: ({ row }) => {
                return (
                    <div className="justify-self-center">
                        <Badge rank={`${row.index + 1}`} />
                    </div>
                );
            },
        },
        {
            header: "Name",
            accessorKey: "team_name",
            cell: ({ getValue, row }) => {
                const name = getValue() as string;
                return (
                    <div className="md:px-4">
                        <img
                            src={
                                row.original.team_logo !== null
                                    ? row.original.team_logo
                                    : "/images/team.png"
                            }
                            title={name}
                            className="mr-2 hidden h-8  w-8 rounded-full border-2 border-[#B990D5] md:inline-block md:h-12 md:w-12"
                        />
                        <span>{name}</span>
                    </div>
                );
            },
        },
        {
            header: "Points",
            accessorKey: "points",
            cell: ({ getValue, row }) => {
                return (
                    <Popover className="relative">
                        {({ open }) => (
                            <>
                                <Popover.Button as={Fragment}>
                                    <div
                                        className={clsx(
                                            "flex",
                                            "items-center",
                                            "justify-center"
                                        )}
                                    >
                                        <span className="mr-4 text-center text-primary-500">
                                            {getValue() as string}
                                        </span>
                                        <span>
                                            {open ? (
                                                <ArrowUpIcon className="h-4 w-4" />
                                            ) : (
                                                <ArrowDownIcon className="h-4 w-4" />
                                            )}
                                        </span>
                                    </div>
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute">
                                        <div className="w-max divide-y rounded-b-lg rounded-r-lg bg-amber-100 p-4">
                                            {row.original.members.map(
                                                (member, idx) => {
                                                    const name = `${
                                                        member.first_name
                                                    } ${member.last_name.at(
                                                        0
                                                    )}.`.trim();
                                                    return (
                                                        <div
                                                            key={`${row.id}-${idx}`}
                                                            className="w-100 flex items-center justify-between gap-8 py-2"
                                                        >
                                                            <div className="text-nowrap">
                                                                <img
                                                                    src={
                                                                        member.avatar !==
                                                                        null
                                                                            ? member.avatar
                                                                            : "/images/team.png"
                                                                    }
                                                                    title={name}
                                                                    className="mr-2 inline-block h-12 w-12 rounded-full border-2 border-[#B990D5]"
                                                                />
                                                                <span>
                                                                    {name}
                                                                </span>
                                                            </div>
                                                            <div className="text-nowrap">
                                                                <img
                                                                    src="/images/points.svg"
                                                                    title={`${name}'s points`}
                                                                    className="mr-2 inline-block h-5 w-5 rounded-full border-2 border-[#B990D5]"
                                                                />
                                                                <span>
                                                                    {
                                                                        member.points
                                                                    }{" "}
                                                                    points
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                );
            },
        },
        {
            header: "Badges",
            accessorKey: "badges",
            cell: ({ getValue }) => {
                const badges = getValue() as LeaderboardBadge[];
                return (
                    <div className="flex flex-row justify-self-center overflow-hidden">
                        {badges.length > 0 &&
                            badges.map((badge, idx) => (
                                <img
                                    key={idx}
                                    src={badge.poster_url}
                                    title={badge.name}
                                    className="mr-2 h-8 w-8 rounded-full"
                                />
                            ))}
                        {badges.length === 0 && <span>No Badges!</span>}
                    </div>
                );
            },
        },
    ];

    const leaderboardTableData = useMemo<TeamLeaderboardRecord[]>(() => {
        return leaderboard;
    }, []);

    return (
        <section>
            <Table
                columns={columns}
                data={leaderboardTableData}
                title="Team Leaderboard"
            />
        </section>
    );
};

export default LeagueTeamLeaderboard;
