import { cva, type VariantProps } from "class-variance-authority";
import { type BaseButtonVariantProps } from "./BaseButton";

export type OutlinedButtonVariantProps = BaseButtonVariantProps &
    VariantProps<typeof outlinedButton>;

const outlinedButton = cva("shadow-sm disabled:border-gray-400", {
    variants: {
        intent: {
            primary:
                "border-purple-700 text-purple-700 hover:bg-purple-700 hover:text-white",
            secondary: "border-purple-100 text-purple-700 hover:bg-purple-100",
            secondaryVariant: "border-slate-100 text-white hover:bg-slate-100",
            accent: "border-orange-700 text-orange-700 hover:bg-orange-700 hover:text-white",
            danger: "border-red-700 text-red-700 hover:bg-red-700 hover:text-white",
            white: "border-gray-300 text-gray-700 hover:bg-gray-50",
            primaryVariant:
                "border-orange-700 text-white hover:bg-orange-700 hover:text-white",
        },
    },
});

export default outlinedButton;
