import React, { useState } from "react";
import { cx } from "class-variance-authority";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const PasswordField = React.forwardRef<
    HTMLInputElement,
    React.InputHTMLAttributes<HTMLInputElement>
>(({ className, ...props }, ref) => {
    const [type, setType] = useState<"password" | "text">("password");

    function togglePasswordVisibility() {
        setType((prevType) => (prevType === "password" ? "text" : "password"));
    }

    return (
        <div className="relative mt-2 rounded-md shadow-sm">
            <input
                ref={ref}
                type={type}
                className={cx(
                    "block appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-purple-500 focus:outline-none focus:ring-purple-500",
                    className
                )}
                {...props}
            />
            <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <span className="sr-only">Toggle Password Visibility</span>
                {type === "text" ? (
                    <EyeSlashIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                    />
                ) : (
                    <EyeIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                    />
                )}
            </button>
        </div>
    );
});

export default PasswordField;
