import { RadioGroup } from "@headlessui/react";
import { Fragment, useState } from "react";
import clsx from "clsx";
import SubmissionFeedback from "../SubmissionFeedback";
import tasks from "../../../../containers/activities/SessionTwo/tasks_schema.json";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";

const SessionTwoSubmission = ({ data = [], sortOrder, session = {}, task }) => {
    const [noOfComments, setNoOfComments] = useState(0);
    const sessionTwo = data.find((session) => session.sort_order === sortOrder);

    const taskName = task[0];
    const taskData = sessionTwo.submissions[taskName];
    const taskAttributes = tasks.find((task) => task.name === taskName);
    const latestTask = taskData[0] || {};
    const task1Content = Object.values(latestTask.content).filter(
        (el) => typeof el === "object"
    );
    const [selected, setSelected] = useState("");

    const handleSubmisionClick = (value) => {
        setSelected(value);
    };

    if (taskName === "task_1") {
        if ((!latestTask || Object.values(latestTask), length == 0)) {
            return <p>Loading...</p>;
        }
        return (
            <SubmissionsTemplate
                latestTask={latestTask}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((step) => {
                        const { fields, question } = step;
                        const fieldData = fields[0];
                        const fieldName = fieldData.name;

                        return (
                            <>
                                <TaskTemplate question={question}>
                                    {task1Content.map((content, idx) => {
                                        const sdg = content.sdg;
                                        const value = content.value;
                                        console.log(sdg, value);
                                        return (
                                            <div
                                                className={`my-3 w-auto rounded-md p-4 ${
                                                    selected === value
                                                        ? `border border-purple-500`
                                                        : ""
                                                }  p-2`}
                                                onClick={() =>
                                                    handleSubmisionClick(value)
                                                }
                                            >
                                                <div className="flex flex-row items-center">
                                                    <img
                                                        src={
                                                            selected === value
                                                                ? "/images/problemImageFocus.svg"
                                                                : "/images/problemImage.svg"
                                                        }
                                                        className="aspect-square h-7"
                                                    />
                                                    <p
                                                        className={`ml-3 ${
                                                            selected === value
                                                                ? "text-purple-500"
                                                                : ""
                                                        }`}
                                                    >{`PROBLEM ${idx + 1}`}</p>
                                                </div>

                                                <p className="my-5 ml-3">
                                                    {value}
                                                </p>

                                                <div className="ml-3 flex flex-row items-center rounded-md bg-purple-100 p-2">
                                                    <div className="flex-0.2 rounded-full bg-purple-300 px-2 py-0.5 text-xs font-semibold text-slate-800">
                                                        SDG
                                                    </div>
                                                    <p className="flex-0.8 ml-4">
                                                        {sdg}
                                                    </p>
                                                </div>
                                                {selected !== value}
                                                <p className="my-7 text-sm text-slate-500">
                                                    {noOfComments === 1
                                                        ? `1 Comment`
                                                        : `${noOfComments} Comments`}
                                                </p>
                                                {selected !== value && <hr />}
                                            </div>
                                        );
                                    })}
                                </TaskTemplate>
                            </>
                        );
                    })}
                </>
            </SubmissionsTemplate>
        );
    } else {
        const task1Data = sessionTwo.submissions.task_1;

        const latestTask1 = task1Data[0];
        const latestTask2 = taskData[0];
        const studentEntry = taskData[0].content.focus_problem;

        const task2Content = Object.values(latestTask1.content).filter(
            (el) => typeof el === "object"
        );

        return (
            <SubmissionsTemplate
                latestTask={latestTask2}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((step, idx) => {
                        const { fields, hint, question } = step;
                        const fieldData = fields[0];

                        return (
                            <>
                                <TaskTemplate question={question}>
                                    <>

                                    
                                        <RadioGroup
                                            as={"ul"}
                                            className={"divide-y"}
                                            disabled
                                        >
                                            <RadioGroup.Label className="sr-only">
                                                {fieldData.label}
                                            </RadioGroup.Label>

                                            {task2Content.map((value, idx) => (
                                                <RadioGroup.Option
                                                    as={Fragment}
                                                    key={idx}
                                                    value={value.value}
                                                >
                                                    <li
                                                        className={
                                                            "flex divide-x bg-white"
                                                        }
                                                    >
                                                        <div className="flex items-center justify-center p-6">
                                                            <span
                                                                className={clsx(
                                                                    value.value ===
                                                                        studentEntry
                                                                        ? "border-transparent bg-purple-600"
                                                                        : "border-gray-300 bg-white",
                                                                    value.value ===
                                                                        studentEntry
                                                                        ? "ring-2 ring-purple-500 ring-offset-2"
                                                                        : "",
                                                                    "flex h-4 w-4 items-center justify-center rounded-full border"
                                                                )}
                                                                aria-hidden="true"
                                                            >
                                                                <span className="h-1.5 w-1.5 rounded-full bg-white" />
                                                            </span>
                                                        </div>

                                                        <div
                                                            className={
                                                                "flex items-center justify-center px-4 py-6 text-gray-700"
                                                            }
                                                        >
                                                            {fieldData.label
                                                                ? fieldData.label.replace(
                                                                      "$0",
                                                                      idx + 1
                                                                  )
                                                                : `Option ${
                                                                      idx + 1
                                                                  }`}
                                                        </div>

                                                        <div
                                                            className={
                                                                "flex-1 px-4 py-6 text-lg"
                                                            }
                                                        >
                                                            <p className="font-semibold">
                                                                {value.value}
                                                            </p>
                                                            <p>{value.sdg}</p>
                                                        </div>
                                                    </li>
                                                </RadioGroup.Option>
                                            ))}
                                        </RadioGroup>



                                    </>
                                </TaskTemplate>
                            </>
                        );
                    })}
                </>
            </SubmissionsTemplate>
        );
    }
};

export default SessionTwoSubmission;
