import axios from "axios";

const baseURL = import.meta.env.VITE_API_URL;

if (!baseURL) {
    throw new Error("Please provide the API_URL in the environment config.");
}

const instance = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-IDT": "B",
        XAT: "U",
    },
});

instance.interceptors.request.use((config) => {
    config.withCredentials = true;
    return config;
});

export default instance;
