import { LockClosedIcon } from "@heroicons/react/24/solid";
import { type FiresideChat, getAllFiresides } from "@src/api/firesides";
import PageHeader from "@src/components/PageHeader";
import { AccountStatus } from "@src/models";
import { type User } from "@src/models/types";
import { type LoaderReturnType } from "@src/utils/type_helpers";
import { type QueryClient } from "@tanstack/query-core";
import clsx from "clsx";
import { useCallback, type ComponentProps } from "react";
import { useLoaderData, useRouteLoaderData } from "react-router";
import { Link } from "react-router-dom";

// TODO: switch to better queryKey abstractions
function firesideQueryOptions() {
    return {
        queryKey: ["firesides"],
        queryFn: async () => await getAllFiresides(),
    };
}

export function loader(queryClient: QueryClient) {
    return async () => {
        const firesides = await queryClient.ensureQueryData(
            firesideQueryOptions()
        );

        return firesides;
    };
}

const breadcrumbPaths = [
    {
        name: "Fireside Chats",
        href: "/students/firesides",
    },
];

const FiresideGallery = () => {
    const user = useRouteLoaderData("root") as User;
    const isFreeTrial = user.account_status === AccountStatus.FREE_TRIAL;

    const firesides = useLoaderData() as LoaderReturnType<typeof loader>;

    return (
        <>
            <PageHeader
                title={"Fireside Chats"}
                breadcrumbPaths={breadcrumbPaths}
            />

            <section className="px-10 pt-10 pb-20">
                {/* When no videos are in the library */}
                {firesides.length === 0 ? (
                    <EmptyState className="mt-10" />
                ) : (
                    <>
                        <h3 className="text-3xl font-semibold">
                            Discover hot topics
                        </h3>

                        {/* Normal fireside list */}
                        {firesides.length > 0 && (
                            <section className="grid w-full mt-10 gap-x-8 gap-y-12 md:grid-cols-2 lg:grid-cols-3">
                                {firesides.map((fireside, idx) => (
                                    <CardContainer
                                        key={idx}
                                        fireside={fireside}
                                        className="relative flex flex-col overflow-hidden shadow-sm rounded-xl"
                                    >
                                        {isFreeTrial && fireside.is_premium && (
                                            <div className="absolute inset-0 bg-gray-900/30"></div>
                                        )}

                                        <section className="aspect-video h-72 bg-purple-50">
                                            <img
                                                className="object-cover w-full h-full mx-auto"
                                                src={
                                                    fireside.poster_image ?? ""
                                                }
                                                alt={`${fireside.title} Poster`}
                                            />
                                        </section>

                                        <section
                                            className={clsx(
                                                "flex flex-1 flex-col justify-between pl-8 pr-6 pb-10 pt-8",
                                                isFreeTrial &&
                                                    fireside.is_premium
                                                    ? "bg-gray-50"
                                                    : "bg-white"
                                            )}
                                        >
                                            <section className="flex items-center">
                                                <h3 className="flex-1 text-2xl font-semibold">
                                                    {fireside.title}
                                                </h3>

                                                {isFreeTrial &&
                                                    fireside.is_premium && (
                                                        <div className="pl-2">
                                                            <LockClosedIcon
                                                                title="Not available on free trial"
                                                                className="w-8 h-8 text-gray-500"
                                                            />
                                                        </div>
                                                    )}
                                            </section>

                                            <section className="flex items-center justify-between mt-4">
                                                <div className="text-lg text-gray-800">
                                                    <p className="font-semibold">
                                                        {fireside.speaker.name}
                                                    </p>
                                                    <p className="text-gray-500">
                                                        {fireside.speaker.title}
                                                    </p>
                                                </div>
                                                <div className="flex items-center">
                                                    <span className="rounded bg-purple-900 p-1.5 text-white">
                                                        {
                                                            fireside.duration_in_minutes
                                                        }{" "}
                                                        min
                                                    </span>
                                                </div>
                                            </section>
                                        </section>
                                    </CardContainer>
                                ))}
                            </section>
                        )}
                    </>
                )}
            </section>
        </>
    );
};

export default FiresideGallery;

function EmptyState({ className }: ComponentProps<"section">) {
    return (
        <section
            className={clsx(
                "mx-auto max-w-2xl rounded-lg bg-white p-8 shadow-md",
                className
            )}
        >
            <svg
                className={"mx-auto h-64 w-64"}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 797.5 834.5"
                data-name="Layer 1"
            >
                <ellipse
                    cx="308.5"
                    cy="780"
                    fill="#3f3d56"
                    rx="308.5"
                    ry="54.5"
                />
                <circle cx="496" cy="301.5" r="301.5" fill="#3f3d56" />
                <circle cx="496" cy="301.5" r="248.898" opacity=".05" />
                <circle cx="496" cy="301.5" r="203.994" opacity=".05" />
                <circle cx="496" cy="301.5" r="146.26" opacity=".05" />
                <path
                    fill="#d0cde1"
                    d="M197.17 328.482s-23.704 66.722-13.169 90.426 27.216 46.53 27.216 46.53-6.145-132.566-14.047-136.956Z"
                />
                <path
                    d="M197.17 328.482s-23.704 66.722-13.169 90.426 27.216 46.53 27.216 46.53-6.145-132.566-14.047-136.956Z"
                    opacity=".1"
                />
                <path
                    fill="#2f2e41"
                    d="M213.85 482.997s-1.755 16.68-2.633 17.558.878 2.634 0 5.268-1.756 6.145 0 7.023-9.657 78.135-9.657 78.135-28.094 36.873-16.68 94.816l3.511 58.821s27.216 1.756 27.216-7.901c0 0-1.756-11.413-1.756-16.68s4.39-5.268 1.756-7.902-2.634-4.39-2.634-4.39 4.39-3.511 3.512-4.39 7.9-63.21 7.9-63.21 9.658-9.657 9.658-14.925v-5.267s4.39-11.413 4.39-12.291 23.704-54.431 23.704-54.431l9.657 38.628 10.535 55.31s5.267 50.041 15.803 69.356c0 0 18.436 63.21 18.436 61.454s30.727-6.145 29.85-14.047-18.437-118.52-18.437-118.52l4.39-164.171Z"
                />
                <path
                    fill="#2f2e41"
                    d="M190.147 740.228s-23.704 46.53-7.901 48.286 21.948 1.756 28.971-5.267c3.84-3.84 11.615-8.992 17.876-12.873a23.117 23.117 0 0 0 10.969-21.982c-.463-4.295-2.068-7.834-6.019-8.164-10.535-.877-22.826-10.535-22.826-10.535ZM320.958 774.467s-23.704 46.53-7.902 48.286 21.948 1.756 28.972-5.267c3.84-3.84 11.615-8.992 17.875-12.873a23.117 23.117 0 0 0 10.97-21.982c-.464-4.295-2.069-7.834-6.02-8.164-10.534-.877-22.825-10.535-22.825-10.535Z"
                />
                <circle cx="295.905" cy="215.433" r="36.905" fill="#ffb8b8" />
                <path
                    fill="#ffb8b8"
                    d="M272.18 227.558s-26.36 48.504-28.469 48.504 47.449 15.816 47.449 15.816 13.707-46.395 15.816-50.612Z"
                />
                <path
                    fill="#d0cde1"
                    d="M312.617 280.635s-52.675-28.971-57.943-28.093-61.454 50.042-60.576 70.234 7.9 53.553 7.9 53.553 2.635 93.06 7.902 93.938-.878 16.68.878 16.68 122.91 0 123.787-2.633-21.948-203.679-21.948-203.679Z"
                />
                <path
                    fill="#ffb8b8"
                    d="M342.028 489.142s16.68 50.92 2.633 49.164-20.192-43.896-20.192-43.896Z"
                />
                <path
                    fill="#d0cde1"
                    d="M297.254 277.563s-32.484 7.023-27.216 50.92 14.925 87.792 14.925 87.792l32.483 71.111 3.512 13.17 23.703-6.146-17.558-101.84s-6.145-108.862-14.047-112.374a34 34 0 0 0-15.802-2.633Z"
                />
                <path
                    d="m277.5 414.958 40.385 71.989-34.025-75.857-6.36 3.868z"
                    opacity=".1"
                />
                <path
                    fill="#2f2e41"
                    d="m332.646 204.566.122-2.82 5.61 1.396a6.27 6.27 0 0 0-2.514-4.615l5.976-.334a64.477 64.477 0 0 0-43.124-26.652c-12.926-1.873-27.319.838-36.182 10.43-4.3 4.654-7.001 10.571-8.923 16.608-3.539 11.118-4.26 24.372 3.12 33.41 7.5 9.184 20.602 10.984 32.406 12.12 4.153.4 8.506.772 12.354-.839a29.721 29.721 0 0 0-1.653-13.037 8.687 8.687 0 0 1-.88-4.152c.525-3.512 5.21-4.397 8.728-3.922s7.75 1.2 10.062-1.495c1.593-1.856 1.499-4.559 1.71-6.995.574-6.634 13.128-7.712 13.188-9.103Z"
                />
                <circle cx="559" cy="744.5" r="43" fill="#6f3894" />
                <circle cx="54" cy="729.5" r="43" fill="#6f3894" />
                <circle cx="54" cy="672.5" r="31" fill="#6f3894" />
                <circle cx="54" cy="624.5" r="22" fill="#6f3894" />
            </svg>
            <p className="mt-8 text-2xl font-medium text-center text-gray-600">
                Our fireside library is currently empty, but we're working hard
                to add more content.
            </p>
        </section>
    );
}

interface CardContainerProps {
    fireside: FiresideChat;
    className: string;
    children: React.ReactNode;
}

function CardContainer({ fireside, className, children }: CardContainerProps) {
    const user = useRouteLoaderData("root") as User;

    const isFreeTrial = user.account_status === AccountStatus.FREE_TRIAL;

    const isClickTarget = useCallback(() => {
        if (!isFreeTrial) {
            return true;
        }

        return !fireside.is_premium;
    }, [fireside]);

    return isClickTarget() ? (
        <Link
            to={`/student/firesides/${fireside.slug}`}
            className={clsx(
                className,
                "hover:shadow-md hover:ring-2 hover:ring-purple-400 focus:ring-purple-200"
            )}
        >
            {children}
        </Link>
    ) : (
        <article className={className}>{children}</article>
    );
}
