import useStore from "@store/useStore";
import NotificationsPopover from "./Navbar/NotificationsPopover";
import UserAvatarMenu from "./Navbar/UserAvatarMenu";

interface PageHeaderProps {
    title: string;
    actions?: React.ReactNode;
}

export default function PageHeaderV2({
    title,
}: PageHeaderProps) {
    const user = useStore((state) => state.user);

    return (
        <header
            data-cy="page-header"
            className="bg-purple-200 px-4 pb-6 pt-3 sm:px-6 md:px-8"
        >
            <div className="mt-4 md:flex md:items-center md:justify-between">
                <div className="min-w-0 flex-1">
                    <h2 className="inline-flex items-center space-x-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight text-purple-700">
                        <span>{title}</span>
                    </h2>
                </div>

                <div className="flex items-center">
                    <NotificationsPopover />
                    {user && <UserAvatarMenu />}
                    {user && <p className="uppercase ml-5 text-purple-700 font-semibold" >{user.full_name}</p>}
                </div>
            </div>
        </header>
    );
}
