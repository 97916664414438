import React from "react";
const dispatchStorageEvent = (key: string, newValue: string | null) => {
    window.dispatchEvent(new StorageEvent("storage", { key, newValue }));
};

const setSessionStorageItem = (key: string, value: unknown) => {
    const stringifiedValue = JSON.stringify(value);
    window.sessionStorage.setItem(key, stringifiedValue);
    dispatchStorageEvent(key, stringifiedValue);
};

const removeSessionStorageItem = (key: string) => {
    window.sessionStorage.removeItem(key);
    dispatchStorageEvent(key, null);
};

const getSessionStorageItem = (key: string) => {
    return window.sessionStorage.getItem(key);
};

const useSessionStorageSubscribe = (callback: () => any) => {
    window.addEventListener("storage", callback);
    return () => {
        window.removeEventListener("storage", callback);
    };
};

const getSessionStorageServerSnapshot = () => {
    throw Error("useSessionStorage is a client-only hook");
};

export function useSessionStorage<TValue = unknown>(
    key: string,
    initialValue: TValue
) {
    const getSnapshot = () => getSessionStorageItem(key);

    const store = React.useSyncExternalStore(
        useSessionStorageSubscribe,
        getSnapshot,
        getSessionStorageServerSnapshot
    );

    const setState = React.useCallback(
        (v: TValue) => {
            try {
                const nextState =
                    typeof v === "function" ? v(JSON.parse(store ?? "")) : v;

                if (nextState === undefined || nextState === null) {
                    removeSessionStorageItem(key);
                } else {
                    setSessionStorageItem(key, nextState);
                }
            } catch (e) {
                console.warn(e);
            }
        },
        [key, store]
    );

    React.useEffect(() => {
        if (
            getSessionStorageItem(key) === null &&
            typeof initialValue !== "undefined"
        ) {
            setSessionStorageItem(key, initialValue);
        }
    }, [key, initialValue]);

    return [
        store != null ? (JSON.parse(store) as TValue) : initialValue,
        setState,
    ] as const;
}
