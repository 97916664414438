import Button from "@components/Button";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import TeamList from "../team/TeamList";
import Confetti from "@src/components/Confetti/Confetti";
import { useQuery } from "@tanstack/react-query";
import { profileQueryOptions } from "@api/user";
import type { Student } from "@src/models/types";

interface TeamSummaryTabProps {
    next: () => void;
    previous: () => void;
}

const TeamSummaryTab = React.forwardRef<HTMLDivElement, TeamSummaryTabProps>(
    ({ previous }, ref) => {
        const initialUser = useRouteLoaderData("root") as Student;

        const { data: user } = useQuery({
            ...profileQueryOptions<Student>(),
            initialData: initialUser,
        });

        const team = user.team();

        if (team == null) {
            return "Loading";
        }

        return (
            <div ref={ref} className="px-4 py-6 sm:px-6 md:px-8">
                <div>
                    <div>
                        <Button size={"sm"} intent={"white"} onClick={previous}>
                            <ArrowLeftIcon className={"h-6 w-6"} />
                            <span className={"ml-1 text-lg"}>
                                Edit Team Members
                            </span>
                        </Button>
                    </div>
                </div>
                {/* Adds the confetti animation */}
                <Confetti />
                <section className="flex flex-col max-w-md mx-auto">
                    <div
                        className={
                            "flex aspect-video h-52 flex-col items-center justify-center bg-green-50"
                        }
                    >
                        <div>
                            <CheckCircleIcon
                                className={"h-16 w-16 text-green-800"}
                            />
                        </div>
                        <h2 className={"text-xl font-medium"}>
                            Team Setup Complete
                        </h2>
                        <p className={"text-gray-700"}>
                            You have successfully created your team.
                        </p>
                    </div>

                    <div className="flex-1 p-4 bg-white">
                        <h3 className={"text-xl font-semibold text-gray-900"}>
                            {team.team_name}
                        </h3>
                        <p className={"text-gray-700"}>
                            {team.team_description}
                        </p>

                        <TeamList user={user} team={team} className="mt-4" />
                    </div>
                </section>

                <div
                    className={
                        "mt-4 flex justify-end rounded-md px-4 pb-6 shadow sm:px-6 md:px-8"
                    }
                >
                    <Button href={"/student/content"}>
                        Get started on the sessions
                    </Button>
                </div>
            </div>
        );
    }
);

export default TeamSummaryTab;
