import PageHeader from "@src/components/PageHeader";
import { type SchoolAdmin } from "@src/models/types";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import TeamSubmissionsTable from "@src/components/tables/TeamSubmissionsTable";
import { getTeamSubmissionsSummaryQuery } from "@src/api/team-submissions";
import { type TeamSubmissionSummary } from "@src/models/types";
import { useQuery, type QueryClient } from "@tanstack/react-query";
import { LoaderReturnType } from "@src/utils/type_helpers";

export const loader = (QueryClient: QueryClient) => {
    return async () => {
        return await QueryClient.ensureQueryData(
            getTeamSubmissionsSummaryQuery()
        );
    };
};

const TeamSubmissionsDashboard = () => {
    const user = useRouteLoaderData("root") as SchoolAdmin;

    const initialData = useLoaderData() as LoaderReturnType<typeof loader>;
    // console.log("INITIAL DATA ---> ", initialData);

    const {
        data: teamSubmissionsSummary,
        isLoading,
        error,
    } = useQuery({
        ...getTeamSubmissionsSummaryQuery(),
        initialData,
    });

    return (
        <div>
            <PageHeader title={user.school.school_name} breadcrumbPaths={[]} />
            <section className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                <h2 className="mb-4 mt-8 text-2xl font-bold leading-6 text-purple-900">
                    Team Submissions
                </h2>
                <TeamSubmissionsTable data={teamSubmissionsSummary} />
            </section>
        </div>
    );
};
export default TeamSubmissionsDashboard;
