import { getSessions, sessionSubmissionsQuery } from "@src/api/sessions";
import { getTeam } from "@src/api/teams";
import PageHeader from "@src/components/PageHeader";
import { useCallback, useState } from "react";
import { type QueryClient, useQuery } from "@tanstack/react-query";
import {
    type LoaderFunctionArgs,
    useLoaderData,
    useParams,
} from "react-router-dom";
import { type LoaderReturnType } from "@src/utils/type_helpers";
import TeamData from "@src/components/Team/TeamData";
import TeamSessions from "./TeamSessions";
import { getTeamSubmissionsQuery } from "@src/api/team-submissions";

function teamDetailQuery(teamId: string) {
    return {
        queryKey: ["teams", "detail", teamId],
        queryFn: async () => await getTeam(parseInt(teamId)),
    };
}

function sessionsQuery() {
    return {
        queryKey: ["sessions", "list"],
        queryFn: getSessions,
    };
}

export function loader(queryClient: QueryClient) {
    return async function ({ params }: LoaderFunctionArgs) {
        const team = await queryClient.ensureQueryData(
            teamDetailQuery(params?.team_id ?? "")
        );

        const sessions = await queryClient.ensureQueryData(sessionsQuery());

        return { team, sessions };
    };
}

export const submissionsLoader = (queryClient: QueryClient) => {
    return async ({ params }: LoaderFunctionArgs) => {
        return await queryClient.ensureQueryData(
            getTeamSubmissionsQuery(params?.team_id ?? "")
        );
    };
};

const TeamSubmissionsPage: React.FC = () => {
    const { team: teamInitialData, sessions: sessionsInitialData } =
        useLoaderData() as LoaderReturnType<typeof loader>;

    // useLoaderData - team submissions
    const initialTeamSubmissions = useLoaderData() as LoaderReturnType<
        typeof submissionsLoader
    >;

    const params = useParams();

    const { data: team } = useQuery({
        ...teamDetailQuery(params.team_id ?? ""),
        initialData: teamInitialData,
    });

    // for team submissions
    const {
        data: teamSubmissions,
        isLoading,
        error,
    } = useQuery({
        ...getTeamSubmissionsQuery(params.team_id ?? ""),
        initialTeamSubmissions,
    });

    const sortedSessions =
        teamSubmissions != null
            ? teamSubmissions.sessions.sort((a, b) => {
                  if (a.session_details && b.session_details) {
                      return (
                          a.session_details.sort_order -
                          b.session_details.sort_order
                      );
                  }
              })
            : [];

    const { data: sessions } = useQuery({
        ...sessionsQuery(),
        initialData: sessionsInitialData,
    });

    const breadcrumbPaths = [
        {
            name: "Team Submissions",
            href: `/school/teams/${team.id}/submissions`,
        },
    ];

    interface QueryOptions {
        sessionId: string;
        studentId: string;
        taskIdentifier: string;
    }

    const [queryOptions, setQueryOptions] = useState<QueryOptions>({
        sessionId: "1",
        studentId: "",
        taskIdentifier: "task_1",
    });

    function updateQueryOption<Key extends keyof QueryOptions>(
        property: Key,
        newValue: QueryOptions[Key]
    ) {
        setQueryOptions((currentValue) => ({
            ...currentValue,
            [property]: newValue,
        }));
    }

    const activeSession = sessions?.find(
        (session) => session.id === parseInt(queryOptions.sessionId)
    );

    const activeSessionIsSessionOne =
        activeSession != null && activeSession?.sort_order === 1;

    const enableSubmissionsQuery = activeSessionIsSessionOne
        ? Boolean(queryOptions.studentId)
        : Boolean(activeSession);

    const { data: submissions } = useQuery({
        ...sessionSubmissionsQuery(
            parseInt(queryOptions.sessionId),
            parseInt(queryOptions.studentId)
        ),
        enabled: enableSubmissionsQuery,
        initialData: {},
    });

    const selectedTaskEntries = submissions[queryOptions.taskIdentifier];
    const [selectedEntryIndex, setSelectedEntryIndex] = useState<number>(0);

    const getStudentDetails = useCallback((studentId: number) => {
        return team.members.find((student) => student.id === studentId);
    }, []);

    return (
        <>
            <PageHeader
                title={"Team Submissions"}
                breadcrumbPaths={breadcrumbPaths}
            />

            <section className="mx-auto max-w-7xl space-y-8 px-4 pb-12 pt-6 sm:px-6 md:px-8">
                {/* Team Summary */}

                <TeamData team={team} />

                {/* Submission Values Display */}

                <section className="max-w-5xl rounded-lg bg-white px-4 py-6 shadow sm:px-6 md:px-8">
                    <TeamSessions sessions={sortedSessions} team={team} />
                </section>
            </section>
        </>
    );
};

export default TeamSubmissionsPage;
