import { useState, useEffect } from "react";
import SubmissionFeedback from "./SubmissionFeedback";

const SubmissionsTemplate = ({ children, latestTask, setNoOfComments }) => {
    const [numberOfComments, setNumberOfComments] = useState(0);

    useEffect(() => {
        setNoOfComments(numberOfComments);
    }, [numberOfComments]);

    return (
        <>
            <div className="flex w-full flex-row items-start justify-between pt-3">
                {/* Market Segements */}
                <div className="bg mr-3 flex flex-1 flex-col p-2">
                    {children}
                </div>

                <SubmissionFeedback
                    submissionId={latestTask.id}
                    setNumberOfComments={setNumberOfComments}
                />
            </div>
        </>
    );
};

export default SubmissionsTemplate;
