import { AccountStatus, SessionStatus } from "@src/models";
import { type User, type StudentSession } from "@src/models/types";
import { cva } from "class-variance-authority";
import { Link, useRouteLoaderData } from "react-router-dom";
import { CheckCircleIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

interface SessionCardProps {
    session: StudentSession;
}

const SessionCard = ({ session }: SessionCardProps) => {
    const user = useRouteLoaderData("root") as User;

    const isFreeTrial = user.account_status === AccountStatus.FREE_TRIAL;

    return (
        <SessionCardContainer session={session} user={user}>
            <div className="relative items-center justify-center w-2/5 bg-white aspect-square md:flex md:aspect-video md:w-full md:px-4 md:py-3">
                {session.status === SessionStatus.DONE && (
                    <div className="absolute inset-0 bg-green-300/30">
                        <CheckCircleIcon className="w-6 h-6 mt-1 ml-auto mr-1 text-green-600" />
                    </div>
                )}
                <img
                    className="object-contain w-full h-full p-5 sesssion-image md:h-fit md:w-24 md:p-0"
                    src={session.poster_image}
                    alt={session.title}
                />
            </div>
            <div
                className={clsx(
                    "flex grow flex-col justify-center px-2 py-3 md:flex-row md:items-center md:justify-between",
                    {
                        "bg-purple-100":
                            session.status === SessionStatus.IN_PROGRESS,
                        "bg-gray-100":
                            session.status === SessionStatus.NOT_STARTED,
                    }
                )}
            >
                <div className="pr-1 truncate">
                    <p className="text-sm font-bold uppercase">
                        Session {session.sort_order}
                    </p>
                    <p title={session.title}>{session.title}</p>
                </div>
                <div className="flex-shrink-0 mt-4 md:mt-0">
                    {isFreeTrial && session.sort_order > 3 ? (
                        <LockClosedIcon className="w-6 h-6 text-gray-800" />
                    ) : (
                        <>
                            {session.status === SessionStatus.NOT_STARTED && (
                                <LockClosedIcon className="w-6 h-6 text-gray-800" />
                            )}
                            {session.status === SessionStatus.IN_PROGRESS && (
                                <span className="font-bold text-orange-600">
                                    0%
                                </span>
                            )}
                            {session.status === SessionStatus.DONE && (
                                <span className="font-bold text-green-600">
                                    100%
                                </span>
                            )}
                        </>
                    )}
                </div>
            </div>
        </SessionCardContainer>
    );
};

export default SessionCard;

interface SessionCardContainerProps {
    session: StudentSession;
    children: React.ReactNode;
    className?: string;
    user: User;
}

function SessionCardContainer({
    session,
    children,
    className = "",
    user,
    ...props
}: SessionCardContainerProps) {
    const { status, session_id: sessionId } = session;

    const isFreeTrial = user.account_status === AccountStatus.FREE_TRIAL;

    function isClickTarget() {
        if (user.is_staff) {
            return true;
        }

        if (isFreeTrial && session.sort_order > 3) {
            return false;
        }

        return status !== SessionStatus.NOT_STARTED;
    }

    const container = cva(
        "flex h-40 md:h-full md:flex-col border rounded-md shadow overflow-hidden transition ring-offset-2  group",
        {
            variants: {
                clickTarget: { true: "", false: "" },
                status: {
                    [SessionStatus.DONE]:
                        "border-green-600 focus:ring-green-600 hover:ring-2 hover:ring-green-600",
                    [SessionStatus.IN_PROGRESS]: "border-gray-100",
                    [SessionStatus.NOT_STARTED]: "border-gray-100",
                },
            },

            compoundVariants: [
                {
                    clickTarget: true,
                    status: SessionStatus.IN_PROGRESS,
                    class: "focus:ring-blue-300 hover:ring-2 hover:ring-blue-300",
                },
            ],
        }
    );

    const containerClasses = container({
        clickTarget: isClickTarget(),
        status,
        class: className,
    });

    return !isClickTarget() ? (
        <article className={containerClasses} {...props}>
            {children}
        </article>
    ) : (
        <Link
            to={`/student/content/${sessionId}/objectives`}
            className={containerClasses}
            {...props}
        >
            {children}
        </Link>
    );
}
