import { Outlet, useNavigation } from "react-router-dom";
import { useEffect } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import * as Sentry from "@sentry/react";
import RootErrorPage from "./error/RootErrorPage";

export default function LoadingIndicator() {
    const navigation = useNavigation();

    useEffect(() => {
        if (["loading", "submitting"].includes(navigation.state)) {
            NProgress.start();
        } else {
            NProgress.done();
        }

        return () => {
            NProgress.done();
        };
    }, [navigation.state]);

    return (
        <Sentry.ErrorBoundary fallback={<RootErrorPage/>}>
            <Outlet />
        </Sentry.ErrorBoundary>
    );
}
