import PageHeader from "@src/components/PageHeader";
import { EyeIcon, ChatBubbleLeftIcon } from "@heroicons/react/24/solid";
import Avatar from "@src/components/Avatar";
import { useNavigate } from "react-router-dom";
import Button from "@src/components/Button";
import { StarAction } from "@src/components/Forum/StarAction";
import { useQuery } from "@tanstack/react-query";
import { postsQuery } from "@src/api/forums";
import { ClipboardDocumentListIcon } from "@heroicons/react/20/solid";

export interface Author {
    name: string,
    points: number,
    icon: string | undefined,
    badges: any[]
}

export interface Post{
    id: number,
    title: string,
    votes: number,
    commentsCount: number,
    views: number,
    created: string,
    createdBy: Author,
    content: string,
}


const Feed = () => {
    const navigate = useNavigate();
    const {data, isLoading} = useQuery({...postsQuery()});

    if (isLoading) {
        return (
            <div className="m-11 flex w-full items-center justify-center">
                <span className="loader"></span>
            </div>
        );
    }

    if (data === undefined || data.results.length == 0) {
        return (
            <div className="flex justify-center">
                <div className="flex w-1/2 flex-col items-center justify-center gap-4">
                    <ClipboardDocumentListIcon className="h-56 w-56 stroke-1 text-slate-300" />
                    <p className="text-center text-2xl">No data found</p>
                </div>
            </div>
        );
    }

    let posts = data!.results;

    return (
        <>
            <PageHeader
                title="Posts"
                breadcrumbPaths={[]}
                actions={(
                    <Button
                        size="lg"
                        intent="primary"
                        href="/student/forum/create">
                            Create Post
                    </Button>
                )}
            />

            <section className="bg-white pl-10 h-fit overflow-auto">
                {
                    posts.map((post, idx) => (
                        <section 
                            className="py-5 border border-solid border-x-0 px-10 hover:bg-purple-100 focus:bg-purple-100"
                            key={post.id}>
                            <div 
                                className="font-bold text-2xl cursor-pointer"
                                onClick={() => navigate(`/student/forum/${post.id}/`, {state: post})}>
                                    {post.title}
                            </div>
                            <div className="my-5" dangerouslySetInnerHTML={{__html: post.content}}/>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-row space-x-2">
                                    <StarAction initialVotes={post.votes} postID={post.id} voted_={post.voted_by_current_user}/>
                                    <div className="flex flex-row bg-purple-100 p-2 rounded-lg">
                                        <EyeIcon className="w-6 h-6 pr-1 text-purple-500"/>
                                        {post.views}
                                    </div>
                                    <div 
                                        className="flex flex-row bg-purple-100 hover:bg-purple-200 p-2 rounded-lg" 
                                        onClick={()=>navigate(`/student/forum/${post.id}/?scrollToComments=true`, {state: post})}
                                    >
                                        <ChatBubbleLeftIcon className="w-6 h-6 pr-1 text-purple-500"/>
                                        {post.comments_count}
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between items-center">
                                    <Avatar src={post.created_by.user.avatar || undefined} name={post.created_by.user.full_name} size="28"/>
                                    <div className="flex flex-row mx-2">
                                        {post.created_by.user.full_name}
                                    </div>
                                    {post.created_by.rank_summary.points || 0} <p className="text-orange-400">XP</p>
                                </div>
                            </div>
                        </section>
                    ))
                }
            </section>
        </>
    )
}

export default Feed;
