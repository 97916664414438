import { RichEditor } from "@src/components/Form";
import PageHeader from "@src/components/PageHeader";
import TiptapEditor from "components/Form/TiptapEditor/TiptapEditor";
import Button from "components/Button";
import { useState } from "react"
import { newPost } from "@src/api/forums";
import { useNavigate } from "react-router-dom";

const breadcrumbPaths = [
    {
        name: "Feed",
        href: "/student/forum",
    },
];


const CreatePost = () => {
    const [content, setContent]  = useState("");
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const createPost = () => {
        try {
            setLoading(true);
            newPost({title, content}).then((post) => {
                setLoading(false);
                navigate(`/student/forum/${post.id}/`, {state: post})
            });
        }
        catch(error) {

        }
    }

    return (
        <>
            <PageHeader
                title="Create Post"
                breadcrumbPaths={breadcrumbPaths}
            />

            <section className="px-20 mt-5">
                <div className="w-full pb-10">
                    <h2 className=" font-bold text-3xl">Title</h2>
                    <input type="text" onChange={(e) => setTitle(e.target.value)} className="w-full text-2xl"/>
                </div>


                <h2 className=" font-bold text-3xl">Content</h2>
                <TiptapEditor height="24rem" content="" onChange={setContent}/>
                
                <div className="mt-3 flex items-center justify-end">
                    <Button onClick={createPost}>
                        {loading? <span className="loader"/>: "Add Post"}
                    </Button>
                </div>
            </section>
        </>
    )
}


export default CreatePost;
