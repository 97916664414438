import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { type Student } from "@src/models/types";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import PointsIcon from "@src/components/Icons/Points";
import PieIcon from "@src/components/Icons/Pie";
import RankingIcon from "@src/components/Icons/Ranking";
import { useQuery } from "@tanstack/react-query";
import { teamScoreQuery } from "@src/api/league";
import { type AxiosError } from "axios";
import { UsersIcon } from "@heroicons/react/24/solid";

interface OverviewProps {
    students: Student[];
    selectedStudent: Student | null;
    setSelectedStudent: (student: Student | null) => void;
}

export default function Overview({
    students,
    selectedStudent,
    setSelectedStudent,
}: OverviewProps) {
    console.log("SELECTED STUDENT ---> ", selectedStudent);
    const {
        data: teamData,
        error: teamDataError,
        isError,
        isLoading,
    } = useQuery({
        ...teamScoreQuery((selectedStudent as Student).teams[0]?.id),
        enabled: selectedStudent !== null,
    });

    return (
        <section>
            {selectedStudent !== null ? (
                <>
                    <h2 className="text-2xl font-semibold">Overview</h2>
                    <div className="mt-5 grid w-full grid-cols-12 gap-4">
                        <section className="col-span-12 rounded-lg border border-gray-200 bg-white px-12 pb-4 pt-8 lg:col-span-4">
                            <h3 className="text-lg text-gray-800">Student</h3>
                            <hr className="w-5 rounded-lg border border-orange-600" />

                            <div className="mt-6 flex flex-1 gap-4">
                                <img
                                    src={
                                        selectedStudent.avatar != null
                                            ? selectedStudent.avatar
                                            : selectedStudent.gender === "F"
                                            ? "/images/avatar-f.png"
                                            : "/images/avatar-m.png"
                                    }
                                    className="h-16 w-16 flex-shrink-0 rounded-full"
                                />
                                <div className="">
                                    <p className="text-xl font-bold">
                                        {selectedStudent.full_name}
                                    </p>
                                    <p className="text-lg font-semibold text-gray-600">
                                        {selectedStudent.school?.school_name}
                                    </p>
                                    <p className="text-lg font-semibold text-gray-400">
                                        {selectedStudent.email}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-6 flex flex-wrap items-center justify-evenly gap-4">
                                <div className="flex items-center">
                                    <RankingIcon className="h-6 w-6" />
                                    <p className="ml-2 text-base font-bold text-gray-800">
                                        {
                                            teamData?.members.find(
                                                (member) =>
                                                    member.id ===
                                                    selectedStudent?.id
                                            )?.cohort_rank
                                        }
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <PieIcon className="h-6 w-6" />
                                    <p className="ml-2 text-base font-bold text-gray-800">
                                        {Math.round(
                                            (selectedStudent.points /
                                                selectedStudent.total_points) *
                                                100
                                        )}{" "}
                                        %
                                    </p>
                                </div>

                                <div className="flex items-center">
                                    <PointsIcon
                                        className={"h-6 w-6 stroke-2"}
                                    />
                                    <p className="ml-2 text-base font-bold text-gray-800">
                                        {selectedStudent?.points} points
                                    </p>
                                </div>
                            </div>

                            <div className="mt-12 w-full">
                                <div className="rounded-md border border-gray-400 ">
                                    <Listbox
                                        value={selectedStudent}
                                        onChange={setSelectedStudent}
                                    >
                                        <div className="relative mt-1">
                                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                                <span className="block truncate">
                                                    Switch Student
                                                </span>

                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Listbox.Button>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute mt-1 max-h-60 w-full divide-y overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {students?.map(
                                                        (
                                                            student,
                                                            studentIdx
                                                        ) => (
                                                            <Listbox.Option
                                                                key={studentIdx}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    `relative cursor-default select-none px-6 py-4 ${
                                                                        active
                                                                            ? "bg-purple-100 text-amber-900"
                                                                            : "text-gray-900"
                                                                    }`
                                                                }
                                                                value={student}
                                                            >
                                                                {({
                                                                    selected,
                                                                }) => (
                                                                    <>
                                                                        <div
                                                                            className={`block truncate ${
                                                                                selected
                                                                                    ? "font-medium"
                                                                                    : "font-normal"
                                                                            } flex items-center gap-4`}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    student.avatar !=
                                                                                    null
                                                                                        ? student.avatar
                                                                                        : student.gender ===
                                                                                          "F"
                                                                                        ? "/images/avatar-f.png"
                                                                                        : "/images/avatar-m.png"
                                                                                }
                                                                                className="h-12 w-12 rounded-full"
                                                                            />
                                                                            <div>
                                                                                <p className="">
                                                                                    {
                                                                                        student.full_name
                                                                                    }
                                                                                </p>

                                                                                <span className="block text-slate-400">
                                                                                    {
                                                                                        student
                                                                                            .school
                                                                                            .school_name
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {selected ? (
                                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        )
                                                    )}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </Listbox>
                                </div>
                            </div>
                        </section>

                        <section className="col-span-12 rounded-lg border border-gray-200 bg-white px-10 pb-4 pt-8 lg:col-span-8">
                            <div className="grid grid-cols-12 gap-6 sm:flex-row lg:justify-around ">
                                {teamData != null && (
                                    <>
                                        <section className="col-span-12 only:col-span-12 lg:col-span-4 xl:col-span-5">
                                            <h3 className="text-lg text-gray-800">
                                                Team
                                            </h3>
                                            <hr className="w-5 rounded-lg border border-purple-600" />
                                            {teamData !== undefined ? (
                                                <>
                                                    <div className="my-8 flex flex-1 flex-wrap gap-4">
                                                        <img
                                                            src={
                                                                teamData.team_logo !==
                                                                null
                                                                    ? teamData.team_logo
                                                                    : "/images/team.png"
                                                            }
                                                            className="h-16 w-16 rounded-full"
                                                        />
                                                        <div>
                                                            <p className="text-xl font-bold">
                                                                {
                                                                    teamData.team_name
                                                                }
                                                            </p>
                                                            <p className="text-lg font-semibold text-gray-600">
                                                                {
                                                                    teamData
                                                                        .members
                                                                        .length
                                                                }{" "}
                                                                students
                                                            </p>
                                                            <p className="text-lg text-gray-400 ">
                                                                {
                                                                    selectedStudent
                                                                        ?.school
                                                                        ?.school_name
                                                                }
                                                            </p>
                                                            <div className="flex items-center">
                                                                <PointsIcon
                                                                    className={
                                                                        "h-6 w-6 stroke-2"
                                                                    }
                                                                />
                                                                <p className="ml-2 text-base font-bold text-gray-800">
                                                                    {
                                                                        teamData.points
                                                                    }{" "}
                                                                    points
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="flex h-full w-full flex-col items-center justify-center">
                                                    <UsersIcon className="h-20 w-20 text-slate-400" />
                                                    <p className="text-center text-xl text-slate-400">
                                                        No team yet, team
                                                        details and progress
                                                        will appear here
                                                    </p>
                                                </div>
                                            )}
                                        </section>
                                        {teamData !== undefined && (
                                            <section className="col-span-12 flex-1 lg:col-span-8 xl:col-span-7">
                                                <h3 className="text-lg text-gray-800">
                                                    Team Members
                                                </h3>
                                                <hr className="w-5 rounded-lg border border-purple-600" />

                                                <div className="my-8 gap-4 divide-y lg:h-56 lg:overflow-y-scroll">
                                                    {teamData.members
                                                        .sort(
                                                            (a, b) =>
                                                                a.cohort_rank -
                                                                b.cohort_rank
                                                        )
                                                        .map((member, i) => (
                                                            <div
                                                                key={i}
                                                                className="grid grid-cols-3 gap-4 py-3"
                                                            >
                                                                <div className="flex items-center gap-2">
                                                                    <img
                                                                        src={
                                                                            member.avatar !=
                                                                            null
                                                                                ? member.avatar
                                                                                : member.gender ===
                                                                                  "F"
                                                                                ? "/images/avatar-f.png"
                                                                                : "/images/avatar-m.png"
                                                                        }
                                                                        className="h-12 w-12 flex-shrink-0 rounded-full"
                                                                    />
                                                                    <span className="text-nowrap text-lg font-semibold text-gray-600">
                                                                        {`${member.first_name} ${member.last_name[0]}.`}
                                                                    </span>
                                                                </div>

                                                                <div className="col-span-2 grid grid-cols-2 items-center gap-4 font-bold md:grid-cols-3">
                                                                    <div className="ml-8 flex gap-2 md:ml-0">
                                                                        <RankingIcon className="h-6 w-6 flex-shrink-0" />
                                                                        <span className="text-base text-gray-800">
                                                                            {
                                                                                member.cohort_rank
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="hidden gap-2 md:flex">
                                                                        <PieIcon className="h-6 w-6 flex-shrink-0" />
                                                                        <span className="text-base text-gray-800">
                                                                            {Math.round(
                                                                                (member.points /
                                                                                    member.total_points) *
                                                                                    100
                                                                            )}
                                                                            %
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex gap-2">
                                                                        <PointsIcon className="h-6 w-6 flex-shrink-0" />
                                                                        <span className="text-nowrap text-base text-gray-800">
                                                                            {
                                                                                member.points
                                                                            }{" "}
                                                                            points
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </section>
                                        )}
                                    </>
                                )}
                                {isError && (
                                    <section className="col-span-12">
                                        <p className="text-center">
                                            An error occurred while fetching
                                            team data:{" "}
                                            {
                                                (teamDataError as AxiosError)
                                                    .message
                                            }
                                        </p>
                                    </section>
                                )}
                                {isLoading && (
                                    <section className="col-span-12 animate-pulse">
                                        <div className="flex flex-wrap gap-8">
                                            <div className="flex flex-1 gap-4">
                                                <div className="h-16 w-16 rounded-full bg-slate-200" />
                                                <div className="flex-1 space-y-4">
                                                    <div className="h-6 w-20 rounded bg-slate-200" />
                                                    <div className="w-26 h-4 rounded bg-slate-200" />
                                                    <div className="w-26 h-4 rounded bg-slate-200" />
                                                    <div className="w-26 h-4 rounded bg-slate-200" />
                                                </div>
                                            </div>
                                            <div className="flex flex-1 flex-col gap-4">
                                                <div className="h-6 w-20 rounded bg-slate-200" />
                                                <div className="flex items-center gap-2">
                                                    <div className="h-12 w-12 rounded-full bg-slate-200" />
                                                    <div className="h-4 w-32 rounded bg-slate-200" />
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <div className="h-12 w-12 rounded-full bg-slate-200" />
                                                    <div className="h-4 w-32 rounded bg-slate-200" />
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <div className="h-12 w-12 rounded-full bg-slate-200" />
                                                    <div className="h-4 w-32 rounded bg-slate-200" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </div>
                        </section>
                    </div>
                </>
            ) : (
                <div className="flex h-full items-center">
                    <p className="text-2xl">
                        Once your child signs up, you will be able to see their
                        data here.
                    </p>
                </div>
            )}
        </section>
    );
}
