import {
    DocumentChartBarIcon,
    FireIcon,
    UserGroupIcon
} from "@heroicons/react/24/outline";
import MainLayout, { type MainLayoutProps } from "./MainLayout";
import SideBar, { type SideBarNavItem } from "../Navbar/SideBar";
import { useRouteLoaderData } from "react-router-dom";
import type { User } from "@src/models/types";
import { AccountStatus } from "@src/models";
import FreeTrialCountdownBanner from "../FreeTrialCountdownBanner";
import BadgeModal from "../Badge/BadgeModal";
import SideBarV2 from "../Navbar/SideBar_v2";
import Topnav from "../Navbar/Topnav";
import { ClassroomIcon } from "../Icons/Classroom";
import { TeamsIcon } from "../Icons/Team";
import { TrophyIcon } from "../Icons/Trophy";
import { ForumsIcon } from "../Icons/Forums";
import { SettingsIcon } from "../Icons/Settings";

export interface StudentLayoutProps extends MainLayoutProps {}

const navItems: SideBarNavItem[] = [
    {
        name: "Classroom",
        link: "/student/content",
        icon: ClassroomIcon,
    },
    {
        name: "My Team",
        link: "/student/team",
        icon: TeamsIcon,
    },
    {
        name: "Leaderboard",
        link: "/student/league/team",
        icon: TrophyIcon,
    },
    {
        name: "Fireside",
        link: "/student/firesides",
        icon: ClassroomIcon,
    },
    {
        name: "Forums",
        link: "/student/forum/",
        icon: ForumsIcon
    },
    {
        name: "Settings",
        link: "/student/profile",
        icon: SettingsIcon
    }
];

export default function StudentLayout({ children }: StudentLayoutProps) {
    const user = useRouteLoaderData("root") as User;
    const isFreeTrial = user.account_status === AccountStatus.FREE_TRIAL;

    return (
        <MainLayout sidebar={<SideBarV2 navItems={navItems} />}>
            {isFreeTrial && (
                <FreeTrialCountdownBanner className="absolute left-[50%] top-0 z-10" />
            )}
            <BadgeModal />
            {children}
        </MainLayout>
    );
}
