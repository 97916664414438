import Button from "@components/Button";
import { FieldError, Label, TextField } from "@components/Form";
import { useForm } from "react-hook-form";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import SettingsCard from "./SettingsCard";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import { updateUser } from "@api/user";
import Avatar from "@components/Avatar";
import { useEffect, useRef } from "react";

function Form({ form }) {
    const user = useRouteLoaderData("root");
    const revalidator = useRevalidator();

    const avatarInput = useRef(null);

    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        resetField,
    } = form;

    const avatarValue = watch("avatar");

    useEffect(() => {
        clearErrors("avatar");
        resetField("avatar");
    }, [avatarValue]);

    const setNewAvatar = (event) => {
        const file = event.target.files[0];

        if (!file) {
            return;
        }
        setValue("avatar", URL.createObjectURL(file));
    };

    const resetAvatar = () => {
        avatarInput.current.value = null;
        setValue("avatar", null);
    };

    const submit = async (data) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            let value = data[key];

            if (key === "avatar") {
                if (value === user.avatar) {
                    return;
                }

                value =
                    avatarInput.current.files.length > 0
                        ? avatarInput.current.files[0]
                        : "";
            }

            formData.set(key, value);
        });

        try {
            await updateUser(formData);

            clearErrors();

            revalidator.revalidate();

            alert("User profile updated successfully.");
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    return (
        <form
            id={"form-personal-information"}
            onSubmit={handleSubmit(submit)}
            className={"max-w-lg space-y-4"}
        >
            <div className="sm:col-span-6">
                <label
                    htmlFor="photo"
                    className="block text-sm font-medium text-blue-gray-900"
                >
                    Avatar
                </label>
                <div className="flex items-center mt-1">
                    <Avatar
                        name={`${user.first_name} ${user.last_name}`}
                        size={45}
                        src={avatarValue}
                    />
                    <div className="flex ml-4">
                        <div className="relative flex items-center px-3 py-2 bg-white border rounded-md shadow-sm cursor-pointer border-blue-gray-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 hover:bg-blue-gray-50">
                            <label
                                htmlFor="user-photo"
                                className="relative text-sm font-medium pointer-events-none text-blue-gray-900"
                            >
                                <span>
                                    {!avatarValue ? "Upload" : "Change"}
                                </span>
                                <span className="sr-only"> user photo</span>
                            </label>
                            <input
                                ref={avatarInput}
                                id="user-photo"
                                name="user-avatar"
                                onChange={setNewAvatar}
                                type="file"
                                accept={"image/*"}
                                className="absolute inset-0 w-full h-full border-gray-300 rounded-md opacity-0 cursor-pointer"
                            />
                        </div>
                        {!!avatarValue && (
                            <Button
                                type="button"
                                variant={"text"}
                                intent={"white"}
                                className="ml-3"
                                onClick={resetAvatar}
                            >
                                Remove
                            </Button>
                        )}
                    </div>
                </div>
                <FieldError
                    className={"mt-1"}
                    message={errors.avatar?.message}
                />
            </div>

            <div>
                <Label htmlFor={"first-name"} name={"First name"} />
                <TextField
                    className={"mt-1 w-full"}
                    type="text"
                    id={"first-name"}
                    {...register("first_name")}
                    required
                />
                <FieldError
                    className={"mt-1"}
                    message={errors.first_name?.message}
                />
            </div>

            <div>
                <Label htmlFor={"last-name"} name={"Last name"} />
                <TextField
                    className={"mt-1 w-full"}
                    type="text"
                    id={"last-name"}
                    {...register("last_name")}
                    required
                />
                <FieldError
                    className={"mt-1"}
                    message={errors.last_name?.message}
                />
            </div>

            <div>
                <Label htmlFor={"user-email"} name={"Email Address"} />
                <TextField
                    className={"mt-1 w-full"}
                    type="email"
                    id={"user-email"}
                    {...register("email")}
                    disabled
                />
                <FieldError
                    className={"mt-1"}
                    message={errors.email?.message}
                />
            </div>
        </form>
    );
}

export default function UpdatePersonalInformation() {
    const user = useRouteLoaderData("root");

    const form = useForm({
        defaultValues: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            avatar: user.avatar,
        },
    });

    const {
        formState: { isSubmitting },
    } = form;

    return (
        <SettingsCard
            title={"Personal Information"}
            form={<Form form={form} />}
            actions={
                <div className={"flex items-center"}>
                    {isSubmitting && (
                        <span className={"text-gray-900 mr-2"}>
                            Saving{" "}
                            <span className={"motion-safe:animate-pulse"}>
                                ...
                            </span>
                        </span>
                    )}
                    <Button
                        type="submit"
                        form="form-personal-information"
                        intent={"primary"}
                    >
                        Update Profile
                    </Button>
                </div>
            }
        />
    );
}
