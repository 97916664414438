import { Tab } from "@headlessui/react";
import PageHeader from "@src/components/PageHeader";
import clsx from "clsx";
import Button from "@src/components/Button";
import {
    ArrowLeftIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
    type LoaderFunctionArgs,
    useNavigate,
    useParams,
    useLoaderData,
} from "react-router-dom";
import { type QueryClient, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { getSessionsQuery, sessionSubmissionsQuery } from "@src/api/sessions";
import SessionTasksDetails from "./SessionTasksDetails";
import StudentInformationContext from "./StudentInformationContext";
import z from "zod";
import { type LoaderReturnType } from "@src/utils/type_helpers";

const RouteParamsSchema = z.object({
    studentId: z.coerce.number(),
    sessionId: z.coerce.number(),
});

export function loader(queryClient: QueryClient) {
    return async ({ params }: LoaderFunctionArgs) => {
        const { studentId, sessionId } = RouteParamsSchema.parse(params);

        const sessionData = await queryClient.ensureQueryData(
            getSessionsQuery()
        );
        const sessionSubmissionData = await queryClient.ensureQueryData(
            sessionSubmissionsQuery(sessionId, studentId)
        );
        return [sessionData, sessionSubmissionData] as const;
    };
}

const tabs = ["Session Overview", "Session Outcomes", "Task Feedback"];

const SessionDetailsOverview = () => {
    const [initialSessionData, initialSessionSubmissionData] =
        useLoaderData() as LoaderReturnType<typeof loader>;

    const { sessionId, studentId } = RouteParamsSchema.parse(useParams());

    const { data: sessionData } = useQuery({
        ...getSessionsQuery(),
        initialData: initialSessionData,
    });
    const { data: sessionSubmissionData } = useQuery({
        ...sessionSubmissionsQuery(sessionId, studentId),
        initialData: initialSessionSubmissionData,
    });

    const navigate = useNavigate();

    const currentSession = sessionData?.find((session) => {
        return session.id === sessionId;
    });

    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleNext = () => {
        setSelectedIndex((selectedIndex + 1) % tabs.length);
    };

    const handleBack = () => {
        setSelectedIndex((selectedIndex - 1 + tabs.length) % tabs.length);
    };

    const breadcrumbs = useMemo(
        () => [
            {
                name: ` Session Feedback : ${currentSession?.title}`,
                href: `/parent/students/${studentId}/feedback-session/${sessionId}`,
            },
        ],
        [currentSession, sessionId]
    );

    return (
        <>
            <PageHeader
                title={currentSession?.title ?? ""}
                breadcrumbPaths={breadcrumbs}
            />
            <StudentInformationContext />
            <section className="container mx-auto px-4 pb-24 sm:px-6 md:px-8">
                {/* back to origin source page  */}

                <div className="mt-5 flex items-center gap-2">
                    <Button
                        variant="outlined"
                        size="xs"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <ArrowLeftIcon className="mx-auto h-4 w-4" />
                        <span className="mx-1">Back</span>
                    </Button>
                    <p>Session {sessionId}</p>
                </div>

                {/* Tabs panels */}
                <div className="mt-5 w-full">
                    <Tab.Group
                        selectedIndex={selectedIndex}
                        onChange={setSelectedIndex}
                    >
                        <Tab.List className="flex">
                            {tabs.map((title, idx) => (
                                <Tab
                                    key={idx}
                                    className={({ selected }) =>
                                        clsx(
                                            "w-full border-b border-purple-300 py-2.5  font-medium leading-5 outline-none",
                                            selected &&
                                                "border-b-2 border-purple-400 text-purple-600"
                                        )
                                    }
                                >
                                    {title}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panel>
                                <div className="grid items-center justify-items-center gap-4 md:grid-cols-5 ">
                                    <div className="col-span-1 space-y-2 pt-10 text-xl md:col-span-3 ">
                                        {currentSession?.objective
                                            .split(".")
                                            .map((line, index) => (
                                                <p
                                                    key={index}
                                                    className="my-5 leading-10"
                                                >
                                                    {line.trim()}
                                                </p>
                                            ))}
                                    </div>
                                    <div className="col-span-1 md:col-span-2">
                                        <img
                                            src="https://s3.amazonaws.com/backendgetkinetic.co/media/ParentModule/parent-session-overview.webp"
                                            alt="session-overiew-image"
                                            className="h-full w-full object-cover"
                                        />
                                    </div>
                                </div>
                            </Tab.Panel>
                            <Tab.Panel>
                                <div className="grid items-center justify-items-center gap-4 md:grid-cols-5 ">
                                    <div className="col-span-1 space-y-2 pt-10 text-xl md:col-span-3 ">
                                        {currentSession?.outcomes
                                            .split(".")
                                            .map((line, indx) => (
                                                <p
                                                    key={indx}
                                                    className="my-5 leading-10"
                                                >
                                                    {line.trim()}
                                                </p>
                                            ))}
                                    </div>
                                    <div className="col-span-1 md:col-span-2">
                                        <img
                                            src="https://s3.amazonaws.com/backendgetkinetic.co/media/ParentModule/parent-session-outcomes.webp"
                                            alt="session-overiew-image"
                                            className="h-full w-full object-cover"
                                        />
                                    </div>
                                </div>
                            </Tab.Panel>
                            <Tab.Panel>
                                {Object.keys(sessionSubmissionData).length !==
                                    0 &&
                                sessionSubmissionData.constructor === Object ? (
                                    <SessionTasksDetails
                                        sessionSubmissionData={
                                            sessionSubmissionData
                                        }
                                    />
                                ) : (
                                    <article className=" mx-auto my-32 max-w-lg rounded-md bg-slate-200 px-6 py-3 text-lg font-semibold leading-8">
                                        {currentSession?.sort_order !== 10 ? (
                                            <p className="text-center">
                                                No session submission data
                                                available
                                            </p>
                                        ) : (
                                            <p>
                                                Since it's a pitch day, students
                                                will be making their
                                                presentations at school. We hope
                                                you will be present to see your
                                                childs project presentation.
                                            </p>
                                        )}
                                    </article>
                                )}
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>

                {/* navigation buttons for the session Details Overview UI */}

                <div className="mr-2 mt-10 flex justify-end gap-10">
                    <Button
                        variant="outlined"
                        size="sm"
                        disabled={selectedIndex === 0}
                        onClick={handleBack}
                    >
                        <ChevronLeftIcon className="mx-auto h-4 w-4 " />
                        <p className="pr-2">Previous</p>
                    </Button>
                    <Button
                        variant="outlined"
                        size="sm"
                        disabled={selectedIndex === 2}
                        onClick={handleNext}
                    >
                        <p className="pl-4">Next</p>
                        <ChevronRightIcon className="mx-auto h-4 w-4" />
                    </Button>
                </div>
            </section>
        </>
    );
};

export default SessionDetailsOverview;
