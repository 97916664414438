import ReactAvatar from "react-avatar";

interface AvatarProps {
    src?: string;
    name: string;
    size?: string;
    className?: string;
}

const Avatar = ({ src, name, size = "45", className }: AvatarProps) => {

    return (
        <ReactAvatar
            name={name}
            maxInitials={1}
            round={true}
            size={size}
            src={src}
            alt={name}
            className={className}
        >
        </ReactAvatar>
    );
};

export default Avatar;
