import clsx from "clsx";

export interface SpeakerAvatarProps {
    speakerAvatar: string;
    speakerName: string;
    className?: string;
}

const SpeakerAvatar = ({
    speakerAvatar,
    speakerName,
    className = "",
}: SpeakerAvatarProps) => {
    return (
        <div
            className={clsx(
                "rounded-full border border-gray-200 p-1",
                className
            )}
        >
            <img
                className="object-fill w-full h-full rounded-full"
                src={speakerAvatar}
                alt={speakerName}
            />
        </div>
    );
}

export default SpeakerAvatar;
