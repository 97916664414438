import AuthLayoutV2 from "@src/components/layout/AuthLayoutV2";
import Button from "@components/Button";
import { login, userTypeLoginRoute } from "@api/auth";
import { Link, useNavigate } from "react-router-dom";
import { FieldError, Label, GeneralError } from "@src/components/Form";
import PasswordFieldV2 from "@src/components/Form/PasswordFieldV2";
import TextFieldV2 from "@src/components/Form/TextFieldV2";
import { z } from "zod";
import { responseErrorToFormErrors } from "@utils/form-errors";
import useStore from "@store/useStore";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { posthog } from "posthog-js";

const loginFormSchema = z.object({
    email: z.string().email(),
    password: z.string(),
});

const LoginV2 = () => {
    const navigate = useNavigate();
    const authenticate = useStore((state) => state.authenticate);
    const setIsOnboarding = useStore((state) => state.setIsOnboarding);

    const onSubmit = async (data) => {
        try {
            const loginResponse = await login(data);

            clearErrors();
            const { user } = loginResponse.data;
            authenticate(user);

            // Identify the user with PostHog
            posthog.identify(user.email, {
                email: user.email,
                name: user.full_name,
                accountStatus: user.account_status,
                userType: user.user_type,
                schoolName: user.school?.school_name,
            });

            const isNewUser = user.status === "NEW";
            const isStudent = user.user_type === "student";

            if (isNewUser && isStudent) {
                // Lets navigate the user straight to password confirm page
                setIsOnboarding(true);
                // navigate(
                //     "/password-reset/MjE0/c83izt-c469631d26f43b83ec37ed39c0b37d30"
                // );
                toast.info(
                    "Your account is new. Please follow the account setup steps from the email we have sent you"
                );
                return;
            }

            if (isStudent) {
                navigate("/welcome-screen");
                return;
            }

            const redirectPath =
                user.status === "NEW"
                    ? "/reset-password"
                    : userTypeLoginRoute(user.user_type);
            navigate(redirectPath, { replace: true });
        } catch (error) {
            console.error(error);
            responseErrorToFormErrors(error, setError);
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({ resolver: zodResolver(loginFormSchema) });

    return (
        <AuthLayoutV2>
            <div
                className="mx-auto mt-32 max-w-md rounded-lg bg-white px-4 py-10 shadow-md sm:px-6 md:px-8"
                style={{ backgroundColor: "rgba(111, 56, 148, 0.3)" }}
            >
                <h1 className="mt-6  text-4xl font-bold text-purple-500">
                    Sign In
                </h1>

                <h1 className="mt-6 text-2xl font-medium text-gray-900">
                    Hey Ian lets get you signed in
                </h1>

                <GeneralError message={errors.general?.message} />

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="mt-6 space-y-6 px-4"
                    method="POST"
                >
                    <div>
                        <Label
                            htmlFor="email"
                            name="Email address"
                            className="text-purple-700"
                        />
                        <TextFieldV2
                            type="email"
                            name="email"
                            id="email"
                            {...register("email")}
                            className="mt-1 w-full"
                            placeholder="email@example.com"
                            autoComplete="email"
                            autoFocus
                            required
                        />
                        <FieldError
                            message={errors.email?.message}
                            className="error-message mt-1"
                        />
                    </div>

                    <div>
                        <Label
                            htmlFor="password"
                            name="Password"
                            className="text-purple-700"
                        />
                        <PasswordFieldV2
                            id="password"
                            name="password"
                            className="mt-1 w-full"
                            {...register("password")}
                            autoComplete="current-password"
                            placeholder="xxxxxxxx"
                            required
                        />
                        <FieldError
                            message={errors.password?.message}
                            className="error-message mt-1"
                        />
                    </div>

                    <div className="flex items-center justify-center">
                        <div>
                            <Link
                                to="/password-reset"
                                className="font-medium text-purple-600 transition-colors hover:text-purple-500"
                            >
                                Forgot your password?
                            </Link>
                        </div>
                    </div>

                    <div>
                        <Button type="submit" size="lg" fullWidth>
                            Sign In
                        </Button>
                    </div>
                </form>
            </div>
        </AuthLayoutV2>
    );
};

export default LoginV2;
