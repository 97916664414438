import clsx from "clsx";
import React, { type SelectHTMLAttributes } from "react";

export type SelectProps = {
    className?: string;
} & SelectHTMLAttributes<HTMLSelectElement>;

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
    ({ className = "", children, ...props }, ref) => {
        return (
            <select
                ref={ref}
                className={clsx(
                    "block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-purple-600",
                    className
                )}
                {...props}
            >
                {children}
            </select>
        );
    }
);

export default Select;
