import { useState } from "react";
import tasks from "../../../../containers/activities/SessionSeven/tasks_schema.json";
import PdfViewer from "@src/components/PdfViewer/PdfViewer";
import Timer from "@src/components/Timer/Timer";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";
import Button from "../../../../components/Button/Button";

const SessionSevenSubmission = ({ data, sortOrder, session, task }) => {
    const [noOfComments, setNoOfComments] = useState(0);

    const sessionSeven = data.find(
        (session) => session.sort_order === sortOrder
    );

    const taskName = task[0];

    if (taskName === "task_1") {
        const taskData = sessionSeven.submissions[taskName];
        const taskAttributes = tasks.find((task) => task.name === taskName);
        const latestTask = taskData[0];
        const files = session.task_1[0].file_uploads;
        const recentFile = files[files.length - 1];

        const handleDownload = () => {
            const signedurlPromise = fetch(recentFile);
            signedurlPromise
                .then((response) => {
                    window.open(response.url, "_blank");
                })
                .catch((error) => {
                    console.log("FILE DOWNLOAD ERROR", error);
                });
        };

        return (
            <SubmissionsTemplate
                latestTask={latestTask}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((step, idx) => {
                        const { fields, question } = step;

                        const options = fields[0].options;
                        const type = fields[0].type;

                        if (type === "view_template") {
                            return (
                                <TaskTemplate question={question}>
                                    <>
                                        {options && (
                                            <PdfViewer attributes={fields[0]} />
                                        )}
                                    </>
                                </TaskTemplate>
                            );
                        } else if (type === "timer") {
                            return (
                                <TaskTemplate question={question}>
                                    <Timer />
                                </TaskTemplate>
                            );
                        } else {
                            return (
                                <TaskTemplate question={question}>
                                    <>
                                        {!recentFile ? (
                                            <p>No files found</p>
                                        ) : (
                                            <div className="w-full overflow-scroll">
                                                <p className="mb-2 w-1/2">
                                                    Files are available
                                                </p>
                                                <Button
                                                    onClick={() =>
                                                        handleDownload()
                                                    }
                                                >
                                                    Download
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                </TaskTemplate>
                            );
                        }
                    })}
                </>
            </SubmissionsTemplate>
        );
    } else {
        const taskData = sessionSeven.submissions[taskName];
        const taskAttributes = tasks.find((task) => task.name === taskName);
        const latestTask = taskData[0];
        const files = session.task_2[0].file_uploads;
        const recentFile = files[files.length - 1];

        function downloadDocument() {
            const signedurlPromise = fetch(recentFile);
            signedurlPromise
                .then((response) => {
                    console.log("DOWNLOAD RESPONSE ---> ", response);
                    window.open(response.url, "_blank");
                })
                .catch((error) => {
                    console.log("FILE DOWNLOAD ERROR", error);
                });
        }

        return (
            <SubmissionsTemplate
                latestTask={latestTask}
                setNoOfComments={setNoOfComments}
            >
                <>
                    {taskAttributes.steps.map((step, idx) => {
                        const { fields, question } = step;
                        const options = fields[0].options;
                        const type = fields[0].type;

                        if (type === "view_template") {
                            return (
                                <TaskTemplate question={question}>
                                    {options && (
                                        <PdfViewer attributes={fields[0]} />
                                    )}
                                </TaskTemplate>
                            );
                        } else if (type === "timer") {
                            return (
                                <TaskTemplate question={question}>
                                    <Timer />
                                </TaskTemplate>
                            );
                        } else {
                            return (
                                <>
                                    <TaskTemplate question={question}>
                                        <>
                                            {!recentFile ? (
                                                <p>No files found</p>
                                            ) : (
                                                <div className="w-full overflow-scroll">
                                                    <p className="mb-2 w-1/2">
                                                        Files are available
                                                    </p>
                                                    <Button
                                                        onClick={() =>
                                                            downloadDocument()
                                                        }
                                                    >
                                                        Download
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    </TaskTemplate>
                                </>
                            );
                        }
                    })}
                </>
            </SubmissionsTemplate>
        );
    }
};

export default SessionSevenSubmission;
