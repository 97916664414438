import { useEffect } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { UsersIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import {
    getTeamStatsInSchool,
    getStudentStatsInSchool,
    getCohorts,
} from "@src/api/schools";
import PageHeader from "@src/components/PageHeader";
import ContentCountCard from "@src/components/ContentCountCard";
import { type SchoolAdmin } from "@src/models/types";
import SchoolDashboardTeamsTable from "@src/components/tables/SchoolDashboardTeamsTable";
import SchoolDashboardStudentsTable from "@src/components/tables/SchoolDashboardStudentsTable";

const SchoolDashboardPage = () => {
    const user = useRouteLoaderData("root") as SchoolAdmin;

    const { data: teamsStats } = useQuery({
        queryKey: ["teams", "stats"],
        queryFn: async () => await getTeamStatsInSchool(),
    });

    const { data: studentStats } = useQuery({
        queryKey: ["students", "stats"],
        queryFn: async () => await getStudentStatsInSchool(),
    });

    const { data: cohorts } = useQuery({
        queryKey: ["cohorts"],
        queryFn: async () => await getCohorts(),
    });

    return (
        <div>
            <PageHeader title={user.school.school_name} breadcrumbPaths={[]} />

            <section className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                <h2 className="mb-4 mt-8 text-2xl font-bold leading-6 text-gray-900">
                    Overview
                </h2>
                <div className="flex flex-wrap justify-center gap-8">
                    <ContentCountCard
                        className="grow"
                        Icon={UsersIcon}
                        textColor="text-orange-600"
                        bgColor="bg-orange-600"
                        bgColorLight="bg-orange-600/5"
                        contentTitle="Total Students"
                        contentCount={studentStats?.total_students ?? 0}
                    />
                    <ContentCountCard
                        className="grow"
                        Icon={UsersIcon}
                        textColor="text-green-500"
                        bgColor="bg-green-500"
                        bgColorLight="bg-green-500/5"
                        contentTitle="Latest Cohort"
                        contentCount={studentStats?.current_students ?? 0}
                    />
                    <ContentCountCard
                        className="grow"
                        Icon={UserGroupIcon}
                        textColor="text-purple-800"
                        bgColor="bg-purple-800"
                        bgColorLight="bg-purple-800/5"
                        contentTitle="Teams"
                        contentCount={teamsStats?.teams_count ?? 0}
                    />
                </div>

                <SchoolDashboardTeamsTable cohorts={cohorts} />

                <SchoolDashboardStudentsTable cohorts={cohorts} />
            </section>
        </div>
    );
};

export default SchoolDashboardPage;
