import './intermittent-loader.css';
const IntermittentLoader = () => {
  return (
    <div className="w-full">
      <div className="h-1.5 w-full bg-slate-100 overflow-hidden">
        <div className="progress w-full h-full bg-slate-300 left-right"></div>
      </div>
    </div>
  );
};

export default IntermittentLoader;
