import { useRouteLoaderData } from "react-router-dom";
import { SessionContentType } from "@src/models";
import Video, { VideoType } from "@components/Content/Video";
import Button from "@components/Button";

const SessionCaseStudy = () => {
    const session = useRouteLoaderData("session");
    const caseStudy = session?.content.find(
        (item) => item.content_type === SessionContentType.CASE_STUDY
    );

    return (
        <section className="px-4 pt-4 pb-8 sm:px-6 md:px-8">
            <h2
                data-cy="session-case-studies-heading"
                className="text-2xl font-bold text-purple-800"
            >
                Case Studies
            </h2>
            <div className="flex mt-2">
                <div className="flex-1">
                    {caseStudy ? (
                        <Video
                            className="max-w-4xl aspect-video"
                            src={caseStudy.source_url}
                            videoType={[VideoType.CaseStudyVideo, caseStudy.id, ""]}
                        />
                    ) : (
                        <p className="font-bold text-center">
                            No case study record
                        </p>
                    )}
                </div>
                <div className="hidden pl-2 md:flex md:items-end">
                    <img
                        className="h-auto max-h-32 md:max-h-48"
                        src="/images/resourceImg.svg"
                        alt=""
                    />
                </div>
            </div>
            <div className="flex justify-end mt-4">
                <Button
                    data-cy="session-case-studies-next-button"
                    variant="outlined"
                    href={`/student/content/${session.session_id}/quiz`}
                >
                    Next
                </Button>
            </div>
        </section>
    );
};

export default SessionCaseStudy;
