import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import pagenotfound from "../../lotties/404page.json";

const PageNotFound = () => {
    const navigate404 = useNavigate();

    return (
        <div className="flex items-center h-screen">
            <div className="flex justify-center">
                <div className="shadow-2xl  max-w-sm md:max-w-[50%] my-5 p-10">
                    <Lottie animationData={pagenotfound} loop play />
                    <div className="text-center">
                        <p>Lets take your back to a familar page here</p>
                        <button
                            className="mt-2 space-x-2 rounded border border-purple-500/[.55] px-6 py-2 text-purple-500
                            transition hover:bg-purple-500/[.1]  focus:outline-none
                            focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                            onClick={() => {
                                navigate404(-1);
                            }}
                        >
                            FAMILIAR PAGE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
