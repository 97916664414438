import { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Button from "../Button";
import TemplateModal from "../Modals/TemplateModal";

const PdfViewer = (props) => {
    
    const displayFileUrl = props.attributes.options.path;
    const fileStatus = props.attributes.options.file_status;
    const heading = props.attributes.label;
    const body = props.attributes.options.optionText1;
    const CTA = props.attributes.options.optionLabel;
    const label = props.attributes.label;

    // usestates for react-pdf functionality
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfloaded, setPdfLoaded] = useState(false);

    const [open, setOpen] = useState(false);

    // download File
    const downloadFile = () => {
        window.open(displayFileUrl, "_blank");
    };

    // when the document is loaded, total page count is available
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        // setPageNumber(1);
        setPdfLoaded(true);
    };

    // set to a different page
    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    // go to the previous page
    const previousPage = () => {
        changePage(-1);
    };

    // go to the next page
    const nextPage = () => {
        changePage(1);
    };

    // custom loading messages
    const LoadingViewIntroFirstFile = () => (
        <div className="grid h-[35rem] w-[55rem] place-content-center rounded-md bg-slate-200 motion-safe:animate-pulse">
            <p className="text-center text-xl  font-bold text-purple-800">
                loading edit...
            </p>
        </div>
    );
    const LoadingDowndLoadFile = () => (
        <div className="mx-auto grid h-[30rem] w-[25rem] place-content-center  rounded-md bg-purple-200  motion-safe:animate-pulse md:w-[30rem] ">
            <p className="text-center text-xl  font-bold text-purple-800">
                loading...
            </p>
        </div>
    );
    // custom error messages
    const ErrorViewIntroFirstFile = () => (
        <>
            <div className="grid h-[35rem] w-[55rem] place-content-center rounded-md bg-slate-200 motion-safe:animate-pulse">
                <p className="text-center text-xl font-bold text-purple-800">
                    Failed to load :(, Please refresh and try again
                </p>
            </div>
        </>
    );
    const ErrorDownLoadFile = (err) => (
        <>
            {console.log("FILE DOWNLOAD ERROR --->", err)}
            <div className="mx-auto grid h-[30rem] w-[25rem] place-content-center rounded-md bg-purple-200  motion-safe:animate-pulse md:w-[30rem] ">
                <p className="text-center text-xl font-bold text-purple-800">
                    Failed to load :(, Please refresh and try again
                </p>
            </div>
        </>
    );
    return (
        <>
            {fileStatus === "view" && (
                <>
                    <div className="grid place-content-center gap-2">
                        <Document
                            file={displayFileUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={<LoadingDowndLoadFile />}
                            error={(e) => console.log("ERROR ---> ", e)}
                        >
                            <Page
                                pageNumber={pageNumber}
                                className="h-[30rem]"
                                scale={0.48}
                            />
                        </Document>
                        {numPages >= 2 && (
                            <>
                                <p className="text-center">
                                    Page {pageNumber} of {numPages}
                                </p>
                            </>
                        )}

                        <div className="flex justify-center gap-4 ">
                            {numPages >=
                                2(
                                    <>
                                        <Button
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >
                                            Prev
                                        </Button>
                                        <Button
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            Next
                                        </Button>
                                    </>
                                )}
                        </div>
                    </div>
                </>
            )}
            {fileStatus === "download" && (
                <>
                    <p className="my-5 text-xl text-purple-900">{label}</p>
                    <div className="grid place-content-center gap-2">
                        <Document
                            file={displayFileUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={<LoadingDowndLoadFile />}
                            error={<ErrorDownLoadFile />}
                        >
                            <Page
                                pageNumber={pageNumber}
                                className="h-[30rem]"
                                scale={0.48}
                            />
                        </Document>
                        {pdfloaded && (
                            <Button
                                className="mx-auto w-28 bg-purple-900"
                                onClick={downloadFile}
                            >
                                Download
                            </Button>
                        )}
                    </div>
                </>
            )}

            {fileStatus === "view_intro_first" && (
                <>
                    <div className="rounded-md bg-white p-4">
                        <h4 className="font-bold">{heading}</h4>
                        <p className="my-4 ml-4 font-medium">{body}</p>
                        <div className="flex">
                            <Button
                                className="ml-auto"
                                onClick={() => setOpen(true)}
                            >
                                {CTA}
                            </Button>
                            <TemplateModal
                                title={heading}
                                open={open}
                                setOpen={setOpen}
                            >
                                <>
                                    <div className=" grid place-content-center gap-2">
                                        <Document
                                            file={displayFileUrl}
                                            onLoadSuccess={
                                                onDocumentLoadSuccess
                                            }
                                            loading={
                                                <LoadingViewIntroFirstFile />
                                            }
                                            error={<ErrorViewIntroFirstFile />}
                                        >
                                            <Page
                                                pageNumber={pageNumber}
                                                className="h-[35rem]"
                                                scale={0.55}
                                            />
                                        </Document>
                                        {numPages >= 2 && (
                                            <>
                                                <p className="text-center">
                                                    Page {pageNumber} of{" "}
                                                    {numPages}
                                                </p>
                                            </>
                                        )}
                                        <div className="flex justify-center gap-4 ">
                                            {numPages >= 2 && (
                                                <>
                                                    <Button
                                                        disabled={
                                                            pageNumber <= 1
                                                        }
                                                        onClick={previousPage}
                                                    >
                                                        Prev
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            pageNumber >=
                                                            numPages
                                                        }
                                                        onClick={nextPage}
                                                    >
                                                        Next
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            </TemplateModal>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PdfViewer;
