import type React from "react";
import { type QueryClient } from "@tanstack/query-core";
import Button from "@components/Button";
import PageHeader from "@components/PageHeader";
import LeaderboardHighlight from "@src/components/LeaderboardHighlight";
import {
    type TeamLeaderboardRecord,
    type IndividualLeaderboardRecord,
} from "@src/models/types";
import {
    individualLeaderboardQuery,
    teamLeaderboardQuery,
} from "@src/api/league";
import { useLoaderData } from "react-router-dom";
import LeagueTeamLeaderboard from "@src/components/tables/LeagueTeamLeaderboard";
import LeagueIndividualLeaderboard from "@src/components/tables/LeagueIndividualTeamLeaderboard";

export function teamLeaderboardLoader(queryClient: QueryClient) {
    return async () => {
        const teamLeaderboard = await queryClient.ensureQueryData(
            teamLeaderboardQuery()
        );

        return teamLeaderboard;
    };
}

export function individualLeaderboardLoader(queryClient: QueryClient) {
    return async () => {
        const individualLeaderboard = await queryClient.ensureQueryData(
            individualLeaderboardQuery()
        );

        return individualLeaderboard;
    };
}

const League: React.FC<{ view: "team" | "individual" }> = ({ view }) => {
    const leaderboard = useLoaderData() as
        | TeamLeaderboardRecord[]
        | IndividualLeaderboardRecord[];

    if (view === "individual") {
        (leaderboard as IndividualLeaderboardRecord[]).forEach((individual) => {
            individual.avatar =
                individual.avatar != null
                    ? individual.avatar
                    : individual.gender === "F"
                    ? "/images/avatar-f.png"
                    : "/images/avatar-m.png";
        });
    }

    return (
        <>
            <PageHeader
                title="Leaderboard"
                breadcrumbPaths={[
                    { name: "Leaderboard", href: "/student/league" },
                ]}
                actions={
                    <Button
                        size="lg"
                        intent="secondary"
                        href="/student/league/my-score"
                    >
                        My Score
                    </Button>
                }
            />
            <section className="px-4 py-6 sm:px-6 md:px-8">
                <div>
                    <Button
                        size="lg"
                        intent={view === "team" ? "primary" : "secondary"}
                        href="/student/league/team"
                        className="rounded-none"
                    >
                        Team
                    </Button>
                    <Button
                        size="lg"
                        intent={view === "individual" ? "primary" : "secondary"}
                        href="/student/league/individual"
                        className="rounded-none"
                    >
                        Individual
                    </Button>
                </div>
                <hr className="mb-4 border-primary-300" />
                <LeaderboardHighlight performers={leaderboard} type={view} />
                <div>
                    {view === "team" && (
                        <LeagueTeamLeaderboard
                            leaderboard={leaderboard as TeamLeaderboardRecord[]}
                        />
                    )}
                    {view === "individual" && (
                        <LeagueIndividualLeaderboard
                            leaderboard={
                                leaderboard as IndividualLeaderboardRecord[]
                            }
                        />
                    )}
                </div>
            </section>
        </>
    );
};
export default League;
