import Alert from "./Alert";

export default function GenericErrorAlert({ className = "" }) {
    return (
        <Alert
            intent="danger"
            title="Something went wrong!"
            description="There was an issue processing your current request. First check your internet connection then try again. If the problem persists, reach out to support we can help you from there."
            className={className}
        />
    );
}
