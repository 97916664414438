import Video from "@src/components/Content/Video_v2";
import Topnav from "@src/components/Navbar/Topnav";
import Button from "@src/components/Button";
import { useNavigate } from "react-router-dom";
import IntroLayout from "@src/components/layout/IntroLayout";

const IntroductionVideo = () => {
    const navigate = useNavigate();

    const handleNavigateNext = () => {
        navigate("/team-setup-video");
    };

    return (
        <IntroLayout showGears={false}>
            <Topnav />
            <p className=" mt-2 w-full text-center text-[30px] font-semibold">
                {" "}
                Introduction Video
            </p>
            <div className="flex w-full flex-col items-center">
                <div className={"my-4  w-[800px] md:my-8 "}>
                    <Video
                        className="aspect-video w-full"
                        title="Kinetic Platform Introduction Video"
                        src="https://player.vimeo.com/video/766891621?h=71d491d069"
                    />
                </div>
            </div>

            <div className={"mr-5 flex justify-end pb-[50px]"}>
                <Button
                    type="submit"
                    size="lg"
                    intent="primaryVariant"
                    fullWidth={false}
                    onClick={handleNavigateNext}
                >
                    Watch the team setup guide video
                </Button>
            </div>
        </IntroLayout>
    );
};

export default IntroductionVideo;
