const ProfileHighlight: React.FC<{
    position: number;
    image: string | null;
    height: string;
}> = ({ position, image, height = "12em" }) => {
    function getAccentColor(position: number) {
        switch (position) {
            case 1:
                return "#DCC7EA";
            case 2:
                return "#FFD9D9";
            default:
                return "#FFCC00";
        }
    }
    return (
        <svg
            viewBox={
                "107.6583 " +
                (position === 0 ? "32.9464 " : "150.232 ") +
                "284.6834 " +
                (position === 0 ? "431.4607" : "314.1752")
            }
            xmlns="http://www.w3.org/2000/svg"
            style={{ height }}
        >
            <defs>
                <filter
                    id="filter0_d_847_37742"
                    x="0"
                    y="0.944824"
                    width="180"
                    height="180"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_847_37742"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_847_37742"
                        result="shape"
                    />
                </filter>
                <pattern
                    id={"patternProfile" + `${position}`}
                    patternContentUnits="objectBoundingBox"
                    width="100%"
                    height="100%"
                >
                    <image
                        width="1"
                        height="1"
                        xlinkHref={image != null ? image : "/images/team.png"}
                    />
                </pattern>
            </defs>
            {position === 0 && (
                <g
                    transform="matrix(1.2825900316238403, 0, 0, 1.2825900316238403, -4.421479225158691, -36.04156494140625)"
                    style={{}}
                >
                    <title>Crown</title>
                    <path
                        d="M 241.065 91.067 C 240.179 90.38 239.103 89.939 237.96 89.793 C 236.817 89.646 235.652 89.801 234.599 90.24 L 215.009 98.329 L 203.626 79.275 C 203.082 78.386 202.294 77.646 201.342 77.131 C 200.39 76.617 199.308 76.345 198.206 76.345 C 197.104 76.345 196.022 76.617 195.07 77.131 C 194.118 77.646 193.33 78.386 192.786 79.275 L 181.403 98.329 L 161.812 90.24 C 160.757 89.802 159.591 89.647 158.446 89.792 C 157.301 89.937 156.223 90.376 155.333 91.061 C 154.442 91.745 153.776 92.647 153.409 93.665 C 153.042 94.682 152.989 95.775 153.256 96.819 L 163.09 135.754 C 163.278 136.508 163.629 137.218 164.121 137.843 C 164.614 138.467 165.238 138.992 165.955 139.385 C 166.926 139.925 168.037 140.211 169.169 140.212 C 169.719 140.211 170.266 140.139 170.795 139.996 C 188.72 135.395 207.653 135.395 225.578 139.996 C 227.215 140.396 228.956 140.176 230.418 139.385 C 231.14 138.997 231.767 138.473 232.26 137.848 C 232.753 137.223 233.101 136.51 233.283 135.754 L 243.155 96.819 C 243.419 95.775 243.363 94.683 242.994 93.666 C 242.625 92.65 241.956 91.75 241.065 91.067 Z"
                        fill="#FFD940"
                    />
                    <circle cx="198.204" cy="66.678" r="12.89" fill="#FFD940" />
                    <circle
                        cx="198.204"
                        cy="111.793"
                        r="9.668"
                        fill="#BE9502"
                    />
                    <circle cx="244.93" cy="87.624" r="8.056" fill="#FFD940" />
                    <circle cx="151.478" cy="87.624" r="8.056" fill="#FFD940" />
                    <circle
                        cx="174.035"
                        cy="116.627"
                        r="4.834"
                        fill="#BE9502"
                    />
                    <circle
                        cx="222.373"
                        cy="116.627"
                        r="4.834"
                        fill="#BE9502"
                    />
                </g>
            )}
            <g
                filter="url(#filter0_d_847_37742)"
                transform="matrix(1.6551361083984375, 0, 0, 1.6551361083984375, 101.03775024414061, 148.66815185546875)"
                style={{}}
            >
                <title>DP</title>
                <rect
                    x="4"
                    y="0.944824"
                    width="172"
                    height="172"
                    rx="86"
                    fill={`url(#${`patternProfile${position}`})`}
                    shapeRendering="crispEdges"
                    style={{}}
                />
                <rect
                    x="5"
                    y="1.94482"
                    width="170"
                    height="170"
                    rx="85"
                    stroke={getAccentColor(position)}
                    strokeWidth="2"
                    shapeRendering="crispEdges"
                    style={{ fill: "none" }}
                />
            </g>
            <g
                style={{}}
                transform="matrix(1.2825900316238403, 0, 0, 1.2825900316238403, -70.64747619628906, -75.68253326416016)"
            >
                <title>Position</title>
                <circle
                    cx="252.587"
                    cy="396.759"
                    r="24.334"
                    fill={getAccentColor(position)}
                    stroke="#B990D5"
                    strokeWidth="3.69599"
                />
                <text
                    style={{
                        fill: "",
                        fontSize: "1.5em",
                        fontWeight: "bold",
                        whiteSpace: "pre",
                    }}
                    x="245.772"
                    y="405.655"
                >
                    {position + 1}
                </text>
            </g>
        </svg>
    );
};

export default ProfileHighlight;
