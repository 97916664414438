import type { User, Student, SchoolAdmin } from "./types";

export const SessionContentType = {
    LEARNING_VIDEO: "learning_video",
    CASE_STUDY: "case_study",
} as const;

export const SessionStatus = {
    IN_PROGRESS: "in_progress",
    DONE: "done",
    NOT_STARTED: "not_started",
} as const;

export const UserType = {
    STUDENT: "student",
    SCHOOL_ADMIN: "school_admin",
    PARENT: "parent",
} as const;

export const AccountStatus = {
    FREE_TRIAL: "free_trial",
    PAID: "paid"
} as const;

export const SchoolCurriculums = {
    British: "british",
    CBC: "cbc",
    "844": "8-4-4",
} as const;

type UserFromAPI = Omit<User | Student, "team">;

export function UserFactory(user: UserFromAPI) {
    if (isStudent(user)) {
        user.team = function () {
            return this.teams.at(0) ?? null;
        }
        return user;
    }

    if (isSchoolAdmin(user)) {
        return user;
    }

    return user as User;
}

export function isStudent(user: User): user is Student {
    return user.user_type === UserType.STUDENT;
}

export function isSchoolAdmin(user: User): user is SchoolAdmin {
    return user.user_type === UserType.SCHOOL_ADMIN;
}
