import { useState } from "react";
import PdfViewer from "@src/components/PdfViewer/PdfViewer";
import tasks from "../../../../containers/activities/SessionFour/tasks_schema.json";
import Timer from "@src/components/Timer/Timer";
import parse from "html-react-parser";
import SubmissionsTemplate from "../SubmissionsTemplate";
import TaskTemplate from "../TaskTemplate";
import Button from "../../../../components/Button/Button";

const SessionFourTaskThreeSubmission = ({ data, sortOrder, session, task }) => {
    const [noOfComments, setNoOfComments] = useState(0);

    const [selectedTask, setSelectedTask] = useState("");

    const sessionFour = data.find(
        (session) => session.sort_order === sortOrder
    );

    const taskName = task[0];
    const taskData = sessionFour.submissions[taskName];
    const taskAttributes = tasks.find((task) => task.name === taskName);

    const surveyReport = taskData[0].content.survey_report;
    const latestTask = taskData[0];

    const files = session.task_3[0].file_uploads;
    const recentFile = files[files.length - 1];

    const handleSelect = (question) => {
        setSelectedTask(question);
    };

    const handleDownload = () => {
        const signedurlPromise = fetch(recentFile);
        signedurlPromise
            .then((response) => {
                window.open(response.url, "_blank");
            })
            .catch((error) => {
                console.log("FILE DOWNLOAD ERROR", error);
            });
    };

    return (
        <SubmissionsTemplate
            latestTask={latestTask}
            setNoOfComments={setNoOfComments}
        >
            <>
                {taskAttributes.steps.map((step, idx) => {
                    const { fields, question } = step;
                    const fieldData = fields[0];
                    switch (fieldData.name) {
                        case "questionaire_templates":
                            return (
                                <div
                                    onClick={() => handleSelect(question)}
                                    className={`m-2 m-2 rounded-md border p-3 ${
                                        question === selectedTask
                                            ? "border-purple-500"
                                            : ""
                                    }`}
                                    key={idx}
                                >
                                    <TaskTemplate question={question}>
                                        <PdfViewer attributes={fields[0]} />;
                                    </TaskTemplate>
                                </div>
                            );
                        case "time_image":
                            return (
                                <div
                                    onClick={() => handleSelect(question)}
                                    className={`m-2 rounded-md border p-3 ${
                                        question === selectedTask
                                            ? "border-purple-500"
                                            : ""
                                    }`}
                                    key={idx}
                                >
                                    <TaskTemplate question={question}>
                                        <Timer />
                                    </TaskTemplate>
                                </div>
                            );

                        case "survey_report":
                            return (
                                <div
                                    onClick={() => handleSelect(question)}
                                    className={`m-2 rounded-md border p-3 ${
                                        question === selectedTask
                                            ? "border-purple-500"
                                            : ""
                                    }`}
                                    key={idx}
                                >
                                    <TaskTemplate question={question}>
                                        <div>{parse(surveyReport)}</div>
                                    </TaskTemplate>
                                </div>
                            );
                        case "survey_files":
                            return (
                                <div
                                    onClick={() => handleSelect(question)}
                                    className={`m-2 rounded-md border p-3 ${
                                        question === selectedTask
                                            ? "border-purple-500"
                                            : ""
                                    }`}
                                    key={idx}
                                >
                                    <TaskTemplate question={question}>
                                        {!recentFile ? (
                                            <p>No files found</p>
                                        ) : (
                                            <div className="w-full overflow-scroll">
                                                <p className="mb-2 w-1/2">
                                                    Files are available
                                                </p>
                                                <Button
                                                    onClick={() =>
                                                        handleDownload()
                                                    }
                                                >
                                                    Download
                                                </Button>
                                            </div>
                                        )}
                                    </TaskTemplate>
                                </div>
                            );
                    }
                })}
            </>
        </SubmissionsTemplate>
    );
};

export default SessionFourTaskThreeSubmission;
