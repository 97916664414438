const SessionActivityErrorBoundary = () => {
    return (
        <section
            className={
                "bg-white max-w-3xl mx-auto mt-8 rounded-md px-4 pt-4 pb-8 sm:px-6 md:px-8"
            }
        >
            <h1 className="text-gray-800 font-semibold text-xl">
                Yikes! This is awkward.
            </h1>
            <p className={"text-gray-700 mt-2 text-lg"}>
                Apologies, we cannot process your request at the moment for this
                activity. You can come back later and check if the issue is
                resolved. If the issue is persisting kindly contact us help
                resolve the problem.
            </p>
        </section>
    );
};

export default SessionActivityErrorBoundary;
