import { cx } from "class-variance-authority";

export default function FieldError({ message, className = "", ...props }) {
    return (
        message && (
            <p className={cx("text-sm text-red-600", className)} {...props}>
                {message}
            </p>
        )
    );
}
