import { useState, useEffect } from "react";
import clsx from "clsx";
import {
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
} from "@heroicons/react/24/solid";
import {
    type ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import type { Cohort } from "@src/models/types";
import { TextField } from "@src/components/Form";
import SelectComponent from "../Select/SelectComponent";
import Button from "@src/components/Button";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const Table: React.FC<{
    title: string;
    columns: Array<ColumnDef<any>>;
    data: any[];
    cohorts?: Cohort[];
    setCohort?: (cohort: Cohort) => void;
}> = (props) => {
    const { title, columns, data, cohorts, setCohort } = props;
    const [selectedCohort, setSelectedCohort] = useState<Cohort>({});
    console.log("SELECTED COHORT---> ", title);
    useEffect(() => {
        console.log("SEET COHORT --> ", setCohort);
        if (setCohort != null || setCohort !== undefined) {
            if (selectedCohort.sort_order != null) {
                setCohort(selectedCohort);
            }
        }
    }, [selectedCohort.sort_order, setCohort]);
    const [globalFilter, setGlobalFilter] = useState("");
    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: setGlobalFilter,
    });

    const getPages = (
        currentPage: number,
        endPage: number,
        maxPages: number
    ) => {
        // eslint-disable-next-line prefer-const
        let pages = [];

        switch (currentPage) {
            case 1:
                for (let i = 1; i <= Math.min(endPage, maxPages); i++) {
                    pages.push(i);
                }
                break;

            case endPage:
                for (
                    let i = endPage;
                    i >= Math.max(1, endPage - maxPages);
                    i--
                ) {
                    pages.push(i);
                }
                pages.reverse();
                break;

            default:
                for (
                    let i = Math.floor(Math.max(1, currentPage - maxPages / 2));
                    i <= Math.min(endPage, currentPage + maxPages / 2);
                    i++
                ) {
                    pages.push(i);
                }
                break;
        }
        return pages;
    };

    const {
        getAllColumns,
        getState,
        getPageCount,
        setPageIndex,
        nextPage,
        previousPage,
        getCanPreviousPage,
        getCanNextPage,
    } = table;

    return (
        <>
            <div className="mt-8 rounded-2xl bg-white pt-8 drop-shadow-md duration-300 ease-out">
                <div className="flex w-full flex-row flex-wrap items-center justify-between  gap-4 px-6">
                    <div>
                        <h2 className="text-2xl font-bold leading-6 text-gray-900">
                            {title}
                        </h2>
                    </div>
                    <div className="flex flex-row gap-4">
                        {/* <Select disabled>
                            <option value="1">Cohort 1</option>
                        </Select> */}
                        {cohorts != null && (
                            <SelectComponent
                                cohorts={cohorts}
                                setCohort={setSelectedCohort}
                            />
                        )}

                        <TextField
                            placeholder="Search"
                            name="Search"
                            value={globalFilter}
                            onChange={(e) => {
                                setGlobalFilter(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="mt-8 overflow-x-auto rounded-xl px-4 pb-6 duration-300 ease-out">
                    <table className="mt-4 min-w-full border-separate border-spacing-y-4 divide-y divide-gray-300 md:mt-8">
                        <thead className="">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className="border-spacing-0  px-6 text-center"
                                >
                                    {headerGroup.headers.map((header) => (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th
                                            key={header.id}
                                            className="bg-slate-50 p-4 text-lg font-normal text-gray-700 md:text-xl"
                                            colSpan={header.colSpan}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {/* Add a sort direction indicator */}
                                            {header.column.getIsSorted() !==
                                            false
                                                ? {
                                                      asc: (
                                                          <span className="ml-2 inline-block h-5 w-5 align-middle">
                                                              <ChevronDoubleUpIcon className="h-5 w-5" />
                                                          </span>
                                                      ),
                                                      desc: (
                                                          <span className="ml-2 inline-block h-5 w-5 align-middle">
                                                              <ChevronDoubleDownIcon className="h-5 w-5" />
                                                          </span>
                                                      ),
                                                  }[
                                                      header.column.getIsSorted() as string
                                                  ] ?? null
                                                : ""}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>

                        <tbody className="divide-y divide-gray-200  ">
                            {table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row, idx) => {
                                    return (
                                        <tr
                                            className={`${
                                                idx % 2 === 0
                                                    ? "bg-white"
                                                    : "bg-purple-50"
                                            } text-lg`}
                                            key={row.id}
                                        >
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => {
                                                    return (
                                                        <td key={cell.id}>
                                                            <div className="m-1.5 flex h-full w-full flex-row items-center justify-center">
                                                                {flexRender(
                                                                    cell.column
                                                                        .columnDef
                                                                        .cell,
                                                                    cell.getContext()
                                                                )}
                                                            </div>
                                                        </td>
                                                    );
                                                })}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className="text-lg">
                                    <td
                                        className="p-6 px-3 text-center"
                                        colSpan={getAllColumns().length}
                                    >
                                        There are no results to show.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="mt-4 flex items-center justify-center">
                        <ChevronLeftIcon
                            className={clsx(
                                "h-7",
                                "w-7",
                                "mx-2",
                                getCanPreviousPage()
                                    ? "cursor-pointer"
                                    : "cursor-default text-gray-400"
                            )}
                            onClick={() => {
                                previousPage();
                            }}
                        />
                        {getPages(
                            getState().pagination.pageIndex + 1,
                            getPageCount(),
                            5
                        ).map((pageNumber) => {
                            return (
                                <Button
                                    intent="primary"
                                    variant={
                                        pageNumber ===
                                        getState().pagination.pageIndex + 1
                                            ? "contained"
                                            : "text"
                                    }
                                    onClick={() => {
                                        setPageIndex(pageNumber - 1);
                                    }}
                                    className="mx-2 !rounded-full"
                                    key={pageNumber}
                                >
                                    {pageNumber}
                                </Button>
                            );
                        })}
                        <ChevronRightIcon
                            className={clsx(
                                "h-7",
                                "w-7",
                                "mx-2",
                                getCanNextPage()
                                    ? "cursor-pointer"
                                    : "cursor-default text-gray-400"
                            )}
                            onClick={() => {
                                if (getCanNextPage()) {
                                    nextPage();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Table;
