import { Model } from "survey-core";
import type { CompleteEvent } from "survey-core/typings/survey-events-api";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./surveyOne.css";
import json from "./ux_survey.json";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getUXSurveyData, postUXSurveyData } from "@src/api/airtable-survey";
import { useRouteLoaderData } from "react-router-dom";
import type { Student, StudentSession } from "@src/models/types";
import { useQuery } from "@tanstack/react-query";
import { delay } from "lodash-es";
import { AccountStatus, SessionStatus } from "@src/models";
import Alert from "../Alert";

function getEnvironment() {
    if (import.meta.env.DEV) {
        return "dev";
    }

    if (window.location.hostname.startsWith("staging")) {
        return "staging";
    }

    return "production";
}

function isEnabledFromConfig() {
    const envValue = import.meta.env.VITE_ENABLE_UX_SURVEY;

    if (envValue === undefined) {
        return true;
    }

    return envValue === "true" ? true : envValue !== "false";
}

function canUserViewSurvey(user: Student, sessions: StudentSession[]) {
    if (user.account_status === AccountStatus.FREE_TRIAL) {
        return false;
    }

    const sessionFour = sessions.find((session) => session.sort_order === 4);
    if (sessionFour == null) {
        return false;
    }

    return sessionFour.status === SessionStatus.DONE;
}

function UXSurvey() {
    const [open, setOpen] = useState<boolean>(false);

    const user = useRouteLoaderData("root") as Student;

    const sessions = useRouteLoaderData("content") as StudentSession[];

    const enableSurvey = useMemo(() => {
        if (!isEnabledFromConfig()) {
            return false;
        }

        return canUserViewSurvey(user, sessions);
    }, [user.account_status, sessions]);

    const recordsQuery = useQuery({
        queryKey: ["ux-survey-records"],
        queryFn: getUXSurveyData,
        enabled: enableSurvey,
    });

    const hasCompletedUXSurvey = useMemo(() => {
        const foundMatch = recordsQuery.data?.records.some(
            (record) => record.fields.email === user.email
        );

        return foundMatch === undefined ? true : foundMatch;
    }, [recordsQuery.data]);

    useEffect(() => {
        if (recordsQuery.isInitialLoading) {
            return;
        }

        const newOpen = !hasCompletedUXSurvey;

        if (newOpen) {
            delay(() => {
                setOpen(true);
            }, 1500);
        } else {
            setOpen(false);
        }
    }, [recordsQuery.isInitialLoading, hasCompletedUXSurvey]);

    const surveyModel = new Model(json);

    const postSurveyData = async (
        survey: typeof surveyModel,
        options: CompleteEvent
    ) => {
        const fields = {
            experience_rating: survey.data.question1,
            experience_improvement: survey.data.question2,
            most_liked_thing: survey.data.question3,
            experienced_errors: survey.data.question4,
            error_description: survey.data.question5,
            recommendation_likelihood: survey.data.question6,
            email: user.email,
            school: user.school.school_name,
            environment: getEnvironment(),
        };

        try {
            await postUXSurveyData(fields);

            options.showDataSavingSuccess("Your responses have been recorded.");

            delay(async () => {
                setOpen(false);
                await recordsQuery.refetch();
            }, 3000);
        } catch (error) {
            options.showDataSavingError("Could not send your responses");
        }
    };

    if (!enableSurvey) {
        return;
    }

    return (
        <Transition show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-full p-6 text-center sm:items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-4xl min-h-full overflow-hidden bg-white shadow-xl rounded-2xl">
                                <Dialog.Title className="sr-only">
                                    User Experience Survey
                                </Dialog.Title>
                                {getEnvironment() === "dev" && (
                                    <Alert
                                        className="mx-auto mb-2 "
                                        intent="info"
                                        title="DEV MODE"
                                        description={
                                            <p>
                                                You can disable the pop up
                                                survey by adding{" "}
                                                <code>
                                                    'VITE_ENABLE_UX_SURVEY=false'
                                                </code>{" "}
                                                env variable entry.
                                            </p>
                                        }
                                    />
                                )}
                                <Survey
                                    model={surveyModel}
                                    onComplete={postSurveyData}
                                />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default UXSurvey;
