export const TeamsIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_188_174)">
                <path d="M20.435 13.4778C21.9589 13.4778 23.1945 12.243 23.1945 10.7191C23.1945 9.19514 21.9588 7.95947 20.435 7.95947C18.9115 7.95947 17.6758 9.19514 17.6758 10.7191C17.6758 12.243 18.9115 13.4778 20.435 13.4778Z" fill="#F69126"/>
                <path d="M23.9685 16.1368C23.8456 15.3979 23.2348 14.3639 22.7725 13.9019C22.7117 13.8407 22.4395 13.8265 22.366 13.8721C21.804 14.2178 21.1438 14.4212 20.4352 14.4212C19.7274 14.4212 19.0672 14.2178 18.5051 13.8721C18.4313 13.8265 18.1595 13.8407 18.0986 13.9019C17.9694 14.0311 17.8284 14.2032 17.6909 14.4027C18.0629 15.103 18.3579 15.8469 18.4643 16.4875C18.5707 17.1297 18.5362 17.7376 18.3641 18.2919C18.9752 18.513 19.7081 18.61 20.4352 18.61C22.3315 18.61 24.2721 17.9525 23.9685 16.1368Z" fill="#F69126"/>
                <path d="M11.9293 12.3137C14.399 12.3137 16.4014 10.3114 16.4014 7.8417C16.4014 5.3728 14.399 3.37048 11.9293 3.37048C9.46006 3.37048 7.4585 5.3728 7.4585 7.8417C7.4585 10.3114 9.46001 12.3137 11.9293 12.3137Z" fill="#F69126"/>
                <path d="M15.7156 12.9986C15.6186 12.902 15.1779 12.8773 15.0581 12.9514C14.1465 13.5123 13.0766 13.8407 11.9289 13.8407C10.7821 13.8407 9.71176 13.5123 8.8006 12.9514C8.68084 12.8772 8.24017 12.902 8.14314 12.9986C7.39215 13.7488 6.40239 15.4259 6.20326 16.6215C5.71229 19.5664 8.85798 20.6296 11.9289 20.6296C15.0008 20.6296 18.1464 19.5664 17.6554 16.6215C17.4563 15.4259 16.4665 13.7488 15.7156 12.9986Z" fill="#F69126"/>
                <path d="M3.56475 13.4778C5.08829 13.4778 6.32391 12.243 6.32391 10.7191C6.32391 9.19514 5.08829 7.95947 3.56475 7.95947C2.0408 7.95947 0.805176 9.19514 0.805176 10.7191C0.805176 12.243 2.0408 13.4778 3.56475 13.4778Z" fill="#F69126"/>
                <path d="M5.39505 16.4875C5.50821 15.8147 5.82748 15.0225 6.22774 14.29C6.11618 14.1376 6.00466 14.0048 5.90173 13.9019C5.84083 13.8407 5.56905 13.8265 5.49523 13.8721C4.93315 14.2178 4.27291 14.4212 3.56482 14.4212C2.85663 14.4212 2.19598 14.2178 1.63437 13.8721C1.56087 13.8265 1.28833 13.8407 1.22744 13.9019C0.763991 14.3638 0.15485 15.3979 0.0314755 16.1368C-0.271712 17.9525 1.66854 18.61 3.56482 18.61C4.24155 18.61 4.92494 18.5267 5.50821 18.3362C5.32441 17.7707 5.28551 17.1485 5.39505 16.4875Z" fill="#F69126"/>
            </g>
            <defs>
                <clipPath id="clip0_188_174">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}