import clsx from "clsx";
import type React from "react";
import {
    KineticTopLeftLogo,
    KineticBottomRightLogo,
} from "../Shapes/kinetic-logos";

interface IntroLayoutProps {
    children: React.ReactNode;
    showGears: boolean;
}

const IntroLayout: React.FC<IntroLayoutProps> = ({ children, showGears }) => {
    return (
        <div className={clsx("flex min-h-screen flex-col")}>
            <>
                {showGears && (
                    <>
                        <KineticTopLeftLogo src={"/images/gears.png"} />
                        <KineticBottomRightLogo src={"/images/gears.png"} />
                    </>
                )}
            </>

            <main className="flex-1">{children}</main>
        </div>
    );
};

export default IntroLayout;
