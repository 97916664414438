import React from "react";
import { cx } from "class-variance-authority";

interface CheckBoxProps extends React.ComponentProps<"input"> {}

const CheckBox = React.forwardRef<HTMLInputElement, CheckBoxProps>(
    ({ className, ...props }, ref) => {
        return (
            <input
                ref={ref}
                type="checkbox"
                className={cx(
                    "h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500",
                    className
                )}
                {...props}
            />
        );
    }
);

export default CheckBox;
