import { useMemo } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";
import { type ColumnDef } from "@tanstack/react-table";

import { type TeamsViewsetResponse } from "@src/models/types";
import Button from "@src/components/Button";
import Table from "@src/components/tables";
import "../../components/Loaders/loader.css";

const TeamSubmissionsTable = ({ data }) => {
    //query to get team submissions

    const teamsTableColumns = useMemo<Array<ColumnDef<TeamsViewsetResponse>>>(
        () => [
            {
                header: "Name",
                accessorKey: "team_name",
            },
            {
                header: "Members",
                accessorKey: "member_count",
                disableGlobalFilter: true,
            },
            {
                header: "Current Session",
                accessorKey: "current_session_group",
            },
            {
                header: "Submissions",
                accessorKey: "submission_count",

                disableGlobalFilter: true,
            },
            {
                header: "Action",
                accessorKey: "id",
                cell: ({ getValue, row }) => {
                    const teamId = getValue() as number;
                    return (
                        <Button
                            variant="outlined"
                            intent="white"
                            type="button"
                            size="lg"
                        >
                            View
                            {/* <span className="sr-only">
                                for {row.original.team_name}
                            </span> */}
                        </Button>
                        // <Link to={`/school/teams/${teamId}/submissions`}>

                        // </Link>
                    );
                },
                disableGlobalFilter: true,
            },
        ],
        []
    );

    if (!data) {
        return (
            <div className="flex w-full items-center justify-center p-4">
                <span className="loader "></span>
            </div>
        );
    }

    return (
        <Table
            columns={teamsTableColumns}
            data={data}
            title="Team Submissions"
        />
    );
};

export default TeamSubmissionsTable;
